import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import { Button, Input, Select, Label, PageHeader } from '../'
import { v4 as uuidv4 } from 'uuid'
import { get } from 'lodash'
import { Colors } from '../../Utils/theme'
import PollQuestionForm from './PollQuestionForm'
import PollSettingsForm from './PollSettingsForm'
import PollTimingForm from './PollTimingForm'
import PollTabs from './PollTabs'
import AddIcon from '../../Assets/Icons/add-dark.svg'

const useStyles = makeStyles((theme) =>({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    width: '100%'
  },
  mainSection: {
    flex: 1,
    position: 'relative'
  },
  mainSectionInner: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflowY: 'auto'
  },
  container: {
    padding: '2.5rem 2rem 4rem'
  },
  contentContainer: {
    width: '100%',
    margin: '0 auto',
    maxWidth: '51rem'
  },
  title: {
    fontSize: '1.875rem',
    fontWeight: 400,
    margin: 0,
    padding: '0 0 .75rem',
    flex: 1
  },
  introContainer: {
    position: 'relative',
    marginBottom: '2rem'
  },
  introHeader: {
    flexDirection: 'row',
    paddingBottom: '1rem'
  },
  actionButtons: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: '1rem',
    display: 'flex',
    flexDirection: 'row'
  },
  footerContainer: {
    paddingTop: '2rem',
    backgroundColor: Colors.lightGrey,
  },
  footerInner: {
    width: '100%',
    margin: '0 auto',
    maxWidth: '51rem',
    display: 'flex',
    flexDirection: 'row'
  },
  saveButtonContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  previousButtonContainer: {
    marginRight: '1.25rem',
  },
  previousButton: {
    cursor: 'pointer',
    height: '3.125rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 1rem',
    textDecoration: 'none',
    color: Colors.black,
    fontWeight: 700,
    borderRadius: '.3125rem',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,.1)'
    }
  },
  flexSpacer: {
    flex: 1
  },
  pollFormContainer: {
    maxWidth: '51rem',
    margin: '0 auto'
  }
}))

function PollForm (props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const [tab, setTab] = useState(1)

  const handleNext = () => {
    if (tab < 3) {
      setTab(tab + 1)
    } else {
      // Save
      props.save()
    }
  }
  const handlePrevious = () => {
    if (tab > 1) {
      setTab(tab - 1)
    }
  }

  const { langTab, setLangTab } = props

  const [locked, setLocked] = useState(false)
  const toggleLock = () => setLocked(!locked)

  const addQuestion = () => {
    props.setQuestions([...props.questions, {
      id: uuidv4(),
      type: 'select',
      titleFi: '',
      titleEn: '',
      contentFi: '',
      contentEn: '',
      answersFi: ['', ''],
      answersEn: ['', '']
    }])
  }

  const addFollowUpQuestion = (parentQuestionId) => {
    const newQuestions = []
    for (const q of props.questions) {
      newQuestions.push(q)
      if (q.id === parentQuestionId) {
        newQuestions.push({
          id: uuidv4(),
          parentId: parentQuestionId,
          parentAnswerIndex: null,
          type: 'select',
          titleFi: '',
          titleEn: '',
          contentFi: '',
          contentEn: '',
          answersFi: ['', ''],
          answersEn: ['', '']
        })
      }
    }
    props.setQuestions(newQuestions)
  }

  const toggleIsOptional = (index) => {
    const newQuestions = [...props.questions]
    newQuestions[index].isOptional = !newQuestions[index].isOptional
    props.setQuestions(newQuestions)
  }

  const setParentAnswerIndex = (index, val) => {
    const newQuestions = [...props.questions]
    newQuestions[index].parentAnswerIndex = val
    props.setQuestions(newQuestions)
  }

  const validationMessage = (field) => {
    if (props.submitted && !field) {
      return {
        error: true,
        helperText: t('required_field')
      }
    }
    return {}
  }

  const setQuestionTitle = (questionIndex, lang, value) => {
    props.setQuestions(props.questions.map((question, index) => {
      if (index === questionIndex) {
        question[(lang === 'en' ? 'titleEn' : 'titleFi')] = value
      }
      return question
    }))
  }

  const setQuestionContent = (questionIndex, lang, value) => {
    props.setQuestions(props.questions.map((question, index) => {
      if (index === questionIndex) {
        question[(lang === 'en' ? 'contentEn' : 'contentFi')] = value
      }
      return question
    }))
  }

  const setQuestionType = (questionIndex, type) => {
    let newQuestions = props.questions.map((question, index) => {
      if (index === questionIndex) {
        question.type = type

        if (type !== 'text') {
          question.isOptional = false
          if (type === 'ranked_multiselect') {
            // Add empty answers fields if not set
            if (!question.answersFi || question.answersFi.length < 3) {
              question.answersFi = ['', '', '']
            }
            if (!question.answersEn || question.answersEn.length < 3) {
              question.answersEn = ['', '', '']
            }
          }

          // Add empty answers fields if not set
          if (!question.answersFi || !question.answersFi.length) {
            question.answersFi = ['', '']
          }
          if (!question.answersEn || !question.answersEn.length) {
            question.answersEn = ['', '']
          }
        } else {
          // Set answers to empty arrays for type = text
          question.answersFi = []
          question.answersEn = []
        }
      }
      return question
    })

    const isParentQuestion = !props.questions[questionIndex].parentId
    const isSelectQuestion = type === ['select', 'multiselect'].includes(type)
    newQuestions = newQuestions.filter(q => {
      return (!isSelectQuestion && isParentQuestion && props.questions[questionIndex].id !== q.parentId)
    })

    props.setQuestions(newQuestions)
  }

  const deleteQuestion = (questionIndex) => {
    const confirm = window.confirm(t('confirm_delete_question'))
    if (confirm) {
      let parentId = 'none'
      if (!props.questions[questionIndex].parentId) {
        parentId = props.questions[questionIndex].id
      }
      props.setQuestions(props.questions.filter((q, index) => index !== questionIndex && q.parentId !== parentId))
    }
  }

  const setQuestionAnswer = (questionIndex, answerIndex, lang, value) => {
    props.setQuestions(props.questions.map((question, qIndex) => {
      if (qIndex === questionIndex) {
        const fieldName = lang === 'en' ? 'answersEn' : 'answersFi'

        question[fieldName] = question[fieldName].map((answer, aIndex) => {
          if (aIndex === answerIndex) {
            return value
          }
          return answer
        })
      }
      return question
    }))
  }

  const deleteAnswer = (questionIndex, answerIndex) => {
    const confirm = window.confirm(t('confirm_delete_answer'))
    if (confirm) {
      props.setQuestions(props.questions.map((question, qIndex) => {
        if (qIndex === questionIndex) {
          return {
            ...question,
            answersFi: question.answersFi.filter((_, aIndex) => aIndex !== answerIndex),
            answersEn: question.answersEn.filter((_, aIndex) => aIndex !== answerIndex)
          }
        }
        return question
      }))
    }
  }

  const addAnswer = (questionIndex) => {
    props.setQuestions(props.questions.map((question, index) => {
      if (index === questionIndex) {
        return {
          ...question,
          answersFi: [...question.answersFi, ''],
          answersEn: [...question.answersEn, '']
        }
      }
      return question
    }))
  }

  const moveQuestion = (originalIndex, direction) => {
    const newQuestions = [...props.questions];

    let parentQuestions = newQuestions.filter(item => !item.parentId)
    const questionId = newQuestions[originalIndex].id
    let index = 0
    for (let i = 0; i < parentQuestions.length; i++) {
      if (parentQuestions[i].id === questionId) {
        index = i
      }
    }

    if (direction === 'up') {
      if (index === 0) return
      const temp = parentQuestions[index - 1]
      parentQuestions[index - 1] = parentQuestions[index]
      parentQuestions[index] = temp
    } else if (direction === 'down') {
      if (index === parentQuestions.length - 1) return
      const temp = parentQuestions[index + 1]
      parentQuestions[index + 1] = parentQuestions[index]
      parentQuestions[index] = temp
    }

    const sortedNewQuestions = []
    for (const parentQuestion of parentQuestions) {
      sortedNewQuestions.push(parentQuestion)
      const subQuestions = newQuestions.filter(item => item.parentId === parentQuestion.id)
      for (const subQuestion of subQuestions) {
        sortedNewQuestions.push(subQuestion)
      }
    }

    props.setQuestions(sortedNewQuestions)
  }

  const renderTabs = () => {
    return (
      <PollTabs
        tabs={[
          {
            name: t('basic_info'),
            value: 1
          },
          {
            name: t('settings'),
            value: 2
          },
          {
            name: t('timing'),
            value: 3
          }
        ]}
        setTab={setTab}
        currentTab={tab}
      />
    )
  }

  const renderIntroContent = () => {
    if (langTab === 'en') {
      return (
        <>
          <Label>{t('title')}</Label>
          <Input
            value={props.titleEn}
            onChange={props.setTitleEn}
            {...validationMessage(props.titleEn)}
          />
          <Label>{t('content')}</Label>
          <Input
            value={props.contentEn}
            onChange={props.setContentEn}
            multiline
            {...validationMessage(props.contentEn)}
          />
        </>
      )
    }
    return (
      <>
        <Label>{t('title')}</Label>
        <Input
          value={props.titleFi}
          onChange={props.setTitleFi}
          {...validationMessage(props.titleFi)}
        />
        <Label>{t('content')}</Label>
        <Input
          value={props.contentFi}
          onChange={props.setContentFi}
          multiline
          {...validationMessage(props.contentFi)}
        />
      </>
    )
  }

  const renderQuestions= () => {
    let lastParentQuestionIndex = 0
    for (let i = 0; i < props.questions.length; i++) {
      if (!props.questions[i].parentId) {
        lastParentQuestionIndex = i
      }
    }

    let parentQuestionIndex = -1

    return (
      <div>
        {props.questions.map((question, index) => {
          let parentQuestion = null
          let reservedParentAnswerIndexes = null

          if (!question.parentId) {
            parentQuestionIndex++
            reservedParentAnswerIndexes = props.questions.filter(q => q.parentId === question.id).map(q => q.parentAnswerIndex)
          } else {
            // Find parent question
            parentQuestion = props.questions.find(item => item.id === question.parentId)
            reservedParentAnswerIndexes = props.questions.filter(q => q.parentId === parentQuestion.id).map(q => q.parentAnswerIndex)
          }


          return (
            <PollQuestionForm
              langTab={langTab}
              setLangTab={setLangTab}
              parentQuestion={parentQuestion}
              reservedParentAnswerIndexes={reservedParentAnswerIndexes}
              question={question}
              index={parentQuestionIndex}
              isLastParentQuestion={index === lastParentQuestionIndex}
              moveQuestionUp={() => moveQuestion(index, 'up')}
              moveQuestionDown={() => moveQuestion(index, 'down')}
              deleteQuestion={() => deleteQuestion(index)}
              setTitle={(lang, title) => setQuestionTitle(index, lang, title)}
              setContent={(lang, content) => setQuestionContent(index, lang, content)}
              setType={(type) => setQuestionType(index, type)}
              setAnswer={(answerIndex, lang, value) => setQuestionAnswer(index, answerIndex, lang, value)}
              addAnswer={() => addAnswer(index)}
              toggleIsOptional={() => toggleIsOptional(index)}
              setParentAnswerIndex={(val) => setParentAnswerIndex(index, val)}
              deleteAnswer={(answerIndex) => deleteAnswer(index, answerIndex)}
              submitted={props.submitted}
              locked={locked}
              toggleLock={toggleLock}
              addFollowUpQuestion={() => addFollowUpQuestion(question.id)}
            />
          )
        })}
        <Button
          rightIcon={AddIcon}
          text={t('add_question')}
          onClick={addQuestion}
          outlined
        />
      </div>
    )
  }

  const getLangOptions = () => {
    return ['fi', 'en'].map(locale => {
      return {
        label: locale.toUpperCase(),
        value: locale
      }
    })
  }

  const isNextEnabled = () => {
    if (tab < 3) return true
    if (props.draft) return true

    // Validate poll
    const requiredFields = [
      'titleFi',
      'contentFi',
      'titleEn',
      'contentEn',
    ]

    for (const field of requiredFields) {
      if (!get(props, field)) return false
    }

    // Validate questions
    for (const question of props.questions) {
      for (const field of requiredFields) {
        if (!get(question, field)) return false
      }

      if (question.answersFi) {
        for (const ans of question.answersFi) {
          if (!ans) return false
        }
      }

      if (question.answersEn) {
        for (const ans of question.answersEn) {
          if (!ans) return false
        }
      }
    }
    if (!props.selectedMainEntityTypes || !props.selectedMainEntityTypes.length) return false

    return true
  }

  const renderFooter = () => {
    const nextButtonText = tab < 3 ? t('next') : t('save')
    return (
      <div className={classes.footerContainer}>
        <div className={classes.footerInner}>
          <div>
            <Select
              options={getLangOptions()}
              value={langTab}
              onChange={setLangTab}
              style={{
                backgroundColor: Colors.white,
                maxWidth: '4.25725rem'
              }}
            />
          </div>

          <div className={classes.flexSpacer} />

          {tab > 1 ? (
            <div className={classes.previousButtonContainer}>
              <div onClick={handlePrevious} className={classes.previousButton}>{t('previous')}</div>
            </div>
          ) : null}
          <div className={classes.saveButtonContainer}>
            <Button
              text={nextButtonText}
              onClick={handleNext}
              disabled={!isNextEnabled()}
              tall
            />
          </div>
        </div>
      </div>
    )
  }

  const renderSettings = () => {
    const mainEntityTypeOptions = props.mainEntityTypes.map(mainEntityType => {
      return {
        name: mainEntityType.name,
        value: mainEntityType.id
      }
    })

    const mainEntityOptions = props.mainEntities.filter(mainEntity => {
      return props.selectedMainEntityTypes.includes(mainEntity.mainEntityTypeId)
    }).map(mainEntity => {
      return {
        name: mainEntity.nameFi || '',
        value: mainEntity.id
      }
    })


    return (
      <PollSettingsForm
        mainEntityTypeOptions={mainEntityTypeOptions}
        selectedMainEntityTypes={props.selectedMainEntityTypes}
        setSelectedMainEntityTypes={props.setSelectedMainEntityTypes}
        mainEntityOptions={mainEntityOptions}
        selectedMainEntities={props.selectedMainEntities}
        setSelectedMainEntities={props.setSelectedMainEntities}
      />
    )
  }

  const renderTiming = () => {
    return (
      <PollTimingForm
        validFrom={props.validFrom}
        setValidFrom={props.setValidFrom}
        validUntil={props.validUntil}
        setValidUntil={props.setValidUntil}
        draft={props.draft}
        setDraft={props.setDraft}
      />
    )
  }

  const renderContent = () => {
    if (tab === 1) {
      return (
        <div>
          {renderIntroContent()}
          {renderQuestions()}
        </div>
      )
    }
    if (tab === 2) {
      return (
        <div>
          {renderSettings()}
        </div>
      )
    }
    if (tab === 3) {
      return (
        <div>
          {renderTiming()}
        </div>
      )
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.mainSection}>
        <div className={classes.mainSectionInner}>
          <PageHeader
            title={props.mode === 'create' ? t('add_new_poll') : t('edit_poll')}
            editMode={props.mode !== 'create'}
            onEditClick={props.onEditClick}
            compact
          />
          <div className={classes.container}>
            <div className={classes.pollFormContainer}>
              {renderTabs()}
              {renderContent()}
            </div>
          </div>

        </div>
      </div>
      {renderFooter()}
    </div>
  )
}

export default PollForm
