import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import { useTranslation } from 'react-i18next'
import { TableHeader, DataMessage } from '..'
import UserTableRow from './UserTableRow'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) =>({
  root: {
  },
  spacer: {
    height: '1rem'
  }
}))

function UserTable (props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const renderRows = () => {
    if (props.users) {
      return props.users.map((user) => (
        <React.Fragment key={user.id}>
          <tr className={classes.spacer} />
          <UserTableRow user={user} isMe={user.id === props.myId} />
        </React.Fragment>
      ))
    }
    return null
  }

  return (
    <>
      <Table classes={{ root: classes.root }}>
        <TableHeader columnNames={[t('name'), t('status'), t('email'), t('role'), t('actions')]} />
        <TableBody>{renderRows()}</TableBody>
      </Table>
      <DataMessage data={props.users} message={t('no_users')} />
    </>
  )

}

export default UserTable
