import React from 'react'
import { useDropzone } from 'react-dropzone'
import makeStyles from '@mui/styles/makeStyles'
import { useStore } from '../../Models/RootStore'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-block'
  },
  childContainer: {
    display: 'inline-block',
    padding: '0 .125rem'
  }
}))

export default function ImageUploadWrapper(props) {
  const classes = useStyles()

  const { fileStore } = useStore()

  const handleImageUpload = async (files) => {
    if (files && files.length) {
      const file = files[0]
      const url = await fileStore.uploadPhoto(file)
      if (url) {
        props.onAddImage(url)
      }
    }
  }

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: 'image/*',
    multiple: false,
    noDrag: true,
    noClick: true,
    noKeyboard: true,
    onDrop: handleImageUpload
  })

  return (
    <button
      {
        ...getRootProps({
          style: {
            display: 'inline-block'
          }
        })
      }
      onClick={open}
      className={props.className}
    >
      <input {...getInputProps()} />
      <div className={classes.childContainer}>
        {props.children}
      </div>
    </button>
  )
}
