import React from 'react'
import { Colors } from '../../Utils/theme'
import makeStyles from '@mui/styles/makeStyles'
import { Tooltip } from '@mui/material'

const useStyles = makeStyles((theme) => ({
  toolButton: {
    backgroundColor: Colors.black80,
    height: '3.125rem',
    width: '3.125rem',
    borderRadius: '0.3125rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 0,
    border: 0,
    cursor: 'pointer',
    transition: 'backgroundColor .2s ease',
    '&:hover': {
      backgroundColor: Colors.black,
    }
  },
  toolButtonActive: {
    backgroundColor: Colors.black
  },
  toolIcon: {
    mineight: '1.25rem',
    minWidth: '1.25rem',
    maxHeight: '1.25rem',
    maxWidth: '1.25rem'
  }
}))

export default function MapToolButton(props) {
  const classes = useStyles()
  const { onClick, active, disabled, icon, iconComponent, title } = props

  const renderIcon = () => {
    if (iconComponent) return iconComponent
    return <img src={icon} className={classes.toolIcon} />
  }

  return (
    <Tooltip title={title}>
      <div
        className={[classes.toolButton, active ? classes.toolButtonActive : ''].join(' ')}
        onClick={disabled ? () => null : onClick}
      >
        {renderIcon()}
      </div>
    </Tooltip>
  )
}
