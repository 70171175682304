import {
  SPACER,
  STREET_ADDRESS,
  CITY,
  POSTAL_CODE,
  DESCRIPTION,
  PARKING_DESCRIPTION_TITLE,
  OPEN_HOURS_TITLE,
  OPEN_HOURS,
  PARKING_AREA_ADDRESS_TITLE
} from '../elements.template'

const LAYOUT = {
  type: 'subentity_parking_area',
  layout: [
    [
      // Start left column
      OPEN_HOURS_TITLE,
      {
        type: 'custom_field',
        field: 'open_hours_type',
        customType: 'checkbox'
      },
      {
        ...OPEN_HOURS,
        hideLabel: false,
        hiddenCheck: (allFields, customFieldValues) => {
          const field = (allFields || []).find(f => f.fieldKey === 'open_hours_type')
          if (field) {
            const val = (customFieldValues || []).find(f => f.id === field.id)
            if (val) {
              // Check if we should hide
              if (val?.value === 'always_open') return true
            }
          }
          return false
        }
      },
      PARKING_AREA_ADDRESS_TITLE,
      {
        type: 'container',
        className: 'customRowFixed',
        items: [
          STREET_ADDRESS,
          SPACER,
          CITY,
          SPACER,
          POSTAL_CODE
        ]
      },
      PARKING_DESCRIPTION_TITLE,
      DESCRIPTION
      // End left column
    ],
    [
      // Start of right column
      // End of right column
    ]
  ]
}

export default LAYOUT
