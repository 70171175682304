import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'
import get from 'lodash/get'
import { useParams } from 'react-router-dom'
import { useStore } from '../../../Models/RootStore'
import { PageHeader, Button, Input } from '../../../Components'
import { Checkbox } from '../../../Components'
import { ENTITY_GEO_TYPES } from '../../../Constants'
import { Colors } from '../../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  contentContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 0
  },
  content: {
    padding: '2.5rem'
  },
  geoTypesContainer: {
    paddingBottom: '2rem'
  },
  validationError: {
    color: Colors.red
  },
  gridContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '1rem 0',
    width: '100%'
  },
  gridColumn: {
    flex: 1,
    paddingRight: '1rem'
  }
}))

const AdminMainEntityType = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { adminStore }: any = useStore()
  const { mainEntityTypeId }: any = useParams()

  useEffect(() => {
    adminStore.getMainEntityType(Number(mainEntityTypeId))
    adminStore.getMainEntityFields()
    adminStore.getMainEntityTypesMainEntityFields()
    adminStore.getSubEntityTypes()
    adminStore.getMainEntityTypeSubEntityTypes(Number(mainEntityTypeId))
  }, [])

  useEffect(() => {
    if (adminStore.mainEntityType && adminStore.mainEntityType.id === Number(mainEntityTypeId)) {
      setType(get(adminStore.mainEntityType, 'type', null))
      setNameFi(get(adminStore.mainEntityType, 'nameFi', null))
      setNameEn(get(adminStore.mainEntityType, 'nameEn', null))
      setGeoTypes(get(adminStore.mainEntityType, 'geoTypes') || [])
    }
  }, [adminStore.mainEntityType])

  useEffect(() => {
    if (adminStore.mainEntityTypesMainEntityFields) {
      setFields(
        adminStore.mainEntityTypesMainEntityFields.filter(item => {
          return item.mainEntityTypeId === Number(mainEntityTypeId)
        }).map(({ mainEntityFieldId }) => mainEntityFieldId)
      )
    }
  }, [adminStore.mainEntityTypesMainEntityFields])

  useEffect(() => {
    if (adminStore.mainEntityTypeSubEntityTypes) {
      setSubEntityTypes(
        adminStore.mainEntityTypeSubEntityTypes.filter(ms => {
          return ms.mainEntityTypeId === Number(mainEntityTypeId)
        }).map(({ subEntityTypeId }) => subEntityTypeId)
      )
    }
  }, [adminStore.mainEntityTypeSubEntityTypes])

  const [type, setType] = useState(null)
  const [nameFi, setNameFi] = useState(null)
  const [nameEn, setNameEn] = useState(null)

  const [geoTypes, setGeoTypes] = useState([])

  const [fields, setFields] = useState([])
  const [subEntityTypes, setSubEntityTypes] = useState([])

  const toggleGeoType = (type) => {
    const newGeoTypes = geoTypes.filter(gt => gt !== type)
    if (newGeoTypes.length === geoTypes.length) newGeoTypes.push(type)
    setGeoTypes(newGeoTypes)
  }

  const handleSave = () => {
    const mainEntityTypeParams: any = {
      type,
      name: nameFi, // Fallback for legacy field
      nameFi,
      nameEn,
      geoTypes: geoTypes.length ? geoTypes : null
    }

    adminStore.editMainEntityType(
      Number(mainEntityTypeId),
      mainEntityTypeParams
    )

    // Detect field changes
    const addFields = []
    const deleteFields = []

    const currentFields = adminStore.mainEntityTypesMainEntityFields.filter(f => {
      return f.mainEntityTypeId === Number(mainEntityTypeId)
    }).map(({ mainEntityFieldId }) => mainEntityFieldId)

    for (const currentField of currentFields) {
      if (!fields.includes(currentField)) {
        deleteFields.push(currentField)
      }
    }

    for (const field of fields) {
      if (!currentFields.includes(field)) {
        addFields.push(field)
      }
    }

    if (deleteFields.length) adminStore.deleteMainEntityTypeFields(mainEntityTypeId, deleteFields)
    if (addFields.length) adminStore.addMainEntityTypeFields(mainEntityTypeId, addFields)

    // Detect sub entity type changes
    const addSubEntityTypes = []
    const deleteSubEntityTypes = []

    const currentSubEntityTypes = adminStore.mainEntityTypeSubEntityTypes.filter(f => {
      return f.mainEntityTypeId === Number(mainEntityTypeId)
    }).map(({ subEntityTypeId }) => subEntityTypeId)

    for (const currentSubEntityType of currentSubEntityTypes) {
      if (!subEntityTypes.includes(currentSubEntityType)) {
        deleteSubEntityTypes.push(currentSubEntityType)
      }
    }

    for (const subEntityType of subEntityTypes) {
      if (!currentSubEntityTypes.includes(subEntityType)) {
        addSubEntityTypes.push(subEntityType)
      }
    }

    if (deleteSubEntityTypes.length) adminStore.deleteMainEntityTypeSubEntityTypes(Number(mainEntityTypeId), deleteSubEntityTypes)
    if (addSubEntityTypes.length) adminStore.addMainEntityTypeSubEntityTypes(Number(mainEntityTypeId), addSubEntityTypes)
  }

  const toggleField = (id) => {
    const newFields = fields.filter(fieldId => fieldId !== id)
    if (newFields.length === fields.length) {
      newFields.push(id)
    }
    setFields(newFields)
  }

  const toggleSubEntityType = (id) => {
    const newSubEntityTypes = subEntityTypes.filter(subEntityTypeId => subEntityTypeId !== id)
    if (newSubEntityTypes.length === subEntityTypes.length) {
      newSubEntityTypes.push(id)
    }
    setSubEntityTypes(newSubEntityTypes)
  }

  const renderLinkedCustomFields = () => {
    return adminStore.mainEntityFields.map(f => {
      return (
        <div key={f.id}>
          <Checkbox
            checked={fields.includes(f.id)}
            onChange={() => {
              toggleField(f.id)
            }}
            label={`${f.id} ${f.labelFi} (${f.fieldKey}, ${f.fieldType})`}
          />
        </div>
      )
    })
  }

  const renderLinkedSubEntityTypes = () => {
    return adminStore.subEntityTypes.map(s => {
      return (
        <div key={s.id}>
          <Checkbox
            checked={subEntityTypes.includes(s.id)}
            onChange={() => {
              toggleSubEntityType(s.id)
            }}
            label={`${s.id} ${s.nameFi} (${s.type}, ${s.geoType})`}
          />
        </div>
      )
    })
  }

  const renderGeoTypeSelectHint = () => {
    if (
      // Cannot remove all geo types if atleast one geo type is already set
      (get(adminStore.mainEntityType, 'geoTypes') || []).length &&
      !geoTypes.length
    ) {
      return (
        <p className={classes.validationError}>
          {t('select_min_1_geo_type')}
        </p>
      )
    }
    return null
  }

  const renderGeoTypes = () => {
    return (
      <div className={classes.geoTypesContainer}>
        <h3>{t('geo_types')}</h3>
        {renderGeoTypeSelectHint()}
        {ENTITY_GEO_TYPES.map(type => {
          const isChecked = geoTypes.includes(type)
          return (
            <div>
              <Checkbox
                key={type}
                checked={isChecked}
                onChange={() => toggleGeoType(type)}
                label={t(type)}
              />
            </div>
          )
        })}
      </div>
    )
  }

  const isSaveDisabled = () => {
    if (
      (
        // Cannot remove all geo types if atleast one geo type is already set
        (get(adminStore.mainEntityType, 'geoTypes') || []).length &&
        !geoTypes.length
      ) ||
      !nameFi ||
      !nameEn ||
      !type
    ) {
      return true
    }
    return false
  }

  return (
    <div className={classes.root}>
      <PageHeader
        title={t('edit_main_entity_type')}
      />
      <div className={classes.contentContainer}>
        <div className={classes.content}>
          <Input label={t('type')} value={type} onChange={setType} />
          <Input label={t('name_fi')} value={nameFi} onChange={setNameFi} />
          <Input label={t('name_en')} value={nameEn} onChange={setNameEn} />
          {renderGeoTypes()}
          <Button
            text={t('save')}
            onClick={handleSave}
            disabled={isSaveDisabled()}
          />
          <hr />
          <div className={classes.gridContainer}>
            <div className={classes.gridColumn}>
              <h2>Custom fields</h2>
              {renderLinkedCustomFields()}
            </div>
            <div className={classes.gridColumn}>
              <h2>Sub entity types</h2>
              {renderLinkedSubEntityTypes()}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default observer(AdminMainEntityType)
