import {
  SPACER,
  DESCRIPTION,
  PROPERTIES_TITLE,
  WC_GENDER_TYPE,
  ACCESSIBILITY_TITLE,
  ACCESSIBLE_ENTRANCE,
  AUTOMATIC_DOOR,
  DOORWAY_WIDTH,
  DOORSTEP,
  DOORSTEP_HEIGHT,
  DESCRIPTION_TITLE
} from '../elements.template'

const LAYOUT = {
  type: 'subentity_wc',
  layout: [
    [
      // Start left column
      PROPERTIES_TITLE,
      WC_GENDER_TYPE,
      { ...ACCESSIBILITY_TITLE, info: 'entrance_accessibility_info' },
      {
        type: 'container',
        className: 'customRowFixed',
        items: [
          ACCESSIBLE_ENTRANCE,
          SPACER,
          SPACER,
          AUTOMATIC_DOOR,
          SPACER,
          SPACER,
          DOORWAY_WIDTH
        ]
      },
      {
        type: 'container',
        className: 'customRowFixed',
        items: [
          DOORSTEP,
          SPACER,
          SPACER,
          DOORSTEP_HEIGHT
        ]
      },
      DESCRIPTION_TITLE,
      DESCRIPTION
      // End left column
    ],
    [
      // Start of right column
      // End of right column
    ]
  ]
}

export default LAYOUT
