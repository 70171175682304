import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'
import get from 'lodash/get'
import { useParams } from 'react-router-dom'
import { useStore } from '../../../Models/RootStore'
import { PageHeader, Button, Input } from '../../../Components'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  contentContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 0
  },
  content: {
    padding: '2.5rem'
  }
}))

const AdminSubEntityField = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { adminStore }: any = useStore()
  const { subEntityFieldId }: any = useParams()

  useEffect(() => {
    adminStore.getSubEntityField(Number(subEntityFieldId))
  }, [])

  useEffect(() => {
    if (adminStore.subEntityField && adminStore.subEntityField.id === Number(subEntityFieldId)) {
      setFieldType(get(adminStore.subEntityField, 'fieldType', null))
      setFieldKey(get(adminStore.subEntityField, 'fieldKey', null))
      setLabelFi(get(adminStore.subEntityField, 'labelFi', null))
      setLabelEn(get(adminStore.subEntityField, 'labelEn', null))
      const optionsObj = get(adminStore.subEntityField, 'options', null)
      setOptions(optionsObj ? JSON.stringify(optionsObj) : null)
      const fieldDefaultValueObj = get(adminStore.subEntityField, 'fieldDefaultValue', null)
      setFieldDefaultValue(fieldDefaultValueObj ? JSON.stringify(fieldDefaultValueObj) : null)
    }
  }, [adminStore.subEntityField])

  const [fieldType, setFieldType] = useState(null)
  const [fieldKey, setFieldKey] = useState(null)
  const [fieldDefaultValue, setFieldDefaultValue] = useState(null)

  const [labelFi, setLabelFi] = useState(null)
  const [labelEn, setLabelEn] = useState(null)
  const [options, setOptions] = useState(null)

  const handleDelete = () => {
    if ((window.prompt('WARNING: This will delete alot of data. Are you absolutely sure what you are about to do? Type YES if you are sure.') || '').toLowerCase() === 'yes') {
      adminStore.deleteSubEntityField(Number(subEntityFieldId))
    }
  }

  const handleSave = () => {
    const optionsJson = (options && options.trim()) ? JSON.parse(options) : null
    const fieldDefaultValueJson = (fieldDefaultValue && fieldDefaultValue.trim()) ? JSON.parse(fieldDefaultValue) : null

    adminStore.editSubEntityField(
      Number(subEntityFieldId),
      {
        fieldType,
        fieldKey,
        labelFi,
        labelEn,
        options: optionsJson,
        fieldDefaultValue: fieldDefaultValueJson
      }
    )
  }

  return (
    <div className={classes.root}>
      <PageHeader
        title={t('edit_sub_entity_field')}
      />
      <div className={classes.contentContainer}>
        <div className={classes.content}>

          <Input label='fieldType' value={fieldType} onChange={setFieldType} />
          <Input label='fieldKey' value={fieldKey} onChange={setFieldKey} />
          <Input label='labelFi' value={labelFi} onChange={setLabelFi} />
          <Input label='labelEn' value={labelEn} onChange={setLabelEn} />
          <Input label='options' value={options} onChange={setOptions} multiline />
          <Input label='fieldDefaultValue' value={fieldDefaultValue} onChange={setFieldDefaultValue} multiline />

          <Button
            text={t('save')}
            onClick={handleSave}
          />
          <div style={{ height: '10rem' }} />
          <Button
            text={t('delete')}
            onClick={handleDelete}
            outlined
          />
        </div>
      </div>
    </div>
  )
}

export default observer(AdminSubEntityField)
