import {
  SPACER,
  DESCRIPTION,
  OPEN_HOURS_TITLE,
  OPEN_HOURS,
  STREET_ADDRESS,
  CITY,
  POSTAL_CODE,
  PROPERTIES_TITLE,
  INDOOR_SPORT_FACILITY_DESCRIPTION_TITLE,
  INDOOR_SPORT_FACILITY_ADDRESS_TITLE,
  INDOOR_SPORTS_OPTIONS_TITLE,
  SERVICE_PHONES_AND_URLS_TITLE,
  SERVICE_PHONES,
  SERVICE_URLS,
  INDOOR_SPORTS,
  INDOOR_SPORT_FACILITY_PROPERTIES
} from '../elements.template'

const LAYOUT = {
  type: 'indoor_sport_facility',
  layout: [
    [
      // Start left column
      OPEN_HOURS_TITLE,
      OPEN_HOURS,
      PROPERTIES_TITLE,
      { ...INDOOR_SPORT_FACILITY_PROPERTIES, hideLabel: true },
      INDOOR_SPORTS_OPTIONS_TITLE,
      { ...INDOOR_SPORTS, hideLabel: true },
      SERVICE_PHONES_AND_URLS_TITLE,
      SERVICE_PHONES,
      SERVICE_URLS,
      INDOOR_SPORT_FACILITY_DESCRIPTION_TITLE,
      DESCRIPTION,
      INDOOR_SPORT_FACILITY_ADDRESS_TITLE,
      {
        type: 'container',
        className: 'customRowFixed',
        style: { paddingBottom: '10rem' },
        items: [
          STREET_ADDRESS,
          SPACER,
          CITY,
          SPACER,
          POSTAL_CODE
        ]
      },
      // End left column
    ],
    [
      // Start of right column
      // End of right column
    ]
  ]
}

export default LAYOUT
