import {
  SALES_SEASON_START_DATE,
  SALES_SEASON_END_DATE,
  DASH_SPACER,
  EXCEPTION_NOTIFICATION,
  CONTACT_INFORMATION_TITLE,
  STREET_ADDRESS,
  SPACER,
  CITY,
  POSTAL_CODE,
  EXCEPTION_NOTIFICATIONS_TITLE,
  OPEN_HOURS,
  EXCEPTION_SCHEDULES,
  SALES_LOCATION_BY_RESERVATION,
  BOOKING_LINK,
  FLEA_MARKET_PLACES,
  WWW,
  FACEBOOK_URL,
  OPEN_HOURS_AND_PRICE_INFO_TITLE,
  SALES_LOCATIONS_TITLE,
  PRICE,
  DESCRIPTION_TITLE,
  DESCRIPTION
 } from '../elements.template'

const LAYOUT = {
  type: 'market',
  layout: [
    [
      // Start left column
      DESCRIPTION_TITLE,
      DESCRIPTION,
      OPEN_HOURS_AND_PRICE_INFO_TITLE,
      { ...OPEN_HOURS, hideLabel: true },
      {
        type: 'container',
        className: 'customColumn',
        style: {},
        items: [
          {
            type: 'container',
            items: [{ type: 'title', name: 'sales_season' }]
          },
          {
            type: 'container',
            className: 'customInnerRow',
            items: [
              SALES_SEASON_START_DATE,
              DASH_SPACER,
              SALES_SEASON_END_DATE
            ]
          }
        ]
      },
      { ...EXCEPTION_SCHEDULES, label: 'exceptions_in_schedules' },
      PRICE,
      SALES_LOCATIONS_TITLE,
      SALES_LOCATION_BY_RESERVATION,
      { ...BOOKING_LINK, style: { flex: 1, maxWidth: '38rem' } },
      FLEA_MARKET_PLACES
      // End left column
    ],
    [
      // Start right column
      EXCEPTION_NOTIFICATIONS_TITLE,
      { ...EXCEPTION_NOTIFICATION, hideLabel: true, style: { paddingTop: '0.375rem' } },
      CONTACT_INFORMATION_TITLE,
      {
        ...WWW,
        label: 'tampere_markets_website_link',
        hideLabel: true,
        style: { flex: 1, maxWidth: '38rem' }
      },
      {
        ...FACEBOOK_URL,
        label: 'tampere_markets_facebook_link',
        hideLabel: true,
        style: { flex: 1, maxWidth: '38rem' }
      },
      {
        type: 'container',
        className: 'customRowFixed',
        style: { paddingBottom: '10rem' },
        items: [
          STREET_ADDRESS,
          SPACER,
          CITY,
          SPACER,
          POSTAL_CODE
        ]
      }
      // End right column
    ]
  ]
}

export default LAYOUT
