import React from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import IconButton from '@mui/material/IconButton'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import { Button } from '..'
import MapToolButton from '../Map/MapToolButton'
import { ViewMode } from '../../Constants'
import { Colors } from '../../Utils/theme'

// Icons
import SplitScreenIcon from '@mui/icons-material/VerticalSplitOutlined'
import OnlyMapIcon from '@mui/icons-material/MapOutlined'
import OnlyTextIcon from '@mui/icons-material/ArticleOutlined'
import AddIcon from '../../Assets/Icons/add.svg'
import EditIcon from '../../Assets/Icons/edit.svg'
import GridIcon from '../../Assets/Icons/grid.svg'
import GridAltIcon from '../../Assets/Icons/grid-alt.svg'
import ListIcon from '../../Assets/Icons/file-structure.svg'
import ListAltIcon from '../../Assets/Icons/file-structure-alt.svg'
// import MapVisibilityIcon from '../../Assets/Icons/map-visibility.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '8.6rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    padding: '1.5rem 2.5rem 0',
    backgroundColor: Colors.backgroundGrey,
    borderBottom: `1px solid ${Colors.black20}`
  },
  rootCompact: {
    minHeight: '5.7rem',
    display: 'flex',
    flexDirection: 'row',
    padding: '1.875rem 2.5rem',
    backgroundColor: Colors.backgroundGrey,
    borderBottom: `1px solid ${Colors.black20}`,
  },
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: 1,
    marginBottom: '1.25rem'
  },
  titleRowCompact: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: 1
  },
  flex: {
    flex: 1
  },
  title: {
    margin: 0,
    fontFamily: 'Rubik',
    fontSize: '2.125rem',
    color: Colors.black,
    lineHeight: '1.1'
  },
  mapHiddenTitle: {
    margin: 0,
    fontFamily: 'Rubik',
    fontSize: '2.125rem',
    color: Colors.black,
    lineHeight: '1.1',
    marginLeft: 'calc((100% - 48rem) / 2)',
    [theme.breakpoints.down('lg')]: {
      marginLeft: 0
    }
  },
  bottomRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  tab: {
    fontFamily: 'Rubik',
    fontWeight: 500,
    color: Colors.black,
    fontSize: '1.125rem',
    textTransform: 'none',
    paddingTop: '.25rem',
    paddingBottom: '.5rem',
    '&:active': {
      color: Colors.violet
    }
  },
  activeTab: {
    fontFamily: 'Rubik',
    fontWeight: 500,
    color: `${Colors.violet} !important`,
    fontSize: '1.125rem',
    textTransform: 'none',
    paddingTop: '.25rem',
    paddingBottom: '.5rem'
  },
  indicator: {
    height: '.25rem',
    backgroundColor: Colors.violet
  },
  viewActions: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'row'
  },
  buttonContainer: {
    alignSelf: 'flex-start',
    paddingLeft: '1rem'
  },
  addButton: {
    minHeight: '3.125rem',
    minWidth: '10rem'
  },
  buttonText: {
    paddingRight: '0.125rem',
    // fontWeight: '400 !important'
  },
  actionIcon: {
    height: '1.25rem',
    width: 'auto',
    maxHeight: '1.25rem',
    maxWidth: '1.25rem'
  },
  mapButtonsContainer: {
    minHeight: '3.125rem',
    width: '11.25rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: '1.5rem'
  },
  mapButtonsCompact: {
    width: '7.25rem'
  }
}))

const styles = {
  materialIcon: {
    color: Colors.white,
    height: '1.75rem',
    width: '1.75rem'
  }
} as const

export default function PageHeader(props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const renderActionButton = () => {
    if (props.onCreateClick) {
      return (
        <div className={classes.buttonContainer}>
          <Button
            text={t('add_new')}
            onClick={props.onCreateClick}
            rightIcon={AddIcon}
            buttonStyle={classes.addButton}
            buttonTextStyle={classes.buttonText}
            disabled={props.createButtonDisabled}
          />
        </div>
      )
    } else if (props.onEditClick) {
      return (
        <div className={classes.buttonContainer}>
          <Button
            text={props.editMode ? t('close_edit') : t('edit')}
            onClick={props.onEditClick}
            rightIcon={props.editMode ? null : EditIcon}
            buttonStyle={classes.addButton}
            buttonTextStyle={classes.buttonText}
            outlined={props?.editMode}
          />
        </div>
      )
    }
    return null
  }

  const renderToggleButton = () => {
    if (props.showMapTools) {
      return (
        <div className={`${classes.mapButtonsContainer} ${props.smallViewport ? classes.mapButtonsCompact : ''}`.trim()}>
          { !props.smallViewport ? (
              <MapToolButton
                title={t('view_split_screen')}
                iconComponent={<SplitScreenIcon sx={styles.materialIcon} />}
                onClick={props.showSplitScreen}
                active={!props.mapHidden && !props.fullScreen}
              />
            ) : null
          }
          <MapToolButton
            title={t('view_without_map')}
            iconComponent={<OnlyTextIcon sx={styles.materialIcon} />}
            onClick={props.smallViewport ? props.showSplitScreen : props.hideMap}
            active={props.mapHidden}
          />
           <MapToolButton
            title={t('view_only_map')}
            iconComponent={<OnlyMapIcon sx={styles.materialIcon} />}
            onClick={props.showFullScreenMap}
            active={props.fullScreen}
          />
        </div>
      )
    }
  }

  const handleTabChange = (evt, value) => {
    props.onTabChange(props.tabs[value].value)
  }

  const renderTabs = () => {
    if (!props.tabs) return null
    return (
      <Tabs
        value={props.currentTab}
        onChange={handleTabChange}
        classes={{ indicator: classes.indicator }}
        aria-label=''
      >
        {
          props.tabs.map((tab, index) => (
            <Tab
              key={index}
              label={tab.label}
              classes={{ root: props.currentTab === tab.value ? classes.activeTab : classes.tab }}
            />
          ))
        }
      </Tabs>
    )
  }

  const renderViewOptions = () => {
    if (!props.viewOnGrid ||!props.viewOnList) return null
    return (
      <div className={classes.viewActions}>
        <IconButton onClick={props.viewOnList} size='large'>
          <img src={props.viewMode === ViewMode.List ? ListIcon : ListAltIcon} className={classes.actionIcon} alt='list' />
        </IconButton>
        <IconButton onClick={props.viewOnGrid} size='large'>
          <img src={props.viewMode === ViewMode.Grid ? GridIcon : GridAltIcon} className={classes.actionIcon} alt='grid' />
        </IconButton>
      </div>
    )
  }

  return (
    <div className={props.compact ? classes.rootCompact : classes.root}>
      <div className={props.compact ? classes.titleRowCompact : classes.titleRow}>
        <h1 className={props.mapHidden ? classes.mapHiddenTitle : classes.title}>{props.title}</h1>
        <div className={classes.flex} />
        {renderActionButton()}
        {renderToggleButton()}
      </div>
      <div className={classes.bottomRow}>
        {renderTabs()}
        {renderViewOptions()}
      </div>
    </div>
  )
}
