import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'
import get from 'lodash/get'
import { useStore } from '../../../Models/RootStore'
import { PageHeader, Title, Button, ImageUpload, Input, Select, DataTable, Dialog, ProgressBox } from '../../../Components'
import { Colors } from '../../../Utils/theme'
import { EmployeeCountOptions, IndustryOptions, RevenueOptions } from '../../../Constants'
import { formatDateTime } from '../../../Utils/dateTime'
import { ValueLimits } from '../../../Constants'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'hidden',
    height: '100%'
  },
  contentContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row'
  },
  content: {
    position: 'relative',
    flex: 1,
    flexDirection: 'column',
    flexGrow: 1
  },
  contentInnerContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    padding: '0.5rem 2.5rem 10rem',
    overflowY: 'auto'
  },
  contentInner: {
    maxWidth: '60rem'
  },
  actions: {
    borderLeft: `1px solid ${Colors.black20}`,
    width: '24rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '2.5rem'
  },
  text: {
    margin: 0,
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: 300,
    color: Colors.black,
    marginBottom: '1.5rem'
  },
  boldText: {
    fontWeight: 700
  },
  line: {
    height: 1,
    width: '100%',
    backgroundColor: Colors.black20,
    marginBottom: '2rem'
  },
  profileContainer: {
    display: 'flex',
    flexDirection: 'row',
    // paddingTop: '1rem',
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column'
    }
  },
  imageContainer: {
    marginTop: '0.375rem'
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column'
    }
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  spacer: {
    width: '3rem',
    height: '1rem',
    [theme.breakpoints.down('lg')]: {
      height: 0
    }
  },
  infoContainer: {
  },
  accountContainer: {
    maxWidth: '20rem'
  },
  locationContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  address: {
    width: 700
  },
  zip: {
    width: 168,
    marginRight: '2rem'
  },
  city: {
    width: 500
  },
  progress: {
    position: 'absolute',
    top: '1rem',
    right: '2.5rem'
  },
  passwordContainer: {
    maxWidth: '24rem',
    marginBottom: '2.5rem'
  },
  changePasswordButton: {
    height: '2.5rem',
    width: '10rem',
    marginTop: '0.5rem'
  },
  changePasswordButtonText: {
    fontSize: '0.875rem'
  },
  loginsContainer: {
    maxWidth: '24rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  rowSpacer: {
    minWidth: '2rem'
  },
  firstName: {
    minWidth: '12rem'
  },
  lastName: {
    flex: 1,
    minWidth: '18rem',
    maxWidth: '26rem'
  },
  email: {
    flex: 1,
    maxWidth: '30rem'
  },
  phone: {
    flex: 1,
    maxWidth: '18rem'
  },
  jobTitle: {
    flex: 1,
    maxWidth: '24rem'
  },
  name: {
    flex: 1,
    maxWidth: '26rem'
  },
  description: {
    flex: 1,
    maxWidth: '44rem'
  }
}))

const TABS = {
  profile: 0,
  account: 1,
  organization: 2,
  notifications: 3,
  security: 4
}

const Settings = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { sessionStore, organizationStore, logStore }: any = useStore()

  useEffect(() => {
    sessionStore.getMe()
    organizationStore.getMyOrganization()
    logStore.getMyLoginLog()
  }, [])

  useEffect(() => {
    setPasswordSubmitted(false)
    setCurrentPassword('')
    setNewPassword('')
    setNewPasswordAgain('')
  }, [sessionStore.changePasswordSuccess])

  // Common
  const [tab, setTab] = useState(TABS.profile)
  const [alertVisible, setAlertVisible] = useState(false)

  // User
  const [userPhoto, setUserPhoto] = useState(get(sessionStore.user, 'photo', null))
  const [firstName, setFirstName] = useState(get(sessionStore.user, 'firstName', ''))
  const [lastName, setLastName] = useState(get(sessionStore.user, 'lastName', ''))
  const [email, setEmail] = useState(get(sessionStore.user, 'email', ''))
  const [phone, setPhone] = useState(get(sessionStore.user, 'phone', ''))
  const [title, setTitle] = useState(get(sessionStore.user, 'title', ''))
  const [description, setDescription] = useState(get(sessionStore.user, 'description', ''))
  const [role, setRole] = useState(get(sessionStore.user, 'role', ''))
  const [submitted, setSubmitted] = useState(false)
  const [passwordSubmitted, setPasswordSubmitted] = useState(false)

  // Organization
  const [organizationLogo, setOrganizationLogo] = useState(get(organizationStore.organization, 'logo'))
  const [organizationName, setOrganizationName] = useState(get(organizationStore.organization, 'name'))
  const [businessId, setBusinessId] = useState(get(organizationStore.organization, 'businessId'))
  const [address, setAddress] = useState(get(organizationStore.organization, 'address'))
  const [zipCode, setZipCode] = useState(get(organizationStore.organization, 'zipCode'))
  const [city, setCity] = useState(get(organizationStore.organization, 'city'))
  const [organizationDescription, setOrganizationDescription] = useState(get(organizationStore.organization, 'description'))
  const [contactInfo, setContactInfo] = useState(get(organizationStore.organization, 'contactInfo'))
  const [employeeCount, setEmployeeCount] = useState(get(organizationStore.organization, 'employeeCount'))
  const [industry, setIndustry] = useState(get(organizationStore.organization, 'industry'))
  const [revenue, setRevenue] = useState(get(organizationStore.organization, 'revenue'))

  // Security
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [newPasswordAgain, setNewPasswordAgain] = useState('')

  useEffect(() => {
    if (organizationStore.organization) {
      setOrganizationLogo(organizationStore.organization.logo)
      setOrganizationName(organizationStore.organization.name)
      setBusinessId(organizationStore.organization.businessId)
      setAddress(organizationStore.organization.address)
      setZipCode(organizationStore.organization.zipCode)
      setCity(organizationStore.organization.city)
      setOrganizationDescription(organizationStore.organization.description)
      setContactInfo(organizationStore.organization.contactInfo)
      setEmployeeCount(organizationStore.organization.employeeCount)
      setIndustry(organizationStore.organization.industry)
      setRevenue(organizationStore.organization.revenue)
    }
  }, [organizationStore.organization])

  const getTabOptions = () => {
    if (role === 'superadmin') {
      return [
        { value: TABS.profile, label: t('own_profile'), description: t('own_profile_description') },
        { value: TABS.security, label: t('security'), description: t('security_description') }
      ]
    } else if (role === 'employee') {
      return [
        { value: TABS.profile, label: t('own_profile'), description: t('own_profile_description') },
        { value: TABS.account, label: t('account_information'), description: t('own_account_description') },
        { value: TABS.security, label: t('security'), description: t('security_description') }
      ]
    }

    return [
      { value: TABS.profile, label: t('own_profile'), description: t('own_profile_description') },
      { value: TABS.account, label: t('account_information'), description: t('own_account_description') },
      { value: TABS.organization, label: t('organization'), description: t('own_organization_description') },
      { value: TABS.security, label: t('security'), description: t('security_description') }
    ]
  }
  const tabOptions = getTabOptions()

  const getRoles = () => {
    // Only return current value, since role cannot be changed from settings
    return [{ label: t(role), value: role }]
  }

  const getLatestLogins = () => {
    return logStore.loginLog.map((item) => [item.ipAddress, formatDateTime(item.createdAt)])
  }

  const selectTab = (value) => setTab(value)
  const openAlert = () => setAlertVisible(true)
  const closeAlert = () => setAlertVisible(false)

  const updateMyPhoto = (files) => {
    if (files && files.length) {
      const file = files[0]
      setUserPhoto(URL.createObjectURL(file))
      sessionStore.updateMyPhoto(file)
    }
  }

  const removeMyPhoto = () => {
    setUserPhoto(null)
    sessionStore.updateMe({ photo: null })
  }

  const deleteProfile = () => {
    closeAlert()
  }

  const updateProfile = () => {
    const currentEmail = get(sessionStore.user, 'email')
    sessionStore.updateMe({ firstName, lastName, phone, title, description })
    if (email && currentEmail && email !== currentEmail) {
      // Create email change request if email was changed
      sessionStore.createEmailChange(email)
    }
    setSubmitted(true)
  }

  const changePassword = () => {
    sessionStore.changePassword(currentPassword, newPassword, newPasswordAgain)
    setPasswordSubmitted(true)
  }

  const updateOrganizationLogo = (files) => {
    if (files && files.length) {
      const file = files[0]
      setOrganizationLogo(URL.createObjectURL(file))
      organizationStore.updateMyOrganizationLogo(file)
    }
  }

  const removeOrganizationLogo = () => {
    setOrganizationLogo(null)
    organizationStore.updateMyOrganization({ logo: null })
  }

  const updateOrganization = () => {
    organizationStore.updateMyOrganization({
      name: organizationName,
      businessId,
      address,
      zipCode,
      city,
      contactInfo,
      description: organizationDescription,
      industry,
      revenue,
      employeeCount
    })

    setSubmitted(true)
  }

  const emailValidation = () => {
    if (submitted && !/\S+@\S+\.\S+/.test(email)) {
      return { error: true, helperText: t('invalid_email') }
    }
    return {}
  }

  const validateRequiredField = (value, errorText) => {
    if (submitted && !value) {
      return { error: true, helperText: t(errorText) }
    }
    return {}
  }

  const validateCurrentPassword = () => {
    if (passwordSubmitted) {
      if (!currentPassword) {
        return { error: true, helperText: t('required_field') }
      }
    }
    return {}
  }

  const validateNewPassword = (passwordValue) => {
    if (passwordSubmitted) {
      if (!passwordValue || passwordValue.length < ValueLimits.PasswordMinLength) {
        return { error: true, helperText: t('password_too_weak') }
      }
      if (newPassword !== newPasswordAgain) {
        return { error: true, helperText: t('passwords_do_not_match') }
      }
    }
    return {}
  }

  const renderProgress = () => {
    if (role === 'superadmin') return null

    let progress = null
    if (tab === TABS.organization) {
      progress = get(organizationStore.organization, 'progress', null)
    } else if (tab !== TABS.security) {
      progress = get(sessionStore.user, 'progress', null)
    }

    if (progress !== null) {
      return <ProgressBox progress={progress} containerStyle={classes.progress} />
    }
    return null
  }

  const renderContent = () => {
    if (tab === TABS.account) {
      return (
        <>
          <Title title={t('permissions')} type='subtitle' />
          <div className={classes.accountContainer}>
            <Select options={getRoles()} label={t('role')} value={role} onChange={setRole} disabled />
          </div>
        </>
      )
    }

    if (tab === TABS.organization) {
      return (
        <>
          <Title title={t('general_information')} type='subtitle' />
          <div className={classes.profileContainer}>
            <div className={classes.imageContainer}>
              <ImageUpload
                photo={organizationLogo}
                onFileUpload={updateOrganizationLogo}
                removePhoto={removeOrganizationLogo}
              />
            </div>
            <div className={classes.spacer} />
            <div className={classes.column}>
              <div className={classes.name}>
                <Input
                  label={t('name')}
                  value={organizationName}
                  onChange={setOrganizationName}
                  {...validateRequiredField(organizationName, 'required_field')}
                />
              </div>
              <Input
                label={t('description')}
                value={organizationDescription}
                onChange={setOrganizationDescription}
                multiline
              />
            </div>
          </div>
          <Title title={t('business_information')} type='subtitle' />
          <div className={classes.rowContainer}>
            <div className={classes.column}>
              <Input label={t('business_id')} value={businessId} onChange={setBusinessId} />
            </div>
            <div className={classes.spacer} />
            <div className={classes.column}>
              <Select
                options={EmployeeCountOptions}
                label={t('employee_count')}
                value={employeeCount}
                onChange={setEmployeeCount}
              />
            </div>
          </div>
          <div className={classes.rowContainer}>
            <div className={classes.column}>
              <Select
                options={IndustryOptions}
                label={t('industry')}
                value={industry}
                onChange={setIndustry}
              />
            </div>
            <div className={classes.spacer} />
            <div className={classes.column}>
              <Select
                options={RevenueOptions}
                label={t('revenue_class')}
                value={revenue}
                onChange={setRevenue}
              />
            </div>
          </div>
          <div className={classes.spacer} />
          <Title title={t('contact_info')} type='subtitle' />
          <div className={classes.locationContainer}>
            <div className={classes.address}>
              <Input label={t('street_address')} value={address} onChange={setAddress} />
            </div>
          </div>
          <div className={classes.locationContainer}>
            <div className={classes.zip}>
              <Input label={t('zip_code')} value={zipCode} onChange={setZipCode} />
            </div>
            <div className={classes.city}>
              <Input label={t('city')} value={city} onChange={setCity} />
            </div>
          </div>
          <Input
            label={t('contact_info')}
            value={contactInfo}
            onChange={setContactInfo}
            multiline
          />
        </>
      )
    }

    if (tab === TABS.security) {
      return (
        <>
          <Title title={t('change_password')} type='subtitle' />
          <div className={classes.passwordContainer}>
            <Input
              label={t('current_password')}
              value={currentPassword}
              onChange={setCurrentPassword}
              type='password'
              {...validateCurrentPassword()}
            />
            <Input
              label={t('new_password')}
              value={newPassword}
              onChange={setNewPassword}
              type='password'
              {...validateNewPassword(newPassword)}
            />
            <Input
              label={t('new_password_again')}
              value={newPasswordAgain}
              onChange={setNewPasswordAgain}
              type='password'
              {...validateNewPassword(newPasswordAgain)}
            />
            <Button
              text={t('change_password')}
              onClick={changePassword}
              buttonStyle={classes.changePasswordButton}
              buttonTextStyle={classes.changePasswordButtonText}
            />
          </div>
          <Title title={t('recent_logins')} type='subtitle' />
          <div className={classes.loginsContainer}>
            <DataTable columnNames={[t('ip_address'), t('login_time')]} data={getLatestLogins()} />
          </div>
        </>
      )
    }

    return (
      <>
        <Title
          title={t('own_information')}
          // description={tabOptions[tab].description}
          type='subtitle'
        />
        <div className={classes.profileContainer}>
          <div className={classes.imageContainer}>
            <ImageUpload
              photo={userPhoto}
              onFileUpload={updateMyPhoto}
              removePhoto={removeMyPhoto}
            />
          </div>
          <div className={classes.spacer} />
          <div className={classes.column}>
            <div className={classes.row}>
              <div className={classes.firstName}>
                <Input label={t('first_name')} value={firstName} onChange={setFirstName} />
              </div>
              <div className={classes.rowSpacer} />
              <div className={classes.lastName}>
                <Input label={t('last_name')} value={lastName} onChange={setLastName} />
              </div>
            </div>
            <div className={classes.email}>
              <Input label={t('email')} value={email} onChange={setEmail} {...emailValidation()} />
            </div>
            <div className={classes.phone}>
              <Input label={t('phone_number')} value={phone} onChange={setPhone} />
            </div>
          </div>
        </div>
        { role !== 'superadmin' && (
          <>
            <Title title={t('additional_information')} type='subtitle' />
            <div className={classes.infoContainer}>
              <div className={classes.jobTitle}>
                <Input label={t('job_title')} value={title} onChange={setTitle} />
              </div>
              <div className={classes.description}>
                <Input
                  label={t('description')}
                  value={description}
                  onChange={setDescription}
                  multiline
                />
              </div>
            </div>
          </>
        )}
      </>
    )
  }

  const renderDeleteProfile = () => {
    if (role !== 'superadmin' && tab === TABS.profile) {
      // TODO: check if can delete profile
      return (
        <div>
          <div className={classes.line} />
          <Button
            text={t('delete_profile')}
            onClick={openAlert}
            outlined
            fullWidth
          />
        </div>
      )
    }
    return null
  }

  const renderActions = () => {
    if (tab === TABS.profile) {
      return (
        <>
          <div>
            <p className={classes.text}>{t('own_profile_actions_description')}</p>
            <p className={classes.text}>
              <span className={classes.boldText}>{t('last_modified')}: </span>
              {formatDateTime(get(sessionStore, 'user.updatedAt'))}
            </p>
            <Button
              text={t('update_own_profile')}
              onClick={updateProfile}
              fullWidth
            />
          </div>
          {renderDeleteProfile()}
        </>
      )
    }

    if (tab === TABS.account) {
      return (
        <>
          <div>
            <p className={classes.text}>
              <span className={classes.boldText}>{t('last_modified')}: </span>
              {formatDateTime(get(sessionStore, 'user.updatedAt'))}
            </p>
          </div>
        </>
      )
    }

    if (tab === TABS.organization) {
      return (
        <div>
          <p className={classes.text}>{t('own_organization_actions_description')}</p>
          <Button text={t('update_info')} onClick={updateOrganization} fullWidth />
        </div>
      )
    }
    return null
  }

  return (
    <div className={classes.root}>
      <PageHeader
        title={t('settings')}
        tabs={tabOptions}
        currentTab={tab}
        onTabChange={selectTab}
      />
      <div className={classes.contentContainer}>
        <div className={classes.content}>
          <div className={classes.contentInnerContainer} key={tab}>
            <div className={classes.contentInner}>
              {renderProgress()}
              {renderContent()}
            </div>
          </div>
        </div>
        <div className={classes.actions}>{renderActions()}</div>
      </div>
      <Dialog
        open={alertVisible}
        handleClose={closeAlert}
        handleOk={deleteProfile}
        title={t('delete_profile_title')}
        description={t('delete_profile_description')}
      />
    </div>
  )
}

export default observer(Settings)
