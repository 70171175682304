import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import Modal from '@mui/material/Modal'
import { Input, Button } from '..'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  modal: {
  },
  contentContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: Colors.white,
    minWidth: '40rem',
    borderRadius: 3
  },
  content: {
    padding: '2rem 3rem',
    paddingBottom: '1rem'
  },
  title: {
    fontFamily: 'Rubik',
    fontWeight: 600,
    color: Colors.black,
    fontSize: '1.675rem',
    marginBottom: '1.25rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  label: {
    fontSize: '1rem',
    fontWeight: 500,
    marginBottom: '0.5rem',
    color: Colors.label
  },
  footerContainer: {
    height: '5.5rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0rem 3rem',
    borderTop: `1px solid ${Colors.black20}`
  },
  saveButton: {
    width: '9rem',
    height: '3.125rem'
  },
  cancelButton: {
    width: '7rem',
    height: '3.125rem',
    border: 'none',
    margin: '0rem 0.75rem',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: Colors.violet10
    }
  }
}))

export default function GpxRecordingModal(props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const { item, handleSave, handleClose } = props

  const [description, setDescription] = useState(item.description)

  const save = () => {
    handleSave(item.id, { description })
  }

  return (
    <Modal onClose={handleClose} classes={{ root: classes.modal }} open>
      <div className={classes.contentContainer}>
        <div className={classes.content}>
          <div className={classes.title}>{t('edit_gpx_recording')}</div>
          <div className={classes.label}>{t('description')}</div>
          <Input value={description} onChange={setDescription} multiline />
        </div>
        <div className={classes.footerContainer}>
          <Button
            onClick={handleClose}
            text={t('cancel')}
            buttonStyle={classes.cancelButton}
            outlined
          />
          <Button
            onClick={save}
            text={t('save')}
            buttonStyle={classes.saveButton}
          />
        </div>
      </div>
    </Modal>
  )
}
