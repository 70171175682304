import {
  HAS_LIGHTS,
  SKI_DIRECTION,
  SKI_STYLE,
  SPACER,
  DASH_SPACER,
  TOTAL_LENGTH_IN_KM,
  USE_START_DATE,
  USE_END_DATE,
  MAINTENANCE_TITLE,
  EXCEPTION_NOTIFICATION,
  LAST_MAINTAINED,
  CONDITION,
  CONDITION_REPORT,
  CONTACT_INFORMATION_TITLE,
  STREET_ADDRESS,
  POSTAL_CODE,
  CITY,
  PROPERTIES_TITLE,
  DESCRIPTION_TITLE,
  DESCRIPTION
} from '../elements.template'

const LAYOUT = {
  type: 'ski_trail',
  layout: [
    [
      // Start left column
      DESCRIPTION_TITLE,
      DESCRIPTION,
      PROPERTIES_TITLE,
      {
        type: 'container',
        className: 'customRowFixed',
        items: [
          TOTAL_LENGTH_IN_KM,
          SPACER,
          SPACER,
          SPACER,
          SKI_DIRECTION
        ]
      },
      {
        type: 'container',
        className: 'customRowFixed',
        items: [
          SKI_STYLE,
          SPACER,
          SPACER,
          HAS_LIGHTS
        ]
      }
      // End left column
    ],
    [
      // Start of right column
      MAINTENANCE_TITLE,
      {
        type: 'container',
        className: 'customRowFixed',
        items: [
          {
            type: 'container',
            className: 'customColumn',
            style: {},
            items: [
              {
                type: 'container',
                items: [{ type: 'title', name: 'in_use_between' }]
              },
              {
                type: 'container',
                className: 'customInnerRow',
                items: [
                  USE_START_DATE,
                  DASH_SPACER,
                  USE_END_DATE
                ]
              },
            ]
          },
          SPACER,
          SPACER,
          LAST_MAINTAINED
        ]
      },
      { ...CONDITION, style: { flex: 1, maxWidth: '24rem' } },
      CONDITION_REPORT,
      EXCEPTION_NOTIFICATION,
      CONTACT_INFORMATION_TITLE,
      {
        type: 'container',
        className: 'customRowFixed',
        style: { paddingBottom: '10rem' },
        items: [
          STREET_ADDRESS,
          SPACER,
          CITY,
          SPACER,
          POSTAL_CODE
        ]
      }
      // End of right column
    ]
  ]
}

export default LAYOUT
