import { createTheme, adaptV4Theme } from '@mui/material/styles'

export enum Colors {
  black = '#000000',
  black90 = 'rgba(0, 0, 0, .9)',
  black80 = '#333333',
  black70 = 'rgba(0, 0, 0, .7)',
  black60 = 'rgba(0, 0, 0, .6)',
  black50 = 'rgba(0, 0, 0, .5)',
  black40 = 'rgba(0, 0, 0, .4)',
  black30 = 'rgba(0, 0, 0, .3)',
  black20 = 'rgba(0, 0, 0, .2)',
  black10 = 'rgba(0, 0, 0, .1)',
  black05 = 'rgba(0, 0, 0, .05)',
  grey = '#C1C1C1',
  greyIcon = 'rgba(181, 181, 181, .7)',
  lightGrey = '#F7F7F7',
  backgroundGrey = '#F4F4F4',
  white = '#FFFFFF',
  white80 = 'rgba(255,255,255,.8)',
  green = '#3EB973',
  red = '#FF1744',
  red20 = 'rgba(255, 23, 68, .2)',
  redHover = '#c20025',
  tableRow = '#E5E5E5',
  lightBlue = 'rgba(0,65,125,0.1)',
  blueLink = '#265EB2',
  blue = '#00417D',
  blue05 = 'rgba(0, 65, 125, .05)',
  mapMarker = '#EB5E57',
  tableHover = '#f8f8f8',
  purple = '#9b51e0',
  geoPoint = '#1565c0',
  geoArea = '#2e7d32',
  geoRoute = '#FF1744',
  label = '#808080',
  inputText = '#343434',
  border = '#CCCCCC',
  violet = '#7A297F',
  violet80 = 'rgba(122,41,127,.8)',
  violet50 = 'rgba(122,41,127,.5)',
  violet30 = 'rgba(122,41,127,.3)',
  violet10 = 'rgba(122,41,127,.1)',
}

// A custom theme for this app
const Theme = createTheme(adaptV4Theme({
  palette: {
    primary: {
      main: Colors.black
    },
    secondary: {
      main: Colors.black
    },
    error: {
      main: Colors.red
    },
    background: {
      default: Colors.white
    }
  }
}))

export default Theme
