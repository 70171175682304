import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import linkifyHtml from 'linkify-html'
import { decode } from 'html-entities'
import * as striptags from 'striptags'
import get from 'lodash/get'
import flatten from 'lodash/flatten'
import CustomField from '../MainEntity/CustomField'
import WeekdayTimeRanges from '../Common/WeekdayTimeRanges'
import { Input, Select, Button, LargeImageUpload, HtmlInput, Checkbox, GpxRecordingModal, FormTabs } from '../../Components'
import { CUSTOM_FIELD_CUSTOM_LAYOUTS, getCustomFieldCustomLayoutStyles } from '../CustomFieldCustomLayout/customFieldCustomLayout'
import { LANG_OPTIONS, featureTypeOptions } from '../../Constants'
import { Colors } from '../../Utils/theme'
import GpxRecordingTable from '../GpxRecordingTable/GpxRecordingTable'
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    flexDirection: 'column',
    display: 'flex',
    overflowY: 'auto',
    padding: '1rem 2.5rem',
    paddingBottom: '10rem'
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  fullContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    width: '100%',
    maxWidth: '48rem',
    alignSelf: 'center'
  },
  content: {
    flexDirection: 'column',
    flexGrow: 1,
    paddingBottom: '.5rem'
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '1rem',
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column'
    }
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  spacer: {
    width: '3rem',
    height: '1rem',
    [theme.breakpoints.down('lg')]: {
      height: 0
    }
  },
  footerContainer: {
    height: '5.5rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0rem 2.5rem',
    borderTop: `1px solid ${Colors.black20}`
  },
  fullFooterContainer: {
    height: '5.5rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderTop: `1px solid ${Colors.black20}`
  },
  footerContentContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: 1
  },
  fullFooterContentContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    maxWidth: '48rem',
    alignSelf: 'center'
  },
  cancelButton: {
    width: '7rem',
    height: '3.125rem',
    border: 'none',
    margin: '0rem 0.75rem',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: Colors.violet10
    }
  },
  saveButton: {
    width: '9rem',
    height: '3.125rem'
  },
  gridContainer: {
    display: 'block'
  },
  title: {
    margin: 0,
    fontSize: '1rem',
    fontWeight: 500,
    marginBottom: '.5rem',
    color: Colors.label
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  entityTitleColumn: {
    flex: 1,
    maxWidth: '70%',
    paddingRight: '1.5rem'
  },
  entityTypeColumn: {
    flex: 1,
    maxWidth: '15rem'
  },
  subentityTypeColumn: {
    flex: 1,
    maxWidth: '20rem',
    paddingRight: '.75rem'
  },
  rootSubentityTypeColumn: {
    flex: 1,
    maxWidth: '30rem',
    paddingLeft: '.75rem'
  },
  radiusColumn: {
    flex: 1,
    maxWidth: '12rem'
  },
  fromDateColumn: {
  },
  untilDateColumn: {
    paddingTop: '2rem',
  },
  limitTimesColumn: {
    paddingBottom: '1.25rem'
  },
  notificationContainer: {
    marginBottom: '1.5rem'
  },
  notificationTitleColumn: {
    flex: 1,
    maxWidth: '30rem'
  },
  booleanRoot: {
    paddingBottom: '0.875rem'
  },
  booleanContainer: {
    width: '10rem',
    height: '3.125rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around'
  },
  wideBooleanContainer: {
    width: '15rem',
    height: '3.125rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  timeRangesContainer: {
    marginTop: '-1.25rem'
  },
  dashSpacer: {
    padding: '2.5rem 1rem 0',
    fontWeight: 500
  },
  flexSpacer: {
    flex: 1,
  },
  timeColumn: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end'
  },
  separatorText: {
    marginBottom: '2.25rem',
    padding: '0 .75rem',
    fontSize: '1rem',
    fontWeight: 500,
    color: Colors.label,
  },
  paddedRow: {

  },
  ...(getCustomFieldCustomLayoutStyles(theme) as any)
}))

const styles = {
  langContainer: {
    marginBottom: '0 !important'
  },
  langSelect: {
    width: '4.125rem'
  }
}

const TABS = {
  basicInfo: 0,
  additionalInfo: 1,
  gpxRecordings: 2
}

function EditSubEntityForm (props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const { subEntity } = props

  const [photo, setPhoto] = useState(subEntity.photo)
  const [nameFi, setNameFi] = useState(subEntity.nameFi || subEntity.name) // TODO:remove fallback
  const [nameEn, setNameEn] = useState(subEntity.nameEn || subEntity.name) // TODO:remove fallback
  const [type, setType] = useState(subEntity.type)
  const [descriptionFi, setDescriptionFi] = useState(get(props.subEntity, 'descriptionHtmlFi') || '')
  const [descriptionEn, setDescriptionEn] = useState(get(props.subEntity, 'descriptionHtmlEn') || '')

  // true = enter, false = leave
  const [arrivalActive, setArrivalActive] = useState(props.subEntity?.type !== 'leave' ? true : false)
  const toggleArrivalActive = () => setArrivalActive(!arrivalActive)

  const [tab, setTab] = useState(0)
  const [lang, setLang] = useState('fi')
  const [subEntityTypeId, setSubEntityTypeId] = useState(subEntity.subEntityTypeId)

  // Geofence fields
  const [validFrom, setValidFrom] = useState(subEntity.validFrom ? moment(subEntity.validFrom).format('YYYY-MM-DD') : null)
  const [validFromTime, setValidFromTime] = useState(subEntity.validFrom ? moment(subEntity.validFrom).format('HH:mm') : null)
  const [validUntil, setValidUntil] = useState(subEntity.validUntil ? moment(subEntity.validUntil).format('YYYY-MM-DD') : null)
  const [validUntilTime, setValidUntilTime] = useState(subEntity.validUntil ? moment(subEntity.validUntil).format('HH:mm') : null)

  const handleSetType = (newType) => {
    if (newType !== type) {
      // Reset sub entity type
      setSubEntityTypeId(null)
    }
    setType(newType)
  }

  const [geofenceTitleFi, setGeofenceTitleFi] = useState(subEntity.geofenceTitleFi)
  const [geofenceDescriptionFi, setGeofenceDescriptionFi] = useState(get(props.subEntity, 'geofenceDescriptionHtmlFi') || '')

  const [geofenceTitleEn, setGeofenceTitleEn] = useState(subEntity.geofenceTitleEn)
  const [geofenceDescriptionEn, setGeofenceDescriptionEn] = useState(get(props.subEntity, 'geofenceDescriptionHtmlEn') || '')

  const [radius, setRadius] = useState(`${subEntity.radius || 200}`)

  const [gpxRecordingModalVisible, setGpxRecordingModalVisible] = useState(false)
  const [gpxRecording, setGpxRecording] = useState(null)

  const [customFields, setCustomFields] = useState([])


  const getName = () => lang === 'en' ? nameEn : nameFi
  const handleSetName = (name) => {
    lang === 'en' ? setNameEn(name) : setNameFi(name)
  }

  const getDescription = () => lang === 'en' ? descriptionEn : descriptionFi
  const handleSetDescription = (description) => {
    lang === 'en' ? setDescriptionEn(description) : setDescriptionFi(description)
  }

  const getGeofenceTitle = () => lang === 'en' ? geofenceTitleEn : geofenceTitleFi
  const handleSetGeofenceTitle = (geofenceTitle) => {
    lang === 'en' ? setGeofenceTitleEn(geofenceTitle) : setGeofenceTitleFi(geofenceTitle)
  }

  const getGeofenceDescription = () => lang === 'en' ? geofenceDescriptionEn : geofenceDescriptionFi
  const handleSetGeofenceDescription = (geofenceDescription) => {
    lang === 'en' ? setGeofenceDescriptionEn(geofenceDescription) : setGeofenceDescriptionFi(geofenceDescription)
  }


  useEffect(() => {
    // Populate custom fields
    const newCustomFields = []
    if (props.subEntity && props.subEntity.fieldValues) {
      for (const val of props.subEntity.fieldValues) {
        newCustomFields.push({ id: val.subEntityFieldId, value: val.value })
      }
      setCustomFields(newCustomFields)
    }
  }, [])

  const getSubEntityTypeOptions = () => {
    const options = props.subEntityTypes.filter((item) => {
      return item.geoType === type
    }).map((item) => {
      return { label: item.name, value: item.id }
    })
    if (options.length) {
      // Add empty option
      return [
        { label: t('type_other'), value: null },
        ...options
      ]
    }
    return options
  }

  const setRadiusControlled = (value) => {
    // Remove digits
    let newValue = (value || '').split('.')[0].replace(/[^0-9-]/g, '').replace(/(?!^)-/g, '')
    if (newValue) {
      const numeric = parseInt(newValue)
      // Don't allow negative numbers
      if (numeric < 0) {
        newValue = '0'
      }
    }
    setRadius(newValue)
  }

  const getCustomFieldValue = (fieldId) => {
    const match = customFields.find(item => {
      return (item.id === fieldId)
    })
    if (match) return match.value || null
    return null
  }

  const setCustomFieldValue = (fieldId, value) => {
    // Find existing
    let existing = false
    const newCustomFields = customFields.map(item => {
      if (item.id === fieldId) {
        existing = true
        return { ...item, value }
      }
      return item
    })
    if (!existing) {
      // Add new
      newCustomFields.push({ id: fieldId, value })
    }
    setCustomFields(newCustomFields)
  }

  const updateEntityPhoto = (files) => {
    if (files && files.length) {
      const file = files[0]
      setPhoto(URL.createObjectURL(file))
      props.updatePhoto(file)
    }
  }

  const removeEntityPhoto = () => {
    setPhoto(null)
    props.removePhoto()
  }

  const handleSave = () => {
    /* Descriptions */
    let newDescriptionHtmlFi = null
    let newDescriptionFi = null

    if (descriptionFi) {
      newDescriptionHtmlFi = linkifyHtml(descriptionFi)
      newDescriptionFi = decode(striptags(newDescriptionHtmlFi, [], '\n').trim()).trim()
      if (!newDescriptionFi) newDescriptionHtmlFi = null
    }

    let newDescriptionHtmlEn = null
    let newDescriptionEn = null

    if (descriptionEn) {
      newDescriptionHtmlEn = linkifyHtml(descriptionEn)
      newDescriptionEn = decode(striptags(newDescriptionHtmlEn, [], '\n').trim()).trim()
      if (!newDescriptionEn) newDescriptionHtmlEn = null
    }

    /* Geofence descriptions */
    let newGeofenceDescriptionHtmlFi = null
    let newGeofenceDescriptionFi = null

    if (geofenceDescriptionFi) {
      newGeofenceDescriptionHtmlFi = linkifyHtml(geofenceDescriptionFi)
      newGeofenceDescriptionFi = decode(striptags(newGeofenceDescriptionHtmlFi, [], '\n').trim()).trim()
      if (!newGeofenceDescriptionFi) newGeofenceDescriptionHtmlFi = null
    }

    let newGeofenceDescriptionHtmlEn = null
    let newGeofenceDescriptionEn = null

    if (geofenceDescriptionEn) {
      newGeofenceDescriptionHtmlEn = linkifyHtml(geofenceDescriptionEn)
      newGeofenceDescriptionEn = decode(striptags(newGeofenceDescriptionHtmlEn, [], '\n').trim()).trim()
      if (!newGeofenceDescriptionEn) newGeofenceDescriptionHtmlEn = null
    }


    let newValidFrom = null
    if (type === 'geofence') {
      newValidFrom = moment(`${validFrom} ${validFromTime}`).format()
    }

    let newValidUntil = null
    if (type === 'geofence') {
      newValidUntil = moment(`${validUntil} ${validUntilTime}`).format()
    }

    props.onSave(props.subEntity.id, {
      nameFi,
      nameEn,
      type,
      subEntityTypeId,
      descriptionFi: newDescriptionFi,
      descriptionHtmlFi: newDescriptionHtmlFi,
      descriptionEn: newDescriptionEn,
      descriptionHtmlEn: newDescriptionHtmlEn,

      geofenceTitleFi,
      geofenceDescriptionFi: newGeofenceDescriptionFi,
      geofenceDescriptionHtmlFi: newGeofenceDescriptionHtmlFi,

      geofenceTitleEn,
      geofenceDescriptionEn: newGeofenceDescriptionEn,
      geofenceDescriptionHtmlEn: newGeofenceDescriptionHtmlEn,

      geofenceType: type !== 'geofence' ? null : (arrivalActive ? 'enter' : 'leave'),

      radius: Number(radius) || 0,
      fields: customFields,
      validFrom: newValidFrom,
      validUntil: newValidUntil
    })
  }

  const editGpxRecording = (rec) => {
    setGpxRecording(rec)
    setGpxRecordingModalVisible(true)
  }

  const saveGpxRecording = (id, rec) => {
    props.updateGpxRecording(id, rec)
    setGpxRecordingModalVisible(false)
  }


  const renderCustomField = (field) => {
    return (
      <CustomField
        value={getCustomFieldValue(field.id)}
        onChange={(value) => setCustomFieldValue(field.id, value)}
        field={field}
        lang={lang}
      />
    )
  }

  const getAdditionalCustomFields = (type, subEntityFields) => {
    const fields = getCustomLayoutFields(type)
    return subEntityFields.filter(mef => !fields.includes(mef.fieldKey))
  }

  const renderCustomLayout = (block, subEntityFields) => {
    if (Array.isArray(block)) {
      return block.map((b) => renderCustomLayout(b, subEntityFields))
    }

    // Check if we should hide this block
    if (block && block.hiddenCheck) {
      if (block.hiddenCheck(subEntityFields, customFields)) return null
    }

    if (block.type === 'container') {
      return (
        <div className={block.className ? classes[block.className] : ''} style={block.style || {}}>
          {renderCustomLayout(block.items, subEntityFields)}
        </div>
      )
    }
    if (block.type === 'title') {
      return (
        <div className={block.className ? classes[block.className] : ''} style={block.style}>
          <div className={classes.customTitle}>
            {t(block.name)}
          </div>
        </div>
      )
    }
    if (block.type === 'big_title') {
      return (
        <div className={block.className ? classes[block.className] : ''} style={block.style}>
          <div className={classes.customBigTitle}>
            {t(block.name)}
          </div>
          {block?.info ? <p className={classes.customBlockInfo}>{t(block.info)}</p> : null}
        </div>
      )
    }
    if (block.type === 'description') {
      return (
        <div>
          <div className={classes.title}>{t('description_text')}</div>
          <HtmlInput
            key={lang}
            value={getDescription()}
            onChange={handleSetDescription}
          />
        </div>
      )
    }
    if (block.type === 'custom_field') {
      const customField = subEntityFields.find((field) => field.fieldKey === block.field)
      return (
        <div style={block.style}>
          {customField ? renderCustomField({
            ...customField,
            labelFi: block.label ? t(block.label) : !block.hideLabel ? customField.labelFi : null,
            customType: block.customType ?? null,
            info: block.info ?? null,
            positiveOptionLabel: block.positiveOptionLabel ?? null,
            negativeOptionLabel: block.negativeOptionLabel ?? null
          }) : null}
        </div>
      )
    }
    return null
  }

  const getCustomLayoutFields = (type) => {
    const extractCustomlayoutFields = (block) => {
      let fields = []
      if (Array.isArray(block)) {
        const newFields = block.map((b) => extractCustomlayoutFields(b))
        fields = [...fields, ...newFields]
      }
      if (block.type === 'container') {
        const newFields = extractCustomlayoutFields(block.items)
        fields = [...fields, ...newFields]
      }
      if (block.type === 'custom_field') {
        return [block.field]
      }
      return flatten(fields).filter(v => !!v)
    }

    if (type) {
      const customLayout: any = CUSTOM_FIELD_CUSTOM_LAYOUTS.find(layout => layout.type === `subentity_${type}`)
      if (customLayout) {
        return extractCustomlayoutFields(customLayout.layout)
      }
    }
    return []
  }

  const renderCustomLayoutCustomFields = (column) => {
    let selectedType = props.subEntityTypes.find((item) => {
      if (subEntityTypeId === item.id) return true
      return false
    })

    // Temporary fix to include custom forms for area and route types
    if (!selectedType && ['area', 'route'].includes(subEntity?.type)) {
      selectedType = subEntity
    }

    if (selectedType) {
      const customLayout: any = CUSTOM_FIELD_CUSTOM_LAYOUTS.find(layout => layout.type === `subentity_${selectedType.type}`)
      if (customLayout && customLayout.layout[column]) {
        return renderCustomLayout(customLayout.layout[column], selectedType.subEntityFields)
      }
    }
    return null
  }

  const renderSubEntityType = () => {
    const subEntityTypeOptions = getSubEntityTypeOptions()
    if (subEntityTypeOptions?.length) {
      return (
        <div className={classes.row}>
          <div className={classes.subentityTypeColumn}>
            <div className={classes.title}>{t('sub_entity_type')}</div>
            <Select
              options={subEntityTypeOptions}
              value={subEntityTypeId}
              onChange={setSubEntityTypeId}
            />
          </div>
        </div>
      )
    }
    return null
  }

  const renderBasicInfo = () => {
    return (
      <div className={props.fullWidth ? classes.gridContainerFull : classes.gridContainer}>
        <div className={props.fullWidth ? classes.gridColumnLeft : ''}>
          <LargeImageUpload
            photo={photo}
            onFileUpload={updateEntityPhoto}
            removePhoto={removeEntityPhoto}
            minimized
          />
          <div className={classes.rowContainer}>
            <div className={classes.entityTitleColumn}>
              <div className={classes.title}>{t('entity_name')}</div>
              <Input
                placeholder={t('entity_name')}
                value={getName()}
                onChange={handleSetName}
              />
            </div>
            <div className={classes.entityTypeColumn}>
              <div className={classes.title}>{t('entity_type')}</div>
              <Select
                options={featureTypeOptions}
                value={type}
                onChange={handleSetType}
                disabled
              />
            </div>
          </div>
          {renderSubEntityType()}
          {
            type === 'geofence' ? (
              <div className={classes.customBigTitle}>{t('properties')}</div>
            ) : null
          }
          {renderGeofenceForm()}
        </div>
        {renderCustomLayoutCustomFields(0)}
        {renderCustomLayoutCustomFields(1)}
      </div>
    )
  }

  const renderGeofenceForm = () => {
    if (type === 'geofence') {
      return (
        <>
          <div className={classes.row}>
            <div className={classes.fromDateColumn}>
              <div className={classes.title}>{t('validity')}</div>
              <Input value={validFrom} onChange={setValidFrom} type='date' />
            </div>
            <div className={classes.timeColumn}>
              <p className={classes.separatorText}>{t('at')}</p>
              <div className={classes.paddedRow}>
                <Input value={validFromTime} onChange={setValidFromTime} type='time' />
              </div>
            </div>
            <div className={classes.dashSpacer}>_</div>
            <div className={classes.untilDateColumn}>
              <Input value={validUntil} onChange={setValidUntil} type='date' />
            </div>
            <div className={classes.timeColumn}>
              <p className={classes.separatorText}>{t('at')}</p>
              <div className={classes.paddedRow}>
                <Input value={validUntilTime} onChange={setValidUntilTime} type='time' />
              </div>
            </div>
          </div>
          <div className={classes.rowContainer}>
            <div className={classes.radiusColumn}>
              <div className={classes.title}>{t('radius_in_meters')}</div>
              <Input value={radius} onChange={setRadiusControlled} type='number' />
            </div>
          </div>
          <div className={classes.customBigTitle}>{t('notification')}</div>
          <div className={classes.booleanRoot}>
            <div className={classes.wideBooleanContainer}>
              {['on_arrival', 'on_leave'].map((label, index) => {
                return (
                  <Checkbox
                    key={index}
                    checked={!!index !== !!arrivalActive}
                    onChange={toggleArrivalActive}
                    label={t(label)}
                  />
                )
              })}
            </div>
          </div>

          <div className={classes.notificationContainer}>
            <div className={classes.notificationTitleColumn}>
              <div className={classes.title}>{t('title')}</div>
              <Input value={getGeofenceTitle()} onChange={handleSetGeofenceTitle} />
            </div>
            <div className={classes.title}>{t('description')}</div>
            <HtmlInput
              key={lang}
              value={getGeofenceDescription()}
              onChange={handleSetGeofenceDescription}
            />
          </div>
        </>
      )
    }
    return null
  }

  const renderGpxRecordingModal = () => {
    if (gpxRecordingModalVisible) {
      return (
        <GpxRecordingModal
          item={gpxRecording}
          handleSave={saveGpxRecording}
          handleClose={() => setGpxRecordingModalVisible(false)}
        />
      )
    }
    return null
  }

  const renderGpxRecordingsTable = () => {
    const filteredItems = (props.mainEntityGpxRecordings || []).filter(item => {
      return props.subEntity && props.subEntity.id === item.subEntityId
    })

    return (
      <>
        <GpxRecordingTable
          items={filteredItems}
          onReplace={props.addOrReplaceFeature}
          exportGpxRecording={props.exportGpxRecording}
          onDelete={props.deleteGpxRecording}
          onEdit={editGpxRecording}
        />
        {renderGpxRecordingModal()}
      </>
    )
  }

  const renderAdditionalInfo = () => {
    const selectedType = props.subEntityTypes.find(item => {
      if (subEntityTypeId === item.id) {
        return true
      }
      return false
    })

    if (selectedType) {
      const fields = getAdditionalCustomFields(selectedType.type, selectedType.subEntityFields)
      return fields.map((field, index) => {
        return (
          <div key={index}>
            {renderCustomField(field)}
          </div>
        )
      })
    }

    return null
  }

  const renderTabs = () => {
    const tabs = [
      { name: t('basic_info'), value: TABS.basicInfo }
    ]

    // Check if we have custom fields
    const selectedType = props.subEntityTypes.find((item) => {
      if (subEntityTypeId === item.id) {
        return true
      }
      return false
    })

    if (
      selectedType &&
      selectedType.subEntityFields.length &&
      getAdditionalCustomFields(selectedType.type, selectedType.subEntityFields).length
    ) {
      tabs.push({ name: t('additional_info'), value: TABS.additionalInfo })
    }

    if (props?.mainEntityGpxRecordings?.find(rec => rec.subEntityId === props.subEntity?.id)) {
      tabs.push({ name: t('gpx_recordings'), value: TABS.gpxRecordings })
    }

    return <FormTabs value={tab} onChange={setTab} tabs={tabs} />
  }

  const renderContent = () => {
    if (tab === TABS.basicInfo) return renderBasicInfo()
    if (tab === TABS.additionalInfo) return renderAdditionalInfo()
    if (tab === TABS.gpxRecordings) return renderGpxRecordingsTable()
  }

  return (
    <>
      <div className={classes.container}>
        <div className={props.fullWidth ? classes.fullContentContainer : classes.contentContainer}>
          {renderTabs()}
          <div className={classes.content}>
            {renderContent()}
          </div>
        </div>
      </div>
      <div className={props.fullWidth ? classes.fullFooterContainer : classes.footerContainer}>
        <div className={props.fullWidth ? classes.fullFooterContentContainer : classes.footerContentContainer}>
          <div>
            <Select
              options={LANG_OPTIONS}
              value={lang}
              onChange={setLang}
              containerStyle={styles.langContainer}
              style={styles.langSelect}
            />
          </div>
          <div className={classes.flexSpacer} />
          <Button
            onClick={props.onCancel}
            text={t('cancel')}
            buttonStyle={classes.cancelButton}
            outlined
          />
          <Button
            onClick={handleSave}
            text={t('save')}
            buttonStyle={classes.saveButton}
          />
        </div>
      </div>
    </>
  )
}

export default EditSubEntityForm
