import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'
import { useStore } from '../../../Models/RootStore'
import { PageHeader } from '../../../Components'
import AdminTable from '../../../Components/Admin/AdminTable'
import { useNavigate } from 'react-router-dom'

const TABS = {
  mainEntityTypes: 0,
  mainEntityFields: 1
}

const useStyles = makeStyles((theme) => ({
  root: {
  },
  container: {
    padding: '3rem'
  },
  cardsContainer: {
  },
  row: {
    display: 'flex',
    flexDirection: 'row'
  }
}))

function AdminMainEntityTypes() {
  const classes = useStyles()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const getTabs = () => [
    { value: TABS.mainEntityTypes, label: t('main_entity_types') },
    { value: TABS.mainEntityFields, label: t('main_entity_fields') }
  ]
  const [tab, setTab] = useState(TABS.mainEntityTypes)

  const selectTab = (value) => setTab(value)

  const { adminStore }: any = useStore()

  useEffect(() => {
    adminStore.getMainEntityTypes()
    adminStore.getMainEntityFields()
  }, [])

  const toCreate = () => {
    if (tab === TABS.mainEntityTypes) navigate('/admin/main-entity-types/create')
    if (tab === TABS.mainEntityFields) navigate('/admin/main-entity-fields/create')
  }

  const openMainEntityType = (item) => navigate(`/admin/main-entity-types/${item.id}`)
  const openMainEntityField = (item) => navigate(`/admin/main-entity-fields/${item.id}`)

  const renderMainEntityTypes = () => (
    <AdminTable
      items={adminStore.mainEntityTypes}
      onClick={openMainEntityType}
      fields={[
        { key: 'id', name: 'id' },
        { key: 'type', name: 'type' },
        { key: 'nameFi', name: 'name_fi' },
        { key: 'nameEn', name: 'name_en' }
      ]}
    />
  )

  const renderMainEntityFields = () => (
    <AdminTable
      items={adminStore.mainEntityFields}
      onClick={openMainEntityField}
      fields={[
        { key: 'id', name: 'id' },
        { key: 'fieldType', name: 'fieldType' },
        { key: 'fieldKey', name: 'fieldKey' },
        { key: 'labelFi', name: 'labelFi' },
        { key: 'labelEn', name: 'labelEn' }
      ]}
    />
  )

  const renderContent = () => {
    if (tab === TABS.mainEntityTypes) return renderMainEntityTypes()
    if (tab === TABS.mainEntityFields) return renderMainEntityFields()
  }

  return (
    <div className={classes.root}>
      <PageHeader
        title={t('main_entity_types')}
        tabs={getTabs()}
        currentTab={tab}
        onTabChange={selectTab}
        onCreateClick={toCreate}
      />
      <div className={classes.container}>
        {renderContent()}
      </div>
    </div>
  )
}

export default observer(AdminMainEntityTypes)
