import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'
import get from 'lodash/get'
import { useParams } from 'react-router-dom'
import { useStore } from '../../../Models/RootStore'
import { PageHeader, Button, Checkbox, Input, Select } from '../../../Components'
import { ENTITY_GEO_TYPES } from '../../../Constants'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  contentContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 0
  },
  content: {
    padding: '2.5rem'
  }
}))

const AdminSubEntityType = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { adminStore }: any = useStore()
  const { subEntityTypeId }: any = useParams()

  useEffect(() => {
    adminStore.getSubEntityType(Number(subEntityTypeId))
    adminStore.getSubEntityFields()
    adminStore.getSubEntityTypesSubEntityFields()
  }, [])

  useEffect(() => {
    if (adminStore.subEntityType && adminStore.subEntityType.id === Number(subEntityTypeId)) {
      setType(get(adminStore.subEntityType, 'type', null))
      setNameFi(get(adminStore.subEntityType, 'nameFi', null))
      setNameEn(get(adminStore.subEntityType, 'nameEn', null))
      setIcon(get(adminStore.subEntityType, 'icon', null))
      setGeoType(get(adminStore.subEntityType, 'geoType', null))
    }
  }, [adminStore.subEntityType])

  useEffect(() => {
    if (adminStore.subEntityTypesSubEntityFields) {
      setFields(
        adminStore.subEntityTypesSubEntityFields.filter(item => {
          return item.subEntityTypeId === Number(subEntityTypeId)
        }).map(({ subEntityFieldId }) => subEntityFieldId)
      )
    }
  }, [adminStore.subEntityTypesSubEntityFields])

  const [type, setType] = useState(null)
  const [nameFi, setNameFi] = useState(null)
  const [nameEn, setNameEn] = useState(null)
  const [icon, setIcon] = useState(null)
  const [geoType, setGeoType] = useState(null)

  const [fields, setFields] = useState([])

  const handleSave = () => {
    adminStore.editSubEntityType(
      Number(subEntityTypeId),
      {
        type,
        name: nameFi,
        nameFi,
        nameEn,
        icon,
        geoType
      }
    )

    // Detect changes
    const addFields = []
    const deleteFields = []

    const currentFields = adminStore.subEntityTypesSubEntityFields.filter(f => {
      return f.subEntityTypeId === Number(subEntityTypeId)
    }).map(({ subEntityFieldId }) => subEntityFieldId)

    for (const currentField of currentFields) {
      if (!fields.includes(currentField)) {
        deleteFields.push(currentField)
      }
    }

    for (const field of fields) {
      if (!currentFields.includes(field)) {
        addFields.push(field)
      }
    }

    if (deleteFields.length) adminStore.deleteSubEntityTypeFields(subEntityTypeId, deleteFields)
    if (addFields.length) adminStore.addSubEntityTypeFields(subEntityTypeId, addFields)
  }

  const toggleField = (id) => {
    const newFields = fields.filter(fieldId => fieldId !== id)
    if (newFields.length === fields.length) {
      newFields.push(id)
    }
    setFields(newFields)
  }

  const renderLinkedCustomFields = () => {
    return adminStore.subEntityFields.map((f, index) => {
      return (
        <div key={f.id}>
          <Checkbox
            checked={fields.includes(f.id)}
            onChange={() => {
              toggleField(f.id)
            }}
            label={`${f.id} ${f.labelFi} (${f.fieldKey}, ${f.fieldType})`}
          />
        </div>
      )
    })
  }

  const getGeoTypeOptions = () => {
    return ENTITY_GEO_TYPES.map(type => {
      return {
        value: type,
        label: type
      }
    })
  }

  return (
    <div className={classes.root}>
      <PageHeader
        title={t('edit_sub_entity_type')}
      />
      <div className={classes.contentContainer}>
        <div className={classes.content}>
          <Input label={t('type')} value={type} onChange={setType} />
          <Input label={t('nameFi')} value={nameFi} onChange={setNameFi} />
          <Input label={t('nameEn')} value={nameEn} onChange={setNameEn} />
          <Input label={t('icon')} value={icon} onChange={setIcon} />

          <Select
            options={getGeoTypeOptions()}
            value={geoType}
            onChange={setGeoType}
            label={t('geo_type')}
          />

          <Button
            text={t('save')}
            onClick={handleSave}
          />
          <hr />
          <h1>Custom fields</h1>
          {renderLinkedCustomFields()}
        </div>
      </div>
    </div>
  )
}

export default observer(AdminSubEntityType)
