import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'

export default class EntityStore {
  rootStore

  mainEntities: any = []
  mainEntity: any = null
  createdMainEntityId: number = null
  mainEntityTypes = []
  mainEntityTypeFields = null
  subEntityTypes = []
  mainEntityTypeSubEntityTypes = []
  mainEntityGpxRecordings = null
  subEntityTypeFields = null
  snapshots = []
  snapshot = null
  snapshotCreatedAt = null
  maintenanceLogs = []
  predefinedCreateMainEntityFields = null
  loading: any = null

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  updateMainEntities = (mainEntities) => { this.mainEntities = mainEntities }
  updateMainEntity = (mainEntity) => { this.mainEntity = mainEntity }
  updateMainEntityTypes = (types) => { this.mainEntityTypes = types }
  updateMainEntityTypeSubEntityTypes = (mainEntityTypeSubEntityTypes) => { this.mainEntityTypeSubEntityTypes = mainEntityTypeSubEntityTypes }
  updateMainEntityTypeFields = (fields) => { this.mainEntityTypeFields = fields }
  updateMainEntityGpxRecordings = (mainEntityGpxRecordings) => { this.mainEntityGpxRecordings = mainEntityGpxRecordings }
  updateSubEntityTypes = (types) => { this.subEntityTypes = types }
  updateSubEntityTypeFields = (fields) => { this.subEntityTypeFields = fields }
  updateSnapshots = (snapshots) => { this.snapshots = snapshots }
  updateSnapshot = (snapshot) => { this.snapshot = snapshot }
  updateSnapshotCreatedAt = (snapshotCreatedAt) => { this.snapshotCreatedAt = snapshotCreatedAt }
  updateMaintenanceLogs = (logs) => { this.maintenanceLogs = logs }
  updateLoading = (loading) => { this.loading = loading }

  setPredefinedCreateMainEntityFields = (fields) => {
    this.predefinedCreateMainEntityFields = {
      ...fields,
      timestamp: Date.now()
    }
  }

  async getMainEntities() {
    this.updateLoading(true)
    const response = await Api.getMainEntities()
    this.updateLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, true, false)

    if (response.ok) {
      const mainEntities = response.data
      this.updateMainEntities(mainEntities)
    }
  }

  async getMainEntity(mainEntityId) {
    this.updateLoading(true)
    const response = await Api.getMainEntity(mainEntityId)
    this.updateLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, true, false)

    if (response.ok) {
      const mainEntity = response.data
      this.updateMainEntity(mainEntity)

      // Update main entity list
      const mainEntities = this.mainEntities.map(item => {
        if (item.id === mainEntityId) return response.data
        return item
      })
      this.updateMainEntities(mainEntities)
    }
  }

  async getMainEntityTypes() {
    this.updateLoading(true)
    const response: any = await Api.getMainEntityTypes()
    this.updateLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, true, false)

    if (response.ok) {
      const mainEntityTypes = response.data
      this.updateMainEntityTypes(mainEntityTypes)
    }
  }

  async getSubEntityTypes() {
    this.updateLoading(true)
    const response: any = await Api.getSubEntityTypes()
    this.updateLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, true, false)
    if (response.ok) {
      const subEntityTypes = response.data
      this.updateSubEntityTypes(subEntityTypes)
    }
  }

  async getMainEntityTypeSubEntityTypes(mainEntityTypeId) {
    this.updateLoading(true)
    const response: any = await Api.getMainEntityTypeSubEntityTypes(mainEntityTypeId)
    this.rootStore.appStore.setErrorOrSuccess(response, true, false)
    if (response.ok) {
      const mainEntityTypeSubEntityTypes = response.data
      this.updateMainEntityTypeSubEntityTypes(mainEntityTypeSubEntityTypes)
    }
  }

  async createMainEntity(mainEntity, callback) {
    this.updateLoading(true)
    const response = await Api.createMainEntity(mainEntity)
    this.updateLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'entity_created')

    if (response.ok) {
      const newMainEntity: any = response.data
      // Load new main entity
      await this.getMainEntity(newMainEntity.id)
      callback(newMainEntity.id)
      /*
      this.createdMainEntityId = newMainEntity.id
      setTimeout(() => this.createdMainEntityId = null, 500)
      */
    }
  }

  async updateMainEntityFields(mainEntityId, mainEntity) {
    this.updateLoading(true)
    const response: any = await Api.updateMainEntity(mainEntityId, mainEntity)
    this.updateLoading(false)
    if (response.ok) {
      // Update main entity list
      const mainEntities = this.mainEntities.map(item => {
        if (item.id === mainEntityId) return response.data
        return item
      })
      this.updateMainEntities(mainEntities)
      // Update current main entity if needed
      if (this.mainEntity && this.mainEntity.id === mainEntityId) {
        const mainEntity = response.data
        this.updateMainEntity(mainEntity)
      }
    }
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'info_saved')
  }

  async deleteMainEntity(mainEntityId) {
    this.updateLoading(true)
    const response = await Api.deleteMainEntity(mainEntityId)
    this.updateLoading(false)
    if (response.ok) {
      // Update main entity list
      this.mainEntities = this.mainEntities.map(item => {
        if (item.id === mainEntityId) return response.data
        return item
      })
      // Update current main entity if needed
      if (this.mainEntity && this.mainEntity.id === mainEntityId) {
        this.mainEntity = response.data
      }
    }
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'entity_deleted')
  }

  async restoreMainEntity(mainEntityId) {
    this.updateLoading(true)
    const response = await Api.updateMainEntity(mainEntityId, { active: true })
    this.updateLoading(false)
    if (response.ok) {
      // Update main entity list
      const mainEntities = this.mainEntities.map(item => {
        if (item.id === mainEntityId) return response.data
        return item
      })
      this.updateMainEntities(mainEntities)
      // Update current main entity if needed
      if (this.mainEntity && this.mainEntity.id === mainEntityId) {
        const mainEntity = response.data
        this.updateMainEntity(mainEntity)
      }
    }
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'entity_restored')
  }

  async createSubEntity(subEntity) {
    this.updateLoading(true)
    const response = await Api.createSubEntity(subEntity)
    this.updateLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'entity_created')

    if (response.ok) {
      // Reload
      await this.getMainEntity(this.mainEntity.id)
    }
  }

  async updateSubEntity(subEntityId, subEntity) {
    this.updateLoading(true)
    const response: any = await Api.updateSubEntity(subEntityId, subEntity)
    this.updateLoading(false)
    if (response.ok) {
      // Update data
      if (this.mainEntity) {
        const mainEntity = this.mainEntity
        mainEntity.subEntities = mainEntity.subEntities.map(item => {
          if (item.id === response.data.id) {
            return response.data
          }
          return item
        })
        this.updateMainEntity(mainEntity)
      }
    }
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'info_saved')
  }

  async deleteSubEntity(subEntityId: number) {
    this.updateLoading(true)
    const response = await Api.deleteSubEntity(subEntityId)
    this.updateLoading(false)
    if (response.ok) {
      this.getMainEntity(this.mainEntity?.id)
    }
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'entity_deleted')
  }

  async getLatestSnapshot(mainEntityId) {
    this.updateLoading(true)
    const response = await Api.getLatestSnapshot(mainEntityId)
    if (response.ok) {
      const snapshot = response.data
      this.updateSnapshot(snapshot)
    }
    this.updateLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, true, false)
  }

  async getSnapshots(mainEntityId) {
    this.updateLoading(true)
    const response: any = await Api.getSnapshots(mainEntityId)
    if (response.ok) {
      const snapshots = response.data
      this.updateSnapshots(snapshots)
    }
    this.updateLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, true, false)
  }

  async createSnapshot(mainEntityId, message) {
    this.updateLoading(true)
    const response = await Api.createSnapshot(mainEntityId, { message })
    if (response.ok) {
      this.getLatestSnapshot(mainEntityId)
      this.updateSnapshotCreatedAt(Date.now())
    }
    this.updateLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'info_saved')
  }

  async restoreSnapshot(snapshotId) {
    this.updateLoading(true)
    const response = await Api.restoreSnapshot(snapshotId)
    if (response.ok) {
    }
    this.updateLoading(false)

    this.rootStore.appStore.setErrorOrSuccess(response, true, 'version_restored')
  }

  async updateMainEntityPhoto(mainEntityId, data) {
    this.updateLoading(true)
    const response = await Api.updateMainEntityPhoto(mainEntityId, data)
    this.updateLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'info_updated')
    if (response.ok) {
      // Update main entity list
      const mainEntities = this.mainEntities.map(item => {
        if (item.id === mainEntityId) return response.data
        return item
      })
      this.updateMainEntities(mainEntities)
      // Update current main entity if needed
      if (this.mainEntity && this.mainEntity.id === mainEntityId) {
        const mainEntity = response.data
        this.updateMainEntity(mainEntity)
      }
    }
  }

  async updateSubEntityPhoto(mainEntityId, data) {
    this.updateLoading(true)
    const response: any = await Api.updateSubEntityPhoto(mainEntityId, data)
    this.updateLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'info_updated')
    if (response.ok) {
      // Update data
      if (this.mainEntity) {
        const mainEntity = this.mainEntity
        mainEntity.subEntities = mainEntity.subEntities.map(item => {
          if (item.id === response.data.id) {
            return response.data
          }
          return item
        })
        this.updateMainEntity(mainEntity)
      }
    }
  }

  async getMainEntityMaintenanceLogs(mainEntityId) {
    this.updateLoading(true)
    try {
      const response: any = await Api.getMainEntityNotes(mainEntityId)
      console.log(response)
      if (response.ok) {
        const logs = response.data ?? []
        this.updateMaintenanceLogs(logs)
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  createMaintenanceLog = async (log) => {
    this.updateLoading(true)
    try {
      const response = await Api.createNote(log)
      if (response && response.ok) {
        this.getMainEntityMaintenanceLogs(log?.mainEntityId)
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  updateMaintenanceLog = async (id, log) => {
    this.updateLoading(true)
    try {
      const response = await Api.editNote(id, log)
      if (response && response.ok) {
        this.getMainEntityMaintenanceLogs(log?.mainEntityId)
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  deleteMaintenanceLog = async (id) => {
    this.updateLoading(true)
    try {
      const response = await Api.deleteNote(id)
      this.rootStore.appStore.setErrorOrSuccess(response, true, 'maintenance_log_deleted')
      if (response && response.ok) {
        if (this.maintenanceLogs) {
          this.updateMaintenanceLogs(this.maintenanceLogs.filter(log => log.id !== id))
        }
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  getGpxRecordings = async (mainEntityId) => {
    this.updateLoading(true)
    this.updateMainEntityGpxRecordings(null)
    const response = await Api.getMainEntityGpxRecordings(mainEntityId)
    if (response.ok) {
      this.updateMainEntityGpxRecordings(response.data.items)
    }
    this.updateLoading(false)
  }

  exportGpxRecording = (gpxRecordingId, filename) => {
    return Api.exportGpxRecordingFile(gpxRecordingId, filename)
  }

  updateGpxRecording = async (id, recording) => {
    this.updateLoading(true)
    const response = await Api.updateGpxRecording(id, recording)
    if (response.ok) {
      this.updateMainEntityGpxRecordings([...(this.mainEntityGpxRecordings || []).map(item => {
        if (item.id === id) return { ...item, ...recording }
        return item
      })])
    }
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'info_updated')
    this.updateLoading(false)
  }

  deleteGpxRecording = async (id) => {
    this.updateLoading(true)
    const response = await Api.updateGpxRecording(id, { active: false })
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'info_updated')
    if (response.ok) {
      this.updateMainEntityGpxRecordings([...(this.mainEntityGpxRecordings || []).filter(item => {
        return item.id !== id
      })])
    }
    this.updateLoading(false)
  }
}
