import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import { useTranslation } from 'react-i18next'
import { TableHeader, DataMessage } from '..'
import EntityVersionTableRow from './EntityVersionTableRow'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  root: {
  },
  spacer: {
    height: '1rem'
  }
}))

function EntityVersionTable (props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const renderRows = () => {
    if (props.items) {
      return props.items.map((item) => {
        return (
          <React.Fragment key={item.id}>
            <div className={classes.spacer} />
            <EntityVersionTableRow item={item} onRestore={props.onRestore} />
          </React.Fragment>
        )
      })
    }
    return null
  }

  return (
    <>
      <Table classes={{ root: classes.root }}>
        <TableHeader columnNames={[t('name'), t('message'), t('type'), t('created_at'), t('actions')]} />
        <TableBody>{renderRows()}</TableBody>
      </Table>
      <DataMessage data={props.items} message={t('no_versions')} />
    </>
  )
}

export default EntityVersionTable
