import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Colors } from '../../Utils/theme'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import Grid from '@mui/material/Grid'
import ButtonBase from '@mui/material/ButtonBase'
import CalendarIcon from '../../Assets/Icons/calendar-filled.svg'
import ProgramIcon from '../../Assets/Icons/program.svg'
import RecordingIcon from '../../Assets/Icons/recording.svg'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    // minWidth: '28rem',
    backgroundColor: Colors.white,
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.black20}`,
    '&:hover': {
      backgroundColor: Colors.black05
    }
  },
  image: {
    backgroundColor: Colors.border,
    height: '15rem',
    maxHeight: '13rem',
    width: 'calc(100% + 2px)',
    marginTop: -1,
    marginLeft: -1,
    [theme.breakpoints.down('xl')]: {
      maxHeight: '12rem'
    },
    [theme.breakpoints.down('md')]: {
      maxHeight: '15rem'
    }
  },
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '0.75rem 1rem'
  },
  type: {
    color: Colors.violet,
    fontSize: '0.875rem',
    fontFamily: 'Roboto',
    fontWeight: 700,
    margin: 0,
    padding: 0,
    paddingBottom: '0.375rem'
  },
  name: {
    color: Colors.inputText,
    fontSize: '1.25rem',
    fontFamily: 'Rubik',
    fontWeight: 700,
    margin: 0,
    padding: 0,
    paddingBottom: '0.25rem',
    textAlign: 'left'
  },
  hr: {
    width: '100%',
    border: 0,
    borderTop: `1px dashed ${Colors.border}`,
    paddingBottom: '0.5rem'
  },
  footer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  leftRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: '3rem'
  },
  rightRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: '1.5rem'
  },
  icon: {
    maxHeight: '1.125rem',
    maxWidth: '1.125rem',
    marginRight: '0.5rem'
  },
  footerText: {
    color: Colors.label,
    fontSize: '0.75rem',
    fontFamily: 'Roboto',
    fontWeight: 600,
    paddingTop: '0.125rem',
    margin: 0,
    padding: 0
  },
  programButton: {
    '&:hover': {
      '& > p': {
        color: `${Colors.violet} !important`
      },
      textDecoration: 'underline'
    }
  },
  badge: {
    position: 'absolute',
    top: '0.75rem',
    left: '0.75rem',
    height: '1.875rem',
    borderRadius: '0.3125rem',
    backgroundColor: Colors.violet,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0 0.5rem'
  },
  badgeIcon: {
    maxHeight: '0.825rem',
    maxWidth: '0.825rem',
    marginRight: '0.5rem'
  },
  badgeText: {
    color: Colors.white,
    fontSize: '0.6875rem',
    fontFamily: 'Roboto',
    fontWeight: 700,
    textTransform: 'uppercase',
    margin: 0,
    padding: 0
  }
}))

function EventEntityCard (props) {
  const { item } = props
  const classes = useStyles()
  const { t } = useTranslation()

  // TODO: fix field ids
  const getDateText = () => {
    let startTime = item.fieldValues.find((field: any) => field.mainEntityFieldId === 120)?.value ?? null
    const now = moment()
    if (startTime) {
      if (moment(startTime).isAfter(now)) {
        startTime = moment(startTime).format(`DD.MM.YYYY [${t('at')}] HH.mm`) ?? ''
        return `${t('date_start')} ${startTime}`
      } else {
        let endTime = item.fieldValues.find((field: any) => field.mainEntityFieldId === 121)?.value ?? null
        if (endTime) {
          if (moment(endTime).isBefore(now)) {
            endTime = moment(endTime).format(`DD.MM.YYYY [${t('at')}] HH.mm`) ?? ''
            return `${t('ended')} ${endTime}`
          } else {
            endTime = moment(endTime).format(`DD.MM.YYYY [${t('at')}] HH.mm`) ?? ''
            return `${t('date_end')} ${endTime}`
          }
        }
      }
    }
    return null
  }

  const onClick = () => props.onClick(item.id)
  const viewProgram = (e) => {
    e.stopPropagation()
    props.onClick(item.id)
  }

  const renderImage = () => {
    if (item?.photo) {
      return <img src={item.photo} alt={t(item.type)} className={classes.image} />
    }
    return <div className={classes.image} />
  }

  // TODO: fix field ids
  const renderOngoingBadge = () => {
    const now = moment()
    const startTime = item.fieldValues.find((field: any) => field.mainEntityFieldId === 120)?.value ?? null
    const endTime = item.fieldValues.find((field: any) => field.mainEntityFieldId === 121)?.value ?? null
    if (startTime && endTime && moment(startTime).isBefore(now) &&  moment(endTime).isAfter(now)) {
      return (
        <div className={classes.badge}>
          <img src={RecordingIcon} alt={t('icon')} className={classes.badgeIcon} />
          <p className={classes.badgeText}>{t('ongoing_now')}</p>
        </div>
      )
    }
    return null
  }

  const renderRightRow = () => {
    return (
      <div className={classes.rightRow}>
        <img src={ProgramIcon} alt={t('program')} className={classes.icon} />
        <ButtonBase className={classes.programButton} onClick={viewProgram}>
          <p className={classes.footerText}>{t('view_program')}</p>
        </ButtonBase>
      </div>
    )
  }

  return (
    <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
      <ButtonBase className={classes.container} onClick={onClick}>
        {renderImage()}
        <div className={classes.content}>
          <p className={classes.type}>{item?.mainEntityType?.name ?? ''}</p>
          <p className={classes.name}>{item?.name ?? ''}</p>
          <hr className={classes.hr} />
          <div className={classes.footer}>
            <div className={classes.leftRow}>
              <img src={CalendarIcon} alt={t('date')} className={classes.icon} />
              <p className={classes.footerText}>{getDateText()}</p>
            </div>
            {/* renderRightRow() */}
          </div>
        </div>
        {renderOngoingBadge()}
      </ButtonBase>
    </Grid>
  )
}

export default EventEntityCard
