import React, { useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import get from 'lodash/get'
import uniq from 'lodash/uniq'
import { PageHeader, Select, Label } from '../../Components'
import PollAnswersChart from './PollAnswersChart'
import { Colors } from '../../Utils/theme'
import PollResultTextAnswers from './PollResultTextAnswers'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '2rem'
  },
  summaryContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '2rem'
  },
  summaryBox: {
    borderRadius: 3,
    padding: '1rem 2rem',
    minWidth: '14rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    border: '1px solid rgba(0, 0, 0, .5)'
  },
  summaryValue: {
    fontSize: '3rem'
  },
  summaryLabel: {
    fontSize: '1.125rem',
    fontWeight: 300
  },
  questionBox: {
    borderRadius: 3,
    border: '1px solid rgba(0, 0, 0, .5)',
    marginBottom: '2rem'
  },
  questionBoxChild: {
    marginLeft: '2rem',
    border: '1px dashed rgba(0, 0, 0, .5)',
  },
  questionBoxInner: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
  },
  questionSpacer: {
    position: 'absolute',
    top: '1rem',
    bottom: '1rem',
    left: '50%',
    width: 1,
    backgroundColor: 'rgba(0, 0, 0, .2)'
  },
  questionColumn: {
    flex: 1,
    padding: '1.5rem 2rem 2rem'
  },
  questionOptions: {
    padding: '1rem 0'
  },
  questionIndex: {
    fontSize: '1.125rem',
    fontWeight: 300
  },
  questionTitle: {
    fontFamily: 'Roboto',
    fontSize: '1.25rem',
    fontWeight: 700,
    color: Colors.violet,
    marginTop: '.25rem',
    marginBottom: '.5rem'
  },
  questionContent: {
    color: Colors.label,
    paddingBottom: '.5rem'
  },
  answerRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  answerIndex: {
    fontSize: '1.5rem',
    color: Colors.violet,
    fontWeight: 700,
    width: '3rem',
    borderRight: '1px solid rgba(0, 0, 0, .5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  answerContent: {
    display: 'flex',
    alignItems: 'center',
    padding: '1rem'
  }
}))

function PollResult(props) {
  const { t } = useTranslation()
  const classes = useStyles()

  const [mainEntityId, setMainEntityId] = useState('all')

  const getPollAnswers = () => {
    if (mainEntityId === 'all') return props.pollAnswers || []
    return (props.pollAnswers || []).filter(ans => {
      return ans.mainEntityId === mainEntityId
    })
  }

  const getTotalUniqueAnswerCount = () => {
    const deviceIds = getPollAnswers().map(ans => ans.deviceId)
    const uniqueDeviceIds = uniq(deviceIds)
    return uniqueDeviceIds.length
  }

  const renderSummary = () => {
    return (
      <div className={classes.summaryContainer}>
        <div className={classes.summaryBox}>
          <div className={classes.summaryValue}>{getTotalUniqueAnswerCount()}</div>
          <div className={classes.summaryLabel}>{t('num_answered_users')}</div>
        </div>
      </div>
    )
  }

  const indexToChar = (index) => String.fromCharCode(65 + parseInt(index || '0', 10))

  const getAnswerCount = (questionId) => {
    const answers = getPollAnswers().filter(item => item.questionId === questionId)
    const deviceIds = []
    const uniqueAnswers = answers.filter(ans => {
      if (!deviceIds.includes(ans.deviceId)) {
        deviceIds.push(ans.deviceId)
        return true
      } else {
        return false
      }
    })
    return uniqueAnswers.length
  }

  const renderChartAnswers = (question) => {
    const answers = getPollAnswers().filter(item => item.questionId === question.id)
    if (!answers.length) return null
    return (
      <PollAnswersChart
        question={question}
        answers={answers}
      />
    )
  }

  const renderTextAnswers = (questionId) => {
    const answers = getPollAnswers().filter(item => item.questionId === questionId)
    return (
      <PollResultTextAnswers
        answers={answers}
        mainEntities={props.mainEntities}
      />
    )
  }

  const renderQuestions = () => {
    const questions = get(props.poll, 'questions', [])

    let nth = 0
    return questions.map((question, index) => {
      let questionIndexTitle
      if (!question.parentId) {
        nth++
        questionIndexTitle = `${t('question')} ${nth}`
      } else {
        questionIndexTitle = `${t('question')} ${nth}.${indexToChar(question.parentAnswerIndex)}`
      }

      const answers = get(question, 'answersFi', [])
      return (
        <div className={[classes.questionBox, (question.parentId ? classes.questionBoxChild : '')].join(' ')}>
          <div className={classes.questionBoxInner}>
            <div className={classes.questionColumn}>
              <div className={classes.questionIndex}>{questionIndexTitle}</div>
              <div className={classes.questionTitle}>{question.titleFi}</div>
              <div className={classes.questionContent}>{question.contentFi}</div>
              <div className={classes.questionOptions}>
                {answers.map((ans, ansIndex) => {
                  return (
                    <div className={classes.answerRow}>
                      <div className={classes.answerIndex}>{indexToChar(ansIndex)}</div>
                      <div className={classes.answerContent}>{ans}</div>
                    </div>
                  )
                })}
              </div>
            </div>
            <div className={classes.questionSpacer} />
            <div className={classes.questionColumn}>
              <div className={classes.questionTitle}>
                {`${t('num_answers')} ${getAnswerCount(question.id)} ${t('num_items')}`}
              </div>
              <div className={classes.questionContent}>
                {t('question_type')}: {t(`answer_type_${question.type}`)}
              </div>
              <div className={classes.questionContent}>
                {question.isOptional ? t('not_mandatory') : t('mandatory')} {t('question').toLowerCase()}
              </div>
              {question.type !== 'text' && renderChartAnswers(question)}
            </div>
          </div>
          {question.type === 'text' && renderTextAnswers(question.id)}
        </div>
      )
    })
  }

  const renderMainEntitySelect = () => {
    const mainEntityOptions = props.mainEntities.filter(mainEntity => {
      return props.poll.mainEntityIds.includes(mainEntity.id)
    }).map(mainEntity => ({ label: mainEntity.nameFi || mainEntity.nameEn || '', value: mainEntity.id }))

    const options = [{ label: t('view_all_entities'), value: 'all' }, ...mainEntityOptions]

    return (
      <>
        <Label>{t('entity')}</Label>
        <Select
          options={options}
          value={mainEntityId}
          onChange={setMainEntityId}
        />
      </>
    )
  }

  return (
    <div className={classes.root}>
      <PageHeader
        title={t('poll_results')}
        onEditClick={props.toEdit}
      />
      <div className={classes.container}>
        {renderMainEntitySelect()}
        {renderSummary()}
        {renderQuestions()}
      </div>
    </div>
  )
}

export default PollResult
