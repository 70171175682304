import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'
import { validatePoll } from '../Utils/poll'

export default class PollStore {
  rootStore
  polls = []
  poll = null
  pollAnswers = []

  loading = null
  createOk = false

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  updatePolls = (polls) => { this.polls = polls }
  updatePollState = (poll) => { this.poll = poll }
  updatePollAnswers = (answers) => { this.pollAnswers = answers }
  updateCreateOk = (ok) => { this.createOk = ok }
  updateLoading = (loading) => { this.loading = loading }

  async getPolls() {
    this.updateLoading(true)
    try {
      const response: any = await Api.getPolls()
      if (response.ok) {
        const polls = response.data
        this.updatePolls(polls)
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async getPoll(id) {
    this.updateLoading(true)
    try {
      const response: any = await Api.getPoll(id)
      if (response.ok) {
        const poll = response.data
        this.updatePollState(poll)
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }


  async getPollAnswers(id) {
    this.updateLoading(true)
    try {
      const response: any = await Api.getPollAnswers(id)
      if (response.ok) {
        const pollAnswers = response.data
        this.updatePollAnswers(pollAnswers)
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async updatePoll(id, poll) {
    const validationError = validatePoll(poll)
    if (validationError) {
      // Simulate api error to display notification
      this.rootStore.appStore.setError(validationError)
      return
    }

    this.updateLoading(true)
    try {
      const response: any = await Api.updatePoll(id, poll)
      if (response.ok) {
        const poll = response.data
        this.updatePollState(poll)
        this.rootStore.appStore.setErrorOrSuccess(response, true, 'info_saved')
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async createPoll(poll) {
    this.updateCreateOk(false)

    const validationError = validatePoll(poll)
    if (validationError) {
      this.rootStore.appStore.setError(validationError)
      return
    }

    this.updateLoading(true)
    try {
      const response: any = await Api.createPoll(poll)
      if (response.ok) {
        this.updateCreateOk(true)
        setTimeout(() => {
          this.updateCreateOk(false)
        }, 500)
        this.rootStore.appStore.setErrorOrSuccess(response, true, 'poll_created')
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async deletePoll(id) {
    this.updateLoading(true)
    try {
      const response: any = await Api.updatePoll(id, { active: false })
      this.rootStore.appStore.setErrorOrSuccess(response, true, 'poll_deleted')
      if (response.ok) {
        const poll = response.data
        if (this.poll && this.poll.id === poll.id) {
          this.updatePollState(poll)
        }
        const polls = this.polls.map(p => {
          if (p.id === poll.id) return poll
          return p
        })
        this.updatePolls(polls)
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async activatePoll(id) {
    this.updateLoading(true)
    try {
      const response: any = await Api.updatePoll(id, { active: true })
      if (response.ok) {
        const poll = response.data
        if (this.poll && this.poll.id === poll.id) {
          this.updatePollState(poll)
        }
        const polls = this.polls.map(p => {
          if (p.id === poll.id) return poll
          return p
        })
        this.updatePolls(polls)
        this.rootStore.appStore.setErrorOrSuccess(response, true, 'poll_activated')
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }
}
