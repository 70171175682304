import makeStyles from '@mui/styles/makeStyles'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  tab: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
    cursor: 'pointer',
    fontSize: '2.25rem',
    marginRight: '2rem',
    fontWeight: 500,
    opacity: 0.3,
    color: Colors.black,
    '&:hover': {
      opacity: 0.4
    },
    userSelect: 'none'
  },
  currentTab: {
    opacity: 1,
    color: Colors.violet,
    '&:hover': {
      opacity: 1
    }
  }
}))

function PollTabs(props) {
  const classes = useStyles()

  const renderItems = () => {
    return props.tabs.map((item, index) => {
      return (
        <div className={[classes.tab, props.currentTab === item.value ? classes.currentTab : ''].join(' ')} onClick={() => props.setTab(item.value)}>
          {index + 1}. {item.name}
        </div>
      )
    })
  }

  return (
    <div className={classes.container}>
      {renderItems()}
    </div>
  )
}

export default PollTabs
