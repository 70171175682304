import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import get from 'lodash/get'
import cloneDeep from 'lodash/cloneDeep'
import Modal from '@mui/material/Modal'
import Switch from '@mui/material/Switch'
import Grow from '@mui/material/Grow'
import { Input, Button } from '..'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  modal: {
    zIndex: 99999
  },
  contentContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: Colors.white,
    minWidth: '45.25rem',
    borderRadius: 3
  },
  content: {
    padding: '2rem 3rem',
    paddingBottom: '1rem'
  },
  title: {
    fontFamily: 'Rubik',
    fontWeight: 600,
    color: Colors.black,
    fontSize: '1.675rem',
    marginBottom: '1.25rem'
  },
  serviceContainer: {
    marginBottom: '2rem'
  },
  label: {
    fontSize: '1rem',
    fontWeight: 500,
    marginBottom: '0.5rem',
    color: Colors.label
  },
  dayRow: {
    height: '3.125rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '1rem'
  },
  dayName: {
    display: 'flex',
    fontWeight: 500,
    alignItems: 'center',
    fontSize: '1.125rem',
    width: '9rem',
    color: Colors.inputText
  },
  openStatusRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  openStatus: {
    display: 'flex',
    fontWeight: 700,
    alignItems: 'center',
    fontSize: '1.125rem',
    width: '6rem',
    color: Colors.inputText,
    marginLeft: '1.25rem'
  },
  timeRangeContainer: {
    display: 'flex'
  },
  timeInputContainer: {
    maxWidth: '7.5rem'
  },
  daySeparator: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '.75rem',
    marginRight: '.75rem',
    whiteSpace: 'nowrap',
    fontWeight: 500,
    color: Colors.label
  },
  daySeparatorLine: {
    fontWeight: 700,
    marginBottom: '0.75rem',
    marginRight: '0.5rem',
    color: Colors.inputText
  },
  footerContainer: {
    height: '5.5rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0rem 3rem',
    borderTop: `1px solid ${Colors.black20}`
  },
  cancelButton: {
    maxWidth: '10.375rem',
    height: '3.125rem',
    border: 'none',
    margin: '0rem 0.75rem',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: Colors.violet10
    }
  },
  saveButton: {
    maxWidth: '10.375rem',
    height: '3.125rem'
  }
}))

const CustomSwitch = styled(Switch)(({ theme }) => ({
  width: '3.125rem',
  height: '1.875rem',
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: '1.375rem'
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(0.563rem)'
    }
  },
  '& .MuiSwitch-switchBase': {
    padding: '0.25rem',
    '&.Mui-checked': {
      transform: 'translateX(1.25rem)',
      color: Colors.white,
      '& + .MuiSwitch-track': {
        border: 0,
        opacity: 1,
        backgroundColor: Colors.violet
      },
      '& .MuiSwitch-thumb': {
        color: Colors.white,
        width: '1.375rem'
      },
    }
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    color: Colors.border,
    width: '1.375rem',
    height: '1.375rem',
    borderRadius: '0.688rem',
    transition: theme.transitions.create(['width'], {
      duration: 200
    })
  },
  '& .MuiSwitch-track': {
    borderRadius: '0.938rem',
    opacity: 1,
    backgroundColor: Colors.lightGrey,
    border: `2px solid ${Colors.border}`,
    boxSizing: 'border-box'
  }
}))

export default function ServiceOpenHoursModal(props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const { index, value, lang, onChange, handleClose } = props

  const [currentLocalValue, setCurrentLocalValue] = useState(
    value && index === value.length ?
      [
        ...value,
        {
          serviceFi: '',
          serviceEn: '',
          openHours: Array(7).fill(null).map(n => [null, null])
        }
      ] :
      value ??
      []
  )

  const langSuffix = lang === 'fi' ? 'Fi' : 'En'

  const getService = () => get(currentLocalValue, `[${index}].service${langSuffix}`)
  const getOpenHours = () => {
    const openHours = get(currentLocalValue, `[${index}].openHours`, null)
    if (openHours && openHours.length) {
      return openHours.slice(0, 7)
    }
    return Array(7).fill(null).map(n => [null, null])
  }
  const getTime = (day, col) => {
    if (currentLocalValue) {
      return get(currentLocalValue[index], `openHours[${day}][${col}]`, null)
    }
    return null
  }

  const [dayStatuses, setDayStatuses] = useState(
    getOpenHours().map((item, idx) => {
      if (index === value.length) {
        return true
      }
      return (getTime(idx, 0) && getTime(idx, 1)) ?? false
    })
  )

  const toggleDayStatus = (dayIndex) => {
    setDayStatuses(dayStatuses.map((item, idx) => {
      if (dayIndex === idx) {
        if (item) {
          const currentVal = getOpenHours()
          const newValue = cloneDeep(currentVal)
          newValue[idx][0] = null
          newValue[idx][1] = null
          setOpenHours(newValue)
        }
        return !item
      }
      return item
    }))
  }

  const setService = (value) => {
    setCurrentLocalValue(currentLocalValue.map((row, i) => {
      if (i === index) {
        if (lang === 'fi') return { ...row, serviceFi: value }
        if (lang === 'en') return { ...row, serviceEn: value }
      }
      return row
    }))
  }

  const setOpenHours = (val) => {
    setCurrentLocalValue(currentLocalValue.map((row, i) => {
      if (i === index) return { ...row, openHours: val }
      return row
    }))
  }

  const handleSetWeekdayTime = (day, col, val) => {
    const currentVal = getOpenHours()
    const newValue = cloneDeep(currentVal)
    newValue[day][col] = val ?? null
    setOpenHours(newValue)
  }

  const handleSave = () => {
    onChange(currentLocalValue)
    handleClose()
  }

  const renderWeekdayTimeRanges = () => {
    const days = Array(7).fill(null).map((x, index) => index)
    return days.map((day, index) => (
      <div key={day} className={classes.dayRow}>
        <div className={classes.dayName}>{t(`day_${day + 1}`)}</div>
        <div className={classes.openStatusRow}>
          <CustomSwitch
            checked={dayStatuses[index]}
            onChange={() => toggleDayStatus(index)}
            inputProps={{ 'aria-label': 'controlled' }}
          />
          <div className={classes.openStatus}>
            {dayStatuses[index] ? t('is_open') : t('closed')}
          </div>
        </div>
        {dayStatuses[index] && [0, 1].map((col) => (
          <React.Fragment key={col}>
            <Grow in={dayStatuses[index]}>
              <div className={classes.timeRangeContainer}>
                {!col && <div className={classes.daySeparator}>{t('time_prefix')}</div>}
                <div className={classes.timeInputContainer}>
                  <Input
                    value={getTime(day, col)}
                    onChange={(val) => handleSetWeekdayTime(day, col, val)}
                    type='time'
                    noMargin
                  />
                </div>
                {!col && (
                  <div className={classes.daySeparator}>
                    <span className={classes.daySeparatorLine}>_</span> {t('time_prefix')}
                  </div>
                )}
              </div>
            </Grow>
          </React.Fragment>
        ))}
      </div>
    ))
  }

  return (
    <Modal
      onClose={handleClose}
      aria-labelledby='modal-service-open-hours-title'
      aria-describedby='modal-service-open-hours-description'
      classes={{ root: classes.modal }}
      open
    >
      <div className={classes.contentContainer}>
        <div className={classes.content}>
          <div className={classes.title}>{t('open_hours')}</div>
          <div className={classes.serviceContainer}>
            <div className={classes.label}>{t('service_name')}</div>
            <Input value={getService()} onChange={setService} />
          </div>
          {renderWeekdayTimeRanges()}
        </div>
        <div className={classes.footerContainer}>
          <Button
            onClick={handleClose}
            text={t('cancel')}
            buttonStyle={classes.cancelButton}
            outlined
          />
          <Button
            onClick={handleSave}
            text={t('save')}
            disabled={!getService()}
            buttonStyle={classes.saveButton}
          />
        </div>
      </div>
    </Modal>
  )
}