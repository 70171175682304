import {
  SPACER,
  DESCRIPTION,
  OPEN_HOURS_TITLE,
  OPEN_HOURS,
  STREET_ADDRESS,
  CITY,
  POSTAL_CODE,
  STORAGE_INFORMATION_TITLE,
  DESCRIPTION_TITLE,
  ADDRESS_INFORMATION_TITLE,
  STORAGE_FEE,
  STORAGE_PAYMENT_METHODS,
  STORAGE_PRICE,
  STORAGE_TIME
} from '../elements.template'

const LAYOUT = {
  type: 'subentity_cloakroom_or_storage',
  layout: [
    [
      // Start left column
      OPEN_HOURS_TITLE,
      OPEN_HOURS,
      STORAGE_INFORMATION_TITLE,
      {
        type: 'container',
        className: 'customRowFixed',
        items: [
          STORAGE_FEE,
          SPACER,
          SPACER,
          STORAGE_PAYMENT_METHODS
        ]
      },
      {
        type: 'container',
        className: 'customRowFixed',
        items: [
          STORAGE_PRICE,
          SPACER,
          SPACER,
          STORAGE_TIME
        ]
      },
      DESCRIPTION_TITLE,
      DESCRIPTION,
      ADDRESS_INFORMATION_TITLE,
      {
        type: 'container',
        className: 'customRowFixed',
        items: [
          STREET_ADDRESS,
          SPACER,
          CITY,
          SPACER,
          POSTAL_CODE
        ]
      }
      // End left column
    ],
    [
      // Start of right column
      // End of right column
    ]
  ]
}

export default LAYOUT
