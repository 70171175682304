import React, { useState, useEffect } from 'react'
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy
} from '@dnd-kit/sortable'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import { DataMessage } from '..'
import SubEntityTableRow from './SubEntityTableRow'
import MainEntityTableRow from './MainEntityTableRow'
import { TableHeader } from '../../Components'

const useStyles = makeStyles((theme) =>({
  spacer: {
    height: '1rem',
    [theme.breakpoints.down('xl')]: {
      height: '0.75rem'
    }
  }
}))

function SubEntityTable (props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const [mainEntityItem, setMainEntityItem] = useState(null)
  const [subEntityItems, setSubEntityItems] = useState([])

  useEffect(() => {
    if (props.items) {
      const mainEntityItem = props.items.find((item: any) => item?.isMainEntity)
      let subEntityItems = props.items.filter((item: any) => !item?.isMainEntity)
      const subEntityOrderIds = mainEntityItem?.sortSubEntityIds ?? null
      if (subEntityOrderIds && subEntityOrderIds?.length) {
        let orderedItems: any[] = []
        for (const id of subEntityOrderIds) {
          const index = subEntityItems.findIndex((item: any) => item?.id === id)
          if (index !== -1) {
            orderedItems.push(subEntityItems[index])
            subEntityItems.splice(index, 1)
          }
        }
        if (subEntityItems?.length) {
          orderedItems = [...orderedItems, ...subEntityItems]
        }
        subEntityItems = orderedItems
      }
      setMainEntityItem(mainEntityItem)
      setSubEntityItems(subEntityItems)
    }
  }, [props.items])

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  )

  const handleDragStart = (event: any) => {}
  const handleDragCancel = () => {}

  const handleDragEnd = (event: any) => {
    const { active, over } = event
    if (active.id !== over.id) {
      const oldIndex = subEntityItems.findIndex((item: any) => item.id === active.id)
      const newIndex = subEntityItems.findIndex((item: any) => item.id === over.id)
      const items = arrayMove(subEntityItems, oldIndex, newIndex)
      setSubEntityItems(items)
      props.updateSubEntityOrder(items.map((item: any) => item?.id))
    }
  }

  const renderMainEntityRow = () => {
    if (mainEntityItem) {
      return (
        <MainEntityTableRow
          item={mainEntityItem}
          addMainEntityId={props.addMainEntityId}
          setAddMainEntityId={props.setAddMainEntityId}
          onClick={props.onMainEntityClick}
          onEdit={() => props.setEditMainEntityId(mainEntityItem?.id)}
          setHighlightedEntity={props.setHighlightedEntity}
        />
      )
    }
    return null
  }

  const renderSubEntityRows = () => {
    if (subEntityItems.length) {
      return subEntityItems.map((item: any, index) => {
        return (
          <SubEntityTableRow
            key={`sub_${item?.id}`}
            item={item}
            addSubEntityId={props.addSubEntityId}
            editSubEntityId={props.editSubEntityId}
            setAddSubEntityId={props.setAddSubEntityId}
            setEditSubEntityId={props.setEditSubEntityId}
            deleteSubEntity={props.deleteSubEntity}
            setHighlightedEntity={props.setHighlightedEntity}
            even={index % 2 === 0}
            onClick={props.onSubEntityClick}
            dragEnabled={subEntityItems?.length > 1}
          />
        )
      })
    }
    return null
  }

  return (
    <DndContext
      sensors={sensors}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      onDragCancel={handleDragCancel}
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis]}
    >
      <Table>
        <TableHeader columnNames={[t('name'), t('type'), t('actions')]} light />
        <div className={classes.spacer} />
        <TableBody>
          {renderMainEntityRow()}
          <SortableContext items={subEntityItems} strategy={verticalListSortingStrategy}>
            {renderSubEntityRows()}
          </SortableContext>
        </TableBody>
      </Table>
      <DataMessage data={props.items} message={t('no_entities')} />
    </DndContext>
  )
}

export default SubEntityTable
