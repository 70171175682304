import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import { useTranslation } from 'react-i18next'
import { TableHeader, DataMessage } from '..'
import makeStyles from '@mui/styles/makeStyles'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { Colors } from '../../Utils/theme'
import { IconButton, Tooltip } from '@mui/material'
import EditIcon from '../../Assets/Icons/edit-dark.svg'
import DeleteIcon from '../../Assets/Icons/delete.svg'
import RestoreIcon from '../../Assets/Icons/restore.svg'
import { Dialog } from '..'

// Custom tables
import DEFAULT_LAYOUT from './entityTableLayout/default.tableLayout'

const CUSTOM_TABLE_LAYOUTS = [
  DEFAULT_LAYOUT
]

const useStyles = makeStyles((theme) => ({
  spacer: {
    height: '1rem'
  },
  root: {
    backgroundColor: Colors.backgroundGrey,
    borderBottom: 'none',
    '&:hover': {
      backgroundColor: `${Colors.tableHover} !important`
    }
  },
  firstCell: {
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: 600,
    height: '3rem',
    color: Colors.black,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    borderBottom: 'none'
  },
  lastCell: {
    height: '3rem',
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    borderBottom: 'none',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  cell: {
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: 400,
    height: '3rem',
    color: Colors.black,
    borderBottom: 'none'
  },
  link: {
    display: 'block',
    textDecoration: 'none',
    padding: '.5rem 0',
    color: Colors.black,
    '&:hover': {
      opacity: 0.8
    }
  },
  editButton: {
    marginRight: '.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.white,
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.black20}`,
    height: '2rem',
    width: '2rem',
    '&:hover': {
      backgroundColor: `${Colors.violet10} !important`
    }
  },
  deleteButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.white,
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.black20}`,
    height: '2rem',
    width: '2rem',
    '&:hover': {
      backgroundColor: `${Colors.violet10} !important`
    }
  },
  restoreButton: {
    backgroundColor: Colors.white,
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.black20}`,
    height: '2rem',
    width: '2rem',
  },
  actionIconsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexSpacing: '.5rem'
  },
  actionIcon: {
    height: 'auto',
    width: '1.125rem',
    maxHeight: '1.125rem',
    maxWidth: '1.125rem'
  },
}))

function CustomEntityTable (props) {
  const classes = useStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [confirmDeleteId, setConfirmDeleteId] = useState(null)
  const [confirmRestoreId, setConfirmRestoreId] = useState(null)

  const handleDelete = (entityId) => {
    if (confirmDeleteId === entityId) {
      setConfirmDeleteId(null)
      props.onDelete(entityId)
    } else {
      setConfirmDeleteId(entityId)
    }
  }

  const handleRestore = (entityId) => {
    if (confirmRestoreId === entityId) {
      setConfirmRestoreId(null)
      props.onRestore(entityId)
    } else {
      setConfirmRestoreId(entityId)
    }
  }

  const closeConfirms = () => {
    setConfirmDeleteId(null)
    setConfirmRestoreId(null)
  }

  const toEditEntity = (entityId) => navigate(`/entity/${entityId}`)

  const renderActions = (entity) => {
    if (entity.active) {
      // Edit and delete button
      return (
        <>
          <div className={classes.actionIconsContainer}>
            <Tooltip title={t('edit')}>
              <IconButton
                onClick={() => toEditEntity(entity.id)}
                className={classes.editButton}
                size='large'
              >
                <img src={EditIcon} alt='icon' className={classes.actionIcon} />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('delete')}>
              <IconButton
                onClick={() => handleDelete(entity.id)}
                className={classes.deleteButton}
                size='large'
              >
                <img src={DeleteIcon} alt='icon' className={classes.actionIcon} />
              </IconButton>
            </Tooltip>
          </div>
          <Dialog
            open={confirmDeleteId === entity.id}
            handleClose={closeConfirms}
            handleOk={() => handleDelete(entity.id)}
            title={t('delete_entity')}
            description={t('delete_entity_description')}
          />
        </>
      )
    } else {
      // Restore button
      return (
        <>
          <Tooltip title={t('restore')}>
            <IconButton
              onClick={() => handleRestore(entity.id)}
              className={classes.restoreButton}
              size='large'
            >
              <img src={RestoreIcon} alt='icon' className={classes.actionIcon} />
            </IconButton>
          </Tooltip>
          <Dialog
            open={confirmRestoreId === entity.id}
            handleClose={closeConfirms}
            handleOk={() => handleRestore(entity.id)}
            title={t('restore_entity')}
            description={t('restore_entity_description')}
          />
        </>
      )
    }
  }

  const renderRow = (layout, composedEntities, entity) => {
    const columns = layout.getColumns(composedEntities)

    return (
      <TableRow classes={{ root: classes.root }} hover>
        {columns.map((col, index) => {
          return (
            <TableCell key={index} className={classes.cell} size='small'>
              <div style={col.style || null}>
                {col.render(entity)}
              </div>
            </TableCell>
          )
        })}
         <TableCell className={classes.cell} size='small'>
          {renderActions(entity)}
        </TableCell>
      </TableRow>
    )
  }

  const composeEntity = (entity) => {
    // Compose relvant data model from entity data
    let typeId = ''
    if (props.entities && props.entities.length) {
      typeId = entity.mainEntityType?.id
    }
    const currentType = props.mainEntityTypes.find(t => t.id === typeId)

    const currentEntity = {
      id: entity.id,
      nameFi: entity.nameFi,
      nameEn: entity.nameEn,
      createdAt: entity.createdAt,
      snapshotCreatedAt: entity.snapshotCreatedAt,
      active: entity.active,
      fields: {}
    }

    const currentMainEntityFields = (currentType && currentType.mainEntityFields) ? currentType.mainEntityFields : []

    for (const mainEntityField of currentMainEntityFields) {
      const currentValue = entity.fieldValues.find(v => v.mainEntityFieldId === mainEntityField.id)
      let currentVal = null
      if (currentValue) currentVal = currentValue.value
      currentEntity.fields[`${mainEntityField.fieldKey || '__placeholder__'}`] = {
        ...mainEntityField,
        value: currentVal
      }
    }

    return currentEntity
  }

  const renderRows = (layout) => {
    if (props.entities) {
      const composedEntities = props.entities.map(composeEntity)

      return composedEntities.map((entity) => {
        return (
          <React.Fragment key={entity.id}>
            <tr className={classes.spacer} />
            {renderRow(layout, composedEntities, entity)}
          </React.Fragment>
        )
      })
    }
    return null
  }

  const renderCustomTableLayout = (layout) => {
    const header = layout.getHeader(props.entities.map(composeEntity))

    return (
      <>
        <Table>
          <TableHeader
            columnNames={
              [
                ...header.map(({ name }) => t(name)),
                t('actions')
              ]
            }
          />
          <TableBody>
            {renderRows(layout)}
          </TableBody>
        </Table>
        <DataMessage data={props.entities} message={t('no_entities')} />
      </>
    )
  }

  const renderContent = () => {
    // Check if custom table layout is found
    if (props.entities && props.entities.length) {
      const type = props.entities[0]?.mainEntityType?.type
      let match = null
      if (type) match = CUSTOM_TABLE_LAYOUTS.find(layout => layout.type === type)
      if (match) return renderCustomTableLayout(match)
    }

    // Fallback to default
    const defaultLayout = CUSTOM_TABLE_LAYOUTS.find(layout => layout.type === '*')
    return renderCustomTableLayout(defaultLayout)

  }

  return renderContent()
}

export default CustomEntityTable



