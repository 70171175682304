import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import { IconButton } from '@mui/material'
import { get } from 'lodash'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import { Checkbox, Button, Input, Select, Label } from '../'
import OrderDown from '../../Assets/Icons/order-down.svg'
import DeleteOutlined from '../../Assets/Icons/delete.svg'
import AddIcon from '../../Assets/Icons/add-dark.svg'
import EditIcon from '../../Assets/Icons/edit-dark.svg'
import { Colors } from '../../Utils/theme'
import { LANG_OPTIONS } from '../../Constants'
import OrderUp from '../../Assets/Icons/order-up.svg'

const useStyles = makeStyles((theme) =>({
  root: {
    position: 'relative'
  },
  arrow: {
    position: 'absolute',
    top: 0,
    left: '1.5rem',
    height: '5rem',
    width: '1.5rem',
    borderLeft: `1px dashed rgba(0,0,0,.15)`,
    borderBottom: `1px dashed rgba(0,0,0,.15)`
  },
  container: {
    padding: '1rem 1.5rem',
    border: '1px solid rgba(0,0,0,.15)',
    position: 'relative',
    marginBottom: '2rem'
  },
  childContainer: {
    marginLeft: '3rem',
    padding: '1rem 1.5rem',
    border: '1px dashed rgba(0,0,0,.15)',
    position: 'relative',
    marginBottom: '2rem'
  },
  header: {
    flexDirection: 'row',
    paddingBottom: '1rem'
  },
  title: {
    fontFamily: 'Rubik',
    fontSize: '1.5rem',
    fontWeight: 600,
    margin: 0,
    padding: 0,
    flex: 1
  },

  answerOptionContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  actionButtons: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: '1rem',
    display: 'flex',
    flexDirection: 'row'
  },
  actionButtonIcon: {
    maxHeight: '1rem',
    maxWidth: '1rem'
  },
  actionButton: {
    margin: 0,
    padding: '.5rem 0',
    borderRadius: 5,
    width: '2.25rem',
    height: '2.25rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '.75rem',
    cursor: 'pointer',
    background: Colors.backgroundGrey
  },
  deleteButton: {
    boxSizing: 'border-box',
    borderRadius: '.25rem',
    marginLeft: '1rem',
    marginTop: '2.5rem',
    padding: 0,
    height: '2.25rem',
    width: '2.25rem',
    backgroundColor: Colors.backgroundGrey
  },
  deleteIcon: {
    height: '1.125rem',
    width: 'auto',
    maxHeight: '1.125rem'
  },
  flex: {
    flex: 1
  },
  dialogSpacer: {
    width: '55rem',
  },
  langContainer: {
    width: '3rem'
  },
  modalFooterContainer: {
    borderTop: '1px solid rgba(0, 0, 0, .2)',
    paddingTop: '1.5rem',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '-.5rem'
  },
  booleanContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  modalRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  modalColumn: {
    flex: 1,
    paddingRight: '4rem'
  },
  semiTitle: {
    fontWeight: 600,
    fontSize: '1.25rem',
    color: Colors.violet
  },
  answerRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  answerIndex: {
    fontWeight: 700,
    fontSize: '1.25rem',
    color: Colors.violet,
    padding: '.25rem 0',
    width: '3rem',
    textAlign: 'center'
  },
  answersContainer: {
    padding: '1rem 0'
  },
  answerContainer: {
    borderLeft: '1px solid rgba(0, 0, 0, .2)',
    padding: '1rem'
  },
  modalTitle: {
    fontFamily: 'Rubik',
    fontSize: '1.5rem',
    fontWeight: 600,
    marginBottom: '1rem',
    flex: 1
  }
}))

const styles = {
  langContainer: {
    maxWidth: '4rem'
  }
}

function PollQuestionForm (props) {
  const classes = useStyles()

  const { t } = useTranslation()

  const getQuestionTypeOptions = () => [
    {
      label: t('answer_type_select'),
      value: 'select'
    },
    {
      label: t('answer_type_multiselect'),
      value: 'multiselect'
    },
    {
      label: t('answer_type_top3'),
      value: 'ranked_multiselect'
    },
    {
      label: t('answer_type_text'),
      value: 'text'
    }
  ]

  const { langTab, setLangTab } = props

  const [editVisible, setEditVisible] = useState(false)

  const toggleEdit = () => setEditVisible(!editVisible)

  const charIndex = (number) => number < 26 ? String.fromCharCode(number + 65) : number

  const indexToChar = (index) => String.fromCharCode(65 + parseInt(index || '0', 10))

  const validationMessage = (field) => {
    if (props.submitted && !field) {
      return {
        error: true,
        helperText: t('required_field')
      }
    }
    return {}
  }

  const renderQuestionAnswers = () => {
    const { question } = props
    const fieldName = langTab === 'en' ? 'answersEn' : 'answersFi'

    if (question.type === 'text') return null

    return question[fieldName].map((answer, answerIndex) => (
      <div key={answerIndex} className={classes.answerOptionContainer}>
        <div className={classes.flex}>
          <Label>{`${t('answer_option_num')} ${charIndex(answerIndex)}`}</Label>
          <Input
            value={answer}
            onChange={(val) => props.setAnswer(answerIndex, langTab, val)}
            {...validationMessage(answer)}
          />
        </div>
        {question[fieldName].length > 2 ?
          (
            <IconButton
              onClick={() => props.deleteAnswer(answerIndex)}
              classes={{ root: classes.deleteButton }}
              size="large"
            >
              <img src={DeleteOutlined} className={classes.deleteIcon} />
            </IconButton>
          ) : null
        }
      </div>
    ))
  }

  const renderAddFollowUpQuestionButton = () => {
    if (!props.question.parentId) {
      if (props.reservedParentAnswerIndexes.length >= get(props.question, 'answersFi.length', 0)) return null
      return (
        <Button
          text={t('add_follow_up_question')}
          onClick={props.addFollowUpQuestion}
          rightIcon={AddIcon}
          outlined
          small
        />
      )
    }
    return null
  }

  const renderQuestion = () => {
    const { question } = props

    const title = question[langTab === 'en' ? 'titleEn' : 'titleFi']

    if (true) {
      return (
        <>
          <Label>{t('question')}</Label>
          <Input
            value={title}
            onChange={(val) => props.setTitle(langTab, val)}
            {...validationMessage(title)}
          />
          {renderAddFollowUpQuestionButton()}
        </>
      )
    }

  }

  const renderArrangeButtons = () => {
    const { index, isLastParentQuestion } = props
    if (!props.question.parentId) {
      return (
        <>
          {(index > 0) ? (
            <div
              onClick={props.moveQuestionUp}
              className={classes.actionButton}
            >
              <img
                src={OrderUp}
                alt='up'
                className={classes.actionButtonIcon}
              />
            </div>
          ) : null}
          {!isLastParentQuestion ? (
            <div
              onClick={props.moveQuestionDown}
              className={classes.actionButton}
            >
              <img
                src={OrderDown}
                alt='down'
                className={classes.actionButtonIcon}
              />
            </div>
          ) : null}
        </>
      )
    }
    return null
  }

  const renderDeleteButton = () => {
    return (
      <div
        onClick={props.deleteQuestion}
        className={classes.actionButton}
      >
        <img
          src={DeleteOutlined}
          alt='delete'
          className={classes.actionButtonIcon}
        />
      </div>
    )
  }

  const renderEditButton = () => {
    return (
      <div
        onClick={toggleEdit}
        className={classes.actionButton}
      >
        <img
          src={EditIcon}
          alt='lock'
          className={classes.actionButtonIcon}
        />
      </div>
    )
  }

  const renderActionButtons = () => {
    return (
      <div className={classes.actionButtons}>
        {renderEditButton()}
        {renderArrangeButtons()}
        {renderDeleteButton()}
      </div>
    )
  }

  const renderParentAnswerIndexSelect = () => {
    const options = (props.parentQuestion[langTab === 'en' ? 'answersEn' : 'answersFi'] || []).map((item, index) => {
      if (index !== props.question.parentAnswerIndex && props.reservedParentAnswerIndexes.includes(index)) return null
      return index
    }).filter(val => val !== null).map(index => {
      return {
        label: indexToChar(index),
        name: indexToChar(index),
        value: index
      }
    })
    return (
      <div>
        <Label>{t('follow_up_question_for_answer')}</Label>
        <Select
          value={props.question.parentAnswerIndex}
          onChange={(val) => props.setParentAnswerIndex(val)}
          options={options}
        />
      </div>
    )
  }

  const renderParentQuestion = () => {
    if (!props.parentQuestion) return null

    const title = props.parentQuestion[langTab === 'en' ? 'titleEn' : 'titleFi']
    const content = props.parentQuestion[langTab === 'en' ? 'contentEn' : 'contentFi']

    return (
      <div>
        <div className={classes.modalTitle}>{t('original_question')}</div>

        <div className={classes.semiTitle}>{title}</div>
        <p>
          {content}
        </p>
        <div className={classes.answersContainer}>
          {(props.parentQuestion[langTab === 'en' ? 'answersEn' : 'answersFi'] || []).map((ans, index) => {
            return (
              <div className={classes.answerRow}>
                <div className={classes.answerIndex}>
                  {indexToChar(index)}
                </div>
                <div className={classes.answerContainer}>
                  {ans}
                </div>
              </div>
            )
          })}
        </div>
        {renderParentAnswerIndexSelect()}
      </div>
    )
  }

  const renderModalForm = () => {
    const { question } = props

    const title = question[langTab === 'en' ? 'titleEn' : 'titleFi']
    const content = question[langTab === 'en' ? 'contentEn' : 'contentFi']

    return (
      <>
        {renderParentQuestion()}
        <div className={classes.modalTitle}>{renderTitle()}</div>

        <Label>{t('question_title')}</Label>
        <Input
          value={title} onChange={(val) => props.setTitle(langTab, val)}
          {...validationMessage(title)}
        />
        <Label>{t('question_content')}</Label>
        <Input
          value={content}
          onChange={(val) => props.setContent(langTab, val)}
          multiline
          {...validationMessage(content)}
        />
        <div className={classes.modalRow}>
          <div className={classes.modalColumn}>
            <Label>{t('question_type')}</Label>
            <Select
              value={question.type}
              onChange={(val) => props.setType(val)}
              options={getQuestionTypeOptions()}
              {...validationMessage(question.type)}
            />
          </div>
          <div className={classes.modalColumn}>
            <Label>{t('question_mandatory')}</Label>
            <div className={classes.booleanContainer}>
              {['mandatory', 'not_mandatory'].map((label, index) => {
                return (
                  <Checkbox
                    key={index}
                    checked={!!index !== !question.isOptional}
                    onChange={props.toggleIsOptional}
                    label={t(label)}
                  />
                )
              })}
            </div>
          </div>
        </div>
        {question.type === 'text' ? null : (
          <>
            <div className={classes.modalTitle}>{t('answer_options')}</div>
            {renderQuestionAnswers()}
            <Button
              rightIcon={AddIcon}
              text={t('add_answer')}
              onClick={props.addAnswer}
              outlined
            />
          </>
        )}
      </>
    )
  }

  const renderModal = () => {
    return (
      <Dialog
        open={editVisible}
        onClose={props.handleClose}
        aria-labelledby='dialog-title'
        aria-describedby='dialog-description'
        maxWidth='md'
        fullWidth
      >
        <div className={classes.dialogSpacer} />
        <DialogContent>
          {renderModalForm()}
        </DialogContent>
        <DialogActions>
          <div className={classes.modalFooterContainer}>
            <Select
              options={LANG_OPTIONS}
              value={langTab}
              onChange={setLangTab}
              containerStyle={styles.langContainer}
              style={styles.langSelect}
            />
            <div className={classes.flex} />
            <Button
              onClick={toggleEdit}
              text={t('ready')}
            />
          </div>
        </DialogActions>
      </Dialog>
    )
  }

  const renderArrow = () => {
    if (props.question.parentId) {
      return (
        <div className={classes.arrow} />
      )
    }
    return null
  }

  const renderTitle = () => {
    if (!props.question.parentId) {
      return `${t('question')} ${props.index + 1}.`
    }
    let char = '?'
    if (props.question.parentAnswerIndex !== null) {
      char = indexToChar(props.question.parentAnswerIndex)
    }
    return `${t('question')} ${props.index + 1}.${char}`
  }

  return (
    <div className={classes.root}>
      <div className={props.question.parentId ? classes.childContainer : classes.container}>
        <div className={classes.header}>
          <h3 className={classes.title}>
            {renderTitle()}
          </h3>
          {renderActionButtons()}
        </div>
        {renderQuestion()}
        {renderModal()}
      </div>
      {renderArrow()}
    </div>
  )
}

export default PollQuestionForm
