import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import { HeadingNode, QuoteNode } from '@lexical/rich-text'
import { ListItemNode, ListNode } from '@lexical/list'
import { AutoLinkNode, LinkNode } from '@lexical/link'
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin'
import { ListPlugin } from '@lexical/react/LexicalListPlugin'
import AutoLinkPlugin from './plugins/AutoLinkPlugin'
import ImagePlugin from './plugins/ImagePlugin'
import HtmlConverterPlugin from './plugins/HtmlConverterPlugin'
import EditorTheme from './theme/EditorTheme'
import './html-input-base.css'
import './html-input-custom.css'
import ToolbarPlugin from './plugins/ToolbarPlugin'
import { ImageNode } from './nodes/ImageNode'
import PastePlugin from './plugins/PastePlugin'

// Based on https://codesandbox.io/s/lexical-rich-text-example-5tncvy

// Catch any errors that occur during Lexical updates and log them
// or throw them as needed. If you don't throw them, Lexical will
// try to recover gracefully without losing user data.
function onError(error) {
  console.error(error);
}

function HtmlInput(props) {
  const initialConfig = {
    namespace: 'MyEditor',
    theme: EditorTheme,
    onError,
    nodes: [
      HeadingNode,
      ListNode,
      ListItemNode,
      QuoteNode,
      AutoLinkNode,
      LinkNode,
      ImageNode
    ]
  }

  const handleSetHtml = (html) => {
    props.onChange(html)
  }

  const getPlaceholder = () => (
    <span className='html-input--placeholder'>{props.placeholder}</span>
  )

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <div className='html-input--container'>
        <ToolbarPlugin />
        <div className='html-input--inner'>
          <RichTextPlugin
            contentEditable={<ContentEditable className="editor-input" />}
            placeholder={getPlaceholder()}
            ErrorBoundary={LexicalErrorBoundary}
          />
          <ImagePlugin />
          <HistoryPlugin />
          <ListPlugin />
          <LinkPlugin />
          <PastePlugin />
          <AutoLinkPlugin />
          <HtmlConverterPlugin
            initialHtml={props.value}
            setHtml={handleSetHtml}
          />
        </div>
      </div>
    </LexicalComposer>
  )
}

export default HtmlInput
