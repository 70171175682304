import {
  DESCRIPTION,
  PROPERTIES_TITLE,
  WATER_POINT_TYPE,
  DESCRIPTION_TITLE
} from '../elements.template'

const LAYOUT = {
  type: 'subentity_water_point',
  layout: [
    [
      // Start left column
      PROPERTIES_TITLE,
      WATER_POINT_TYPE,
      DESCRIPTION_TITLE,
      DESCRIPTION
      // End left column
    ],
    [
      // Start of right column
      // End of right column
    ]
  ]
}

export default LAYOUT
