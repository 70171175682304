import {
  SPACER,
  SPORT_FIELD_LIST,
  BOOKING_CALENDAR_LINK,
  MAINTENANCE_TITLE,
  MAINTENANCE_FREQUENCY,
  LAST_MAINTAINED,
  EXCEPTION_NOTIFICATION,
  CONTACT_INFORMATION_TITLE,
  STREET_ADDRESS,
  CITY,
  POSTAL_CODE,
  PROPERTIES_TITLE,
  DESCRIPTION,
  DESCRIPTION_TITLE
} from '../elements.template'

const LAYOUT = {
  type: 'beach_volley',
  layout: [
    [
      // Start left column
      DESCRIPTION_TITLE,
      DESCRIPTION,
      PROPERTIES_TITLE,
      SPORT_FIELD_LIST,
      { ...BOOKING_CALENDAR_LINK, style: { flex: 1, maxWidth: '38rem' } }
      // End left column
    ],
    [
      // Start right column
      MAINTENANCE_TITLE,
      {
        type: 'container',
        className: 'customRowFixed',
        items: [
          { ...MAINTENANCE_FREQUENCY, style: { flex: 1, maxWidth: '24rem' } },
          SPACER,
          LAST_MAINTAINED
        ]
      },
      EXCEPTION_NOTIFICATION,
      CONTACT_INFORMATION_TITLE,
      {
        type: 'container',
        className: 'customRowFixed',
        style: { paddingBottom: '10rem' },
        items: [
          STREET_ADDRESS,
          SPACER,
          CITY,
          SPACER,
          POSTAL_CODE
        ]
      }
      // End right column
    ]
  ]
}

export default LAYOUT
