import React from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import makeStyles from '@mui/styles/makeStyles'
import { IconButton, Tooltip } from '@mui/material'
import EditIcon from '../../Assets/Icons/edit-dark.svg'
import DeleteIcon from '../../Assets/Icons/delete.svg'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    padding: '.625rem',
    borderRadius: '.3125rem',
    marginBottom: '.9375rem'
  },
  even: {
    backgroundColor: '#FEF3FF'
  },
  odd: {
    backgroundColor: '#FDE5FE'
  },
  photo: {
    height: '3.75rem',
    width: '3.75rem',
    minHeight: '3.75rem',
    minWidth: '3.75rem',
    backgroundPosition: '50% 50%',
    backgroundSize: 'cover'
  },
  placeholderPhoto: {
    height: '3.75rem',
    width: '3.75rem',
    minHeight: '3.75rem',
    minWidth: '3.75rem',
    backgroundColor: 'rgba(0,0,0,.25)'
  },
  content: {
    flex: 1,
    padding: '0 1rem'
  },
  times: {
    fontSize: '1rem',
    fontWeight: 400,
    color: 'rgba(79, 79, 79, 0.8)'
  },
  title: {
    fontFamily: 'Roboto',
    fontWeight: 800,
    color: Colors.black,
    fontSize: '1.25rem',
  },
  button: {
    backgroundColor: Colors.white,
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.black20}`,
    height: '2rem',
    width: '2rem',
    marginLeft: '0.5rem',
    '&:hover': {
      backgroundColor: `${Colors.violet10} !important`
    }
  },
  icon: {
    height: '1.125rem',
    width: 'auto',
    maxHeight: '1.125rem',
    maxWidth: '1.125rem'
  },
  actions: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'row'
  }
}))

export default function ProgramRepeaterItem(props) {
  const classes = useStyles()
  const { t } = useTranslation()
  const { item } = props

  const renderPhoto = (photo) => {
    if (photo) {
      return (
        <div
          className={classes.photo}
          style={{
            backgroundImage: `url(${photo})`
          }}
        />
      )
    }
    return <div className={classes.placeholderPhoto} />
  }

  const renderTimeRange = () => {
    let str = ''
    if (item.startDate) {
      str = moment(item.startDate).format('HH.mm')
    }
    if (item.endDate) {
      str = `${str}-${moment(item.endDate).format('HH.mm')}`
    }
    return str
  }

  return (
    <div className={[classes.container, props.odd ? classes.odd : classes.even].join(' ')}>
      {renderPhoto(item.photo)}
      <div className={classes.content}>
        <div className={classes.title}>{item.titleFi || item.titleEn}</div>
        <div className={classes.times}>
          {renderTimeRange()}
        </div>
      </div>
      <div className={classes.actions}>
        <Tooltip title={t('edit')}>
          <IconButton className={classes.button} onClick={props.onEdit} size='large'>
            <img src={EditIcon} className={classes.icon} />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('delete')}>
          <IconButton onClick={props.onDelete} className={classes.button} size='large'>
            <img src={DeleteIcon} className={classes.icon} />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  )
}
