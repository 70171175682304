import moment from 'moment'

export const validatePoll = (poll) => {
  // Don't validate drafts
  if (poll.draft) return null

  // No questions set -> activate / deactivate action
  if (!poll.questions) return null

  const fieldCounters = {
    Fi: 0,
    En: 0
  }

  for (const locale of ['Fi', 'En']) {
    for (const field of ['title', 'content']) {
      if (poll[`${field}${locale}`]) {
        fieldCounters[locale] = fieldCounters[locale] + 1
      }
    }

    if (!poll.questions.length) {
      return 'validation_missing_question'
    }

    // Check that questions are either filled or empty
    for (const question of poll.questions) {
      for (const field of ['title', 'content']) {
        if (question[`${field}${locale}`]) {
          fieldCounters[locale] = fieldCounters[locale] + 1
        }
      }
      if (question.type !== 'text') {
        if (question[`answers${locale}`]) {
          fieldCounters[locale] = fieldCounters[locale] + (question[`answers${locale}`].filter(val => !!val)).length
        }
      }
    }
  }
  if (!fieldCounters.Fi && !fieldCounters.En) {
    return 'validation_fill_all_fields'
  }
  if (fieldCounters.Fi !== fieldCounters.En) {
    return 'validation_missing_fields'
  }

  // Validate dates
  if (!poll.validFrom) return 'validation_missing_valid_from'
  // if (!poll.validUntil) return 'validation_missing_valid_until'

  if (moment(poll.validUntil).isBefore(moment(poll.validFrom))) {
    return 'validation_valid_until_before_valid_from'
  }

  return null
}
