import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import AddIcon from '../../Assets/Icons/add-dark.svg'
import MainEntityNotificationsTable from './MainEntityNotificationsTable'
import MainEntityNotificationModal from './MainEntityNotificationModal'
import { Button, Dialog } from '../.'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  container: {
  },
  customBigTitle: {
    fontFamily: 'Rubik',
    fontWeight: 600,
    color: Colors.violet,
    fontSize: '1.675rem',
    paddingBottom: '0.325rem',
    marginBottom: '1.5rem',
    borderBottom: `2px solid ${Colors.border}`
  },
  spacer: {
    height: '1.375rem'
  }
}))

function MainEntityNotifications (props) {
  const classes: any = useStyles()
  const { t } = useTranslation()

  const [addMode, setAddMode] = useState(false)
  const [editId, setEditId] = useState(null)
  const [deleteId, setDeleteId] = useState(null)

  const addNotification = () => {
    setAddMode(true)
  }

  const closeModals = () => {
    setAddMode(false)
    setEditId(null)
  }

  const handleDelete = () => {
    if (deleteId) {
      props.deleteMainEntityNotification(deleteId)
      setDeleteId(null)
    }
  }

  const handleSaveItem = (item) => {
    if (addMode) {
      // Create new
      props.createMainEntityNotification(item)
    } else if (editId) {
      // Edit existing
      props.updateMainEntityNotification(editId, item)
    }
    closeModals()
  }

  const renderNotifications = () => {
    if (props?.mainEntityNotifications?.length) {
      return (
        <>
          <div className={classes.customBigTitle}>{t('notifications')}</div>
          <MainEntityNotificationsTable
            items={props.mainEntityNotifications}
            onEdit={setEditId}
            onDelete={setDeleteId}
          />
        </>
      )
    }
    return null
  }

  const renderAddModal = () => {
    const editItem = editId === null ? null : props.mainEntityNotifications.find(notification => notification.id === editId)
    if (addMode || editItem) {
      return (
        <MainEntityNotificationModal
          handleSave={handleSaveItem}
          item={editItem}
          handleClose={closeModals}
        />
      )
    }
    return null
  }

  const renderDeleteDialog = () => {
    return (
      <Dialog
        open={!!deleteId}
        handleClose={() => setDeleteId(null)}
        handleOk={handleDelete}
        title={t('delete_main_entity_notification')}
        description={t('delete_main_entity_notification_description')}
      />
    )
  }

  return (
    <div className={classes.container}>
      <div className={classes.customBigTitle}>{t('add_notification')}</div>
      <Button
        text={t('add_new')}
        onClick={addNotification}
        rightIcon={AddIcon}
        outlined
      />
      <div className={classes.spacer} />
      <div className={classes.spacer} />
      {renderNotifications()}
      {renderAddModal()}
      {renderDeleteDialog()}
    </div>
  )
}

export default MainEntityNotifications
