import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import Modal from '@mui/material/Modal'
import Grid from '@mui/material/Grid'
import ButtonBase from '@mui/material/ButtonBase'
import { Button } from '..'
import { MapThemes } from '../../Constants'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  modal: {
    width: '100%',
    zIndex: 99999
  },
  contentContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: Colors.white,
    borderRadius: 3
  },
  content: {
    width: '100%',
    padding: '2.75rem 6rem',
    maxWidth: '95vw',
    maxHeight: '95vh !important',
    overflowY: 'auto'
  },
  selectThemeContent: {
    minWidth: '50.75rem',
    [theme.breakpoints.down('xl')]: {
      minWidth: '52.75rem'
    }
  },
  title: {
    fontFamily: 'Rubik',
    fontWeight: 700,
    color: Colors.black,
    fontSize: '2.25rem',
    marginBottom: '1.125rem',
    textAlign: 'center',
    [theme.breakpoints.down('lg')]: {
      fontSize: '2rem',
      marginBottom: '1rem'
    }
  },
  highlight: {
    color: Colors.violet
  },
  info: {
    margin: 0,
    fontFamily: 'Roboto',
    fontSize: '1.125rem',
    fontWeight: 300,
    color: Colors.black70,
    marginBottom: '2.5rem',
    textAlign: 'center',
    [theme.breakpoints.down('lg')]: {
      fontSize: '1rem'
    }
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '0.3125rem',
    marginBottom: '0.75rem',
    // backgroundColor: Colors.black,
    '& img': {
      transition: 'opacity .5s',
      opacity: 0.45
    },
    '&:hover': {
      '& img': {
        opacity: 1
      }
    },
    '&:active': {
      color: Colors.black
    }
  },
  activeGridItem: {
    boxShadow: `rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px`,
    '& img': {
      opacity: 1
    }
  },
  gridItemName: {
    color: Colors.black70,
    fontSize: '1.125rem',
    fontFamily: 'Roboto',
    fontWeight: 700,
    margin: 0,
    padding: 0,
    textAlign: 'center',
    [theme.breakpoints.down('lg')]: {
      fontSize: '1rem'
    }
  },
  image: {
    width: '100%',
    height: '8.375rem',
    borderRadius: '0.3125rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
  label: {
    fontSize: '1rem',
    fontWeight: 500,
    marginBottom: '0.375rem',
    color: Colors.black
  },
  footerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '3rem',
    [theme.breakpoints.down('lg')]: {
      marginTop: '2.5rem'
    }
  },
  primaryButton: {
    width: '10rem',
    height: '3.125rem !important',
    border: 'none'
  },
  secondaryButton: {
    width: '9rem',
    height: '3.125rem !important',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: Colors.violet10
    }
  },
  textButton: {
    width: '9rem',
    height: '3.125rem',
    border: 0,
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: Colors.violet10
    }
  },
  spacer: {
    width: '2.5rem'
  }
}))

export default function SelectMapThemeModal(props: any) {
  const classes = useStyles()
  const { t } = useTranslation()

  const { open, handleClose, currentTheme } = props

  const [theme, setTheme] = useState(currentTheme ?? null)

  const handleUpdate = (theme) => {
    setTheme(theme)
    props?.handleUpdate(theme)
  }
  const handleSave = () => props.handleSave(theme)

  const renderThemes = () => {
    return MapThemes.map((mapTheme: any) => (
      <Grid item xl={4} key={mapTheme.key}>
        <ButtonBase
          className={`
            ${classes.gridItem} ${mapTheme?.value === theme ? classes.activeGridItem : ''}
            `.trim()
          }
          onClick={() => handleUpdate(mapTheme.value)}
        >
          <img src={mapTheme.image} alt={theme.text} className={classes.image} />
        </ButtonBase>
        <div className={classes.row}>
          <p className={classes.gridItemName}>{mapTheme.text}</p>
        </div>
      </Grid>
    ))
  }

  return (
    <Modal open={open} onClose={handleClose} classes={{ root: classes.modal }}>
      <div className={classes.contentContainer}>
        <div className={classes.content}>
          <div className={classes.selectThemeContent}>
            <div className={classes.title}>{t('select_map_theme')}</div>
            <p className={classes.info}>{t('select_map_theme_info')}</p>
            <Grid
              direction='row'
              justifyContent='flex-start'
              alignItems='center'
              spacing={6}
              container
            >
              {renderThemes()}
            </Grid>
            <div className={classes.footerContainer}>
              <Button
                onClick={handleClose}
                text={t('cancel')}
                buttonStyle={classes.secondaryButton}
                outlined
              />
              <div className={classes.spacer} />
              <Button
                onClick={handleSave}
                text={t('save')}
                buttonStyle={classes.primaryButton}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
