import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'

export default class UserStore {
  rootStore
  users = []
  userInvites = []
  user = null
  loading = null

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  updateUsers = (users) => { this.users = users }
  updateUserInvites = (invites) => { this.userInvites = invites }
  updateUser = (user) => { this.user = user }
  updateLoading = (loading) => { this.loading = loading }

  async getMyOrganizationUsers() {
    this.updateLoading(true)
    const response: any = await Api.getMyOrganizationUsers()
    if (response.ok) {
      const users = response.data
      this.updateUsers(users)
    }
    this.updateLoading(false)
  }

  async getOrganizationsUsers(id) {
    this.updateLoading(true)
    this.updateLoading(false)
  }

  async getUser(id) {
    this.updateLoading(true)
    this.updateUser(null)
    const response = await Api.getMyOrganizationUser(id)
    this.updateLoading(false)
    if (response.ok) {
      const user = response.data
      this.updateUser(user)
    }
  }

  async updateUserData(id, data) {
    this.updateLoading(true)
    const response = await Api.updateUser(id, data)
    this.updateLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'info_updated')
    if (response.ok) {
      const user = response.data
      this.updateUser(user)
    }
  }

  async updateUserPhoto(id, data) {
    this.updateLoading(true)
    const response = await Api.updateUserPhoto(id, data)
    this.updateLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'info_updated')
    if (response.ok) {
      const user = response.data
      this.updateUser(user)
    }
  }

  async getUserInvites() {
    this.updateLoading(true)
    const response: any = await Api.getUserInvites()
    this.updateLoading(false)
    if (response.ok) {
      const userInvites = response.data
      this.updateUserInvites(userInvites)
    }
  }

  async inviteUser(data) {
    this.updateLoading(true)
    const response = await Api.inviteUser(data)
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'invite_sent')
    this.updateLoading(false)
    if (response.ok) {
      // Reload invites
      this.getUserInvites()
    }
  }

  async verifyUserInvite(data) {
    this.updateLoading(true)
    const response = await Api.verifyUserInvite(data)
    if (response.ok) {
      // TODO: Redirect to login and display some message?
    }
    this.updateLoading(false)
  }

  async deactivateUser(id) {
    this.updateLoading(true)
    const response = await Api.deactivateUser(id)
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'user_deactivated')
    this.updateLoading(false)
  }

  async addUserAccessRights(userId: number, mainEntityIds: number[]) {
    this.updateLoading(true)
    const response = await Api.addUserAccessRights(userId, { mainEntityIds })
    this.rootStore.appStore.setErrorOrSuccess(response, true, false)
    this.updateLoading(false)

    if (response.ok) {
      const user = response.data
      this.updateUser(user)
    }
  }

  async removeUserAccessRights(userId: number, mainEntityIds: number[]) {
    this.updateLoading(true)
    const response = await Api.removeUserAccessRights(userId, { mainEntityIds })
    this.rootStore.appStore.setErrorOrSuccess(response, true, false)
    this.updateLoading(false)

    if (response.ok) {
      const user = response.data
      this.updateUser(user)
    }
  }

  async addUserMainEntityTypeAccessRights(userId: number, mainEntityTypeIds: number[]) {
    this.updateLoading(true)
    const response = await Api.addUserMainEntityTypeAccessRights(userId, { mainEntityTypeIds })
    this.rootStore.appStore.setErrorOrSuccess(response, true, false)
    this.updateLoading(false)

    if (response.ok) {
      const user = response.data
      this.updateUser(user)
    }
  }

  async removeUserMainEntityTypeAccessRights(userId: number, mainEntityTypeIds: number[]) {
    this.updateLoading(true)
    const response = await Api.removeUserMainEntityTypeAccessRights(userId, { mainEntityTypeIds })
    this.rootStore.appStore.setErrorOrSuccess(response, true, false)
    this.updateLoading(false)

    if (response.ok) {
      const user = response.data
      this.updateUser(user)
    }
  }
}
