import { DESCRIPTION, DESCRIPTION_TITLE, PARKING_ZONE_COLOR_CODE, PROPERTIES_TITLE } from '../elements.template'

const LAYOUT = {
  type: 'parking_zone',
  layout: [
    [
      // Start left column
      DESCRIPTION_TITLE,
      DESCRIPTION,
      PROPERTIES_TITLE,
      PARKING_ZONE_COLOR_CODE
      // End left column
    ],
    [
      // Start right column
      // End right column
    ]
  ]
}

export default LAYOUT
