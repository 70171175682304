import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'
import moment from 'moment'
import makeStyles from '@mui/styles/makeStyles'
import { useStore } from '../../../Models/RootStore'
import Grid from '@mui/material/Grid'
import { PageHeader, EventEntityCard, CreateMainEntityModal } from '../../../Components'
import { MainEntityCategoryTypeMappings, MainEntityTypes, MainEntityCategories } from '../../../Constants'
import { useNavigate } from 'react-router-dom'
import { Colors } from '../../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: '10rem'
  },
  container: {
    padding: '2.5rem'
  },
  noResultsText: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    color: Colors.black70,
    fontSize: '1.25rem',
    fontFamily: 'Roboto',
    fontWeight: 700
  }
}))

enum Tabs {
  All = 0,
  Ongoing = 1,
  Upcoming = 2,
  Finished = 3,
  Deleted = 4
}

function Events() {
  const classes = useStyles()
  const navigate = useNavigate()

  const { t } = useTranslation()

  const { entityStore, sessionStore }: any = useStore()

  useEffect(() => {
    entityStore.getMainEntities()
    entityStore.getMainEntityTypes()
  }, [])

  const [createOpen, setCreateOpen] = useState(false)
  const [tab, setTab] = useState(Tabs.All)

  const getTabs = () => [
    { value: Tabs.All, label: t('all_items') },
    { value: Tabs.Ongoing, label: t('ongoing') },
    { value: Tabs.Upcoming, label: t('upcoming') },
    { value: Tabs.Finished, label: t('finished') },
    { value: Tabs.Deleted, label: t('deleted_items') }
  ]

  const selectTab = (value: number) => setTab(value)

  const toggleCreateOpen = () => setCreateOpen(!createOpen)
  const createEntity = (fields: any) => {
    const body = {
      name: fields?.nameFi,
      nameFi: fields?.nameFi,
      nameEn: fields?.nameEn,
      mainEntityTypeId: fields?.type,
      // TODO: fix later
      areaMargin: 0
    }
    const callback = (entityId) => navigate(`/entity/${entityId}?mode=edit`)
    entityStore.createMainEntity(body, callback)
  }

  const getEvents = () => {
    let events: any = []
    const eventTypes = MainEntityCategoryTypeMappings.event ?? []

    for (const mainEntity of entityStore.mainEntities || []) {
      const isVisible = !!mainEntity.active
      if (isVisible) {
        const type = mainEntity?.mainEntityType?.type ?? null
        if (type && eventTypes.includes(type)) {
          events.push(mainEntity)
        }
      }
    }

    // TODO: fix field ids
    if (tab === Tabs.Ongoing) {
      const now = moment()
      events = events.filter((event: any) => {
        const startTime = event.fieldValues.find((field: any) => field.mainEntityFieldId === 120)?.value ?? null
        const endTime = event.fieldValues.find((field: any) => field.mainEntityFieldId === 121)?.value ?? null
        return startTime && endTime && moment(startTime).isBefore(now) &&  moment(endTime).isAfter(now)
      })
    } else if (tab === Tabs.Upcoming) {
      const now = moment()
      events = events.filter((event: any) => {
        const startTime = event.fieldValues.find((field: any) => field.mainEntityFieldId === 120)?.value ?? null
        return !startTime || (startTime && moment(startTime).isAfter(now))
      })
    } else if (tab === Tabs.Finished) {
      const now = moment()
      events = events.filter((event: any) => {
        const endTime = event.fieldValues.find((field: any) => field.mainEntityFieldId === 121)?.value ?? null
        return endTime && moment(endTime).isBefore(now)
      })
    } else if (tab === Tabs.Deleted) {
      events = events.filter((event: any) => !event?.active)
    }

    return sortBy(
      events,
      (event: any) => event.fieldValues.find((field: any) => field.mainEntityFieldId === 120)?.value
    )
  }

  const renderEvents = () => {
    return getEvents().map((item: any) => (
      <EventEntityCard
        key={item.id}
        item={item}
        onClick={() => navigate(`/entity/${item.id}`)}
      />
    ))
  }

  const renderCreateEntityModal = () => {
    if (createOpen) {
      return (
        <CreateMainEntityModal
          open={createOpen}
          handleCreate={createEntity}
          handleClose={toggleCreateOpen}
          allMainEntityTypes={Object.values(MainEntityTypes)}
          myMainEntityTypes={entityStore.mainEntityTypes}
          entityCategory={MainEntityCategories.Event}
        />
      )
    }
    return null
  }

  return (
    <div className={classes.root}>
      <PageHeader
        title={t('major_events')}
        onCreateClick={toggleCreateOpen}
        createButtonDisabled={!['admin', 'boss', 'entity_admin'].includes(get(sessionStore.user, 'role'))}
        tabs={getTabs()}
        currentTab={tab}
        onTabChange={selectTab}
      />
      <div className={classes.container}>
        <Grid
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          spacing={6}
          container
        >
          {renderEvents()}
        </Grid>
      </div>
      {renderCreateEntityModal()}
    </div>
  )
}

export default observer(Events)
