import { RENDER_STATE, SHAPE } from './mapConstants'
import get from 'lodash/get'
import { Colors } from '../../Utils/theme'

const CIRCLE_RADIUS = '0.75rem'
const DEFAULT_STROKE = Colors.violet
const DEFAULT_FILL = Colors.violet

const ACTIVE_COLOR = 'rgb(235, 94, 88)'

const ACTIVE_COLOR_POINT = Colors.violet
const ACTIVE_COLOR_AREA = Colors.violet
const ACTIVE_COLOR_ROUTE = Colors.violet
const ACTIVE_FILL_OPACITY = 0.75

const ACTIVE_FILL_COLOR = Colors.violet
const INACTIVE_COLOR = Colors.violet
const INACTIVE_FILL_OPACITY = 0.5

const FULL_OPACITY = 1.0

const RECT_STYLE = {
  stroke: ACTIVE_COLOR,
  strokeWidth: 2,
  x: -6,
  y: -6,
  height: 12,
  width: 12
}

const SELECTED_STYLE = {
  stroke: ACTIVE_COLOR,
  strokeWidth: 2,
  fill: INACTIVE_COLOR,
  fillOpacity: INACTIVE_FILL_OPACITY
}

const SELECTED_STYLE_POINT = {
  stroke: ACTIVE_COLOR_POINT,
  strokeWidth: 2,
  fill: INACTIVE_COLOR,
  fillOpacity: INACTIVE_FILL_OPACITY
}

const SELECTED_STYLE_AREA = {
  stroke: ACTIVE_COLOR_AREA,
  strokeWidth: 2,
  fill: INACTIVE_COLOR,
  fillOpacity: INACTIVE_FILL_OPACITY
}

const SELECTED_STYLE_ROUTE = {
  stroke: ACTIVE_COLOR_ROUTE,
  strokeWidth: 2,
  fill: INACTIVE_COLOR,
  fillOpacity: INACTIVE_FILL_OPACITY
}

const HOVERED_STYLE = {
  stroke: ACTIVE_COLOR,
  strokeWidth: 2,
  fill: ACTIVE_FILL_COLOR,
  fillOpacity: ACTIVE_FILL_OPACITY
}

const HOVERED_STYLE_POINT = {
  stroke: ACTIVE_COLOR_POINT,
  strokeWidth: 2,
  fill: ACTIVE_FILL_COLOR,
  fillOpacity: FULL_OPACITY
}

const HOVERED_STYLE_AREA = {
  stroke: ACTIVE_COLOR_AREA,
  strokeWidth: 2,
  fill: ACTIVE_FILL_COLOR,
  fillOpacity: ACTIVE_FILL_OPACITY
}

const HOVERED_STYLE_ROUTE = {
  stroke: ACTIVE_COLOR_ROUTE,
  strokeWidth: 2,
  fill: ACTIVE_FILL_COLOR,
  fillOpacity: ACTIVE_FILL_OPACITY
}

const UNCOMMITTED_STYLE = {
  stroke: INACTIVE_COLOR,
  strokeDasharray: '4,2',
  strokeWidth: 2,
  fill: INACTIVE_COLOR,
  fillOpacity: 0.5
}

const INACTIVE_STYLE = UNCOMMITTED_STYLE

const DEFAULT_STYLE = {
  stroke: DEFAULT_STROKE,
  strokeWidth: 2,
  fill: DEFAULT_FILL,
  fillOpacity: 0.1
}

function featureStyle({ feature, state }) {
  const type = feature.properties.shape || feature.geometry.type
  let style = null

  switch (state) {
    case RENDER_STATE.SELECTED:
      style = { ...SELECTED_STYLE }

      if (type === SHAPE.POINT) style = { ...SELECTED_STYLE_POINT }
      if (type === SHAPE.POLYGON) style = { ...SELECTED_STYLE_AREA }
      if (type === SHAPE.LINE_STRING) style = { ...SELECTED_STYLE_ROUTE }

      break

    case RENDER_STATE.HOVERED:
      style = { ...HOVERED_STYLE }

      if (type === SHAPE.POINT) style = { ...HOVERED_STYLE_POINT }
      if (type === SHAPE.POLYGON) style = { ...HOVERED_STYLE_AREA }
      if (type === SHAPE.LINE_STRING) style = { ...HOVERED_STYLE_ROUTE }

      break

    case RENDER_STATE.UNCOMMITTED:
    case RENDER_STATE.CLOSING:
      style = { ...UNCOMMITTED_STYLE }
      break

    case RENDER_STATE.INACTIVE:
      style = { ...INACTIVE_STYLE }
      break

    default:
      style = { ...DEFAULT_STYLE }
  }

  switch (type) {
    case SHAPE.POINT:
      style.r = CIRCLE_RADIUS
      break
    case SHAPE.LINE_STRING:
      style.fill = 'none'
      break
    case SHAPE.POLYGON:
      if (state === RENDER_STATE.CLOSING) {
        style.strokeDasharray = '4,2'
      }

      break
    case SHAPE.RECTANGLE:
      if (state === RENDER_STATE.UNCOMMITTED) {
        style.strokeDasharray = '4,2'
      }

      break
    default:
  }

  return style
}

export function getFeatureStyle (highlightedEntity) {
  const styleGetter = (params) => {
    const typeMatch = !get(highlightedEntity, 'mainEntityId') === !get(params.feature, 'properties.mainEntityId')
    if (
      highlightedEntity &&
      typeMatch &&
      (
        highlightedEntity.id === get(params.feature, 'properties.subEntityId') ||
        !get(params.feature, 'properties.subEntityId')
      )
    ) {
      return featureStyle({ ...params, state: RENDER_STATE.HOVERED })
    }
    return featureStyle(params)
  }
  return styleGetter
}

export function editHandleStyle({ feature, shape, index, state }) {
  let style = {}
  switch (state) {
    case RENDER_STATE.SELECTED:
      style = { ...SELECTED_STYLE }
      break

    case RENDER_STATE.HOVERED:
      style = { ...HOVERED_STYLE }
      break

    case RENDER_STATE.UNCOMMITTED:
    case RENDER_STATE.CLOSING:
      style = { ...UNCOMMITTED_STYLE }
      break

    case RENDER_STATE.INACTIVE:
      style = { ...INACTIVE_STYLE }
      break

    default:
      style = { ...DEFAULT_STYLE }
  }

  switch (shape) {
    case 'circle':
      //@ts-ignore
      style.r = CIRCLE_RADIUS
      break
    case 'rect':
      style = { ...style, ...RECT_STYLE }
      break
    default:
  }

  return style
}
