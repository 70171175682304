import React from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import makeStyles from '@mui/styles/makeStyles'
import { Tooltip, IconButton } from '@mui/material'
import EditIcon from '../../Assets/Icons/edit-dark.svg'
import DeleteIcon from '../../Assets/Icons/delete.svg'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  headerTitle: {
    fontWeight: 500,
    color: Colors.label,
    marginBottom: '1rem'
  },
  box: {
    padding: '.25rem 2rem 2rem',
    backgroundColor: Colors.lightGrey,
    marginBottom: '1rem',
    position: 'relative',
    borderRadius: '.3125rem'
  },
  description: {
    fontSize: '1.25rem',
    fontWeight: 300,
    paddingBottom: '1rem'
  },
  timeRow: {
    fontSize: '1.25rem',
    fontWeight: 300
  },
  content: {
    flex: 1,
    padding: '0 1rem'
  },
  times: {
    fontSize: '1rem',
    fontWeight: 400,
    color: 'rgba(79, 79, 79, 0.8)'
  },
  title: {
    fontFamily: 'Roboto',
    fontWeight: 800,
    color: Colors.black,
    fontSize: '1.5rem',
  },
  button: {
    backgroundColor: Colors.white,
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.black20}`,
    height: '2rem',
    width: '2rem',
    marginLeft: '0.5rem',
    '&:hover': {
      backgroundColor: `${Colors.violet10} !important`
    }
  },
  icon: {
    height: '1.125rem',
    width: 'auto',
    maxHeight: '1.125rem',
    maxWidth: '1.125rem'
  },
  actions: {
    top: '1rem',
    right: '1rem',
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row'
  }
}))

export default function EventServiceRepeaterItem(props) {
  const classes = useStyles()
  const { t } = useTranslation()
  const { item } = props

  const renderTimes = () => {
    if (item && item.times) {
      return item.times.map(time => {
        return (
          <div className={classes.timeRow}>
            {moment(time.startDate).format('DD.MM.YYYY HH:mm')}-{moment(time.endDate).format('HH:mm')}
          </div>
        )
      })
    }
    return null
  }

  return (
    <div>
      <div className={classes.headerTitle}>{t('service')} #{props.index + 1}</div>
      <div className={classes.box}>
        <div className={classes.actions}>
          <Tooltip title={t('edit')}>
            <IconButton className={classes.button} onClick={props.onEdit}>
              <img src={EditIcon} className={classes.icon} />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('delete')}>
            <IconButton className={classes.button} onClick={props.onDelete} size='large'>
              <img src={DeleteIcon} className={classes.icon} />
            </IconButton>
          </Tooltip>
        </div>
        <h2 className={classes.title}>{item.titleFi || item.titleEn}</h2>
        <p className={classes.description}>{item.descriptionFi || item.descriptionEn}</p>
        {renderTimes()}
      </div>
    </div>
  )
}
