import React from 'react'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import makeStyles from '@mui/styles/makeStyles'
import { Colors } from '../../Utils/theme'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import ProfileAlt from '../../Assets/Images/profile.png'
import PhoneIcon from '../../Assets/Icons/puhelin.svg'
import MailIcon from '../../Assets/Icons/maili.svg'
import { Tooltip } from '@mui/material'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: Colors.backgroundGrey,
    '&:hover': {
      backgroundColor: `${Colors.tableHover} !important`
    }
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  cell: {
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: 400,
    color: Colors.black,
    borderBottom: 'none'
  },
  firstCell: {
    fontFamily: 'Roboto',
    fontSize: '1.125rem',
    fontWeight: 600,
    height: '3rem',
    color: Colors.black,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    borderBottom: 'none'
  },
  lastCell: {
    height: '3rem',
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    borderBottom: 'none',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  link: {
    display: 'block',
    padding: '.5rem 0',
    textDecoration: 'none',
    color: Colors.black
  },
  photo: {
    height: '2.5rem',
    width: '2.5rem',
    objectFit: 'cover',
    borderRadius: '100%',
    marginRight: '1rem'
  },
  buttonLink: {
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.white,
    border: `1px solid ${Colors.black20}`,
    height: '2.25rem',
    width: '2.25rem',
    marginTop: '1rem',
    borderRadius: '0.3125rem',
    marginRight: '.75rem',
    '&:hover': {
      backgroundColor: Colors.violet10
    }
  },
  actionIcon: {
    height: '1.125rem',
    width: '1.125rem'
  },
  online: {
    height: '1.5rem',
    width: '1.5rem',
    borderRadius: '2rem',
    backgroundColor: '#ABC872',
    border: '.25rem solid #E5EBDA'
  },
  offline: {
    height: '1.5rem',
    width: '1.5rem',
    borderRadius: '2rem',
    backgroundColor: '#EB5E58',
    border: '.25rem solid #F2D6D5'
  },
  onlineStatus: {
    marginLeft: '.5rem',
    opacity: 0.7
  }
}))

function UserTableRow (props) {
  const { user, isMe } = props
  const classes = useStyles()
  const { t } = useTranslation()

  const renderCall = () => {
    if (user.phone) {
      return (
        <Tooltip title={user.phone}>
          <a className={classes.buttonLink} href={`tel:${user.phone}`}>
            <img src={PhoneIcon} alt='call' className={classes.actionIcon} />
          </a>
        </Tooltip>
      )
    }
    return null
  }

  return (
    <TableRow classes={{ root: classes.root }} hover>
      <TableCell className={classes.firstCell} size='small'>
        <Link className={classes.link} to={isMe ? `/settings` : `/users/${user.id}`}>
          <div className={classes.row}>
            <img className={classes.photo} src={user.photo || ProfileAlt} alt='photo' />
            <span>{user.firstName} {user.lastName}</span>
          </div>
        </Link>
      </TableCell>
      <TableCell className={classes.cell} size='small'>
        <div className={classes.row}>
          <div className={user.isOnline ? classes.online : classes.offline} />
          <div className={classes.onlineStatus}>{user.isOnline ? t('online') : t('offline')}</div>
        </div>
      </TableCell>
      <TableCell className={classes.cell} size='small'>{user.email}</TableCell>
      <TableCell className={classes.cell} size='small'>{t(user.role)}</TableCell>
      <TableCell className={classes.lastCell} size='small'>
        {renderCall()}
        <Tooltip title={user.email}>
          <a className={classes.buttonLink} href={`mailto:${user.email}`}>
            <img src={MailIcon} alt='email' className={classes.actionIcon} />
          </a>
        </Tooltip>
      </TableCell>
    </TableRow>
  )
}

export default UserTableRow
