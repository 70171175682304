import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import get from 'lodash/get'
import makeStyles from '@mui/styles/makeStyles'
import { useStore } from '../../../Models/RootStore'
import { Button, Select, WikiEditorRepeater, WikiHeader } from '../../../Components'
import { Link, useParams } from 'react-router-dom'
import { Colors } from '../../../Utils/theme'
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
  },
  mainSection: {
    flex: 1,
    position: 'relative'
  },
  mainSectionInner: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflowY: 'auto'
  },
  container: {
    padding: '2.5rem 2rem 4rem'
  },
  contentContainer: {
    width: '100%',
    margin: '0 auto',
    maxWidth: '51rem'
  },
  footerContainer: {
    paddingTop: '2rem',
    backgroundColor: Colors.lightGrey,
  },
  footerInner: {
    width: '100%',
    margin: '0 auto',
    maxWidth: '51rem',
    display: 'flex',
    flexDirection: 'row'
  },
  saveButtonContainer: {
    width: '12rem',
    display: 'flex',
    flexDirection: 'row'
  },
  cancelButtonContainer: {
    marginRight: '1.25rem',
  },
  cancelLink: {
    height: '3.125rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 1rem',
    textDecoration: 'none',
    color: Colors.black,
    fontWeight: 700,
    borderRadius: '.3125rem',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,.1)'
    }
  },
  flexSpacer: {
    flex: 1
  },
  lastModifiedContainer: {
    marginLeft: '1rem',
    paddingTop: '.75rem'
  }
}))

function WikiEntityTypes() {
  const classes = useStyles()

  const { mainEntityType, mainEntityId }: any = useParams()
  const { t } = useTranslation()

  const [lastModified, setLastModified] = useState(null)

  const [data, setData] = useState({
    exceptionNotifications: [],
    guides: []
  })

  const [lang, setLang] = useState('fi')

  const setExceptionNotifications = (items) => {
    const newData = {
      ...data,
      exceptionNotifications: items
    }
    setData(newData)
  }

  const setGuides = (items) => {
    const newData = {
      ...data,
      guides: items
    }
    setData(newData)
  }

  const { entityStore, infoPageStore }: any = useStore()

  useEffect(() => {
    entityStore.getMainEntities()
    entityStore.getMainEntityTypes()
    infoPageStore.getInfoPages()
    if (mainEntityType === 'service') {
      infoPageStore.getServicePage()
    }
  }, [])

  const handleSave = () => {
    if (mainEntityType === 'service') {

      infoPageStore.createOrEditServicePage({ data })

    } else {
      if (mainEntityId) {
        const mId = parseInt(mainEntityId, 10)
        infoPageStore.createOrEditMainEntityPage(mId, { data })
      } else {
        const mainEntityTypeId = parseInt(mainEntityType, 10)
        infoPageStore.createOrEditMainEntityTypePage(mainEntityTypeId, { data })
      }
    }
  }

  useEffect(() => {
    // Find current info page
    if (infoPageStore.infoPages) {
      for (const infoPage of infoPageStore.infoPages) {
        if (mainEntityId) {
          if (infoPage.mainEntityId === parseInt(mainEntityId, 10)) {
            // Validate data
            if (
              infoPage.data &&
              infoPage.data.exceptionNotifications &&
              infoPage.data.guides
            ) {
              setData(infoPage.data)
              setLastModified(infoPage.updatedAt)
            }
          }
        } else {
          if (
            !infoPage.mainEntityId &&
            infoPage.mainEntityTypeId === parseInt(mainEntityType, 10)
          ) {
            // Validate data
            if (
              infoPage.data &&
              infoPage.data.exceptionNotifications &&
              infoPage.data.guides
            ) {
              setData(infoPage.data)
              setLastModified(infoPage.updatedAt)
            }
          }
        }
      }
    }
  }, [infoPageStore.infoPages])

  useEffect(() => {
    if (mainEntityType === 'service' && infoPageStore.servicePage) {
      setData(infoPageStore.servicePage.data)
      setLastModified(infoPageStore.servicePage.updatedAt)
    }
  }, [infoPageStore.servicePage])


  const renderFooter = () => {
    return (
      <div className={classes.footerContainer}>
        <div className={classes.footerInner}>
          <div>
            <Select
              options={[
                {
                  label: 'FI',
                  value: 'fi'
                },
                {
                  label: 'EN',
                  value: 'en'
                }
              ]}
              value={lang}
              onChange={setLang}
              style={{
                backgroundColor: Colors.white,
                maxWidth: '4.25725rem'
              }}
            />
          </div>
          <div className={classes.lastModifiedContainer}>
            {lastModified ? `${t('updated_at')} ${moment(lastModified).format('DD.MM.YYYY [klo] HH:mm')}` : null}
          </div>
          <div className={classes.flexSpacer} />
          <div className={classes.cancelButtonContainer}>
            <Link to='/wiki' className={classes.cancelLink}>{t('cancel')}</Link>
          </div>
          <div className={classes.saveButtonContainer}>
            <Button
              text={t('save')}
              onClick={handleSave}
              tall
            />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <div className={classes.mainSection}>
        <div className={classes.mainSectionInner}>
          <WikiHeader
            title={mainEntityId ? t('single_main_entity_guide') : t('entity_type_guides')}
          />
          <div className={classes.container}>
            <div className={classes.contentContainer}>
            <WikiEditorRepeater
              title={t('exception_notifications')}
              allBoxTitle={t('announcement')}
              items={get(data, 'exceptionNotifications', [])}
              setItems={setExceptionNotifications}
              lang={lang}
              useValidityRange
            />
            <WikiEditorRepeater
              title={t('guides')}
              allBoxTitle={t('content_section')}
              items={get(data, 'guides', [])}
              setItems={setGuides}
              lang={lang}
            />
            </div>
          </div>
        </div>
      </div>
      {renderFooter()}
    </div>
  )
}

export default observer(WikiEntityTypes)
