import React from 'react'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import makeStyles from '@mui/styles/makeStyles'
import IconButton from '@mui/material/IconButton'
import { Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Colors } from '../../Utils/theme'
import { GEOMETRY_TYPES } from '../../Utils/map'
import AddIcon from '../../Assets/Icons/add-dark.svg'
import EditIconDark from '../../Assets/Icons/edit-dark.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: Colors.backgroundGrey,
    '&:hover': {
      backgroundColor: Colors.black20
    }
  },
  evenRoot: {
    backgroundColor: Colors.white,
    '&:hover': {
      backgroundColor: Colors.black20
    }
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  firstCell: {
    width: '100%',
    height: '3rem',
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: 600,
    color: Colors.black,
    borderBottom: 'none'
  },
  cell: {
    height: '3rem',
    fontFamily: 'Roboto',
    fontSize: '1rem',
    color: Colors.black,
    borderBottom: 'none'
  },
  lastCell: {
    height: '3rem',
    borderBottom: 'none',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  link: {
    textDecoration: 'none',
    color: Colors.black,
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8
    }
  },
  buttonLink: {
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.white,
    width: 32,
    height: 32,
    borderRadius: '0.3125rem',
    marginRight: '1rem'
  },
  actionButton: {
    backgroundColor: Colors.white,
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.black20}`,
    height: '2rem',
    width: '2rem',
    marginLeft: '.5rem',
    '&:hover': {
      backgroundColor: `${Colors.violet10} !important`
    }
  },
  actionIcon: {
    height: '1.125rem',
    width: 'auto',
    maxHeight: '1.125rem',
    maxWidth: '1.125rem'
  },
  typeBadge: {
    fontFamily: 'Roboto',
    fontSize: '0.875rem',
    fontWeight: 600
  },
  addButton: {
    backgroundColor: Colors.white,
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.black20}`,
    height: '2rem',
    width: '2rem',
    marginLeft: '0.5rem',
    '&:hover': {
      backgroundColor: `${Colors.white} !important`
    }
  },
  addButtonActive: {
    backgroundColor: Colors.black20,
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.black20}`,
    height: '2rem',
    width: '2rem',
    marginLeft: '0.5rem',
    '&:hover': {
      backgroundColor: `${Colors.black20} !important`
    }
  },
}))

function MainEntityTableRow (props) {
  const { item } = props
  const classes = useStyles()
  const { t } = useTranslation()

  const renderEditButton = () => {
    return (
      <Tooltip title={t('edit_entity')}>
        <IconButton onClick={props.onEdit} className={classes.actionButton} size='large'>
          <img src={EditIconDark} className={classes.actionIcon} alt='icon' />
        </IconButton>
      </Tooltip>
    )
  }

  const handleSetAddMainEntityId = () => {
    if (props.addMainEntityId === item.id) {
      props.setAddMainEntityId(null)
    } else {
      props.setAddMainEntityId(item.id)
    }
  }

  const renderAddGeoButton = () => {
    // Check if geo is not drawed
    if (!item.hasGeo) {
      return (
        <Tooltip title={t('add_geo_feature')}>
          <IconButton
            onClick={handleSetAddMainEntityId}
            className={props.addMainEntityId === item.id ? classes.addButtonActive : classes.addButton}
            size='large'
          >
            <img src={AddIcon} className={classes.actionIcon} alt='add' />
          </IconButton>
        </Tooltip>
      )
    }
  }

  const handleClick = () => props.onClick(item.id)

  const renderType = () => {
    if (item.type) {
      return <div className={classes.typeBadge}>{t(item.type)}</div>
    }
    return null
  }

  return (
    <TableRow
      classes={{ root: props.even ? classes.evenRoot : classes.root }}
      onMouseEnter={() => props.setHighlightedEntity(props.item)}
      onMouseLeave={() => props.setHighlightedEntity(null)}
    >
      <TableCell className={classes.firstCell} size='small'>
        <div className={classes.link} onClick={handleClick}>
          <div className={classes.row}>
            <span>{item.nameFi || item.nameEn}</span>
          </div>
        </div>
      </TableCell>
      <TableCell className={classes.cell} size='small'>
        {renderType()}
      </TableCell>
      <TableCell className={classes.lastCell} size='small'>
        {renderEditButton()}
        {renderAddGeoButton()}
      </TableCell>
    </TableRow>
  )
}

export default MainEntityTableRow
