import {
  SPACER,
  STREET_ADDRESS,
  CITY,
  POSTAL_CODE,
  WWW,
  OPEN_HOURS_TITLE,
  OPEN_HOURS,
  SERVICE_PHONES_AND_URLS_TITLE,
  SERVICE_PHONES,
  SERVICE_URLS,
  RESTAURANT_ADDRESS_TITLE,
  DESCRIPTION,
  RESTAURANT_DESCRIPTION_TITLE
} from '../elements.template'

const LAYOUT = {
  type: 'subentity_restaurant',
  layout: [
    [
      // Start left column
      OPEN_HOURS_TITLE,
      OPEN_HOURS,
      SERVICE_PHONES_AND_URLS_TITLE,
      SERVICE_PHONES,
      SERVICE_URLS,
      RESTAURANT_DESCRIPTION_TITLE,
      DESCRIPTION,
      RESTAURANT_ADDRESS_TITLE,
      {
        type: 'container',
        className: 'customRowFixed',
        items: [
          STREET_ADDRESS,
          SPACER,
          CITY,
          SPACER,
          POSTAL_CODE
        ]
      },
      {
        ...WWW,
        label: 'website_url',
        hideLabel: true,
        style: { flex: 1, maxWidth: '38rem' }
      },
      // End left column
    ],
    [
      // Start of right column
      // End of right column
    ]
  ]
}

export default LAYOUT
