import {
  EXCEPTION_NOTIFICATION,
  CONTACT_INFORMATION_TITLE,
  STREET_ADDRESS,
  SPACER,
  CITY,
  POSTAL_CODE,
  EXCEPTION_NOTIFICATIONS_TITLE,
  OPEN_HOURS,
  EXCEPTION_SCHEDULES,
  OPEN_HOURS_TITLE,
  TOUR_SCHEDULES_LINK,
  MUSEUM_THEMES,
  PRICING_AND_SERVICES_TITLE,
  PRICE,
  PAYMENT_METHODS,
  GUIDER_TOUR,
  RESERVATION_NUMBER,
  TOUR_PRICING,
  EXHIBITION_INFORMATION_TITLE,
  DESCRIPTION_TITLE,
  DESCRIPTION
 } from '../elements.template'

const LAYOUT = {
  type: 'museum',
  layout: [
    [
      // Start left column
      DESCRIPTION_TITLE,
      DESCRIPTION,
      OPEN_HOURS_TITLE,
      { ...OPEN_HOURS, hideLabel: true },
      { ...EXCEPTION_SCHEDULES, label: 'exceptions_in_schedules' },
      EXHIBITION_INFORMATION_TITLE,
      { ...TOUR_SCHEDULES_LINK, style: { flex: 1, maxWidth: '38rem' } },
      MUSEUM_THEMES,
      PRICING_AND_SERVICES_TITLE,
      PRICE,
      PAYMENT_METHODS,
      {
        type: 'container',
        className: 'customRowFixed',
        items: [
          { ...GUIDER_TOUR, style: { flex: 1, maxWidth: '10rem' } },
          SPACER,
          { ...RESERVATION_NUMBER, style: { flex: 1, maxWidth: '20rem' } },
        ]
      },
      TOUR_PRICING
      // End left column
    ],
    [
      // Start right column
      EXCEPTION_NOTIFICATIONS_TITLE,
      { ...EXCEPTION_NOTIFICATION, hideLabel: true, style: { paddingTop: '0.375rem' } },
      CONTACT_INFORMATION_TITLE,
      {
        type: 'container',
        className: 'customRowFixed',
        style: { paddingBottom: '10rem' },
        items: [
          STREET_ADDRESS,
          SPACER,
          CITY,
          SPACER,
          POSTAL_CODE
        ]
      }
      // End right column
    ]
  ]
}

export default LAYOUT
