import {
  SPACER,
  DESCRIPTION,
  STREET_ADDRESS,
  CITY,
  POSTAL_CODE,
  DESCRIPTION_TITLE,
  ADDRESS_INFORMATION_TITLE,
  SERVICES_TITLE,
  ACTIVITIES_TITLE,
  ACCESSIBILITY_TITLE,
  UNOBSTRUCTED_PASSAGE,
  PASSAGE_INSTRUCTIONS,
  CONTACT_INFORMATION_TITLE,
  CONTACT_PERSON,
  PHONE,
  EMAIL,
  WWW,
  CAMPFIRE_SERVICES,
  CAMPFIRE_ACTIVITIES
} from '../elements.template'

const LAYOUT = {
  type: 'subentity_campfire',
  layout: [
    [
      // Start left column
      SERVICES_TITLE,
      CAMPFIRE_SERVICES,
      ACTIVITIES_TITLE,
      CAMPFIRE_ACTIVITIES,,
      { ...ACCESSIBILITY_TITLE, info: 'passage_accessibility_info' },
      UNOBSTRUCTED_PASSAGE,
      PASSAGE_INSTRUCTIONS,
      DESCRIPTION_TITLE,
      DESCRIPTION,
      CONTACT_INFORMATION_TITLE,
      {
        type: 'container',
        className: 'customRowFixed',
        style: { flexWrap: 'wrap' },
        items: [
          { ...CONTACT_PERSON, style: { flex: 1, minWidth: '9rem', maxWidth: '18rem' } },
          SPACER,
          { ...EMAIL, style: { flex: 1, minWidth: '16rem', maxWidth: '24rem' } },
          SPACER,
          { ...PHONE, style: { flex: 1, minWidth: '9rem', maxWidth: '12rem' } }
        ]
      },
      { ...WWW, style: { flex: 1, maxWidth: '38rem' } },
      ADDRESS_INFORMATION_TITLE,
      {
        type: 'container',
        className: 'customRowFixed',
        items: [
          STREET_ADDRESS,
          SPACER,
          CITY,
          SPACER,
          POSTAL_CODE
        ]
      }
      // End left column
    ],
    [
      // Start of right column
      // End of right column
    ]
  ]
}

export default LAYOUT
