import React from 'react'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  root: {
  },

  header: {
    display: 'flex',
    flexDirection: 'row',
    background: '#333',
    color: '#fff'
  },
  row: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    '&:hover': {
      background: '#eee'
    }
  },
  columnShort: {
    padding: '.5rem 1rem',
    minWidth: '3rem',
    background: 'rgba(0,0,0,.1)'
  },
  column: {
    flex: 1,
    padding: '.5rem 1rem'
  }
}))

function AdminTable(props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const renderHeader = () => {
    return (
      <div className={classes.header}>
        {props.fields.map((item, index) => {
          return (
            <div key={index} className={item.key === 'id' ? classes.columnShort : classes.column}>
              {t(item.name)}
            </div>
          )
        })}
      </div>
    )
  }

  const renderRows = () => {
    return props.items.sort((a, b) => a.id - b.id).map((item, index) => {
      return (
        <div className={classes.row} onClick={() => props.onClick(item)}>
          {props.fields.map((field, fieldIndex) => {
            return (
              <div key={`${index}-${fieldIndex}`} className={field.key === 'id' ? classes.columnShort : classes.column}>
                {item[field.key]}
              </div>
            )
          })}
        </div>
      )
    })
  }

  return (
    <div className={classes.root}>
      {renderHeader()}
      {renderRows()}
    </div>
  )
}

export default AdminTable
