import {
  SPACER,
  DESCRIPTION,
  OPEN_HOURS_TITLE,
  OPEN_HOURS,
  STREET_ADDRESS,
  CITY,
  POSTAL_CODE,
  PROPERTIES_TITLE,
  WINTER_MAINTENANCE,
  STAIR_LENGTH,
  STAIR_COUNT,
  STAIRS_DESCRIPTION_TITLE,
  STAIRS_ADDRESS_TITLE,
  HAS_LIGHTS,
  WORKOUT_STAIRS_EQUIPMENT
} from '../elements.template'

const LAYOUT = {
  type: 'workout_stairs',
  layout: [
    [
      // Start left column
      OPEN_HOURS_TITLE,
      {
        type: 'custom_field',
        field: 'open_hours_type',
        customType: 'checkbox'
      },
      {
        ...OPEN_HOURS,
        hideLabel: false,
        hiddenCheck: (allFields, customFieldValues) => {
          const field = (allFields || []).find(f => f.fieldKey === 'open_hours_type')
          if (field) {
            const val = (customFieldValues || []).find(f => f.id === field.id)
            if (val) {
              // Check if we should hide
              if (val?.value === 'always_open') return true
            }
          }
          return false
        }
      },
      PROPERTIES_TITLE,
      {
        type: 'container',
        className: 'customRowFixed',
        items: [
          WINTER_MAINTENANCE,
          SPACER,
          SPACER,
          STAIR_LENGTH,
          SPACER,
          SPACER,
          STAIR_COUNT
        ]
      },
      {
        type: 'container',
        className: 'customRowFixed',
        items: [
          HAS_LIGHTS,
          SPACER,
          SPACER,
          WORKOUT_STAIRS_EQUIPMENT
        ]
      },
      STAIRS_DESCRIPTION_TITLE,
      DESCRIPTION,
      STAIRS_ADDRESS_TITLE,
      {
        type: 'container',
        className: 'customRowFixed',
        style: { paddingBottom: '10rem' },
        items: [
          STREET_ADDRESS,
          SPACER,
          CITY,
          SPACER,
          POSTAL_CODE
        ]
      },
      // End left column
    ],
    [
      // Start of right column
      // End of right column
    ]
  ]
}

export default LAYOUT
