import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'

export default class AdminStore {
  rootStore

  mainEntityTypes = []
  mainEntityType = null
  mainEntityFields = []
  mainEntityTypesMainEntityFields = []
  mainEntityField = null
  mainEntityTypeFields = null

  subEntityTypes = []
  subEntityType = null
  subEntityFields = []
  subEntityTypesSubEntityFields = []
  subEntityField = null
  subEntityTypeFields = null

  organizationMainEntityTypes = []
  organizationUsers = []
  organizationInvites = []

  mainEntityTypeSubEntityTypes = []

  loading: any = null

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  updateMainEntityTypes = (types) => { this.mainEntityTypes = types }
  updateMainEntityType = (type) => { this.mainEntityType = type }
  updateMainEntityField = (field) => { this.mainEntityField = field }
  updateMainEntityFields = (fields) => { this.mainEntityFields = fields }
  updateMainEntityTypesMainEntityFields = (items) => { this.mainEntityTypesMainEntityFields = items }
  updateMainEntityTypeFields = (fields) => { this.mainEntityTypeFields = fields }

  updateSubEntityTypes = (types) => { this.subEntityTypes = types }
  updateSubEntityType = (type) => { this.subEntityType = type }
  updateSubEntityField = (field) => { this.subEntityField = field }
  updateSubEntityFields = (fields) => { this.subEntityFields = fields }
  updateSubEntityTypesSubEntityFields = (items) => { this.subEntityTypesSubEntityFields = items }
  updateSubEntityTypeFields = (fields) => { this.subEntityTypeFields = fields }

  updateOrganizatonMainEntityTypes = (organizationMainEntityTypes) => { this.organizationMainEntityTypes = organizationMainEntityTypes }
  updateOrganizationUsers = (organizationUsers) => { this.organizationUsers = organizationUsers }
  updateOrganizationInvites = (organizationInvites) => { this.organizationInvites = organizationInvites }

  updateMainEntityTypeSubEntityTypes = (mainEntityTypeSubEntityTypes) => { this.mainEntityTypeSubEntityTypes = mainEntityTypeSubEntityTypes }

  updateLoading = (loading) => { this.loading = loading }

  async getMainEntityTypes() {
    this.updateLoading(true)
    const response: any = await Api.getAllMainEntityTypes()
    this.updateLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, true, false)

    if (response.ok) {
      const mainEntityTypes = response.data
      this.updateMainEntityTypes(mainEntityTypes)
    }
  }

  async getMainEntityFields() {
    this.updateLoading(true)
    const response = await Api.getMainEntityFields()
    if (response.ok) {
      const mainEntityFields = response.data
      this.updateMainEntityFields(mainEntityFields)
    }
    this.updateLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, true, false)
  }

  async getMainEntityField(id) {
    this.updateLoading(true)
    const response: any = await Api.getMainEntityFields()
    this.updateLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, true, false)

    if (response.ok) {
      const mainEntityField = response.data.find(item => item.id === id)
      this.updateMainEntityField(mainEntityField)
    }
  }

  async getMainEntityTypesMainEntityFields() {
    this.updateLoading(true)
    const response: any = await Api.getMainEntityTypesMainEntityFields()
    this.updateLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, true, false)

    if (response.ok) {
      const items = response.data
      this.updateMainEntityTypesMainEntityFields(items)
    }
  }

  async getMainEntityType(id) {
    this.updateLoading(true)
    const response: any = await Api.getAllMainEntityTypes()
    this.updateLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, true, false)

    if (response.ok) {
      const mainEntityType = response.data.find(item => item.id === id)
      this.updateMainEntityType(mainEntityType)
    }
  }

  async editMainEntityType(id, data) {
    this.updateLoading(true)
    const response: any = await Api.updateMainEntityType(id, data)
    this.updateLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, true, true)
  }

  async createMainEntityType(mainEntityType) {
    this.updateLoading(true)
    const response: any = await Api.createMainEntityType(mainEntityType)
    this.updateLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, true, true)
  }

  async editMainEntityField(id, data) {
    this.updateLoading(true)
    const response: any = await Api.updateMainEntityField(id, data)
    this.updateLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, true, true)
  }

  async createMainEntityField(mainEntityField) {
    this.updateLoading(true)
    const response: any = await Api.createMainEntityField(mainEntityField)
    this.updateLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, true, true)
  }

  async deleteMainEntityField (id) {
    this.updateLoading(true)
    const response: any = await Api.deleteMainEntityField(id)
    this.updateLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, true, true)
  }

  async addMainEntityTypeFields(mainEntityTypeId, addFieldIds) {
    const body = addFieldIds.map(mainEntityFieldId => {
      return (
        {
          mainEntityTypeId,
          mainEntityFieldId
        }
      )
    })
    const response: any = await Api.addMainEntityTypeFields(body)
    this.rootStore.appStore.setErrorOrSuccess(response, true, true)
  }

  async deleteMainEntityTypeFields(mainEntityTypeId, deleteFieldIds) {
    const body = deleteFieldIds.map(mainEntityFieldId => {
      return (
        {
          mainEntityTypeId,
          mainEntityFieldId
        }
      )
    })
    const response: any = await Api.deleteMainEntityTypeFields(body)
    this.rootStore.appStore.setErrorOrSuccess(response, true, true)
  }

  async getSubEntityTypes() {
    this.updateLoading(true)
    const response: any = await Api.getSubEntityTypes()
    this.updateLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, true, false)
    if (response.ok) {
      const subEntityTypes = response.data
      this.updateSubEntityTypes(subEntityTypes)
    }
  }

  async getSubEntityTypesSubEntityFields() {
    this.updateLoading(true)
    const response: any = await Api.getSubEntityTypesSubEntityFields()
    this.updateLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, true, false)

    if (response.ok) {
      const items = response.data
      this.updateSubEntityTypesSubEntityFields(items)
    }
  }

  async getSubEntityType(id) {
    this.updateLoading(true)
    const response: any = await Api.getSubEntityTypes()
    this.updateLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, true, false)

    if (response.ok) {
      const subEntityType = response.data.find(item => item.id === id)
      this.updateSubEntityType(subEntityType)
    }
  }
  async getSubEntityFields() {
    this.updateLoading(true)
    const response = await Api.getSubEntityFields()
    if (response.ok) {
      const subEntityFields = response.data
      this.updateSubEntityFields(subEntityFields)
    }
    this.updateLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, true, false)
  }

  async getSubEntityField(id) {
    this.updateLoading(true)
    const response: any = await Api.getSubEntityFields()
    this.updateLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, true, false)

    if (response.ok) {
      const subEntityField = response.data.find(item => item.id === id)
      this.updateSubEntityField(subEntityField)
    }
  }

  async editSubEntityType(id, data) {
    this.updateLoading(true)
    const response: any = await Api.updateSubEntityType(id, data)
    this.updateLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, true, true)
  }

  async createSubEntityType(subEntityType) {
    this.updateLoading(true)
    const response: any = await Api.createSubEntityType(subEntityType)
    this.updateLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, true, true)
  }

  async editSubEntityField(id, data) {
    this.updateLoading(true)
    const response: any = await Api.updateSubEntityField(id, data)
    this.updateLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, true, true)
  }

  async createSubEntityField(subEntityField) {
    this.updateLoading(true)
    const response: any = await Api.createSubEntityField(subEntityField)
    this.updateLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, true, true)
  }

  async deleteSubEntityField (id) {
    this.updateLoading(true)
    const response: any = await Api.deleteSubEntityField(id)
    this.updateLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, true, true)
  }

  async addSubEntityTypeFields(subEntityTypeId, addFieldIds) {
    const body = addFieldIds.map(subEntityFieldId => {
      return (
        {
          subEntityTypeId,
          subEntityFieldId
        }
      )
    })
    const response: any = await Api.addSubEntityTypeFields(body)
    this.rootStore.appStore.setErrorOrSuccess(response, true, true)
  }

  async deleteSubEntityTypeFields(subEntityTypeId, deleteFieldIds) {
    const body = deleteFieldIds.map(subEntityFieldId => {
      return (
        {
          subEntityTypeId,
          subEntityFieldId
        }
      )
    })
    const response: any = await Api.deleteSubEntityTypeFields(body)
    this.rootStore.appStore.setErrorOrSuccess(response, true, true)
  }

  async addMainEntityTypeSubEntityTypes(mainEntityTypeId, subEntityTypeIds) {
    const body = {
      mainEntityTypeId,
      subEntityTypeIds
    }
    const response: any = await Api.addMainEntityTypeSubEntityTypes(body)
    this.rootStore.appStore.setErrorOrSuccess(response, true, true)
  }

  async deleteMainEntityTypeSubEntityTypes(mainEntityTypeId, subEntityTypeIds) {
    const body =  {
      mainEntityTypeId,
      subEntityTypeIds
    }
    const response: any = await Api.deleteMainEntityTypeSubEntityTypes(body)
    this.rootStore.appStore.setErrorOrSuccess(response, true, true)
  }

  async getOrganizationMainEntityTypes(organizationId: number) {
    const response = await Api.getAdminOrganizationMainEntityTypes(organizationId)
    if (response.ok) {
      const organizatonMainEntityTypes = response.data
      this.updateOrganizatonMainEntityTypes(organizatonMainEntityTypes)
    }
  }

  async addOrganizationMainEntityTypes(organizationId: number, mainEntityTypeIds: number[]) {
    if (mainEntityTypeIds.length) {
      this.updateLoading(true)
      const response = await Api.addOrganizationMainEntityTypes({
        organizationId,
        mainEntityTypeIds
      })
      this.rootStore.appStore.setErrorOrSuccess(response, true, true)
      this.updateLoading(false)
    }
  }

  async deleteOrganizationMainEntityTypes(organizationId: number, mainEntityTypeIds: number[]) {
    if (mainEntityTypeIds.length) {
      this.updateLoading(true)
      const response = await Api.deleteOrganizationMainEntityTypes({
        organizationId,
        mainEntityTypeIds
      })
      this.rootStore.appStore.setErrorOrSuccess(response, true, true)
      this.updateLoading(false)
    }
  }

  async getOrganizationUsers(organizationId: number) {
    this.updateLoading(true)
    const response = await Api.getOranizationUsers(organizationId)
    this.rootStore.appStore.setErrorOrSuccess(response, true, false)
    if (response.ok) {
      const users = response.data
      this.updateOrganizationUsers(users)
    }

    this.updateLoading(false)
  }

  async getOrganizationInvites(organizationId: number) {
    this.updateLoading(true)
    const response = await Api.getOrganizationInvitedUsers(organizationId)
    this.rootStore.appStore.setErrorOrSuccess(response, true, false)
    if (response.ok) {
      const invites = response.data
      this.updateOrganizationInvites(invites)
    }

    this.updateLoading(false)
  }

  async getMainEntityTypeSubEntityTypes(mainEntityTypeId) {
    this.updateLoading(true)
    const response = await Api.getMainEntityTypeSubEntityTypes(mainEntityTypeId)
    this.updateLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, true, false)
    if (response.ok) {
      this.updateMainEntityTypeSubEntityTypes(response.data)
    }
  }
}
