import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import orderBy from 'lodash/orderBy'
import makeStyles from '@mui/styles/makeStyles'
import { useStore } from '../../../Models/RootStore'
import { useNavigate } from 'react-router-dom'
import { PageHeader, UserTable, UserInviteTable, UserGrid, DataActions } from '../../../Components'
import { UserSortOptions, ViewMode } from '../../../Constants'

const useStyles = makeStyles((theme) => ({
  root: {
  },
  container: {
    padding: '2.5rem'
  }
}))

const TABS = {
  all: 0,
  active: 1,
  deleted: 2,
  invites: 3
}

function Users() {
  const classes = useStyles()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { userStore, sessionStore }: any = useStore()

  useEffect(() => {
    userStore.getMyOrganizationUsers()
    userStore.getUserInvites()
  }, [])

  const inviteUser = (email, role) => {
    userStore.inviteUser({ email, role })
  }

  const [tab, setTab] = useState(0)

  const selectTab = (value) => setTab(value)

  const getTabs = () => [
    { value: TABS.all, label: t('all_items') },
    { value: TABS.active, label: t('active_items') },
    { value: TABS.deleted, label: t('deleted_items') },
    { value: TABS.invites, label: t('invites') }
  ]

  const [searchValue, setSearchValue] = useState('')
  const [sortValue, setSortValue] = useState(UserSortOptions[0].value)
  const [viewMode, setViewMode] = useState(ViewMode.List)

  const viewOnGrid = () => setViewMode(ViewMode.Grid)
  const viewOnList = () => setViewMode(ViewMode.List)

  const toCreateUser = () => navigate('/users/invite')
  const toUser = (id) => {
    if (id === sessionStore.user.id) {
      return navigate(`/settings`)
    }
    navigate(`/users/${id}`)
  }

  const getUsers = () => {
    let users
    if (tab === TABS.all) users = userStore.users
    if (tab === TABS.active) users = userStore.users.filter(user => user.active)
    if (tab === TABS.deleted) users = userStore.users.filter(user => !user.active)
    if (tab === TABS.invites) users = userStore.userInvites

    if (searchValue) {
      users = users.filter((user) => {
        const { firstName = '', lastName = '', role = '', email = '' } = user
        const targets = [firstName, lastName, t(role), email]
        return targets.some((target) => target.toLowerCase().includes(searchValue.trim().toLowerCase()))
      })
    }

    if (tab === TABS.invites && sortValue === 'created_at') {
      users = orderBy(users, 'expiresAt', 'desc')
    } else if (sortValue === 'created_at') {
      users = orderBy(users, 'createdAt', 'desc')
    } else if (tab === TABS.invites) {
      users = orderBy(users, 'email', 'asc')
    } else {
      users = orderBy(users, 'lastName', 'asc')
    }

    return users
  }

  const renderTable = () => {
    if (tab === TABS.invites) {
      return <UserInviteTable users={getUsers()} inviteUser={inviteUser} />
    }
    if (viewMode === ViewMode.List) {
      return <UserTable users={getUsers()} myId={sessionStore.user.id} />
    }
    return <UserGrid users={getUsers()} onClick={toUser} />
  }

  return (
    <div className={classes.root}>
      <PageHeader
        title={t('users')}
        tabs={getTabs()}
        currentTab={tab}
        onTabChange={selectTab}
        onCreateClick={toCreateUser}
        {...tab !== TABS.invites ? { viewMode, viewOnGrid, viewOnList } : {}}
      />
      <div className={classes.container}>
        <DataActions
          searchPlaceholder={tab === TABS.invites ? t('search_by_email') : t('search_by_name')}
          searchValue={searchValue}
          onSearchChange={setSearchValue}
          sortOptions={UserSortOptions}
          sortValue={sortValue}
          onSortChange={setSortValue}
        />
        {renderTable()}
      </div>
    </div>
  )
}

export default observer(Users)
