import React, { useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import IconButton from '@mui/material/IconButton'
import { Tooltip } from '@mui/material'
import { Colors } from '../../Utils/theme'
import { useTranslation } from 'react-i18next'
import { formatDateTime } from '../../Utils/dateTime'
import { ConditionOptions } from '../../Constants'
import { Dialog } from '../../Components'
import EditIconDark from '../../Assets/Icons/edit-dark.svg'
import DeleteIcon from '../../Assets/Icons/delete.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: Colors.backgroundGrey,
    borderBottom: 'none',
    '&:hover': {
      backgroundColor: `${Colors.tableHover} !important`
    }
  },
  firstCell: {
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: 600,
    height: '3rem',
    width: '11rem',
    color: Colors.black,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    borderBottom: 'none'
  },
  lastCell: {
    height: '3rem',
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    borderBottom: 'none',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  conditionCell: {
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: 400,
    height: '3rem',
    color: Colors.black,
    borderBottom: 'none',
    width: '13.5rem'
  },
  actionButton: {
    backgroundColor: Colors.white,
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.black20}`,
    height: '2rem',
    width: '2rem',
    marginLeft: '0.5rem',
    '&:hover': {
      backgroundColor: `${Colors.white} !important`
    }
  },
  actionIcon: {
    height: '1.25rem',
    width: 'auto',
    maxHeight: '1.25rem',
    maxWidth: '1.25rem'
  }
}))

function MaintenanceLogTableRow (props: any) {
  const { log } = props
  const classes = useStyles()

  const { t } = useTranslation()

  const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false)

  const getCondition = () => {
    const condition = log?.status ?? null
    if (condition) {
      return ConditionOptions.find((item) => item.value === condition)?.label
    }
    return '-'
  }

  const getDescription = () => {
    const description = log?.content ?? null
    if (description) {
      if (description.length > 20) {
        return `${log.content.substring(0, 20)}...`
      }
      return log.content
    }
    return '-'
  }

  const handleEdit = () => props.editLog(log)
  const handleDelete = () => {
    if (!confirmDeleteVisible) {
      setConfirmDeleteVisible(true)
    } else {
      props.deleteLog(log.id)
      setConfirmDeleteVisible(false)
    }
  }
  const renderDeleteDialog = () => {
    return (
      <Dialog
        open={confirmDeleteVisible}
        handleClose={() => setConfirmDeleteVisible(false)}
        handleOk={handleDelete}
        title={t('delete_maintenance_log')}
        description={t('confirm_delete_maintenance_log_description')}
      />
    )
  }

  return (
    <TableRow classes={{ root: classes.root }} hover>
      <TableCell className={classes.firstCell} size='small'>
        {log?.publishedAt ? formatDateTime(log.publishedAt) : '-'}
      </TableCell>
      <TableCell className={classes.conditionCell} size='small'>
        {getCondition()}
      </TableCell>
      <TableCell className={classes.conditionCell} size='small'>
        {getDescription()}
      </TableCell>
      <TableCell className={classes.lastCell} size='small'>
        <Tooltip title={t('edit_maintenance_log')}>
          <IconButton onClick={handleEdit} className={classes.actionButton} size='large'>
            <img src={EditIconDark} className={classes.actionIcon} alt='icon' />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('delete_maintenance_log')}>
          <IconButton onClick={handleDelete} className={classes.actionButton} size='large'>
            <img src={DeleteIcon} className={classes.actionIcon} alt='icon' />
          </IconButton>
        </Tooltip>
        {renderDeleteDialog()}
      </TableCell>
    </TableRow>
  )
}

export default MaintenanceLogTableRow
