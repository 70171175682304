import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import get from 'lodash/get'
import makeStyles from '@mui/styles/makeStyles'
import { useStore } from '../../../Models/RootStore'
import Grid from '@mui/material/Grid'
import { WikiHeader, WikiMainEntityTypeCard } from '../../../Components'
import { useNavigate } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
  },
  container: {
    padding: '2.5rem 2rem 4rem'
  },
  cardsContainer: {
    width: '100%',
    margin: '0 auto',
    maxWidth: '51rem'
  }
}))

const EVENT_TYPES: string[] = [
  'music_event',
  'sports_event',
  'theme_event'
]

function WikiEntityTypes() {
  const classes = useStyles()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const { entityStore, infoPageStore, sessionStore }: any = useStore()
  useEffect(() => {
    entityStore.getMainEntities()
    entityStore.getMainEntityTypes()
    infoPageStore.getInfoPages()
  }, [])

  const getMainEntityTypes = () => {
    const mainEntityTypes: any = []
    const counts = {}

    for (const mainEntity of entityStore.mainEntities || []) {
      if (mainEntity.mainEntityType) {
        // Update counts
        counts[mainEntity.mainEntityType.id] = (counts[mainEntity.mainEntityType.id] || 0) + 1
        if (counts[mainEntity.mainEntityType.id] === 1) {
          // Add only first time
          mainEntityTypes.push(mainEntity.mainEntityType)
        }
      }
    }

    return mainEntityTypes.map(mainEntityType => ({
      ...mainEntityType
    }))
  }

  const toWiki = (mainEntityType) => navigate(`/wiki/${mainEntityType}`)

  const toMainEntityWiki = (mainEntityType) => navigate(`/wiki/type/${mainEntityType}`)

  const renderServiceInfo = () => {
    if (get(sessionStore, 'user.role') === 'boss') {
      return (
        <WikiMainEntityTypeCard
          key='service'
          item={{
            name: t('service_guide')
          }}
          onClick={() => toWiki('service')}
          service
        />
      )
    }
    return null
  }

  const renderMainEntityTypes = () => {
    let mainEntityTypes = getMainEntityTypes()

    return mainEntityTypes.map(item => {
      let onClick = () => toWiki(item.id)
      const type = item.type
      if (EVENT_TYPES.includes(type)) {
        onClick = () => {
          toMainEntityWiki(item.id)
        }
      }

      return (
        <WikiMainEntityTypeCard
          key={item.id}
          item={item}
          onClick={onClick}
        />
      )
    })
  }

  return (
    <div className={classes.root}>
      <WikiHeader
        title={t('entity_type_guides')}
      />
      <div className={classes.container}>

        <div className={classes.cardsContainer}>
          <Grid
            direction='row'
            justifyContent='flex-start'
            alignItems='center'
            spacing={2}
            container
          >
            {renderServiceInfo()}
            {renderMainEntityTypes()}
          </Grid>
        </div>
      </div>
    </div>
  )
}

export default observer(WikiEntityTypes)
