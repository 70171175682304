import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import sortBy from 'lodash/sortBy'
import { Button, Dialog } from '..'
import EventServiceRepeaterItem from './EventServiceRepeaterItem'
import AddIcon from '../../Assets/Icons/add-dark.svg'
import EventServiceModal from './EventServiceModal'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingBottom: '1.5rem'
  },
  list: {
    paddingBottom: '1rem'
  },
  header: {
    display: 'flex',
    flexDirection: 'row'
  },
  placeholderPhoto: {
    height: '3.75rem',
    width: '3.75rem',
    minHeight: '3.75rem',
    minWidth: '3.75rem',
    backgroundColor: 'rgba(0,0,0,.25)'
  },
  flex: {
    flex: 1
  },
  pager: {
    background: Colors.white,
    marginLeft: '1rem',
    height: '3.125rem',
    width: '3.125rem',
    minWidth: '3.125rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `2px solid ${Colors.border}`,
    borderRadius: '.3125rem',
    cursor: 'pointer'
  },
  pagerDisabled: {
    background: Colors.white,
    marginLeft: '1rem',
    height: '3.125rem',
    width: '3.125rem',
    minWidth: '3.125rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `2px solid ${Colors.border}`,
    borderRadius: '.3125rem',
    opacity: 0.5
  },
  pagerIcon: {
    height: '100%',
    width: '100%',
    maxHeight: '.5rem'
  }
}))

export default function EventServiceRepeater(props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const [editIndex, setEditIndex] = useState(null)
  const [deleteIndex, setDeleteIndex] = useState(null)
  const [addMode, setAddMode] = useState(false)

  const handleEdit = (index) => {
    setAddMode(false)
    setDeleteIndex(null)
    setEditIndex(index)
  }

  const handleDelete = (index) => {
    setAddMode(false)
    setEditIndex(null)

    // Confirm delete
    if (deleteIndex === index) {
      const newItems = props.value.filter((item, idx) => {
        if (idx === index) {
          return false
        }
        return true
      })
      props.onChange(newItems)
      setDeleteIndex(null)
    } else {
      // Open delete dialog
      setDeleteIndex(index)
    }
  }

  const renderItem = (item, index) => (
    <EventServiceRepeaterItem
      item={item}
      index={index}
      onEdit={() => handleEdit(index)}
      onDelete={() => handleDelete(index)}
    />
  )

  const handleSaveItem = (item) => {
    if (editIndex !== null) {
      const newItems = props.value.map((oldItem, index) => {
        if (index === editIndex) {
          return item
        }
        return oldItem
      })
      const sortedNewItems = sortBy(newItems, 'nameFi')
      props.onChange(sortedNewItems)
    } else {
      // Add new
      const newItems = [...(props.value || []), item]

      // Sort
      const sortedNewItems = sortBy(newItems, 'nameFi')
      props.onChange(sortedNewItems)
    }
    closeModals()
  }

  const closeModals = () => {
    setEditIndex(null)
    setAddMode(false)
    setDeleteIndex(null)
  }

  const renderAddOrEditModal = () => {
    if (editIndex !== null || addMode) {
      return (
        <EventServiceModal
          handleSave={handleSaveItem}
          item={editIndex === null ? null : props.value[editIndex]}
          handleClose={closeModals}
        />
      )
    }
  }

  const getItems = () => {
    return (props.value || []).map((item, index) => ({ ...item, index }))
  }

  return (
    <div className={classes.container}>
      <div className={classes.list}>
        {getItems().map((item) => {
          return (
            <React.Fragment key={item.index}>
              {renderItem(item, item.index)}
            </React.Fragment>
          )
        })}
      </div>
      <Button
        text={t('add_new')}
        onClick={() => setAddMode(true)}
        rightIcon={AddIcon}
        outlined
      />
      {renderAddOrEditModal()}
      <Dialog
        open={deleteIndex !== null}
        handleClose={() => setDeleteIndex(null)}
        handleOk={() => handleDelete(deleteIndex)}
        title={t('confirm_delete_program')}
        description={t('confirm_delete_program_description')}
      />
    </div>
  )

}
