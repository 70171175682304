import React from 'react'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import { Checkbox } from '../'

const useStyles = makeStyles((theme) =>({
  description: {
    fontWeight: 300,
    marginBottom: '2rem'
  },
  checkboxContainer: {
    float: 'left',
    paddingBottom: '1rem',
    paddingRight: '1rem'
  },
  clearFix: {
    display: 'table',
    clear: 'both'
  }
}))

function PollSettingsForm (props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const toggleMainEntityType = (id) => {
    const newSelectedMainEntityTypes = props.selectedMainEntityTypes.filter(selectedId => selectedId !== id)
    if (newSelectedMainEntityTypes.length === props.selectedMainEntityTypes.length) {
      newSelectedMainEntityTypes.push(id)
    }
    props.setSelectedMainEntityTypes(newSelectedMainEntityTypes)
  }

  const toggleMainEntity = (id) => {
    const newSelectedMainEntities = props.selectedMainEntities.filter(selectedId => selectedId !== id)
    if (newSelectedMainEntities.length === props.selectedMainEntities.length) {
      newSelectedMainEntities.push(id)
    }
    props.setSelectedMainEntities(newSelectedMainEntities)
  }

  const renderMainEntityTypes = () => {
    return props.mainEntityTypeOptions.map((mainEntityType, index) => {
      const isChecked = props.selectedMainEntityTypes.includes(mainEntityType.value)

      return (
        <div className={classes.checkboxContainer} key={index}>
          <Checkbox
            checked={isChecked}
            onChange={() => toggleMainEntityType(mainEntityType.value)}
            label={mainEntityType.name}
          />
        </div>
      )
    })
  }

  const renderMainEntities = () => {
    return props.mainEntityOptions.map((mainEntity, index) => {
      const isChecked = props.selectedMainEntities.includes(mainEntity.value)

      return (
        <div className={classes.checkboxContainer} key={`${index}-${mainEntity.value}`}>
          <Checkbox
            checked={isChecked}
            onChange={() => toggleMainEntity(mainEntity.value)}
            label={mainEntity.name}
          />
        </div>
      )
    })
  }

  return (
    <div>
      <div>
        <p className={classes.description}>
          {t('poll_settings_description')}
        </p>
      </div>
      <div>
        {renderMainEntityTypes()}
      </div>
      <div className={classes.clearFix} />
      <div>
        <p className={classes.description}>
          {t('poll_settings_select_main_entities')}
        </p>
      </div>
      <div>
        {renderMainEntities()}
      </div>
      <div className={classes.clearFix} />
    </div>
  )
}

export default PollSettingsForm
