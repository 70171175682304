import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'

export default class InfoPageStore {
  rootStore
  infoPages: any = []
  infoPage = null
  servicePage = null // Huoltomies app service info page
  loading: any = null

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  updateInfoPages = (infoPages) => { this.infoPages = infoPages }
  updateInfoPage = (infoPage) => { this.infoPage = infoPage }
  updateServicePage = (servicePage) => { this.servicePage = servicePage }
  updateLoading = (loading) => { this.loading = loading }

  async getInfoPages() {
    this.updateLoading(true)
    const response = await Api.getInfoPages()
    this.updateLoading(false)

    // Hide unauthorized error
    if (response?.status !== 403) {
      this.rootStore.appStore.setErrorOrSuccess(response, true, false)
    }

    if (response.ok) {
      const infoPages = response.data
      this.updateInfoPages(infoPages)
    }
  }

  async getServicePage() {
    this.updateLoading(true)
    const response = await Api.getServicePage()
    this.updateLoading(false)

    if (response.status !== 404) {
      this.rootStore.appStore.setErrorOrSuccess(response, true, false)
    }

    if (response.ok) {
      const servicePage = response.data
      this.updateServicePage(servicePage)
    }
  }

  async getInfoPage(pageId) {
    this.updateLoading(true)
    const response = await Api.getInfoPage(pageId)
    this.updateLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, true, false)

    if (response.ok) {
      const infoPage = response.data
      this.updateInfoPages(this.infoPages.map(item => {
        if (item.id === infoPage.id) return infoPage
        return item
      }))
      this.updateInfoPage(infoPage)
    }
  }

  async editInfoPage(pageId, params) {
    this.updateLoading(true)
    const response = await Api.editInfoPage(pageId, params)
    this.updateLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'info_updated')

    if (response.ok) {
      const infoPage = response.data
      this.updateInfoPages(this.infoPages.map(item => {
        if (item.id === infoPage.id) return infoPage
        return item
      }))
      this.updateInfoPage(infoPage)
    }
  }

  async createInfoPage(params) {
    this.updateLoading(true)
    const response = await Api.createInfoPage(params)
    this.updateLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'info_updated')

    if (response.ok) {
      const infoPage = response.data
      this.getInfoPages()
      this.updateInfoPage(infoPage)
    }
  }

  async createOrEditServicePage(params) {
    this.updateLoading(true)
    const response = await Api.createOrEditServicePage(params)
    this.updateLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'info_updated')

    if (response.ok) {
      const servicePage = response.data
      this.updateServicePage(servicePage)
    }
  }

  async createOrEditMainEntityPage(mainEntityId, params) {
    this.updateLoading(true)
    const response = await Api.createOrEditMainEntityPage(mainEntityId, params)
    this.updateLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'info_updated')

    if (response.ok) {
      const infoPage = response.data
      this.getInfoPages()
      this.updateInfoPage(infoPage)
    }
  }

  async createOrEditMainEntityTypePage(mainEntityTypeId, params) {
    this.updateLoading(true)
    const response = await Api.createOrEditMainEntityTypePage(mainEntityTypeId, params)
    this.updateLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'info_updated')

    if (response.ok) {
      const infoPage = response.data
      this.getInfoPages()
      this.updateInfoPage(infoPage)
    }
  }
}
