import {
  SPACER,
  EXCEPTION_NOTIFICATIONS_TITLE,
  EXCEPTION_NOTIFICATION,
  CONTACT_INFORMATION_TITLE,
  STREET_ADDRESS,
  CITY,
  POSTAL_CODE,
  PHONE,
  EMAIL,
  WINTER_MAINTENANCE,
  EQUIPMENT_TITLE,
  PLAYGROUND_EQUIPMENT,
  DESCRIPTION,
  DESCRIPTION_TITLE
} from '../elements.template'


const LAYOUT = {
  type: 'playground',
  layout: [
    [
      // Start left column
      DESCRIPTION_TITLE,
      DESCRIPTION,
      WINTER_MAINTENANCE,
      EQUIPMENT_TITLE,
      PLAYGROUND_EQUIPMENT
      // End left column
    ],
    [
      // Start right column
      EXCEPTION_NOTIFICATIONS_TITLE,
      { ...EXCEPTION_NOTIFICATION, hideLabel: true, style: { paddingTop: '0.375rem' } },
      CONTACT_INFORMATION_TITLE,
      {
        type: 'container',
        className: 'customRowFixed',
        items: [
          { ...PHONE, style: { flex: 1, maxWidth: '18rem' } },
          SPACER,
          { ...EMAIL, style: { flex: 1, maxWidth: '30rem' } }
        ]
      },
      {
        type: 'container',
        className: 'customRowFixed',
        style: { paddingBottom: '10rem' },
        items: [
          STREET_ADDRESS,
          SPACER,
          CITY,
          SPACER,
          POSTAL_CODE
        ]
      }
      // End right column
    ]
  ]
}

export default LAYOUT
