import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Colors } from '../../Utils/theme'
import { useTranslation } from 'react-i18next'
import Grid from '@mui/material/Grid'
import ButtonBase from '@mui/material/ButtonBase'
import { MainEntityIcons } from '../../Constants'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    backgroundColor: Colors.backgroundGrey,
    borderRadius: '0.3125rem',
    padding: 30,
    border: `1px solid ${Colors.border}`,
    marginBottom: '1rem',
    '&:hover': {
      backgroundColor: Colors.violet10
    },
    '&:active': {
      color: Colors.violet
    }
  },
  last: {
    marginRight: 0
  },
  businessId: {
    color: Colors.black80,
    fontSize: '0.875rem',
    marginBottom: '0.25rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    height: '2rem'
  },
  spacer: {
    flex: 1
  },
  name: {
    color: Colors.black70,
    fontSize: '1.25rem',
    fontFamily: 'Roboto',
    fontWeight: 700,
    margin: 0,
    padding: 0,
    textAlign: 'center'
  },
  count: {
    color: Colors.violet
  },
  icon: {
    height: 'auto',
    width: '9rem',
    maxHeight: '9rem',
    maxWidth: '9rem'
  }
}))

function MainEntityTypeCard (props) {
  const { item } = props
  const classes = useStyles()
  const { t } = useTranslation()

  const onClick = () => props.onClick(item.id)

  const renderImage = () => {
    const icon = MainEntityIcons[item?.type] ?? null
    if (icon) {
      return <img src={icon} alt={t(item.type)} className={classes.icon} />
    }
    return null
  }

  return (
    <Grid item xl={2} lg={3} md={4} xs={6}>
      <ButtonBase className={classes.container} onClick={onClick}>
        {renderImage()}
      </ButtonBase>
      <div className={classes.row}>
        <p className={classes.name}>
          {item.name} (<span className={classes.count}>{item.count}</span>)
        </p>
      </div>
    </Grid>
  )
}

export default MainEntityTypeCard
