import {
  EXCEPTION_NOTIFICATION,
  CONTACT_INFORMATION_TITLE,
  STREET_ADDRESS,
  SPACER,
  CITY,
  POSTAL_CODE,
  EXCEPTION_NOTIFICATIONS_TITLE,
  OPEN_HOURS,
  EXCEPTION_SCHEDULES,
  EQUIPMENT_COUNT,
  OPENED,
  AREA,
  YEARLY_VISITOR_COUNT,
  AMUSEMENT_PARK_EQUIPMENT,
  SERVICES_CONTACT_INFORMATION_TITLE,
  SERVICES_CONTACT_INFORMATION,
  OPEN_HOURS_AND_PRICE_INFO_TITLE,
  PRICE,
  PROPERTIES_TITLE,
  DESCRIPTION_TITLE,
  DESCRIPTION
 } from '../elements.template'

const LAYOUT = {
  type: 'amusement_park',
  layout: [
    [
      // Start left column
      DESCRIPTION_TITLE,
      DESCRIPTION,
      OPEN_HOURS_AND_PRICE_INFO_TITLE,
      { ...OPEN_HOURS, hideLabel: false },
      EXCEPTION_SCHEDULES,
      PRICE,
      PROPERTIES_TITLE,
      {
        type: 'container',
        className: 'customRowFixed',
        style: { flex: 1, flexWrap: 'wrap' },
        items: [
          { ...EQUIPMENT_COUNT, label: 'equipment_count' },
          SPACER,
          AREA,
          SPACER,
          OPENED,
          SPACER,
          {
            ...YEARLY_VISITOR_COUNT,
            style: { flex: 1, minWidth: '10.5rem', maxWidth: '15rem' },
            label: 'yearly_visitor_count'
          }
        ]
      },
      AMUSEMENT_PARK_EQUIPMENT
      // End left column
    ],
    [
      // Start right column
      SERVICES_CONTACT_INFORMATION_TITLE,
      SERVICES_CONTACT_INFORMATION,
      EXCEPTION_NOTIFICATIONS_TITLE,
      { ...EXCEPTION_NOTIFICATION, hideLabel: true, style: { paddingTop: '0.375rem' } },
      CONTACT_INFORMATION_TITLE,
      {
        type: 'container',
        className: 'customRowFixed',
        style: { paddingBottom: '10rem' },
        items: [
          STREET_ADDRESS,
          SPACER,
          CITY,
          SPACER,
          POSTAL_CODE
        ]
      }
      // End right column
    ]
  ]
}

export default LAYOUT
