import { useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import get from 'lodash/get'
import chunk from 'lodash/chunk'
import { Select } from '../../Components'
import { formatDateTime } from '../../Utils/dateTime'
import { Colors } from '../../Utils/theme'
import ChevronLeft from '../../Assets/Icons/mini-arrow-left.svg'
import ChevronRight from '../../Assets/Icons/mini-arrow-right.svg'

const useStyles = makeStyles((theme) => ({
  root: {
  },
  textAnswersContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0 1rem',
    overflowX: 'auto',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    }
  },
  textAnswerPlaceholder: {
    margin: '1rem',
    flex: 1,
    padding: '1rem',
    borderRadius: 3,
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  textAnswer: {
    margin: '1rem',
    backgroundColor: Colors.backgroundGrey,
    flex: 1,
    padding: '1rem',
    borderRadius: 3
  },
  textAnswerDate: {
    marginTop: '.5rem',
    color: 'rgba(0, 0, 0, .7)'
  },
  footer: {
    paddingTop: '1rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  footerText: {
    fontSize: '0.8rem',
    padding: '.25rem 1rem 2rem 2rem'
  },
  flex: {
    flex: 1
  },
  pagerContainer: {
    border: `2px solid ${Colors.border}`,
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '1.5rem',
    marginLeft: '1rem'
  },
  pagerButton: {
    padding: '.2125rem .85rem',
    cursor: 'pointer'
  },
  pagerButtonDisabled: {
    padding: '.2125rem .85rem',
    opacity: 0.5
  },
  pagerMiddle: {
    padding: '.2125rem 1rem',
    backgroundColor: Colors.backgroundGrey
  },
  chevron: {
    opacity: 0.7,
    height: '.6rem'
  }
}))

function PollResultTextAnswers(props) {
  const { t } = useTranslation()
  const classes = useStyles()

  const [page, setPage] = useState(0)
  const [perPage, setPerPage] = useState(6)

  const handleSetPerPage = (value) => {
    setPerPage(value)
    setPage(0)
  }

  const renderMainEntity = (mainEntityId) => {
    const match = props.mainEntities.find(item => item.id === mainEntityId)
    let place = null
    if (match) place = match.nameFi || match.nameEn || null
    if (place) return `@ ${place}`
    return null
  }

  const renderTextAnswers = () => {
    const answers = props.answers || []
    const currentAnswers = answers.slice(page * perPage, (page + 1) * perPage)

    // Add null placeholders
    const leftovers = currentAnswers.length % 3
    if (leftovers) currentAnswers.push(null)
    if (leftovers === 1) currentAnswers.push(null)

    const chunks = chunk(currentAnswers, 3)

    return chunks.map(chunk => {
      return (
        <div className={classes.textAnswersContainer}>
          {chunk.map(ans => {
            if (ans === null) return <div className={classes.textAnswerPlaceholder} />
            return (
              <div className={classes.textAnswer}>
                <div>{ans.textAnswer}</div>
                <div className={classes.textAnswerDate}>{formatDateTime(ans.createdAt)} {renderMainEntity(ans.mainEntityId)}</div>
              </div>
            )
          })}
        </div>
      )
    })
  }

  const getPerPageOptions = () => {
    return [3, 6, 9, 12].map(num => {
      return {
        label: `${num}`,
        value: num
      }
    })
  }

  const previousPage = () => {
    if (page > 0) setPage(page - 1)
  }

  const nextPage = () => {
    if (page < Math.floor(props.answers.length / perPage)) setPage(page + 1)
  }

  const renderPager = () => {
    const numPages = Math.floor(props.answers.length / perPage) + 1
    const currentPage = page + 1
    return (
      <div className={classes.pagerContainer}>
        <div
          className={(currentPage > 1) ? classes.pagerButton : classes.pagerButtonDisabled}
          onClick={previousPage}
        >
          <img src={ChevronLeft} className={classes.chevron} />
        </div>
        <div className={classes.pagerMiddle}>
          {`${currentPage} / ${numPages}`}
        </div>
        <div
          className={(currentPage < numPages) ? classes.pagerButton : classes.pagerButtonDisabled}
          onClick={nextPage}
        >
          <img src={ChevronRight} className={classes.chevron} />
        </div>
      </div>
    )
  }

  const renderFooter = () => {
    if (!get(props.answers, 'length')) return null
    return (
      <div className={classes.footer}>
        <div className={classes.footerText}>{t('show_at_once')}</div>
        <div>
          <Select
            options={getPerPageOptions()}
            value={perPage}
            onChange={handleSetPerPage}
            style={{ height: '2rem' }}
          />
        </div>
        {renderPager()}
        <div className={classes.flex} />
      </div>
    )
  }

  return (
    <div className={classes.root}>
      {renderTextAnswers()}
      {renderFooter()}
    </div>
  )
}

export default PollResultTextAnswers
