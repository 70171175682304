import {
  EXCEPTION_NOTIFICATION,
  CONTACT_INFORMATION_TITLE,
  STREET_ADDRESS,
  SPACER,
  CITY,
  POSTAL_CODE,
  EXCEPTION_NOTIFICATIONS_TITLE,
  WWW,
  ADDITIONAL_SERVICES_TITLE,
  NATURE_TRAIL_SERVICES,
  CONTACT_PERSON,
  PHONE,
  EMAIL,
  TRIP_ADVISOR_URL,
  DESCRIPTION_TITLE,
  DESCRIPTION,
  ADDRESS_INFORMATION_TITLE
 } from '../elements.template'

const LAYOUT = {
  type: 'nature_trail',
  layout: [
    [
      // Start left column
      DESCRIPTION_TITLE,
      DESCRIPTION,
      ADDITIONAL_SERVICES_TITLE,
      NATURE_TRAIL_SERVICES,
      EXCEPTION_NOTIFICATIONS_TITLE,
      { ...EXCEPTION_NOTIFICATION, hideLabel: true, style: { paddingTop: '0.375rem' } }
      // End left column
    ],
    [
      // Start right column
      CONTACT_INFORMATION_TITLE,
      {
        type: 'container',
        className: 'customRowFixed',
        items: [
          { ...CONTACT_PERSON, style: { flex: 1, maxWidth: '24rem' } },
          SPACER,
          { ...PHONE, style: { flex: 1, maxWidth: '24rem' } }
        ]
      },
      {
        type: 'container',
        className: 'customRowFixed',
        style: { flexWrap: 'wrap' },
        items: [
          { ...EMAIL, style: { flex: 1, minWidth: '10rem', maxWidth: '18rem' } },
          SPACER,
          { ...WWW, style: { flex: 1, minWidth: '24rem', maxWidth: '38rem' } }
        ]
      },
      { ...TRIP_ADVISOR_URL, style: { flex: 1,  maxWidth: '38rem' } },
      ADDRESS_INFORMATION_TITLE,
      {
        type: 'container',
        className: 'customRowFixed',
        style: { paddingBottom: '10rem' },
        items: [
          STREET_ADDRESS,
          SPACER,
          CITY,
          SPACER,
          POSTAL_CODE
        ]
      }
      // End right column
    ]
  ]
}

export default LAYOUT
