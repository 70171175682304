import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import makeStyles from '@mui/styles/makeStyles'
import MainEntityNotificationsTableRow from './MainEntityNotificationsTableRow'
import { Colors } from '../../Utils/theme'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '-0.5rem'
  },
  spacer: {
    height: '1rem'
  },
  outerContainer: {
    borderBottom: 'none',
    marginBottom: '1rem'
  },
  container: {
    borderBottom: 'none'
  },
  cell: {
    height: 50,
    padding: '0 1rem',
    color: Colors.black,
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: 700
  },
  firstCell: {
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5
  },
  lastCell: {
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5
  }
}))

function MainEntityNotificationsTable (props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const renderHeader = () => {
    const columnNames = [t('title'), t('sent_at'), t('actions')]
    return (
      <TableHead className={classes.outerContainer}>
        <TableRow className={classes.container}>
          {columnNames.map((columnName, index) => {
            let className = classes.cell
            if (index === 0) className = `${classes.cell} ${classes.firstCell}`
            else if (index === columnNames.length - 1) className = `${classes.cell} ${classes.lastCell}`

            return <TableCell key={index} className={className}>{columnName}</TableCell>
          })}
        </TableRow>
      </TableHead>
    )
  }

  const renderRows = () => {
    if (props.items) {
      return props.items.map((item, index) => {
        return (
          <MainEntityNotificationsTableRow
            onEdit={() => props.onEdit(item.id)}
            onDelete={() => props.onDelete(item.id)}
            key={index}
            item={item}
            odd={index % 2 !== 0}
          />
        )
      })
    }
    return null
  }

  return (
    <div>
      <Table classes={{ root: classes.root }}>
        {renderHeader()}
        <TableBody>{renderRows()}</TableBody>
      </Table>
    </div>
  )

}

export default MainEntityNotificationsTable
