import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import IconButton from '@mui/material/IconButton'
import { Tooltip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import Modal from '@mui/material/Modal'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'
import striptags from 'striptags'
import { decode } from 'html-entities'
import linkifyHtml from 'linkify-html'
import { Input, Button, Select, HtmlInput } from '..'
import AddIcon from '../../Assets/Icons/add-dark.svg'
import DeleteIcon from '../../Assets/Icons/delete.svg'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  modal: {
    // zIndex: 99999
  },
  contentContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: Colors.white,
    minWidth: '40rem',
    borderRadius: 3
  },
  content: {
    padding: '2rem 3rem',
    paddingBottom: '1rem',
    maxHeight: 'calc(90vh - 4rem)',
    overflow: 'auto'
  },
  formContainer: {
    paddingBottom: '2rem'
  },
  title: {
    fontFamily: 'Rubik',
    fontWeight: 600,
    color: Colors.black,
    fontSize: '1.675rem',
    marginBottom: '1.25rem'
  },
  subTitle: {
    marginTop: '-1rem',
    fontFamily: 'Rubik',
    fontWeight: 600,
    color: Colors.black,
    fontSize: '1.25rem',
    marginBottom: '1.25rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  dateContainer: {
    marginRight: '1.5rem'
  },
  timeContainer: {
  },
  label: {
    fontSize: '1rem',
    fontWeight: 500,
    marginBottom: '0.5rem',
    color: Colors.label
  },
  footerContainer: {
    height: '5.5rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0rem 3rem',
    borderTop: `1px solid ${Colors.black20}`
  },
  saveButton: {
    width: '9rem',
    height: '3.125rem'
  },
  cancelButton: {
    width: '7rem',
    height: '3.125rem',
    border: 'none',
    margin: '0rem 0.75rem',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: Colors.violet10
    }
  },
  langContainer: {
    flex: 1,
    marginTop: '1.325rem'
  },
  spacer: {
    width: '1rem'
  },
  deleteContainer: {
    marginTop: '.5rem',
    marginLeft: '.5rem'
  },
  actionButton: {
    backgroundColor: Colors.white,
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.black20}`,
    height: '2rem',
    width: '2rem',
    marginLeft: '0.5rem',
    '&:hover': {
      backgroundColor: `${Colors.violet10} !important`
    }
  },
  actionIcon: {
    height: '1.125rem',
    width: 'auto',
    maxHeight: '1.125rem',
    maxWidth: '1.125rem'
  }
}))

export default function EventServiceModal(props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const { item, handleClose } = props

  const [lang, setLang] = useState('fi')
  const [titleFi, setTitleFi] = useState(get(item, 'titleFi') || null)
  const [titleEn, setTitleEn] = useState(get(item, 'titleEn') || null)
  const [times, setTimes] = useState([])

  const [descriptionHtmlFi, setDescriptionHtmlFi] = useState(get(item, 'descriptionHtmlFi') || '')
  const [descriptionHtmlEn, setDescriptionHtmlEn] = useState(get(item, 'descriptionHtmlEn') || '')

  const addTime = () => {
    setTimes([...times, {
      startDate: null,
      startTime: null,
      endTime: null
    }])
  }

  const handleDeleteTime = (index) => {
    setTimes([...times.filter((_, idx) => idx !== index)])
  }

  useEffect(() => {
    if (item && item.times) {
      const newTimes = item.times.map(time => {
        return {
          startDate: moment(time.startDate).format('YYYY-MM-DD'),
          startTime: moment(time.startDate).format('HH:mm'),
          endTime: moment(time.endDate).format('HH:mm')
        }
      })
      setTimes(newTimes)
    }
  }, [])

  const save = () => {
    let newDescriptionHtmlFi = null
    let newDescriptionFi = null

    if (descriptionHtmlFi) {
      newDescriptionHtmlFi = linkifyHtml(descriptionHtmlFi)
      newDescriptionFi = decode(striptags(newDescriptionHtmlFi, [], '\n').trim()).trim()
      if (!newDescriptionFi) newDescriptionHtmlFi = null
    }

    let newDescriptionHtmlEn = null
    let newDescriptionEn = null

    if (descriptionHtmlEn) {
      newDescriptionHtmlEn = linkifyHtml(descriptionHtmlEn)
      newDescriptionEn = decode(striptags(newDescriptionHtmlEn, [], '\n').trim()).trim()
      if (!newDescriptionEn) newDescriptionHtmlEn = null
    }

    const newTimes = []
    for (const time of times) {
      if (time.startDate && time.startTime && time.endTime) {
        // Ceck if end date should be on next day
        const startTimeTestMoment = moment(`2000-01-01 ${time.startTime}`)
        const endTimeTestMoment = moment(`2000-01-01 ${time.startTime}`)
        let endDate = time.startDate
        if (endTimeTestMoment.isBefore(startTimeTestMoment)) {
          endDate = moment(time.startDate).add(1, 'day').format('YYYY-MM-DD')
        }
        const newStartDate = moment(`${time.startDate} ${time.startTime}`)
        const newEndDate = moment(`${endDate} ${time.endTime}`)

        newTimes.push({
          startDate: moment(newStartDate).format(),
          endDate: moment(newEndDate).format()
        })
      }
    }

    const newItem = {
      titleFi,
      titleEn,
      descriptionFi: newDescriptionFi,
      descriptionEn: newDescriptionEn,
      descriptionHtmlFi: newDescriptionHtmlFi,
      descriptionHtmlEn: newDescriptionHtmlEn,
      times: sortBy(newTimes, 'startDate')
    }

    props.handleSave(newItem)
  }

  const isSaveDisabled = () => {
    if (!titleFi && !titleEn) return true
    return false
  }

  const handleSetTitle = (value) => {
    if (lang === 'en') {
      setTitleEn(value)
    } else {
      setTitleFi(value)
    }
  }

  const getDescriptionHtml = () => {
    if (lang === 'en') {
      return descriptionHtmlEn
    } else {
      return descriptionHtmlFi
    }
  }

  const setDescriptionHtml = (value) => {
    if (lang === 'en') {
      setDescriptionHtmlEn(value)
    } else {
      setDescriptionHtmlFi(value)
    }
  }

  const setStartDate = (value, index) => {
    const newTimes = [...times]
    newTimes[index].startDate = value
    setTimes(newTimes)
  }

  const setStartTime = (value, index) => {
    const newTimes = [...times]
    newTimes[index].startTime = value
    setTimes(newTimes)
  }

  const setEndTime = (value, index) => {
    const newTimes = [...times]
    newTimes[index].endTime = value
    setTimes(newTimes)
  }

  const renderTimeRepeater = () => {
    return times.map((item, index) => {
      const { startDate, startTime, endTime } = item
      return (
        <div key={index} className={classes.row}>
          <div className={classes.dateContainer}>
            <div className={classes.label}>{t('date')}</div>
            <Input value={startDate} onChange={(value) => setStartDate(value, index)} type='date' />
          </div>
          <div className={classes.timeContainer}>
            <div className={classes.label}>{t('start_time')}</div>
            <Input value={startTime} onChange={(value) => setStartTime(value, index)} type='time' />
          </div>
          <div className={classes.spacer} />
          <div className={classes.timeContainer}>
            <div className={classes.label}>{t('end_time')}</div>
            <Input value={endTime} onChange={(value) => setEndTime(value, index)} type='time' />
          </div>
          <div className={classes.deleteContainer}>
            <Tooltip title={t('delete')}>
              <IconButton onClick={() => handleDeleteTime(index)} className={classes.actionButton} size='large'>
                <img src={DeleteIcon} className={classes.actionIcon} alt='icon' />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      )
    })
  }

  const renderAddTimeRowButton = () => {
    return (
      <Button
        text={t('add_row')}
        onClick={addTime}
        rightIcon={AddIcon}
        outlined
      />
    )
  }

  return (
    <Modal onClose={handleClose} classes={{ root: classes.modal }} open>
      <div className={classes.contentContainer}>
        <div className={classes.content}>
          <div className={classes.formContainer}>
            <div className={classes.title}>{item ? t('edit_contact_info') : t('add_contact_info')}</div>
            <div>
              <Input
                label={t('service_name')}
                value={lang === 'en' ? titleEn : titleFi}
                onChange={handleSetTitle}
              />
              <div className={classes.label}>{t('description_text')}</div>
              <HtmlInput
                key={lang}
                placeholder={t('description')}
                value={getDescriptionHtml()}
                onChange={setDescriptionHtml}
              />
            </div>
            <div className={classes.subTitle}>{t('open_hours')}</div>
            {renderTimeRepeater()}
            {renderAddTimeRowButton()}
          </div>
        </div>
        <div className={classes.footerContainer}>
          <div className={classes.langContainer}>
            <Select
              options={[
                { label: 'FI', value: 'fi' },
                { label: 'EN', value: 'en' }
              ]}
              value={lang}
              onChange={setLang}
              style={{
                backgroundColor: Colors.white,
                maxWidth: '4.25725rem'
              }}
            />
          </div>
          <Button
            onClick={handleClose}
            text={t('cancel')}
            buttonStyle={classes.cancelButton}
            outlined
          />
          <Button
            onClick={save}
            text={t('save')}
            disabled={isSaveDisabled()}
            buttonStyle={classes.saveButton}
          />
        </div>
      </div>
    </Modal>
  )
}
