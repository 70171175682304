import {
  SPACER,
  BOOKING_CALENDAR_LINK,
  MAINTENANCE_TITLE,
  MAINTENANCE_FREQUENCY,
  LAST_MAINTAINED,
  EXCEPTION_NOTIFICATION,
  CONTACT_INFORMATION_TITLE,
  STREET_ADDRESS,
  CITY,
  POSTAL_CODE,
  HAS_LIGHTS,
  TRACK_SURFACE,
  GRASS_TYPE,
  GRASS_USAGE,
  OPEN_HOURS_TYPE,
  OPEN_HOURS_DESCRIPTION,
  ATHLETICS_LIST,
  SPORT_FIELD_OTHER_SERVICES,
  PROPERTIES_TITLE,
  ADDITIONAL_SERVICES_TITLE,
  OPEN_HOURS_TITLE,
  DESCRIPTION,
  DESCRIPTION_TITLE
} from '../elements.template'

const LAYOUT = {
  type: 'sport_field',
  layout: [
    [
      // Start left column
      DESCRIPTION_TITLE,
      DESCRIPTION,
      PROPERTIES_TITLE,
      {
        type: 'container',
        className: 'customRowFixed',
        items: [
          { ...TRACK_SURFACE, style: { flex: 1, maxWidth: '33rem' } },
          SPACER,
          SPACER,
          HAS_LIGHTS
        ]
      },
      {
        type: 'container',
        className: 'customRowFixed',
        items: [
          { ...GRASS_TYPE, style: { flex: 1, maxWidth: '40%' } },
          SPACER,
          GRASS_USAGE
        ]
      },
      { ...ATHLETICS_LIST, label: 'sport_options' },
      ADDITIONAL_SERVICES_TITLE,
      SPORT_FIELD_OTHER_SERVICES,
      // End left column
    ],
    [
      // Start right column
      OPEN_HOURS_TITLE,
      {
        type: 'container',
        className: 'customRowFixed',
        items: [
          { ...OPEN_HOURS_TYPE, style: { flex: 1, maxWidth: '11rem' } },
          SPACER,
          { ...BOOKING_CALENDAR_LINK, style: { flex: 1 } }
        ]
      },
      OPEN_HOURS_DESCRIPTION,
      MAINTENANCE_TITLE,
      {
        type: 'container',
        className: 'customRowFixed',
        items: [
          { ...MAINTENANCE_FREQUENCY, style: { flex: 1, maxWidth: '24rem' } },
          SPACER,
          LAST_MAINTAINED
        ]
      },
      EXCEPTION_NOTIFICATION,
      CONTACT_INFORMATION_TITLE,
      {
        type: 'container',
        className: 'customRowFixed',
        style: { paddingBottom: '10rem' },
        items: [
          STREET_ADDRESS,
          SPACER,
          CITY,
          SPACER,
          POSTAL_CODE
        ]
      }
      // End right column
    ]
  ]
}

export default LAYOUT
