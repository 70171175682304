import React from 'react'
import { Link } from 'react-router-dom'
import makeStyles from '@mui/styles/makeStyles'
import { Colors } from '../../Utils/theme'
import { useTranslation } from 'react-i18next'
import Title from '../Common/Title'
import { DataMessage } from '..'

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: Colors.backgroundGrey,
    padding: '1.5rem',
    borderRadius: '0.3125rem'
  },
  titleContainer: {
    flex: 1
  },
  link: {
    textDecoration: 'none',
    color: 'inherit'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0.5rem 0',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: Colors.black05
    }
  },
  column: {
    display: 'flex',
    alignItems: 'center',
    color: Colors.black
  },
  indexContainer: {
    backgroundColor: Colors.white,
    borderRadius: '100%',
    height: '2rem',
    width: '2rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '1rem'
  },
  index: {
    margin: 0,
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: 700,
    color: Colors.black70
  },
  title: {
    margin: 0,
    fontFamily: 'Rubik',
    fontSize: '1rem',
    fontWeight: 400,
    color: Colors.black
  },
  type: {
    margin: 0,
    fontFamily: 'Roboto',
    fontSize: '0.875rem',
    fontWeight: 400,
    color: Colors.black70
  },
  value: {
    margin: 0,
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: 400,
    color: Colors.black70,
    marginRight: '0.75rem'
  }
}))

function TopList (props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const renderRows = () => props.items.map((item, index) => {
    const linkTarget = `/entity/${item.id}`

    return (
      <Link key={index} to={linkTarget} className={classes.link}>
        <div className={classes.row}>
          <div className={classes.column}>
            <div className={classes.indexContainer}>
              <p className={classes.index}>{index + 1}.</p>
            </div>
          </div>
          <div className={classes.titleContainer}>
            <p className={classes.title}>{item.name}</p>
            <p className={classes.type}>{item.type}</p>
          </div>
          <p className={classes.value}>{item.count}</p>
        </div>
      </Link>
    )
  })

  return (
    <div className={classes.container}>
      <Title title={props.title} type='widget' />
      {renderRows()}
      <DataMessage data={props.items} message={t('no_entities')} />
    </div>
  )
}

export default TopList
