import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import sortBy from 'lodash/sortBy'
import makeStyles from '@mui/styles/makeStyles'
import Modal from '@mui/material/Modal'
import Grid from '@mui/material/Grid'
import ButtonBase from '@mui/material/ButtonBase'
import { Input, Button } from '..'
import {
  MainEntityCategories,
  MainEntityCategoryIcons,
  MainEntityCategoryTypeMappings,
  MainEntityIcons
} from '../../Constants'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  modal: {
    width: '100%',
    zIndex: 99999
  },
  contentContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: Colors.white,
    borderRadius: 3,
    // minWidth: '40rem',
    // maxWidth: '100rem'
  },
  content: {
    width: '100%',
    padding: '2.75rem 6rem',
    maxWidth: '95vw',
    maxHeight: '95vh !important',
    overflowY: 'auto',
    [theme.breakpoints.down('lg')]: {
      padding: '2rem 4rem',
    }
    // maxWidth: '48rem'
  },
  selectCategoryContent: {
    minWidth: '34rem',
    maxWidth: '34rem',
    width: '34rem',
    [theme.breakpoints.down('lg')]: {
    }
  },
  selectEntityContent: {
    minWidth: '57rem',
    maxWidth: '57rem',
    width: '57rem',
    [theme.breakpoints.down('lg')]: {
      minWidth: '47rem',
      maxWidth: '47rem',
      width: '47rem'
    }
  },
  nameEntityContent: {
    maxWidth: '30rem'
  },
  title: {
    fontFamily: 'Rubik',
    fontWeight: 700,
    color: Colors.black,
    fontSize: '2.25rem',
    marginBottom: '1.125rem',
    textAlign: 'center',
    [theme.breakpoints.down('lg')]: {
      fontSize: '2rem',
      marginBottom: '1rem'
    }
  },
  entitiesTitle: {
    fontFamily: 'Rubik',
    fontWeight: 700,
    color: Colors.black,
    fontSize: '2.25rem',
    marginBottom: '2rem',
    textAlign: 'center',
    [theme.breakpoints.down('lg')]: {
      fontSize: '2rem',
      marginBottom: '1.5rem'
    }
  },
  highlight: {
    color: Colors.violet
  },
  info: {
    margin: 0,
    fontFamily: 'Roboto',
    fontSize: '1.125rem',
    fontWeight: 300,
    color: Colors.black70,
    marginBottom: '2rem',
    textAlign: 'center',
    [theme.breakpoints.down('lg')]: {
      fontSize: '1rem'
    }
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1.5rem',
    backgroundColor: Colors.backgroundGrey,
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.border}`,
    marginBottom: '0.5rem',
    '&:hover': {
      backgroundColor: Colors.violet10
    },
    '&:active': {
      color: Colors.violet
    },
    [theme.breakpoints.down('lg')]: {
      padding: '1.25rem'
    }
  },
  gridItemName: {
    color: Colors.black70,
    fontSize: '1.125rem',
    fontFamily: 'Roboto',
    fontWeight: 700,
    margin: 0,
    padding: 0,
    textAlign: 'center',
    [theme.breakpoints.down('lg')]: {
      fontSize: '1rem'
    }
  },
  categoryIcon: {
    height: 'auto',
    width: '6.25rem',
    maxHeight: '6.25rem',
    maxWidth: '6.25rem'
  },
  entityIcon: {
    height: 'auto',
    width: '6.25rem',
    maxHeight: '6.25rem',
    maxWidth: '6.25rem',
    [theme.breakpoints.down('lg')]: {
      width: '4.5rem',
      maxHeight: '4.5rem',
      maxWidth: '4.5rem'
    }
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
  label: {
    fontSize: '1rem',
    fontWeight: 500,
    marginBottom: '0.375rem',
    color: Colors.black
  },
  footerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '3rem',
    [theme.breakpoints.down('lg')]: {
      marginTop: '2.5rem'
    }
  },
  footerWideContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '2rem',
    [theme.breakpoints.down('lg')]: {
      marginTop: '1.75rem'
    }
  },
  primaryButton: {
    width: '10rem',
    height: '3.125rem',
    border: 'none',
  },
  secondaryButton: {
    width: '9rem',
    height: '3.125rem',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: Colors.violet10
    }
  },
  textButton: {
    width: '9rem',
    height: '3.125rem',
    border: 0,
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: Colors.violet10
    }
  },
  spacer: {
    width: '2.5rem'
  },
  disabled: {
    opacity: 0.4
  }
}))

enum CreateEntityPhase {
  SelectEntityCategory = 1,
  SelectEntityType,
  NameEntity
}

export default function CreateMainEntityModal(props: any) {
  const classes = useStyles()
  const { t } = useTranslation()

  const { open, handleClose, allMainEntityTypes, myMainEntityTypes, entityCategory } = props

  const [phase, setPhase] = useState(CreateEntityPhase.SelectEntityCategory)
  const [category, setCategory] = useState<any>(null)
  const [type, setType] = useState<any>(null)
  const [nameFi, setNameFi] = useState('')
  const [nameEn, setNameEn] = useState('')

  const getPhaseContainerStyle = () => {
    if (phase === CreateEntityPhase.SelectEntityCategory) {
      return classes.selectCategoryContent
    } else if (phase === CreateEntityPhase.SelectEntityType) {
      return classes.selectEntityContent
    } else if (phase === CreateEntityPhase.NameEntity) {
      return classes.nameEntityContent
    }
    return ''
  }

  const getIsCategoryDisabled = (category: string) => {
    const types = MainEntityCategoryTypeMappings?.[category] ?? []
    if (types?.length) {
      if (myMainEntityTypes.some((item) => types.includes(item.type))) {
        return false
      }
    }
    return true
  }

  const getIsEntityDisabled = (entity: any) => {
    if (myMainEntityTypes.find((item) => item.type === entity)) {
      return false
    }
    return true
  }

  const selectCategory = (item: string) => {
    setCategory(item)
    setPhase(phase + 1)
  }

  const selectType = (item: any) => {
    setType(item)
    setPhase(phase + 1)
  }

  const handleBack = () => {
    setPhase(phase - 1)
  }

  useEffect(() => {
    if (entityCategory) {
      selectCategory(entityCategory)
    }
  }, [])

  const handleCreate = () => {
    props.handleCreate({
      nameFi,
      nameEn,
      type: myMainEntityTypes.find((item) => item.type === type)?.id ?? null
    })
  }

  const renderTitle = () => {
    if (phase === CreateEntityPhase.SelectEntityCategory) {
      return (
        <div className={classes.title}>
          {t('select_entitys')}
          <span className={classes.highlight}> {t('type').toLowerCase()}</span>
        </div>
      )
    } else if (phase === CreateEntityPhase.SelectEntityType) {
      return (
        <div className={classes.entitiesTitle}>
          {t('select')}
          <span className={classes.highlight}> {t(category).toLowerCase() ?? ''}</span>
        </div>
      )
    }
    let label = t(type).toLowerCase() ?? ''
    if (type === 'beach_volley') {
      label = `${label} ${t('field').toLowerCase()}`
    }
    return (
      <div className={classes.title}>
        {t('give_name')}
        <span className={classes.highlight}> {label}</span>
      </div>
    )
  }

  const renderInfo = () => {
    if (phase === CreateEntityPhase.SelectEntityCategory) {
      return <p className={classes.info}>{t('select_entity_category_info')}</p>
    } else if (phase === CreateEntityPhase.NameEntity) {
      return <p className={classes.info}>{t('name_entity_info')}</p>
    }
    return null
  }

  const renderEntityCategories = () => {
    return Object.values(MainEntityCategories).map((item: string) => (
      <Grid item xs={4}>
        <ButtonBase
          className={classes.gridItem}
          onClick={() => selectCategory(item)}
          disabled={getIsCategoryDisabled(item)}
          classes={{ disabled: classes.disabled }}
        >
          <img src={MainEntityCategoryIcons[item]} alt={t(item)} className={classes.categoryIcon} />
        </ButtonBase>
        <div className={classes.row}>
          <p className={classes.gridItemName}>{t(item)}</p>
        </div>
      </Grid>
    ))
  }

  const renderEntityTypes = () => {
    if (category) {
      const entityKeys = MainEntityCategoryTypeMappings[category]
      let entityTypes = entityKeys.map((key: string) => {
        return allMainEntityTypes.find((item: any) => item === key)
      })
      if (entityTypes?.length) {
        entityTypes = sortBy(entityTypes, (type) => t(type))
        return entityTypes.map((item: any) => {
          return (
            <Grid item xs={12/5}>
              <ButtonBase
                className={classes.gridItem}
                onClick={() => selectType(item)}
                disabled={getIsEntityDisabled(item)}
                classes={{ disabled: classes.disabled }}
              >
                <img
                  src={MainEntityIcons[item]}
                  alt={t(item)}
                  className={classes.entityIcon}
                />
              </ButtonBase>
              <div className={classes.row}>
                <p className={classes.gridItemName}>{t(item)}</p>
              </div>
            </Grid>
          )
        })
      } else {
        return null
      }
    }
    return null
  }

  const renderContent = () => {
    if (phase === CreateEntityPhase.SelectEntityCategory) {
      return (
        <Grid
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          spacing={6}
          container
        >
          {renderEntityCategories()}
        </Grid>
      )
    } else if (phase === CreateEntityPhase.SelectEntityType) {
      return (
        <Grid
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          spacing={5}
          container
        >
          {renderEntityTypes()}
        </Grid>
      )
    }
    return (
      <>
        <div className={classes.label}>
          {t('entity_name')} (<span className={classes.highlight}>FI</span>)
          </div>
        <Input value={nameFi} onChange={setNameFi} autoFocus />
        <div className={classes.label}>
          {t('entity_name')} (<span className={classes.highlight}>EN</span>)
          </div>
        <Input value={nameEn} onChange={setNameEn} />
      </>
    )
  }

  const renderActions = () => {
    if (phase === CreateEntityPhase.SelectEntityCategory) {
      return (
        <div className={classes.footerContainer}>
          <Button
            onClick={handleClose}
            text={t('cancel')}
            buttonStyle={classes.primaryButton}
          />
        </div>
      )
    } else if (phase === CreateEntityPhase.SelectEntityType) {
      return (
        <div className={classes.footerContainer}>
          { !entityCategory ? (
              <Button
                onClick={handleBack}
                text={t('previous')}
                buttonStyle={classes.secondaryButton}
                outlined
              />
            ) : null
          }
          <div className={classes.spacer} />
          <Button
            onClick={handleClose}
            text={t('cancel')}
            buttonStyle={classes.primaryButton}
          />
        </div>
      )
    }
    return (
      <div className={classes.footerWideContainer}>
        <Button
          onClick={handleBack}
          text={t('previous')}
          buttonStyle={classes.secondaryButton}
          outlined
        />
        <Button
          onClick={handleClose}
          text={t('cancel')}
          buttonStyle={classes.textButton}
          outlined
        />
        <Button
          onClick={handleCreate}
          text={t('save')}
          buttonStyle={classes.primaryButton}
          disabled={!nameFi}
        />
      </div>
    )
  }

  return (
    <Modal open={open} onClose={handleClose} classes={{ root: classes.modal }}>
      <div className={classes.contentContainer}>
        <div className={classes.content}>
          <div className={getPhaseContainerStyle()}>
            {renderTitle()}
            {renderInfo()}
            {renderContent()}
            {renderActions()}
          </div>
        </div>
      </div>
    </Modal>
  )
}
