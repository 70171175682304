import {
  SPACER,
  COURSE_CLASSIFICATION,
  NUMBER_OF_BASKETS,
  COURSE_TYPE,
  DISC_GOLF_BASKET,
  DISC_GOLF_TEE_AREAS,
  TOPOGRAPHY,
  DISC_GOLF_OTHER_SERVICES,
  OPEN_HOURS_AND_PRICE_INFO_TITLE,
  OPEN_HOURS_TYPE,
  BOOKING_CALENDAR_LINK,
  IS_FREE,
  PRICE,
  MAINTENANCE_TITLE,
  LAST_MAINTAINED,
  EXCEPTION_NOTIFICATION,
  CONTACT_INFORMATION_TITLE,
  STREET_ADDRESS,
  CITY,
  POSTAL_CODE,
  MAINTENANCE_FREQUENCY,
  COURSE_INFORMATION_TITLE,
  EQUIPMENT_AND_SERVICES_TITLE,
  DESCRIPTION_TITLE,
  DESCRIPTION
} from '../elements.template'

const LAYOUT = {
  type: 'disc_golf',
  layout: [
    [
      // Start left column
      DESCRIPTION_TITLE,
      DESCRIPTION,
      COURSE_INFORMATION_TITLE,
      {
        type: 'container',
        className: 'customRowFixed',
        items: [
          COURSE_CLASSIFICATION,
          SPACER,
          NUMBER_OF_BASKETS,
          SPACER,
          COURSE_TYPE
        ]
      },
      {
        type: 'container',
        className: 'customRowFixed',
        items: [
          DISC_GOLF_BASKET,
          SPACER,
          DISC_GOLF_TEE_AREAS
        ]
      },
      { ...TOPOGRAPHY, style: { maxWidth: '28rem' } },
      EQUIPMENT_AND_SERVICES_TITLE,
      DISC_GOLF_OTHER_SERVICES
      // End left column
    ],
    [
      // Start right column
      OPEN_HOURS_AND_PRICE_INFO_TITLE,
      {
        type: 'container',
        className: 'customRowFixed',
        items: [
          { ...OPEN_HOURS_TYPE, style: { flex: 1, maxWidth: '11rem' } },
          SPACER,
          { ...BOOKING_CALENDAR_LINK, style: { flex: 1 } }
        ]
      },
      IS_FREE,
      PRICE,
      MAINTENANCE_TITLE,
      {
        type: 'container',
        className: 'customRowFixed',
        items: [
          { ...MAINTENANCE_FREQUENCY, style: { flex: 1, maxWidth: '24rem' } },
          SPACER,
          LAST_MAINTAINED
        ]
      },
      EXCEPTION_NOTIFICATION,
      CONTACT_INFORMATION_TITLE,
      {
        type: 'container',
        className: 'customRowFixed',
        style: { paddingBottom: '10rem' },
        items: [
          STREET_ADDRESS,
          SPACER,
          CITY,
          SPACER,
          POSTAL_CODE
        ]
      }
      // End right column
    ]
  ]
}

export default LAYOUT
