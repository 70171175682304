import get from 'lodash/get'
import { renderCondition, renderName } from './entityTable.utils'
import moment from 'moment'

const TABLE_LAYOUT = {
  type: '*',

  getHeader: (items) => {
    const hasCondition = (items || []).find(i => {
      const match = get(i, 'fields.condition.value', null)
      return !!match
    })

    const hasServicedAt = (items || []).find(i => {
      const match = get(i, 'fields.last_maintained', null)
      return !!match
    })

    const headerColumns = [
      {
        name: 'entity',
      },
      hasCondition ? {
        name: 'condition'
      } : null,
      hasServicedAt ? {
        name: 'serviced_at'
      } : null,
      {
        name: 'snapshot_published_at'
      }
    ].filter(v => !!v)

    return headerColumns
  },

  getColumns: (items) => {
    const hasCondition = (items || []).find(i => {
      const match = get(i, 'fields.condition.value', null)
      return !!match
    })

    const hasServicedAt = (items || []).find(i => {
      const match = get(i, 'fields.last_maintained', null)
      return !!match
    })

    const columns = [
      // Entity name
      {
        style: {
          fontWeight: '500'
        },
        render: (item) => renderName(item)
      },
      // Condition
      hasCondition ? ({
        render: (item) => renderCondition(item)
      }) : null,
      // Serviced at
      hasServicedAt ? {
        render: (item) => {
          const lastServiced = get(item, 'fields.last_maintained.value')
          if (!lastServiced) return null
          return moment(lastServiced).format('DD.MM.YYYY')
        }
      }: null,
      // Updated at
      {
        render: (item) => {
          return item.snapshotCreatedAt ? moment(item.snapshotCreatedAt).format('DD.MM.YYYY HH:mm') : '-'
        }
      }
    ].filter(v => !!v)
    return columns
  }
}

export default TABLE_LAYOUT
