import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import { useTranslation } from 'react-i18next'
import { TableHeader, DataMessage } from '..'
import FeedbackTableRow from './FeedbackTableRow'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1400
  },
  smallSpacer: {
    height: '0.25rem'
  },
  spacer: {
    height: '1rem'
  }
}))

function FeedbackTable (props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const renderRows = () => {
    if (props.items) {
      return props.items.map((item) => {
        return (
          <React.Fragment key={item.id}>
            <div className={classes.spacer} />
            <FeedbackTableRow
              item={item}
              onDelete={props.onDelete}
              onRestore={props.onRestore}
            />
          </React.Fragment>
        )
      })
    }
    return null
  }

  return (
    <>
      <Table classes={{ root: classes.root }}>
        <TableHeader
          columnNames={[
            t('entity'),
            t('content'),
            t('rating'),
            t('status'),
            t('sent_at')
          ]}
        />
        <div className={classes.smallSpacer} />
        <TableBody>{renderRows()}</TableBody>
      </Table>
      <DataMessage data={props.items} message={t('no_feedbacks')} />
    </>
  )
}

export default FeedbackTable
