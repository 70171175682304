import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'
import { useStore } from '../../../Models/RootStore'
import { PageHeader, Button, Input, Select } from '../../../Components'
import { CUSTOM_FILED_TYPES } from '../../../Constants'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  contentContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 0
  },
  content: {
    padding: '2.5rem'
  }
}))

const AdminMainEntityField = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { adminStore }: any = useStore()

  const [fieldType, setFieldType] = useState(null)
  const [fieldKey, setFieldKey] = useState(null)

  const [labelFi, setLabelFi] = useState(null)
  const [labelEn, setLabelEn] = useState(null)
  const [options, setOptions] = useState(null)

  const handleSave = () => {
    const optionsJson = options && options.trim() ? JSON.parse(options) : null

    adminStore.createMainEntityField(
      {
        fieldType,
        fieldKey,
        labelFi,
        labelEn,
        options: optionsJson
      }
    )
  }

  const getTypeOptions = () => CUSTOM_FILED_TYPES.map(type => {
    return {
      label: type,
      value: type
    }
  })

  return (
    <div className={classes.root}>
      <PageHeader
        title={t('create_main_entity_field')}
      />
      <div className={classes.contentContainer}>
        <div className={classes.content}>
          <Select
            options={getTypeOptions()}
            value={fieldType}
            onChange={setFieldType}
            label='fieldType'
          />

          <Input
            label='fieldKey'
            value={fieldKey}
            onChange={setFieldKey}
          />
          <Input
            label='labelFi'
            value={labelFi}
            onChange={setLabelFi}
          />
          <Input
            label='labelEn'
            value={labelEn}
            onChange={setLabelEn}
          />
          <Input
            label='options'
            value={options}
            onChange={setOptions}
            multiline
          />
          <Button
            text={t('save')}
            onClick={handleSave}
          />
        </div>
      </div>
    </div>
  )
}

export default observer(AdminMainEntityField)
