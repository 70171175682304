import React, { useState } from 'react'
import moment from 'moment'
import makeStyles from '@mui/styles/makeStyles'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import IconButton from '@mui/material/IconButton'
import { Tooltip } from '@mui/material'
import { Colors } from '../../Utils/theme'
import { useTranslation } from 'react-i18next'
import { formatDateTime } from '../../Utils/dateTime'
import { Dialog } from '../../Components'
import MapIcon from '../../Assets/Icons/pin-dark.svg'
import EditIcon from '../../Assets/Icons/edit-dark.svg'
import DeleteIcon from '../../Assets/Icons/delete.svg'
import GpxIcon from '../../Assets/Icons/gpx-dark.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: Colors.backgroundGrey,
    borderBottom: 'none',
    '&:hover': {
      backgroundColor: `${Colors.tableHover} !important`
    }
  },
  firstCell: {
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: 600,
    height: '3rem',
    width: '11rem',
    color: Colors.black,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    borderBottom: 'none'
  },
  lastCell: {
    height: '3rem',
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    borderBottom: 'none',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  cell: {
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: 400,
    height: '3rem',
    color: Colors.black,
    borderBottom: 'none',
    width: '13.5rem'
  },
  actionButton: {
    backgroundColor: Colors.white,
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.black20}`,
    height: '2rem',
    width: '2rem',
    marginLeft: '0.5rem',
    '&:hover': {
      backgroundColor: `${Colors.white} !important`
    }
  },
  actionIcon: {
    height: '1.25rem',
    width: 'auto',
    maxHeight: '1.25rem',
    maxWidth: '1.25rem'
  }
}))

function GpxRecordingTableRow (props: any) {
  const { item } = props
  const classes = useStyles()

  const [alertVisible, setAlertVisible] = useState(false)

  const { t } = useTranslation()

  const handleReplace = () => {
    if (!item.mainEntityId) {
      return console.log('error, no main entity id')
    }

    const feature = item.geo
    if (feature) {
      feature.properties = {
        isMainEntity: !item.subEntityId,
        mainEntityId: item.mainEntityId,
        subEntityId: item.subEntityId || null
      }
    }
    props.onReplace(feature)
  }

  const handleEdit = () => props.onEdit(item)

  const handleDelete = () => {
    props.onDelete(item.id)
    setAlertVisible(false)
  }

  const handleDownload = () => props.exportGpxRecording(item.id, `export-${item.id}-${moment(item.createdAt).format('YYYY-MM-DD_HHmmss')}`)

  const renderDeleteModal = () => {
    return (
      <Dialog
        open={alertVisible}
        handleClose={() => setAlertVisible(false)}
        handleOk={handleDelete}
        title={t('delete_gpx_recording_title')}
        description={t('delete_gpx_recording_description')}
      />
    )
  }

  return (
    <>
      <TableRow classes={{ root: classes.root }} hover>
        <TableCell className={classes.firstCell} size='small'>
          {formatDateTime(item.createdAt)}
        </TableCell>
        <TableCell className={classes.cell} size='small'>
          {item.type ? t(item.type) : '-'}
        </TableCell>
        <TableCell className={classes.cell} size='small'>
          {item.description}
        </TableCell>
        <TableCell className={classes.lastCell} size='small'>
          <Tooltip title={t('gpx_recording_apply_to_map')}>
            <IconButton onClick={handleReplace} className={classes.actionButton} size='large'>
              <img src={MapIcon} className={classes.actionIcon} alt='icon' />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('edit')}>
            <IconButton onClick={handleEdit} className={classes.actionButton} size='large'>
              <img src={EditIcon} className={classes.actionIcon} alt='icon' />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('download_gpx_file')}>
            <IconButton onClick={handleDownload} className={classes.actionButton} size='large'>
              <img src={GpxIcon} className={classes.actionIcon} alt='icon' />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('delete')}>
            <IconButton onClick={() => setAlertVisible(true)} className={classes.actionButton} size='large'>
              <img src={DeleteIcon} className={classes.actionIcon} alt='icon' />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
      {renderDeleteModal()}
    </>
  )
}

export default GpxRecordingTableRow
