import {
  EXCEPTION_NOTIFICATION,
  CONTACT_INFORMATION_TITLE,
  STREET_ADDRESS,
  SPACER,
  CITY,
  POSTAL_CODE,
  EXCEPTION_NOTIFICATIONS_TITLE,
  FACULTIES_TITLE,
  FACULTIES,
  CAMPUSES_TITLE,
  CAMPUSES,
  SERVICES_CONTACT_INFORMATION_TITLE,
  SERVICES_CONTACT_INFORMATION,
  DESCRIPTION_TITLE,
  DESCRIPTION
 } from '../elements.template'

const LAYOUT = {
  type: 'university',
  layout: [
    [
      // Start left column
      DESCRIPTION_TITLE,
      DESCRIPTION,
      FACULTIES_TITLE,
      FACULTIES,
      CAMPUSES_TITLE,
      CAMPUSES,
      SERVICES_CONTACT_INFORMATION_TITLE,
      SERVICES_CONTACT_INFORMATION
      // End left column
    ],
    [
      // Start right column
      EXCEPTION_NOTIFICATIONS_TITLE,
      { ...EXCEPTION_NOTIFICATION, hideLabel: true, style: { paddingTop: '0.375rem' } },
      CONTACT_INFORMATION_TITLE,
      {
        type: 'container',
        className: 'customRowFixed',
        style: { paddingBottom: '10rem' },
        items: [
          STREET_ADDRESS,
          SPACER,
          CITY,
          SPACER,
          POSTAL_CODE
        ]
      }
      // End right column
    ]
  ]
}

export default LAYOUT
