import {
  SPACER,
  MAINTENANCE_TITLE,
  LAST_MAINTAINED,
  EXCEPTION_NOTIFICATION,
  CONTACT_INFORMATION_TITLE,
  STREET_ADDRESS,
  CITY,
  POSTAL_CODE,
  PROPERTIES_TITLE,
  MULTIPURPOSE_FIELD_SURFACE,
  ACCESSIBLE,
  CONDITION,
  MULTIPURPOSE_FIELD_EQUIPMENT,
  USE_START_DATE,
  DASH_SPACER,
  USE_END_DATE,
  EQUIPMENT_TITLE,
  FIELD_IN_USE,
  DESCRIPTION_TITLE,
  DESCRIPTION
} from '../elements.template'

const LAYOUT = {
  type: 'multipurpose_field',
  layout: [
    [
      // Start left column
      DESCRIPTION_TITLE,
      DESCRIPTION,
      PROPERTIES_TITLE,
      {
        type: 'container',
        className: 'customRowFixed',
        items: [
          { ...MULTIPURPOSE_FIELD_SURFACE, style: { flex: 1, maxWidth: '16rem' } },
          SPACER,
          { ...FIELD_IN_USE, style: { flex: 1, maxWidth: '16rem' } },
          SPACER,
          ACCESSIBLE
        ]
      },
      EQUIPMENT_TITLE,
      MULTIPURPOSE_FIELD_EQUIPMENT
      // End left column
    ],
    [
      // Start right column
      MAINTENANCE_TITLE,
      {
        type: 'container',
        className: 'customColumn',
        style: {},
        items: [
          {
            type: 'container',
            items: [{ type: 'title', name: 'in_use_between' }]
          },
          {
            type: 'container',
            className: 'customInnerRow',
            items: [
              USE_START_DATE,
              DASH_SPACER,
              USE_END_DATE
            ]
          },
        ]
      },
      {
        type: 'container',
        className: 'customRowFixed',
        items: [
          { ...CONDITION, style: { flex: 1, maxWidth: '24rem' } },
          SPACER,
          SPACER,
          LAST_MAINTAINED
        ]
      },
      EXCEPTION_NOTIFICATION,
      CONTACT_INFORMATION_TITLE,
      {
        type: 'container',
        className: 'customRowFixed',
        style: { paddingBottom: '10rem' },
        items: [
          STREET_ADDRESS,
          SPACER,
          CITY,
          SPACER,
          POSTAL_CODE
        ]
      }
      // End right column
    ]
  ]
}

export default LAYOUT
