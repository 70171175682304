import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Colors } from '../../Utils/theme'
import { useTranslation } from 'react-i18next'
import ButtonBase from '@mui/material/ButtonBase'
import ProfileAlt from '../../Assets/Images/profile.png'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '18rem',
    height: '16rem',
    backgroundColor: Colors.backgroundGrey,
    borderRadius: '0.3125rem',
    marginBottom: '2.5rem',
    marginRight: '2.5rem',
    '&:hover': {
      backgroundColor: Colors.grey
    }
  },
  last: {
    marginRight: 0
  },
  inactive: {
    opacity: 0.5
  },
  photo: {
    height: '7.75rem',
    width: '7.75rem',
    objectFit: 'cover',
    borderRadius: '100%',
    marginBottom: '1rem'
  },
  name: {
    fontFamily: 'Rubik',
    fontWeight: 700,
    fontSize: '1rem',
    color: Colors.black,
    marginBottom: 5
  },
  email: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '1rem',
    color: Colors.black80,
    marginBottom: 8
  },
  role: {
    fontFamily: 'Roboto',
    fontWeight: 300,
    fontSize: '1rem',
    color: Colors.black80
  }
}))

function UserCard (props) {
  const { user } = props
  const classes = useStyles()
  const { t } = useTranslation()

  const isActive = user.active
  const onClick = () => props.onClick(user.id)

  return (
    <ButtonBase className={`${classes.container} ${!isActive ? classes.inactive : ''}`.trim()} onClick={onClick}>
      <img className={classes.photo} src={user.photo || ProfileAlt} alt='' />
      <p className={classes.name}>{user.firstName} {user.lastName}</p>
      <p className={classes.email}>{user.email}</p>
      <p className={classes.role}>{t(user.role)}</p>
    </ButtonBase>
  )
}

export default UserCard
