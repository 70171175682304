import {
  SPACER,
  DESCRIPTION,
  OPEN_HOURS_TITLE,
  OPEN_HOURS,
  STREET_ADDRESS,
  CITY,
  POSTAL_CODE,
  SERVICE_PHONES_AND_URLS_TITLE,
  SERVICE_PHONES,
  SERVICE_URLS,
  ADDRESS_INFORMATION_TITLE,
  DESCRIPTION_TITLE
} from '../elements.template'

const LAYOUT = {
  type: 'subentity_ticket_sale',
  layout: [
    [
      // Start left column
      OPEN_HOURS_TITLE,
      OPEN_HOURS,
      ADDRESS_INFORMATION_TITLE,
      {
        type: 'container',
        className: 'customRowFixed',
        items: [
          STREET_ADDRESS,
          SPACER,
          CITY,
          SPACER,
          POSTAL_CODE
        ]
      },
      DESCRIPTION_TITLE,
      DESCRIPTION,
      SERVICE_PHONES_AND_URLS_TITLE,
      SERVICE_PHONES,
      SERVICE_URLS
      // End left column
    ],
    [
      // Start of right column
      // End of right column
    ]
  ]
}

export default LAYOUT
