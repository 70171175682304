import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import { useTranslation } from 'react-i18next'
import { TableHeader, DataMessage } from '..'
import PollTableRow from './PollTableRow'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) =>({
  root: {
  },
  spacer: {
    height: '1rem'
  }
}))

function PollTable (props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const renderRows = () => {
    if (props.items) {
      return props.items.map((item) => (
        <React.Fragment key={item.id}>
          <div className={classes.spacer} />
          <PollTableRow
            item={item}
            onDelete={props.onDelete}
            onRestore={props.onRestore}
          />
        </React.Fragment>
      ))
    }
    return null
  }

  return (
    <>
      <Table classes={{ root: classes.root }}>
        <TableHeader columnNames={[t('title'), t('num_answers'), t('poll_table_valid_from'), t('poll_table_valid_until'), t('actions')]} />
        <TableBody>{renderRows()}</TableBody>
      </Table>
      <DataMessage data={props.items} message={t('no_polls')} />
    </>
  )

}

export default PollTable
