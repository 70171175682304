import {
  DESCRIPTION,
  DESCRIPTION_TITLE
} from '../elements.template'

const LAYOUT = {
  type: 'subentity_area',
  layout: [
    [
      // Start left column
      DESCRIPTION_TITLE,
      DESCRIPTION
      // End left column
    ],
    [
      // Start of right column
      // End of right column
    ]
  ]
}

export default LAYOUT
