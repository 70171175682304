import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import get from 'lodash/get'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'
import { useStore } from '../../../Models/RootStore'
import { Title, TopList, WeeklyInterestBarChart } from '../../../Components'
import { Colors } from '../../../Utils/theme'
import UserStatsIcon from '../../../Assets/Icons/user-stats.svg'

const useStyles = makeStyles((theme) => ({
  root: {
  },
  container: {
    padding: '2.5rem'
  },
  info: {
    margin: 0,
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: 300,
    color: Colors.black,
    marginBottom: '2.5rem'
  },
  countsContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '3rem'
  },
  countContainer: {
    background: Colors.backgroundGrey,
    flex: 1,
    height: '11rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '0.3125rem',
    marginRight: '3rem'
  },
  countValue: {
    margin: 0,
    fontFamily: 'Rubik',
    fontSize: '3rem',
    paddingBottom: '.25rem',
    fontWeight: 500,
    color: Colors.black
  },
  countKey: {
    display: 'block',
    textAlign: 'center',
    paddingLeft: '.25rem',
    paddingRight: '.25rem',
    margin: 0,
    marginTop: '-0.5rem',
    fontFamily: 'Roboto',
    fontSize: '1.125rem',
    fontWeight: 300,
    color: Colors.black
  },
  cardsContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  card: {
    flex: 1
  },
  cardSpacer: {
    width: '3rem'
  },
  usersCard: {
    backgroundColor: Colors.backgroundGrey,
    display: 'flex',
    paddingTop: '2rem',
    paddingBottom: '2rem',
    borderRadius: '0.3125rem',
    marginTop: '3rem'
  },
  usersCardIcon: {
    paddingLeft: '1.5rem',
    paddingRight: '0.5rem',
    alignItems: 'center',
    justifyContent: 'center'
  },
  usersCardContentContainer: {
    flex: 1,
    paddingLeft: '1rem'
  },
  usersCardTitle: {
    fontSize: '3rem',
    color: Colors.black70,
    fontWeight: 600
  },
  usersCardNumbers: {
    display: 'inline-block',
    fontSize: '1rem',
    marginLeft: '1rem',
    color: Colors.black70,
    fontWeight: 400
  },
  usersCardContent: {
    fontSize: '1.25rem',
    fontWeight: 300,
    color: Colors.black80
  },
  noRightMargin: {
    marginRight: 0
  }
}))

function Dashboard() {
  const classes = useStyles()

  const { sessionStore, analyticsStore }: any = useStore()

  const { t } = useTranslation()

  useEffect(() => {
    analyticsStore.getAnalyticsDashboard()
  }, [])

  const getTitle = () => {
    const firstName = get(sessionStore.user, 'firstName') || ''
    return `${t('hello_name')} ${firstName}`.trim()
  }

  const renderCountBoxes = () => {
    const allUserCount = get(analyticsStore, 'analytics.userCount.allUserCount', '-')
    const mainEntityCount = get(analyticsStore, 'analytics.entityCounts.mainEntityCount', '-')
    const subEntityCount = get(analyticsStore, 'analytics.entityCounts.subEntityCount', '-')
    const analyticsEventsCount = get(analyticsStore, 'analytics.analyticsEventsCount', '-')

    const counters = [
      { name: t('count_users'), value: allUserCount },
      { name: t('count_main_entities'), value: mainEntityCount },
      { name: t('count_sub_entities'), value: subEntityCount },
      { name: t('count_analytics_events'), value: analyticsEventsCount }
    ]

    return counters.map((item, index) => {
      return (
        <div
          key={index}
          className={`${classes.countContainer} ${index === counters.length - 1 ? classes.noRightMargin : ''}`}
        >
          <p className={classes.countValue}>{item.value}</p>
          <p className={classes.countKey}>{item.name}</p>
        </div>
      )
    })
  }

  const renderUserCounts = () => {
    if (get(analyticsStore, 'analytics.userCount.allUserCount')) {
      const allUserCount = get(analyticsStore, 'analytics.userCount.allUserCount')
      const newUserCount = get(analyticsStore, 'analytics.userCount.newUserCount')

      const percentage = `${Math.round((newUserCount / allUserCount) * 100)}%`

      return (
        <div className={classes.usersCard}>
          <div className={classes.usersCardIcon}>
            <img src={UserStatsIcon} />
          </div>
          <div className={classes.usersCardContentContainer}>
            <div className={classes.usersCardTitle}>
              {percentage}
              <div className={classes.usersCardNumbers}>
                {`(${newUserCount}/${allUserCount})`}
              </div>
            </div>
            <div className={classes.usersCardContent}>
              {t('new_users_last_30_days')}
            </div>
          </div>
        </div>
      )
    }
    return null
  }

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Title title={getTitle()} />
        <p className={classes.info}>{t('dashboard_info')}</p>
        <div className={classes.countsContainer}>
          {renderCountBoxes()}
        </div>
        <div className={classes.cardsContainer}>
          <div className={classes.card}>
            <WeeklyInterestBarChart
              items={get(analyticsStore, 'analytics.weeklyInterestCounts', [])}
            />
            {renderUserCounts()}
          </div>
          <div className={classes.cardSpacer} />
          <div className={classes.card}>
            <TopList
              title={t('most_viewed_entities')}
              items={get(analyticsStore, 'analytics.topMainEntities', [])}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default observer(Dashboard)
