import {
  EXCEPTION_NOTIFICATION,
  CONTACT_INFORMATION_TITLE,
  STREET_ADDRESS,
  SPACER,
  CITY,
  POSTAL_CODE,
  EXCEPTION_NOTIFICATIONS_TITLE,
  OPEN_HOURS_TITLE,
  SERVICE_OPEN_HOURS,
  TIMETABLES_TITLE,
  LINKS,
  SERVICES_CONTACT_INFORMATION_TITLE,
  SERVICES_CONTACT_INFORMATION,
  EXCEPTION_SCHEDULES,
  DESCRIPTION,
  DESCRIPTION_TITLE
 } from '../elements.template'

const LAYOUT = {
  type: 'station',
  layout: [
    [
      // Start left column
      DESCRIPTION_TITLE,
      DESCRIPTION,
      OPEN_HOURS_TITLE,
      SERVICE_OPEN_HOURS,
      { ...EXCEPTION_SCHEDULES, label: 'exceptions_in_schedules' },
      TIMETABLES_TITLE,
      LINKS,
      SERVICES_CONTACT_INFORMATION_TITLE,
      SERVICES_CONTACT_INFORMATION
      // End left column
    ],
    [
      // Start right column
      EXCEPTION_NOTIFICATIONS_TITLE,
      { ...EXCEPTION_NOTIFICATION, hideLabel: true, style: { paddingTop: '0.375rem' } },
      CONTACT_INFORMATION_TITLE,
      {
        type: 'container',
        className: 'customRowFixed',
        style: { paddingBottom: '10rem' },
        items: [
          STREET_ADDRESS,
          SPACER,
          CITY,
          SPACER,
          POSTAL_CODE
        ]
      }
      // End right column
    ]
  ]
}

export default LAYOUT
