import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import orderBy from 'lodash/orderBy'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { PageHeader, DataActions, OrganizationGrid } from '../../../Components'
import { OrganizationSortOptions } from '../../../Constants'

const useStyles = makeStyles((theme) => ({
  root: {
  },
  content: {
    padding: '2.5rem'
  }
}))

function Organizations(props) {
  const classes = useStyles()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [searchValue, setSearchValue] = useState('')
  const [sortValue, setSortValue] = useState(OrganizationSortOptions[1].value)

  const { organizationStore }: any = useStore()
  useEffect(() => {
    organizationStore.getOrganizations()
  }, [])

  const toCreateOrganization = () => navigate('/organizations/create')
  const toOrganization = (id) => navigate(`/organizations/${id}`)

  const getOrganizations = () => {
    let organizations = organizationStore.organizations
    if (searchValue) {
      organizations = organizations.filter((organization) => {
        const { name = '', businessId = '' } = organization
        const targets = [name, businessId]
        return targets.some((target) => target.toLowerCase().includes(searchValue.trim().toLowerCase()))
      })
    }

    if (sortValue === 'created_at') {
      organizations = orderBy(organizations, ['createdAt'], 'desc')
    } else {
      organizations = orderBy(organizations, ['name'], 'asc')
    }

    return organizations
  }

  return (
    <div className={classes.root}>
      <PageHeader
        title={t('organizations')}
        onCreateClick={toCreateOrganization}
      />
      <div className={classes.content}>
        <DataActions
          searchPlaceholder={t('search_by_name')}
          searchValue={searchValue}
          onSearchChange={setSearchValue}
          sortOptions={OrganizationSortOptions}
          sortValue={sortValue}
          onSortChange={setSortValue}
        />
        <OrganizationGrid organizations={getOrganizations()} onClick={toOrganization} />
      </div>
    </div>
  )
}

export default observer(Organizations)
