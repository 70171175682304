import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useStore } from '../../../Models/RootStore'
import makeStyles from '@mui/styles/makeStyles'
import { PageHeader, Title, Input, Button } from '../../../Components'

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'calc(100% - 5rem)',
    overflow: 'hidden'
  },
  description: {
    marginBottom: '3rem'
  },
  buttonContainer: {
    maxWidth: '12rem'
  },
  contentContainer: {
    padding: '3rem'
  }
}))

const EmailChange = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { sessionStore }: any = useStore()

  let initialCode = ''
  if (window.location.search) {
    const parts = window.location.search.split('code=')
    if (parts.length > 1 && parts[1].split('&')[0].length === 36) {
      initialCode = parts[1].split('&')[0]
    }
  }

  useEffect(() => {
    if (initialCode) {
      sessionStore.getEmailChange(initialCode)
    }
  }, [])

  const [code, setCode] = useState(initialCode)

  const verifyEmailChange = () => sessionStore.verifyEmailChange(code)

  const getDescription = () => {
    if (sessionStore.loading) return null
    if (sessionStore.emailChange) {
      return sessionStore.emailChange.email
    } else {
      return t('invalid_or_expired_code_description')
    }
  }

  return (
    <div className={classes.root}>
      <PageHeader title={t('verification')} />
      <div className={classes.contentContainer}>
        <Title
          title={t('verify_email_change')}
          description={getDescription()}
          type='subtitle'
          descriptionStyle={classes.description}
        />
        {!initialCode && <Input label={t('code')} value={code} onChange={setCode} />}
        {(!initialCode || !!sessionStore.emailChange) && (
          <div className={classes.buttonContainer}>
            <Button text={t('verify')} onClick={verifyEmailChange} />
          </div>
        )}
      </div>
    </div>
  )
}

export default observer(EmailChange)
