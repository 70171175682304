import { $generateHtmlFromNodes, $generateNodesFromDOM } from "@lexical/html";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getRoot, $insertNodes } from "lexical";
import { useEffect } from "react";

const HtmlConverterPlugin = (props) => {
  const [editor] = useLexicalComposerContext();

  const initializeFromHtml = (htmlString) => {
    editor.update(() => {
      // In the browser you can use the native DOMParser API to parse the HTML string.
      const parser = new DOMParser();
      const dom = parser.parseFromString(htmlString, 'text/html');

      // Once you have the DOM instance it's easy to generate LexicalNodes.
      const nodes = $generateNodesFromDOM(editor, dom);

      // Select the root
      $getRoot().select();

      // Insert them at a selection.
      $insertNodes(nodes);
    })
  }

  useEffect(() => {
    if (props.initialHtml) {
      initializeFromHtml(props.initialHtml)
    }
  }, [])

  /*
  const cleanHtmlString = (str) => {
    let htmlString = str

    // Remove extra wrapper elements
    htmlString = htmlString.replace(/<\/?span[^>]*>/g, '')
    htmlString = htmlString.replace(/<\/?strong[^>]*>/g, '')
    htmlString = htmlString.replace(/<\/?em[^>]*>/g, '')

    // Remove unneeded attributes
    htmlString = htmlString.replace(/ class="[^"]+"/g, '')
    htmlString = htmlString.replace(/ dir="[^"]+"/g, '')
    htmlString = htmlString.replace(/ value="[^"]+"/g, '')

    return htmlString
  }
  */

  useEffect(() => {
    const removeUpdateListener = editor.registerUpdateListener(
      ({ editorState }) => {
        editorState.read(() => {
          const htmlString = $generateHtmlFromNodes(editor, null);
          props.setHtml(htmlString)
        });
      }
    );

    setTimeout(() => editor.blur(), 100)
    return () => {
      removeUpdateListener();
    };
  }, [editor]);

  return null;
}

export default HtmlConverterPlugin
