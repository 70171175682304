import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'

export default class AnalyticsStore {
  rootStore
  analytics = null
  entityAnalytics = null
  loading = null

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  updateAnalytics = (analytics) => { this.analytics = analytics }
  updateLoading = (loading) => { this.loading = loading }

  async getAnalyticsDashboard() {
    this.updateLoading(true)
    const response: any = await Api.getAnalyticsDashboard()
    this.updateLoading(false)
    if (response.ok) {
      const analytics = response.data
      this.updateAnalytics(analytics)
    }
    this.rootStore.appStore.setErrorOrSuccess(response, true, false)
  }

  async getMainEntityAnalytics(id) {
    this.updateLoading(true)
    const response: any = await Api.getMainEntityAnalytics()
    this.updateLoading(false)
    if (response.ok) {
      const analytics = response.data
      this.updateAnalytics(analytics)
    }
    this.rootStore.appStore.setErrorOrSuccess(response, true, false)
  }
}
