import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import Grid from '@mui/material/Grid'
import uniq from 'lodash/uniq'
import sortBy from 'lodash/sortBy'
import makeStyles from '@mui/styles/makeStyles'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { PageHeader, DataActions, FeedbackCard } from '../../../Components'
import { Colors } from '../../../Utils/theme'

const useStyles = makeStyles({
  container: {
  },
  preSpacer: {
    height: '.5rem'
  },
  content: {
    padding: '2.5rem'
  },
  monthContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: '1.75rem',
    borderBottom: `1px solid ${Colors.black20}`,
    marginBottom: '1.75rem'
  },
  monthBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: Colors.backgroundGrey,
    borderRadius: '0.3125rem',
    height: '4rem',
    width: '6rem',
    marginRight: '1.5rem'
  },
  itemsContainer: {
    flex: 1
  },
  year: {
    fontSize: '1rem',
    fontFamily: 'Rubik',
    fontWeight: 700
  },
  month: {
    fontSize: '0.875rem',
    fontFamily: 'Rubik'
  }
})

const TABS = {
  waitingModeration: 0,
  published: 1,
  hidden: 2
}

function Feedbacks() {
  const classes = useStyles()
  const { t } = useTranslation()

  const [filterValue, setFilterValue] = useState(0)
  const [searchValue, setSearchValue] = useState('')

  const [tab, setTab] = useState(TABS.waitingModeration)
  const selectTab = (value) => setTab(value)

  const { feedbackStore }: any = useStore()
  useEffect(() => {
    feedbackStore.getEntityFeedbacks()
  }, [])

  const getFeedbacksTabCount = (tab) => {
    let feedbacks = feedbackStore.entityFeedbacks
    if (searchValue) {
      feedbacks = feedbacks.filter((feedback) => {
        const { message = '', title = '', mainEntityName = '', subEntityName = ''} = feedback
        const targets = [message, title, mainEntityName, subEntityName]
        return targets.some((target) => (target || '').toLowerCase().includes(searchValue.trim().toLowerCase()))
      })
    }
    if (filterValue) {
      // Filter by main entity
      feedbacks = feedbacks.filter(f => f.mainEntityId === filterValue)
    }

    // Filter
    if (tab === TABS.waitingModeration) {
      feedbacks = feedbacks.filter(f => !f.moderatedBy)
    } else if (tab === TABS.published) {
      feedbacks = feedbacks.filter(f => f.public)
    } else if (tab === TABS.hidden) {
      feedbacks = feedbacks.filter(f => f.moderatedBy && !f.public)
    }

    return feedbacks.length
  }

  const getTabs = () => {
    const tabs = [
      {
        label: `${t('waiting_moderations')} (${getFeedbacksTabCount(0)})`,
        value: TABS.waitingModeration,
      },
      {
        label: `${t('publisheds')} (${getFeedbacksTabCount(1)})`,
        value: TABS.published
      },
      {
        label: `${t('hiddened')} (${getFeedbacksTabCount(2)})`,
        value: TABS.hidden
      }
    ]
    return tabs
  }

  const getFeedbacks = () => {
    let feedbacks = feedbackStore.entityFeedbacks
    if (searchValue) {
      feedbacks = feedbacks.filter((feedback) => {
        const { message = '', title = '', mainEntityName = '', subEntityName = ''} = feedback
        const targets = [message, title, mainEntityName, subEntityName]
        return targets.some((target) => (target || '').toLowerCase().includes(searchValue.trim().toLowerCase()))
      })
    }
    if (filterValue) {
      // Filter by main entity
      feedbacks = feedbacks.filter(f => f.mainEntityId === filterValue)
    }

    // Filter
    if (tab === TABS.waitingModeration) {
      feedbacks = feedbacks.filter(f => !f.moderatedBy)
    } else if (tab === TABS.published) {
      feedbacks = feedbacks.filter(f => f.public)
    } else if (tab === TABS.hidden) {
      feedbacks = feedbacks.filter(f => f.moderatedBy && !f.public)
    }

    return feedbacks
  }

  const getFeedbacksByMonths = () => {
    const feedbacks = getFeedbacks()
    let months = feedbacks.map(feedback => moment(feedback.createdAt).format('YYYY-MM'))
    months = uniq(months)
    return months.map(month => {
      return {
        month: moment(month + '-15').month() + 1,
        year: moment(month + '-15').year(),
        items: feedbacks.filter(feedback => moment(feedback.createdAt).format('YYYY-MM') === month)
      }
    })
  }

  const renderItems = () => {
    const months = getFeedbacksByMonths()
    return months.map((monthItem, index) => {
      return (
        <div key={index} className={classes.monthContainer}>
          <div className={classes.monthBox}>
            <div className={classes.year}>{monthItem.year}</div>
            <div className={classes.month}>{t(`month_${monthItem.month}`)}</div>
          </div>
          <div className={classes.itemsContainer}>
            <Grid
              direction='row'
              justifyContent='flex-start'
              alignItems='center'
              spacing={5}
              container
            >
              {monthItem.items.map((item, index) => {
                return (
                  <Grid key={index} item xl={6} lg={6} xs={12}>
                    <FeedbackCard
                      item={item}
                      makePublic={() => {
                        feedbackStore.moderateEntityFeedback(item.id, { public: true })
                      }}
                      makePrivate={() => {
                        feedbackStore.moderateEntityFeedback(item.id, { public: false })
                      }}
                    />
                  </Grid>
                )
              })}
            </Grid>
          </div>
        </div>
      )
    })
  }

  const getMainEntityOptions = () => {
    const options = [{ label: t('all_entities'), value: 0, sortName: '0' }]
    for (const feedback of feedbackStore.entityFeedbacks) {
      if (feedback.mainEntityId && !options.find(option => option.value === feedback.mainEntityId) ) {
        options.push({
          label: feedback.mainEntityName,
          value: feedback.mainEntityId,
          sortName: `${feedback.mainEntityName}`.toLowerCase()
        })
      }
    }
    return sortBy(options, 'sortName')
  }

  return (
    <div className={classes.container}>
      <PageHeader
        title={t('feedbacks')}
        tabs={getTabs()}
        currentTab={tab}
        onTabChange={selectTab}
      />
      <div className={classes.content}>
        <DataActions
          searchPlaceholder={t('search')}
          searchValue={searchValue}
          onSearchChange={setSearchValue}
          sortOptions={getMainEntityOptions()}
          sortValue={filterValue}
          onSortChange={setFilterValue}
        />
        <div className={classes.preSpacer} />
        {renderItems()}
      </div>
    </div>
  )
}

export default observer(Feedbacks)
