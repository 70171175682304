import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'
import get from 'lodash/get'
import { useParams } from 'react-router-dom'
import { useStore } from '../../../Models/RootStore'
import { PageHeader, EntityVersionTable } from '../../../Components'

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: 200
  },
  contentContainer: {
    padding: '2.5rem'
  }
}))

function EntityVersionHistory(props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const { entityStore }: any = useStore()
  const { entityId }: any = useParams()

  useEffect(() => {
    entityStore.getSnapshots(entityId)
    entityStore.getMainEntityTypes()
  }, [])

  const handleRestoreSnapshot = (snapshotId) => {
    entityStore.restoreSnapshot(snapshotId)
  }

  const getSnapshots = () => {
    const mainEntityTypes = entityStore.mainEntityTypes

    return entityStore.snapshots.map(item => {
      let type = ''
      const typeItem = mainEntityTypes.find(({ id }) => id === get(item, 'config.mainEntity.mainEntityTypeId'))
      if (typeItem) type = typeItem.name

      return {
        id: item.id,
        name: get(item, 'config.mainEntity.name'),
        message: item.message,
        type,
        createdAt: item.createdAt
      }
    })
  }

  const renderContent = () => {
    return (
      <EntityVersionTable
        items={getSnapshots()}
        onRestore={handleRestoreSnapshot}
      />
    )
  }

  return (
    <div className={classes.root}>
      <PageHeader title={t('publications')} compact />
      <div className={classes.contentContainer}>
        {renderContent()}
      </div>
    </div>
  )
}

export default observer(EntityVersionHistory)
