import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'

export default class OrganizationStore {
  rootStore

  organizations: any = []
  organization = null
  organizationUsers = []
  createOrganizationSuccess = false
  loading = null

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  updateOrganizations = (organizations) => { this.organizations = organizations }
  updateOrganization = (organization) => { this.organization = organization }
  updateOrganizationUsers = (users) => { this.organizationUsers = users }
  updateCreateOrganizationSuccess = (success) => { this.createOrganizationSuccess = success }
  updateLoading = (loading) => { this.loading = loading }

  async getMyOrganization() {
    this.updateLoading(true)
    const response = await Api.getMyOrganization()
    this.updateLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, true, false)
    if (response.ok) {
      const organization = response.data
      this.updateOrganization(organization)
    }
  }

  async updateMyOrganization(data) {
    this.updateLoading(true)
    const response = await Api.updateMyOrganization(data)
    this.updateLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'info_updated')
    if (response.ok) {
      const organization = response.data
      this.updateOrganization(organization)
    }
  }

  async updateMyOrganizationLogo(data) {
    this.updateLoading(true)
    const response = await Api.updateMyOrganizationLogo(data)
    this.updateLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'info_updated')
    if (response.ok) {
      const organization = response.data
      this.updateOrganization(organization)
    }
  }

  async getOrganizations() {
    this.updateLoading(true)
    const response = await Api.getOrganizations()
    this.updateLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, true, false)
    if (response.ok) {
      const organizations = response.data
      this.updateOrganizations(organizations)
    }
  }

  async getOrganization(id) {
    this.updateLoading(true)
    const response = await Api.getOrganization(id)
    this.updateLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, true, false)
    if (response.ok) {
      const organization = response.data
      this.updateOrganization(organization)
    }
  }

  async createOrganization(data) {
    this.createOrganizationSuccess = false
    this.updateLoading(true)
    const response = await Api.createOrganization(data)
    this.updateLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'organization_created')

    if (response.ok) {
      this.updateCreateOrganizationSuccess(true)
      // TODO: Redirect?
    }
  }

  async updateOrganizationData(id, data) {
    this.updateLoading(true)
    const response = await Api.updateOrganization(id, data)
    this.updateLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'info_updated')

    if (response.ok) {
      const organization = response.data
      this.updateOrganization(organization)
    }
  }

  async updateOrganizationLogo(id, data) {
    this.updateLoading(true)
    const response = await Api.updateOrganizationLogo(id, data)
    this.updateLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'info_updated')
    if (response.ok) {
      const organization = response.data
      this.updateOrganization(organization)
    }
  }

  async deactivateOrganization(id) {
    this.updateLoading(true)
    this.updateLoading(false)
  }

  async inviteAdminUser(organizationId, data) {
    this.updateLoading(true)
    const response = await Api.adminInviteUser(organizationId, data)
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'invite_sent')
    this.updateLoading(false)
  }
}
