import React from 'react'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import { Button, Input } from '..'
import AddIcon from '../../Assets/Icons/add-dark.svg'
import DeleteIcon from '../../Assets/Icons/delete.svg'
import { IconButton, Tooltip } from '@mui/material'
import { Colors } from '../../Utils/theme'


const useStyles = makeStyles((theme) => ({
  container: {
    paddingBottom: '1.5rem'
  },
  list: {
  },
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  actionIcon: {

  },
  actionButtons: {
    paddingLeft: '1rem',
    marginTop: '2.5rem'
  },
  actionButton: {
    backgroundColor: Colors.white,
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.black20}`,
    height: '2rem',
    width: '2rem',
    maxHeight: '2rem',
    maxWidth: '2rem'
  },
  spacer: {
    width: '2rem',
    minWidth: '2rem'
  }
}))

export default function ServiceContactInfoRepeater(props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const addItem = () => {
    const newValue = [...(props.value || []), {
      name: null,
      value: null
    }]
    props.onChange(newValue)
  }

  const handleDelete = (index) => {
    const newValue = props.value.filter((item, idx) => {
      return idx !== index
    })
    props.onChange(newValue)
  }

  const setName = (value, index) => {
    const newValue = [...props.value]
    newValue[index].name = value
    props.onChange(newValue)
  }

  const setValue = (value, index) => {
    const newValue = [...props.value]
    newValue[index].value = value
    props.onChange(newValue)
  }

  const renderItem = (item, index) => {
    const valueLabel = props.type === 'www' ? t('url') : t('phone_number')
    return (
      <div className={classes.row}>
        <Input
          label={t('service')}
          value={item.name}
          onChange={(value) => setName(value, index)}
        />
        <div className={classes.spacer} />
        <Input
          label={valueLabel}
          value={item.value}
          onChange={(value) => setValue(value, index)}
        />
        <div className={classes.actionButtons}>
          <Tooltip title={t('delete')}>
            <IconButton
              onClick={() => handleDelete(index)}
              className={classes.actionButton}
              size='large'
            >
              <img src={DeleteIcon} alt='icon' className={classes.actionIcon} />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    )
  }


  const getItems = () => {
    return (props.value || []).map((item, index) => ({ ...item, index }))
  }

  return (
    <div className={classes.container}>
      <div className={classes.list}>
        {getItems().map((item) => {
          return (
            <React.Fragment key={item.index}>
              {renderItem(item, item.index)}
            </React.Fragment>
          )
        })}
      </div>
      <Button
        text={props?.type === 'phone' ? t('add_new_service_number') : t('add_new_url')}
        onClick={addItem}
        rightIcon={AddIcon}
        outlined
      />
    </div>
  )

}
