import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { AppLayout } from '../../Layouts'
import Dashboard from './Dashboard/Dashboard'
import Entities from './Entities/Entities'
import EntityTypes from './Entities/EntityTypes'
import Entity from './Entities/Entity'
import EntityVersionHistory from './Entities/EntityVersionHistory'
import Events from './Events/Events'
import Users from './Users/Users'
import User from './Users/User'
import InviteUser from './Users/InviteUser'
import Organizations from './Organizations/Organizations'
import Organization from './Organizations/Organization'
import CreateOrganization from './Organizations/CreateOrganization'
import Settings from './Settings/Settings'
import EmailChange from './EmailChange/EmailChange'
import Feedbacks from './Feedback/Feedbacks'
import Feedback from './Feedback/Feedback'
import Polls from './Poll/Polls'
import Poll from './Poll/Poll'
import WikiEntityTypes from './Wiki/WikiEntityTypes'
import WikiEntities from './Wiki/WikiEntities'
import Wiki from './Wiki/Wiki'
import CreatePoll from './Poll/CreatePoll'
import AdminMainEntityTypes from './Admin/AdminMainEntityTypes'
import AdminMainEntityType from './Admin/AdminMainEntityType'
import AdminMainEntityField from './Admin/AdminMainEntityField'
import AdminCreateMainEntityField from './Admin/AdminCreateMainEntityField'
import AdminCreateMainEntityType from './Admin/AdminCreateMainEntityType'
import AdminSubEntityTypes from './Admin/AdminSubEntityTypes'
import AdminSubEntityType from './Admin/AdminSubEntityType'
import AdminSubEntityField from './Admin/AdminSubEntityField'
import AdminCreateSubEntityType from './Admin/AdminCreateSubEntityType'
import AdminCreateSubEntityField from './Admin/AdminCreateSubEntityField'
import { Role } from '../../Constants'

export default function Authorized(props) {
  const isSuperAdmin = props.user && props.user.role === Role.SuperAdmin
  const isBoss = props.user && props.user.role === Role.Boss
  return (
    <AppLayout>
      <Routes>
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/entity-types' element={<EntityTypes />} />
        <Route path='/entities' element={<Entities />} />
        <Route path='/entity/:entityId/version' element={<EntityVersionHistory />} />
        <Route path='/entity/:entityId' element={<Entity />} />
        <Route path='/events' element={<Events />} />
        <Route path='/feedbacks' element={<Feedbacks />} />
        <Route path='/feedback/:feedbackId' element={<Feedback />} />
        <Route path='/polls' element={<Polls />} />
        <Route path='/polls/create' element={<CreatePoll />} />
        <Route path='/poll/:pollId' element={<Poll />} />
        <Route path='/wiki' element={<WikiEntityTypes />} />
        <Route path='/wiki/type/:mainEntityType' element={<WikiEntities />} />
        <Route path='/wiki/entity/:mainEntityId' element={<Wiki />} />
        <Route path='/wiki/:mainEntityType' element={<Wiki />} />
        <>
          {(!isBoss ? null : (
            <>
              <Route
                path='/users'
                element={<Users />}
              />
              <Route
                path='/users/invite'
                element={<InviteUser />}
              />
              <Route
                path='/users/:userId'
                element={<User />}
              />
            </>
          ))}
        </>
        <>
          {(!isSuperAdmin ? null : (
            <>
              <Route
                path='/organizations'
                element={<Organizations />}
              />
              <Route
                path='/organizations/create'
                element={<CreateOrganization />}
              />
              <Route
                path='/organizations/:organizationId'
                element={<Organization />}
              />
              <Route
                path='/admin/main-entity-types'
                element={<AdminMainEntityTypes />}
              />
              <Route
                path='/admin/main-entity-types/create'
                element={<AdminCreateMainEntityType />}
              />
              <Route
                path='/admin/main-entity-types/:mainEntityTypeId'
                element={<AdminMainEntityType />}
              />
              <Route
                path='/admin/main-entity-fields/create'
                element={<AdminCreateMainEntityField />}
              />
              <Route
                path='/admin/main-entity-fields/:mainEntityFieldId'
                element={<AdminMainEntityField />}
              />
              <Route
                path='/admin/sub-entity-types'
                element={<AdminSubEntityTypes />}
              />
              <Route
                path='/admin/sub-entity-types/create'
                element={<AdminCreateSubEntityType />}
              />
              <Route
                path='/admin/sub-entity-types/:subEntityTypeId'
                element={<AdminSubEntityType />}
              />
              <Route
                path='/admin/sub-entity-fields/create'
                element={<AdminCreateSubEntityField />}
              />
              <Route
                path='/admin/sub-entity-fields/:subEntityFieldId'
                element={<AdminSubEntityField />}
              />
            </>
          ))}
        </>
        {/* <Route path='/analytics' element={<Analytics />} /> */}
        <Route path='/settings' element={<Settings />} />
        <Route path='/email-change' element={<EmailChange />} />
        <Route path='*' element={<Navigate replace to={isSuperAdmin ? '/organizations' : '/dashboard'} />} />
      </Routes>
    </AppLayout>
  )
}
