import { createContext, useContext } from 'react'
import AppStore from './AppStore'
import SessionStore from './SessionStore'
import UserStore from './UserStore'
import OrganizationStore from './OrganizationStore'
import LogStore from './LogStore'
import AnalyticsStore from './AnalyticsStore'
import EntityStore from './EntityStore'
import FeedbackStore from './FeedbackStore'
import InfoPageStore from './InfoPageStore'
import PollStore from './PollStore'
import AdminStore from './AdminStore'
import FileStore from './FileStore'
import MainEntityNotificationStore from './MainEntityNotificationStore'

class RootStore {
  appStore
  sessionStore
  userStore
  organizationStore
  logStore
  analyticsStore
  entityStore
  feedbackStore
  infoPageStore
  pollStore
  adminStore
  fileStore
  mainEntityNotificationStore

  constructor() {
    this.appStore = new AppStore(this)
    this.sessionStore = new SessionStore(this)
    this.userStore = new UserStore(this)
    this.organizationStore = new OrganizationStore(this)
    this.logStore = new LogStore(this)
    this.analyticsStore = new AnalyticsStore(this)
    this.entityStore = new EntityStore(this)
    this.feedbackStore = new FeedbackStore(this)
    this.infoPageStore = new InfoPageStore(this)
    this.pollStore = new PollStore(this)
    this.adminStore = new AdminStore(this)
    this.fileStore = new FileStore(this)
    this.mainEntityNotificationStore = new MainEntityNotificationStore(this)
  }
}

export const createStore = () => {
  const rootStore = new RootStore()
  return rootStore
}

export const StoreContext = createContext({})
export const StoreProvider = StoreContext.Provider

export const useStore = () => useContext(StoreContext)
