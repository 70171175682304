import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import makeStyles from '@mui/styles/makeStyles'
import Modal from '@mui/material/Modal'
import { Input, Button, Select } from '..'
import { ConditionOptions } from '../../Constants'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  modal: {
    // zIndex: 99999
  },
  contentContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: Colors.white,
    minWidth: '40rem',
    borderRadius: 3
  },
  content: {
    padding: '2rem 3rem',
    paddingBottom: '1rem'
  },
  title: {
    fontFamily: 'Rubik',
    fontWeight: 600,
    color: Colors.black,
    fontSize: '1.675rem',
    marginBottom: '1.25rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  dateContainer: {
    marginRight: '1.5rem'
  },
  timeContainer: {
  },
  label: {
    fontSize: '1rem',
    fontWeight: 500,
    marginBottom: '0.5rem',
    color: Colors.label
  },
  footerContainer: {
    height: '5.5rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0rem 3rem',
    borderTop: `1px solid ${Colors.black20}`
  },
  saveButton: {
    width: '9rem',
    height: '3.125rem'
  },
  cancelButton: {
    width: '7rem',
    height: '3.125rem',
    border: 'none',
    margin: '0rem 0.75rem',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: Colors.violet10
    }
  }
}))

export default function MaintenanceLogModal(props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const { log, subentities, handleSave, handleClose } = props

  const [date, setDate] = useState(log ? moment(log?.publishedAt).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'))
  const [time, setTime] = useState(log ? moment(log?.publishedAt).format('HH:mm') : moment().format('HH:mm'))
  const [subentity, setSubentity] = useState(log?.subEntityId ?? null)
  const [condition, setCondition] = useState(log?.status ?? null)
  const [description, setDescription] = useState(log?.content ?? null)

  const getTitle = () => {
    if (log) {
      return t('edit_maintenance_log')
    }
    return t('add_maintenance_log')
  }

  const save = () => {
    const body: any = { type: 'maintenance_log' }
    if (log?.mainEntityId) body.mainEntityId = log.mainEntityId
    if (subentity) body.subEntityId = subentity
    if (condition) body.status = condition
    if (description) body.content = description
    if (date && time) {
      body.publishedAt = moment(`${date} ${time}`)
    }

    if (log) {
      handleSave(body, log?.id)
    } else {
      handleSave(body)
    }
  }

  const renderSubentitySelect = () => {
    const options = subentities
      .filter((item) => item?.type !== 'geofence')
      .map((item) => ({ label: item?.name, value: item?.id }))

    if (options?.length) {
      return (
        <>
          <div className={classes.label}>{t('subentity')}</div>
          <Select options={options} value={subentity} onChange={setSubentity} />
        </>
      )
    }
    return null
  }

  return (
    <Modal onClose={handleClose} classes={{ root: classes.modal }} open>
      <div className={classes.contentContainer}>
        <div className={classes.content}>
          <div className={classes.title}>{getTitle()}</div>
          <div className={classes.row}>
            <div className={classes.dateContainer}>
              <div className={classes.label}>{t('date')}</div>
              <Input value={date} onChange={setDate} type='date' />
            </div>
            <div className={classes.timeContainer}>
              <div className={classes.label}>{t('time')}</div>
              <Input value={time} onChange={setTime} type='time' />
            </div>
          </div>
          {renderSubentitySelect()}
          <div className={classes.label}>{t('condition')}</div>
          <Select options={ConditionOptions} value={condition} onChange={setCondition} />
          <div className={classes.label}>{t('description')}</div>
          <Input value={description} onChange={setDescription} multiline />
        </div>
        <div className={classes.footerContainer}>
          <Button
            onClick={handleClose}
            text={t('cancel')}
            buttonStyle={classes.cancelButton}
            outlined
          />
          <Button
            onClick={save}
            text={t('save')}
            disabled={!condition && !description}
            buttonStyle={classes.saveButton}
          />
        </div>
      </div>
    </Modal>
  )
}