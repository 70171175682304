import React, { useEffect, useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Colors } from '../../Utils/theme'
import { HtmlInput, Input } from '..'
import OrderUp from '../../Assets/Icons/order-up.svg'
import OrderDown from '../../Assets/Icons/order-down.svg'
import DeleteOutlined from '../../Assets/Icons/delete.svg'
import IconLock from '../../Assets/Icons/lock-closed-dark.svg'
import IconLockOpen from '../../Assets/Icons/lock-open-dark.svg'
import { useTranslation } from 'react-i18next'
import { EditorState, ContentState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import linkifyHtml from 'linkify-html'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '2rem',
    border: '1px solid rgba(0,0,0,.5)',
    borderRadius: '.3125rem',
    padding: '0 1.7875rem 1rem',
    position: 'relative'
  },
  title: {
    margin: '0 auto',
    width: '100%',
    maxWidth: '51rem',
    fontFamily: 'Rubik',
    fontSize: '1.875rem',
    color: Colors.black,
    lineHeight: '1.1',
    paddingBottom: '.28rem',
    borderBottom: '2px solid #ccc'
  },
  addButtonContainer: {
    width: '9rem',
    margin: '1rem 0 3rem'
  },
  label: {
    fontSize: '1rem',
    fontWeight: 500,
    paddingBottom: '.5rem',
    color: Colors.label
  },
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  dash: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 .66rem 1.5rem'
  },
  actionButtons: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: '1.25rem 1.6875rem',
    display: 'flex',
    flexDirection: 'row'
  },
  actionButtonIcon: {
    maxHeight: '1rem',
    maxWidth: '1rem'
  },
  actionButton: {
    margin: 0,
    padding: '.5rem 0',
    borderRadius: 5,
    width: '2.25rem',
    height: '2.25rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '.75rem',
    cursor: 'pointer',
    background: Colors.backgroundGrey
  },
  deleteButton: {
    boxSizing: 'border-box',
    borderRadius: '.25rem',
    marginLeft: '1rem',
    marginTop: '2.25rem',
    padding: 0,
    height: '3rem',
    width: '3rem',
    backgroundColor: Colors.red,
    '&:hover': {
      backgroundColor: Colors.red
    }
  },
  deleteIcon: {
    height: '1.5rem',
    width: 'auto',
    maxHeight: '1.5rem'
  },
  dateInput: {
  }
}))

export default function WikiBox(props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const [titleFi, setTitleFi] = useState(null)
  const [titleEn, setTitleEn] = useState(null)
  const [htmlFi, setHtmlFi] = useState('')
  const [htmlEn, setHtmlEn] = useState('')
  const [validFrom, setValidFrom] = useState(null)
  const [validUntil, setValidUntil] = useState(null)

  const initialize = () => {
    setTitleFi(props.item.titleFi)
    setTitleEn(props.item.titleEn)
    setHtmlFi(props.item.htmlFi || '')
    setHtmlEn(props.item.htmlEn || '')

    if (props.item.validFrom) {
      setValidFrom(props.item.validFrom)
    }
    if (props.item.validUntil) {
      setValidUntil(props.item.validUntil)
    }
  }

  const commitChanges = () => {
    const newHtmlFi = htmlFi ? linkifyHtml(htmlFi) : null
    const newHtmlEn = htmlEn ? linkifyHtml(htmlEn) : null

    props.updateItem({
      titleFi,
      titleEn,
      htmlFi: newHtmlFi,
      htmlEn: newHtmlEn,
      validFrom,
      validUntil
    })
  }

  useEffect(() => {
    initialize()
  }, [])


  const getTitle = () => {
    if (props.lang === 'fi') {
      return titleFi
    }
    if (props.lang === 'en') {
      return titleEn
    }
  }

  const getHtml = () => {
    if (props.lang === 'fi') {
      return htmlFi
    }
    if (props.lang === 'en') {
      return htmlEn
    }
  }

  const setTitle = (value) => {
    if (props.lang === 'fi') {
      setTitleFi(value)
    }
    if (props.lang === 'en') {
      setTitleEn(value)
    }

    commitChanges()

  }

  const setHtml = (value) => {
    if (props.lang === 'fi') {
      setHtmlFi(value)
    }
    if (props.lang === 'en') {
      setHtmlEn(value)
    }

    commitChanges()
  }

  const handleSetValidFrom = (value) => {
    setValidFrom(value)
    commitChanges()
  }

  const handleSetValidUntil = (value) => {
    setValidUntil(value)
    commitChanges()
  }

  const renderValidityRange = () => {
    if (!props.useValidityRange) return null

    return (
      <>
        <div className={classes.label}>{t('visible_between')}</div>
        <div className={classes.row}>
          <div className={classes.dateInput}>
            <Input
              value={validFrom}
              onChange={handleSetValidFrom}
              type='date'
            />
          </div>
          <div className={classes.dash}>—</div>
          <div className={classes.dateInput}>
            <Input
              value={validUntil}
              onChange={handleSetValidUntil}
              type='date'
            />
          </div>
        </div>
      </>
    )
  }

  const renderArrangeButtons = () => {
    const { index, totalItems } = props
    return (
      <>
        {(index > 0) ? (
          <div
            onClick={props.moveUp}
            className={classes.actionButton}
          >
            <img
              src={OrderUp}
              alt='up'
              className={classes.actionButtonIcon}
            />
          </div>
        ) : null}
        {(index < (totalItems - 1)) ? (
          <div
            onClick={props.moveDown}
            className={classes.actionButton}
          >
            <img
              src={OrderDown}
              alt='down'
              className={classes.actionButtonIcon}
            />
          </div>
        ) : null}
      </>
    )
  }

  const renderDeleteButton = () => {
    return (
      <div
        onClick={props.delete}
        className={classes.actionButton}
      >
        <img
          src={DeleteOutlined}
          alt='delete'
          className={classes.actionButtonIcon}
        />
      </div>
    )
  }

  const renderLockButton = () => {
    const icon = props.locked ? IconLock : IconLockOpen
    return (
      <div
        onClick={props.toggleLock}
        className={classes.actionButton}
      >
        <img
          src={icon}
          alt='lock'
          className={classes.actionButtonIcon}
        />
      </div>
    )
  }

  const renderToolbar = () => {
    return (
      <div className={classes.actionButtons}>
        {renderArrangeButtons()}
        {renderLockButton()}
        {renderDeleteButton()}
      </div>
    )
  }

  const renderContent = () => {
    if (props.locked) return null
    return (
      <>
        <div className={classes.label}>{t('info_text')}</div>
        <HtmlInput
          key={props.lang}
          placeholder={t('description')}
          value={getHtml()}
          onChange={setHtml}
        />
        {renderValidityRange()}
      </>
    )
  }

  return (
    <div className={classes.root}>
      {renderToolbar()}
      <h2>{props.title}</h2>
      <div className={classes.label}>{t('title')}</div>
      <Input
        value={getTitle()}
        onChange={setTitle}
      />
      {renderContent()}
    </div>
  )
}
