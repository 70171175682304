import React, { useState } from 'react'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import makeStyles from '@mui/styles/makeStyles'
import { Colors } from '../../Utils/theme'
import { Link } from 'react-router-dom'
import { IconButton, Tooltip } from '@mui/material'
import DeleteIcon from '../../Assets/Icons/delete.svg'
import RestoreIcon from '../../Assets/Icons/restore.svg'
import { useTranslation } from 'react-i18next'
import { Dialog } from '..'
import { formatDateTime } from '../../Utils/dateTime'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: Colors.backgroundGrey,
    borderBottom: 'none',
    '&:hover': {
      backgroundColor: `${Colors.tableHover} !important`
    }
  },
  firstCell: {
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: 600,
    height: '3rem',
    color: Colors.black,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    borderBottom: 'none'
  },
  lastCell: {
    height: '3rem',
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    borderBottom: 'none',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  cell: {
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: 400,
    height: '3rem',
    color: Colors.black,
    borderBottom: 'none'
  },
  link: {
    display: 'block',
    textDecoration: 'none',
    padding: '.5rem 0',
    color: Colors.black,
    '&:hover': {
      opacity: 0.8
    }
  },
  deleteButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.white,
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.black20}`,
    height: '2rem',
    width: '2rem',
    //paddingLeft: '0.825rem',
    '&:hover': {
      backgroundColor: `${Colors.white} !important`
    }
  },
  restoreButton: {
    backgroundColor: Colors.white,
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.black20}`,
    height: '2rem',
    width: '2rem',
  },
  actionIcon: {
    height: 'auto',
    width: '1rem',
    maxHeight: '1rem',
    maxWidth: '1rem'
  },
}))

function EntityTableRow (props: any) {
  const { entity } = props
  const classes = useStyles()

  const { t } = useTranslation()

  const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false)
  const [confirmRestoreVisible, setConfirmRestoreVisible] = useState(false)

  const handleDelete = () => {
    if (confirmDeleteVisible) {
      setConfirmDeleteVisible(false)
      props.onDelete(entity.id)
    } else {
      setConfirmDeleteVisible(true)
    }
  }

  const handleRestore = () => {
    if (confirmRestoreVisible) {
      setConfirmRestoreVisible(false)
      props.onRestore(entity.id)
    } else {
      setConfirmRestoreVisible(true)
    }
  }

  const closeConfirms = () => {
    setConfirmDeleteVisible(false)
    setConfirmRestoreVisible(false)
  }

  const renderActions = () => {
    if (entity.active) {
      // Delete button
      return (
        <>
          <Tooltip title={t('delete')}>
            <IconButton onClick={handleDelete} className={classes.deleteButton} size='large'>
              <img src={DeleteIcon} alt='icon' className={classes.actionIcon} />
            </IconButton>
          </Tooltip>
          <Dialog
            open={confirmDeleteVisible}
            handleClose={closeConfirms}
            handleOk={handleDelete}
            title={t('delete_entity')}
            description={t('delete_entity_description')}
          />
        </>
      )
    } else {
      // Restore button
      return (
        <>
          <Tooltip title={t('restore')}>
            <IconButton onClick={handleRestore} className={classes.restoreButton} size='large'>
              <img src={RestoreIcon} alt='icon' className={classes.actionIcon} />
            </IconButton>
          </Tooltip>
          <Dialog
            open={confirmRestoreVisible}
            handleClose={closeConfirms}
            handleOk={handleRestore}
            title={t('restore_entity')}
            description={t('restore_entity_description')}
          />
        </>
      )
    }
  }

  return (
    <TableRow classes={{ root: classes.root }} hover>
      <TableCell className={classes.firstCell} size='small'>
        <Link to={`/entity/${entity.id}`} className={classes.link}>
          {entity.name}
        </Link>
      </TableCell>
      <TableCell className={classes.cell} size='small'>
        {entity.mainEntityType.name}
      </TableCell>
      <TableCell className={classes.cell} size='small'>
        {entity.snapshotCreatedAt ? formatDateTime(entity.snapshotCreatedAt) : '-'}
      </TableCell>
      <TableCell className={classes.lastCell} size='small'>
        {renderActions()}
      </TableCell>
    </TableRow>
  )
}

export default EntityTableRow
