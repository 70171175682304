import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import get from 'lodash/get'
import chunk from 'lodash/chunk'
import cloneDeep from 'lodash/cloneDeep'
import { Input, Button } from '..'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  timeRangeButtonsRow: {
    display: 'flex',
    marginTop: '1.75rem',
    marginBottom: '1.5rem'
  },
  weekdaysButton: {
    maxWidth: '7.5rem',
    height: '3.125rem',
    border: `1px solid ${Colors.violet}`,
    marginRight: '1.5rem',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: Colors.violet10
    }
  },
  weekdaysAndWeekendButton: {
    maxWidth: '6rem',
    height: '3.125rem',
    border: `1px solid ${Colors.violet}`,
    marginRight: '1.5rem',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: Colors.violet10
    }
  },
  dailyButton: {
    maxWidth: '9.5rem',
    height: '3.125rem',
    border: `1px solid ${Colors.violet}`,
    marginRight: '1.5rem',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: Colors.violet10
    }
  },
  timeRangeActiveButton: {
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: Colors.black80
    }
  },
  timeRangeButtonText: {
    fontSize: '1rem',
    fontWeight: 400
  },
  timeRangesContainer: {
    maxWidth: '48rem',
    padding: '1.5rem 1.5rem 0.5rem 1.5rem',
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.border}`,
    marginBottom: '2.875rem',
    [theme.breakpoints.down('md')]: {
      maxWidth: '24.5rem'
    }
  },
  timeRangesWeekdaysContainer: {
    maxWidth: '26.25rem',
    padding: '1.5rem 1.5rem 0.5rem 1.5rem',
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.border}`,
    marginBottom: '2.875rem'
  },
  timeRangesWeekdaysAndWeekendContainer: {
    maxWidth: '27.75rem',
    padding: '1.5rem 1.5rem 0.5rem 1.5rem',
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.border}`,
    marginBottom: '2.875rem'
  },
  timeRangesTitle: {
    fontSize: '1rem',
    fontWeight: 500,
    marginBottom: '0.5rem',
    color: Colors.label
  },
  dayChunkContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      display: 'block'
    }
  },
  dayChunkBlock: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingRight: '2.5rem',
    [theme.breakpoints.down('md')]: {
      paddingRight: 0
    }
  },
  dayRow: {
    height: '3.125rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '1rem'
  },
  dayName: {
    display: 'flex',
    fontWeight: 700,
    alignItems: 'center',
    fontSize: '1rem',
    width: '1.25rem'
  },
  semiLongDayName: {
    width: '3.375rem'
  },
  longDayName: {
    width: '4.75rem'
  },
  timeInputContainer: {
    maxWidth: '7.5rem'
  },
  daySeparator: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '.75rem',
    marginRight: '.75rem',
    whiteSpace: 'nowrap'
  },
  daySeparatorLine: {
    fontWeight: 700,
    marginBottom: '0.75rem',
    marginRight: '0.5rem'
  }
}))

const TimeRangeTypes = {
  OnWeekdays: 'on_weekdays',
  OnWeekdaysAndWeekend: 'on_weekdays_and_weekend',
  OnDailyBasis: 'on_daily_basis'
}

export default function WeekdayTimeRanges(props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const handleSetWeekdayTime = (type, day, col, value) => {
    const currentVal = props.value.slice(0, 7) ?? props.value
    const isValidCurrentValue = (
      currentVal &&
      Array.isArray(currentVal) &&
      currentVal.length === 7 &&
      !currentVal.find(d => (!Array.isArray(d) || d.length !== 2))
    )
    const currentValue = isValidCurrentValue ? props.value : Array(7).fill(null).map(n => [null, null])
    const newValue = cloneDeep(currentValue)

    if (type && type === TimeRangeTypes.OnWeekdays) {
      newValue[0][col] = value || null
      newValue[1][col] = value || null
      newValue[2][col] = value || null
      newValue[3][col] = value || null
      newValue[4][col] = value || null
    } else if (type && type === TimeRangeTypes.OnWeekdaysAndWeekend) {
      if (day === 1) {
        newValue[5][col] = value || null
      } else if (day === 2) {
        newValue[6][col] = value || null
      } else {
        newValue[0][col] = value || null
        newValue[1][col] = value || null
        newValue[2][col] = value || null
        newValue[3][col] = value || null
        newValue[4][col] = value || null
      }
    } else {
      newValue[day][col] = value || null
    }
    props.onChange(newValue)
  }

  const setWeekdayType = (type, weekdaysAreSame) => {
    if (!props.value) {
      props.onChange([...Array(7).fill(null).map(n => [null, null]), type])
    } else {
      if (type === TimeRangeTypes.OnWeekdays || type === TimeRangeTypes.OnWeekdaysAndWeekend) {
        if (!weekdaysAreSame) {
          return props.onChange([...Array(5).fill(null).map(n => [null, null]), ...props.value.slice(5, 7), type])
        }
      }
      props.onChange([...props.value.slice(0, 7), type])
    }
  }

  const getType = () => {
    const hasType = props.value?.length && props.value.length === 8
    if (hasType) {
      return get(props.value, [props.value.length - 1])
    }
    return null
  }
  const getTime = (day, col) => {
    if (props.value) {
      const type = getType()
      if (type) {
        if (type === TimeRangeTypes.OnWeekdays) {
          return get(props.value, `[0][${col}]`, null)
        } else if (type === TimeRangeTypes.OnWeekdaysAndWeekend) {  
          if (day === 1) return get(props.value, `[5][${col}]`, null)
          if (day === 2) return get(props.value, `[6][${col}]`, null)
          return get(props.value, `[0][${col}]`, null)
        }
      }
      return get(props.value, `[${day}][${col}]`, null)
    }
  }

  const fullWeek = Array(7).fill(null).map((x, index) => index)
  const hasDayValue = (day) => (getTime(day, 0) && getTime(day, 1)) ?? false
  const groupHasSameTime = (dayGroup) => {
    const allHaveValues = dayGroup.every((day) => props.value[day][0] && props.value[day][1])
    if (allHaveValues) {
      const firstValue = `${dayGroup[0][0]}-${dayGroup[0][1]}`
      return dayGroup.slice(1).every((day) => `${dayGroup[day][0]}-${dayGroup[day][1]}` === firstValue)
    }
    return false
  }
  const weekdaysAreSame = props.value?.length && groupHasSameTime(fullWeek.slice(0, 5))
  const hasSaturdayValue = props.value?.length && hasDayValue(5)
  const hasSundayValue = props.value?.length && hasDayValue(6)

  const type = getType()

  // Set type
  if (!type) {
    if (!props.value) {
      setWeekdayType(TimeRangeTypes.OnDailyBasis, weekdaysAreSame)
    } else {
      if (weekdaysAreSame && !hasSaturdayValue && !hasSundayValue) {
        setWeekdayType(TimeRangeTypes.OnWeekdays, weekdaysAreSame)
      } else if (weekdaysAreSame && (hasSaturdayValue || hasSundayValue)) {
        setWeekdayType(TimeRangeTypes.OnWeekdaysAndWeekend, weekdaysAreSame)
      } else {
        setWeekdayType(TimeRangeTypes.OnDailyBasis, weekdaysAreSame)
      }
    }
  }

  let allDays, daysChunks, containerStyle
  if (type === TimeRangeTypes.OnWeekdays) {
    allDays = Array(1).fill(null).map((x, index) => index)
    daysChunks = [allDays]
    containerStyle = classes.timeRangesWeekdaysContainer
  } else if (type === TimeRangeTypes.OnWeekdaysAndWeekend) {
    allDays = Array(3).fill(null).map((x, index) => index)
    daysChunks = [allDays]
    containerStyle = classes.timeRangesWeekdaysAndWeekendContainer
  } else {
    allDays = Array(7).fill(null).map((x, index) => index)
    daysChunks = chunk(allDays, 4)
    containerStyle = classes.timeRangesContainer
  }
  
  return (
    <>
      <div className={classes.timeRangeButtonsRow}>
        <Button
          onClick={() => setWeekdayType(TimeRangeTypes.OnWeekdays, weekdaysAreSame)}
          text={t('only_on_weekdays')}
          buttonStyle={`${classes.weekdaysButton} ${type === TimeRangeTypes.OnWeekdays ? classes.timeRangeActiveButton : ''}`.trim()}
          buttonTextStyle={classes.timeRangeButtonText}
          disabled={props.disabled}
          outlined={type !== TimeRangeTypes.OnWeekdays}
        />
        <Button
          onClick={() => setWeekdayType(TimeRangeTypes.OnWeekdaysAndWeekend, weekdaysAreSame)}
          text={t('on_weekdays_and_weekend')}
          buttonStyle={`${classes.weekdaysAndWeekendButton} ${type === TimeRangeTypes.OnWeekdaysAndWeekend ? classes.timeRangeActiveButton : ''}`.trim()}
          buttonTextStyle={classes.timeRangeButtonText}
          disabled={props.disabled}
          outlined={type !== TimeRangeTypes.OnWeekdaysAndWeekend}
        />
        <Button
          onClick={() => setWeekdayType(TimeRangeTypes.OnDailyBasis, weekdaysAreSame)}
          text={t('on_daily_basis')}
          buttonStyle={`${classes.dailyButton} ${type === TimeRangeTypes.OnDailyBasis ? classes.timeRangeActiveButton : ''}`.trim()}
          buttonTextStyle={classes.timeRangeButtonText}
          disabled={props.disabled}
          outlined={type !== TimeRangeTypes.OnDailyBasis}
        />
      </div>
      <div className={containerStyle}>
        <div className={classes.dayChunkContainer}>
          {daysChunks.map((days, index) => {
            return (
              <div key={index} className={index === 0 ? classes.dayChunkBlock : ''}>
                {days.map(day => {
                  return (
                    <div key={day} className={classes.dayRow}>
                      <div className={`${classes.dayName} ${days.length === 1 ? classes.semiLongDayName : daysChunks.length === 1 ? classes.longDayName : ''}`.trim()}>
                        {
                          days.length === 1 ?
                          t('on_weekdays') :
                          daysChunks.length === 1 && days.length === 3 ?
                          (day === 0 ? t('on_weekdays') : day === 1 ? t('saturday') : t('sunday')) :
                          t(`day_short_${day + 1}`)
                        }
                      </div>
                      {[0, 1].map(col => {
                        return (
                          <React.Fragment key={col}>
                            {!col && <div className={classes.daySeparator}>{t('time_prefix')}</div>}
                            <div className={classes.timeInputContainer}>
                              <Input
                                value={getTime(day, col)}
                                onChange={(val) => handleSetWeekdayTime(type, day, col, val)}
                                disabled={props.disabled}
                                type='time'
                                noMargin
                              />
                            </div>
                            {!col && (
                              <div className={classes.daySeparator}>
                                <span className={classes.daySeparatorLine}>_</span> {t('time_prefix')}
                              </div>
                            )}
                          </React.Fragment>
                        )
                      })}
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}
