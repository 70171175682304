import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import makeStyles from '@mui/styles/makeStyles'
import DataTableRow from './DataTableRow'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '-0.5rem'
  },
  spacer: {
    height: '1rem'
  },
  outerContainer: {
    borderBottom: 'none',
    marginBottom: '1rem'
  },
  container: {
    borderBottom: 'none'
  },
  cell: {
    height: 50,
    padding: '0 1rem',
    color: Colors.black,
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: 700
  },
  firstCell: {
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5
  },
  lastCell: {
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5
  }
}))

function DataTable (props) {
  const classes = useStyles()

  const renderRows = () => {
    if (props.data) {
      return props.data.map((columns, index) => <DataTableRow key={index} data={columns} odd={index % 2 !== 0} />)
    }
    return null
  }

  const renderHeader = () => {
    return (
      <TableHead className={classes.outerContainer}>
        <TableRow className={classes.container}>
          {props.columnNames.map((columnName, index) => {
            let className = classes.cell
            if (index === 0) className = `${classes.cell} ${classes.firstCell}`
            else if (index === props.columnNames.length - 1) className = `${classes.cell} ${classes.lastCell}`

            return <TableCell key={columnName} className={className}>{columnName}</TableCell>
          })}
        </TableRow>
      </TableHead>
    )
  }

  return (
    <Table classes={{ root: classes.root }}>
      {renderHeader()}
      <TableBody>{renderRows()}</TableBody>
    </Table>
  )

}

export default DataTable
