import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'
import get from 'lodash/get'
import uniq from 'lodash/uniq'
import orderBy from 'lodash/orderBy'
import flatten from 'lodash/flatten'
import { useParams } from 'react-router-dom'
import { useStore } from '../../../Models/RootStore'
import { PageHeader, Title, Button, ImageUpload, Checkbox, Input, Select, Dialog } from '../../../Components'
import { Colors } from '../../../Utils/theme'
import { MainEntityCategoryTypeMappings, ROLES } from '../../../Constants'
import { formatDateTime } from '../../../Utils/dateTime'
import { IconButton } from '@mui/material'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'hidden',
    height: '100%'
  },
  contentContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row'
  },
  content: {
    position: 'relative',
    flex: 1,
    flexDirection: 'column',
    flexGrow: 1
  },
  contentInnerContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    padding: '0.5rem 2.5rem 10rem',
    overflowY: 'auto'
  },
  contentInner: {
    maxWidth: '60rem'
  },
  actions: {
    borderLeft: `1px solid ${Colors.black20}`,
    width: '24rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '2.5rem'
  },
  text: {
    margin: 0,
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: 300,
    color: Colors.black,
    marginBottom: '1.5rem',
    [theme.breakpoints.down('xl')]: {
      fontSize: '1rem'
    }
  },
  boldText: {
    fontWeight: 700
  },
  line: {
    height: 1,
    width: '100%',
    backgroundColor: Colors.black20,
    marginBottom: '2rem'
  },
  profileContainer: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column'
    }
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginLeft: '3rem',
    [theme.breakpoints.down('lg')]: {
      marginLeft: 0
    }
  },
  infoContainer: {
  },
  accountContainer: {
    maxWidth: '20rem'
  },
  bigLabel: {
    fontSize: '1.25rem',
    fontFamily: 'Rubik'
  },
  mediumContent: {
    maxWidth: '40rem'
  },
  imageContainer: {
    marginTop: '0.375rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  rowSpacer: {
    minWidth: '2rem'
  },
  firstName: {
    minWidth: '12rem'
  },
  lastName: {
    flex: 1,
    minWidth: '18rem',
    maxWidth: '26rem'
  },
  email: {
    flex: 1,
    maxWidth: '30rem'
  },
  phone: {
    flex: 1,
    maxWidth: '18rem'
  },
  jobTitle: {
    flex: 1,
    maxWidth: '24rem'
  },
  description: {
    flex: 1,
    maxWidth: '44rem'
  },
  subtitleRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  subtitle: {
    fontFamily: 'Rubik',
    fontWeight: 600,
    fontSize: '1.125rem',
    color: Colors.violet
  },
  entityTypesContainer: {
    paddingTop: '0.375rem',
    paddingBottom: '1.125rem',
    paddingLeft: '2.125rem'
  },
  entityTypesRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingTop: '0.375rem',
    paddingBottom: '1.125rem',
    paddingLeft: '2.125rem'
  },
  categoryName: {
  },
  mainEntitiesContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingLeft: '2.125rem',
    paddingTop: '0.125rem',
    paddingBottom: '0.5rem'
  },
  mainEntityContainer: {
  },
  checkboxRoot: {
    marginRight: 0
  },
  largeCheckbox: {
    height: '1.375rem',
    width: '1.375rem',
    marginRight: '0.25rem'
  },
  largeCheck: {
    height: '0.825rem',
    maxHeight: '0.825rem',
    maxWidth: '0.825rem'
  },
  checkbox: {
    height: '1.25rem',
    width: '1.25rem',
    marginRight: '0.25rem'
  },
  check: {
    height: '0.75rem',
    maxHeight: '0.75rem',
    maxWidth: '0.75rem'
  },
  smallCheckbox: {
    height: '1.125rem',
    width: '1.125rem',
    marginRight: '0.125rem'
  },
  smallCheck: {
    height: '0.675rem',
    maxHeight: '0.675rem',
    maxWidth: '0.675rem'
  },
  label: {
    fontSize: '1rem',
    fontWeight: 500,
    marginRight: '0.25rem'
  },
  rowLabel: {
    fontSize: '1rem',
    fontWeight: 500,
    marginRight: '2rem'
  },
  smallLabel: {
    fontSize: '0.938rem',
    marginRight: '2rem'
  },
  toggleButton: {
    height: '2rem',
    width: '2rem'
  }
}))

const TABS = {
  user: 0,
  account: 1
}

const User = () => {
  const classes = useStyles()
  const { userStore, entityStore, sessionStore }: any = useStore()
  const { userId }: any = useParams()

  useEffect(() => {
    userStore.getUser(userId)
    entityStore.getMainEntities()
    entityStore.getMainEntityTypes()
  }, [])

  useEffect(() => {
    if (userStore.user) {
      setPhoto(get(userStore.user, 'photo', null))
      setFirstName(get(userStore.user, 'firstName'))
      setLastName(get(userStore.user, 'lastName'))
      setEmail(get(userStore.user, 'email'))
      setPhone(get(userStore.user, 'phone'))
      setTitle(get(userStore.user, 'title'))
      setDescription(get(userStore.user, 'description'))
      setRole(get(userStore.user, 'role'))
      setAccessRights(get(userStore.user, 'accessRights', []).map(ar => ar.mainEntityId).filter(val => !!val))
      setMainEntityTypeAccessRights(get(userStore.user, 'accessRights', []).map(ar => ar.mainEntityTypeId).filter(val => !!val))
    }
  }, [userStore.user])

  const [editMode, setEditMode] = useState(false)
  const [alertVisible, setAlertVisible] = useState(false)
  const [tab, setTab] = useState(TABS.user)
  const [photo, setPhoto] = useState(get(userStore.user, 'photo', null))
  const [firstName, setFirstName] = useState(get(userStore.user, 'firstName', ''))
  const [lastName, setLastName] = useState(get(userStore.user, 'lastName', ''))
  const [email, setEmail] = useState(get(userStore.user, 'email', ''))
  const [phone, setPhone] = useState(get(userStore.user, 'phone', ''))
  const [title, setTitle] = useState(get(userStore.user, 'title', ''))
  const [description, setDescription] = useState(get(userStore.user, 'description', ''))
  const [role, setRole] = useState(get(userStore.user, 'role', ''))
  const [mainEntityTypeAccessRights, setMainEntityTypeAccessRights] = useState(get(userStore.user, 'accessRights', []).map(ar => ar.mainEntityTypeId).filter(val => !!val))
  const [accessRights, setAccessRights] = useState(get(userStore.user, 'accessRights', []).map(ar => ar.mainEntityId).filter(val => !!val))
  const [expandedTypes, setExpandedTypes] = useState([])

  useEffect(() => {
    if (role) {
      if (role === userStore?.user?.role) {
        setMainEntityTypeAccessRights(get(userStore.user, 'accessRights', []).map(ar => ar.mainEntityTypeId).filter(val => !!val))
        setAccessRights(get(userStore.user, 'accessRights', []).map(ar => ar.mainEntityId).filter(val => !!val))
        setExpandedTypes([])
      } else {
        setMainEntityTypeAccessRights([])
        setAccessRights([])
        setExpandedTypes([])
      }
    }
  }, [role])

  const toggleExpandType = (typeId) => {
    const newExpandedTypes = [...expandedTypes].filter(id => id !== typeId)
    if (newExpandedTypes.length === expandedTypes.length) newExpandedTypes.push(typeId)
    setExpandedTypes(newExpandedTypes)
  }

  const toggleAccessRight = (mainEntityId) => {
    const newAccessRights = [...accessRights].filter(id => id !== mainEntityId)
    if (newAccessRights.length === accessRights.length) newAccessRights.push(mainEntityId)
    setAccessRights(newAccessRights)
  }

  const bulkToggleMainEntityTypeAccessRights = (ids: number[]) => {
    let newMainEntityTypeAccessRights: number[] = uniq([...mainEntityTypeAccessRights, ...ids])
    if (newMainEntityTypeAccessRights.length === mainEntityTypeAccessRights.length) {
      newMainEntityTypeAccessRights = newMainEntityTypeAccessRights.filter(id => !ids.includes(id))
    }
    setMainEntityTypeAccessRights(newMainEntityTypeAccessRights)
  }

  const toggleMainEntityTypeAccessRight = (typeId: number) => {
    const newMainEntityTypeAccessRights = [...mainEntityTypeAccessRights].filter(id => id !== typeId)
    if (newMainEntityTypeAccessRights.length === mainEntityTypeAccessRights.length) {
      newMainEntityTypeAccessRights.push(typeId)
    }
    setMainEntityTypeAccessRights(newMainEntityTypeAccessRights)
  }

  const bulkToggleAccessRights = (mainEntityIds) => {
    let newAccessRights = uniq([...accessRights, ...mainEntityIds])
    if (newAccessRights.length === accessRights.length) {
      newAccessRights = newAccessRights.filter(id => !mainEntityIds.includes(id))
    }
    setAccessRights(newAccessRights)
  }

  const isCategorySelected = (items) => {
    if (role === 'employee') {
      const ids = flatten(
        items.map((item: any) => item.mainEntities.map((entity: any) => entity.id))
      )
      return ids.every((id: number) => accessRights.includes(id))
    } else {
      const ids = items.map((item: any) => item.id)
      return ids.every((id: number) => mainEntityTypeAccessRights.includes(id))
    }
  }

  const isMainEntitySelected = (type) => {
    if (role === 'employee') {
      return !type.mainEntities.find((item) => !accessRights.includes(item.id))
    } else {
      return mainEntityTypeAccessRights.includes(type.id)
    }
  }

  const toggleCategoryTypes = (items) => {
    if (role === 'employee') {
      const ids = flatten(
        items.map((item: any) => item.mainEntities.map((entity: any) => entity.id))
      )
      bulkToggleAccessRights(ids)
    } else {
      const ids = items.map((item: any) => item.id)
      bulkToggleMainEntityTypeAccessRights(ids)
    }
  }

  const toggleMainEntityType = (type: any) => {
    if (role === 'employee') {
      const ids = type?.mainEntities.map((item: any) => item.id)
      bulkToggleAccessRights(ids)
    } else {
      toggleMainEntityTypeAccessRight(type?.id)
    }
  }

  const toggleEditMode = () => setEditMode(!editMode)
  const openAlert = () => setAlertVisible(true)
  const closeAlert = () => setAlertVisible(false)
  const selectTab = (value) => setTab(value)

  const { t } = useTranslation()

  const getTabOptions = () => [
    { value: TABS.user, label: t('user_details'), description: t('lorem') },
    { value: TABS.account, label: t('account_information'), description: t('lorem') }
  ]
  const tabOptions = getTabOptions()

  const getRoles = () => ROLES.map((role, index) => ({ id: index + 1, label: t(role), value: role }))

  const getUpdateText = () => {
    if (tab === TABS.user) return t('update_profile')
    return t('update_account_information')
  }

  const updatePhoto = (files) => {
    if (files && files.length) {
      const file = files[0]
      setPhoto(URL.createObjectURL(file))
      userStore.updateUserPhoto(userId, file)
    }
  }

  const removePhoto = () => {
    setPhoto(null)
    userStore.updateUserData(userId, { photo: null })
  }

  const activateProfile = () => {
    closeAlert()
    userStore.activateUser(userStore.user.id)
  }

  const deleteProfile = () => {
    closeAlert()
    userStore.deactivateUser(userStore.user.id)
  }

  const updateUser = () => {
    userStore.updateUserData(userId, {
      firstName,
      lastName,
      email,
      phone,
      title,
      description,
      role
    })

    // Update access rights
    if (role === 'employee') {
      const oldAccessRights = userStore.user.accessRights.map(ar => ar.mainEntityId).filter(val => !!val)
      const addAccessRightMainEntityIds = accessRights.filter(id => !oldAccessRights.includes(id))
      const removeAccessRightMainEntityIds = oldAccessRights.filter(id => !accessRights.includes(id))

      if (addAccessRightMainEntityIds.length) {
        userStore.addUserAccessRights(userId, addAccessRightMainEntityIds)
      }
      if (removeAccessRightMainEntityIds.length) {
        userStore.removeUserAccessRights(userId, removeAccessRightMainEntityIds)
      }

      // Remove main entity type level access rights
      const mainEntityTypeAccessRightsToRemove = userStore.user.accessRights.map(ar => ar.mainEntityTypeId).filter(val => !!val)
      if (mainEntityTypeAccessRightsToRemove.length) {
        userStore.removeUserMainEntityTypeAccessRights(mainEntityTypeAccessRightsToRemove)
      }
    }
    if (role === 'entity_admin') {
      const oldAccessRights = userStore.user.accessRights.map(ar => ar.mainEntityTypeId).filter(val => !!val)
      const addAccessRightMainEntityTypeIds = mainEntityTypeAccessRights.filter(id => !oldAccessRights.includes(id))
      const removeAccessRightMainEntityTypeIds = oldAccessRights.filter(id => !mainEntityTypeAccessRights.includes(id))

      if (addAccessRightMainEntityTypeIds.length) {
        userStore.addUserMainEntityTypeAccessRights(userId, addAccessRightMainEntityTypeIds)
      }
      if (removeAccessRightMainEntityTypeIds.length) {
        userStore.removeUserMainEntityTypeAccessRights(userId, removeAccessRightMainEntityTypeIds)
      }

      // Remove entity id level access rights
      const accessRightsToRemove = userStore.user.accessRights.map(ar => ar.mainEntityId).filter(val => !!val)
      if (accessRightsToRemove.length) {
        userStore.removeUserAccessRights(userId, accessRightsToRemove)
      }
    }
  }

  const isActive = get(userStore.user, 'active')
  const disabled = !editMode || !isActive

  const getAccessRightTree = () => {
    return Object.keys(MainEntityCategoryTypeMappings).map((key: string) => {
      const types = MainEntityCategoryTypeMappings[key].filter((type: string) => {
        const entityType = entityStore?.mainEntityTypes.find((item) => item?.type === type)
        if (entityType) {
          if (role === 'entity_admin') {
            return true
          }
          return entityStore
            .mainEntities
            .filter((mainEntity: any) => mainEntity.mainEntityTypeId === entityType.id)
            .length
        }
        return false
      })
      if (types.length) {
        return ({
          id: key,
          title: t(key),
          items: orderBy(
            types.map((type: any) => {
              const item = entityStore?.mainEntityTypes.find((item) => item?.type === type)
              return ({
                type,
                id: item.id,
                name: item.name,
                mainEntities: role === 'employee' ? orderBy(
                  entityStore.mainEntities
                    .filter((mainEntity: any) => mainEntity.mainEntityTypeId === item.id)
                    .map((mainEntity: any) => ({ id: mainEntity.id, name: mainEntity.name })),
                  'name',
                  'asc'
                ) : []
              })
            }),
            'name',
            'asc'
          )
        })
      }
      return null
    }).filter((item) => item)
  }

  const renderToggleButton = (type: any) => {
    if (type?.mainEntities?.length && role === 'employee') {
      return (
        <IconButton className={classes.toggleButton} onClick={() => toggleExpandType(type.id)}>
          {expandedTypes.includes(type.id) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      )
    }
    return null
  }

  const renderAvailableEntityTypes = (items: any) => {
    if (items?.length) {
      return items.map((type: any) => (
        <React.Fragment key={type.id}>
          <div className={classes.categoryName}>
            <Checkbox
              checked={isMainEntitySelected(type)}
              onChange={() => toggleMainEntityType(type)}
              label={type.name}
              classes={{ root: classes.checkboxRoot, label: role === 'employee' ? classes.label : classes.rowLabel }}
              containerStyle={classes.checkbox}
              checkStyle={classes.check}
            />
            {renderToggleButton(type)}
          </div>
          {renderExpandedAccessRights(type)}
        </React.Fragment>
      ))
    }
    return null
  }

  const renderExpandedAccessRights = (type: any) => {
    if (role === 'employee' && expandedTypes.includes(type.id)) {
      return (
        <div className={classes.mainEntitiesContainer}>
          {type.mainEntities.map((mainEntity: any) => (
              <div key={mainEntity.id} className={classes.mainEntityContainer}>
                <Checkbox
                  checked={accessRights.includes(mainEntity.id)}
                  onChange={() => toggleAccessRight(mainEntity.id)}
                  label={mainEntity.name}
                  classes={{ root: classes.checkboxRoot, label: classes.smallLabel }}
                  containerStyle={classes.smallCheckbox}
                  checkStyle={classes.smallCheck}
                />
              </div>
            )
          )}
        </div>
      )
    }
    return null
  }

  const renderAvailableAccessRights = () => {
    return getAccessRightTree().map((item: any) => (
      <React.Fragment key={item.id}>
        <Checkbox
          checked={isCategorySelected(item?.items)}
          onChange={() => toggleCategoryTypes(item?.items)}
          label={item?.title ?? ''}
          classes={{ root: classes.checkboxRoot, label: classes.subtitle }}
          containerStyle={classes.largeCheckbox}
          checkStyle={classes.largeCheck}
        />
        <div className={role === 'employee' ? classes.entityTypesContainer : classes.entityTypesRowContainer}>
          {renderAvailableEntityTypes(item?.items)}
        </div>
      </React.Fragment>
    ))
  }

  const renderAccessRights = () => {
    if (['employee', 'entity_admin'].includes(role)) {
      return (
        <>
          <Title title={t('entity_level_access_rights')} type='subtitle' />
          {renderAvailableAccessRights()}
        </>
      )
    }
    return null
  }

  const renderContent = () => {
    if (tab === TABS.account) {
      return (
        <>
          <Title title={t('account_information')} type='subtitle' />
          <div className={classes.accountContainer}>
            <Select
              options={getRoles()}
              label={t('role')}
              value={role}
              onChange={setRole}
              disabled={disabled || (get(userStore.user, 'id') === get(sessionStore.user, 'id')) }
            />
          </div>
          {renderAccessRights()}
        </>
      )
    }

    return (
      <>
        <Title title={t('general_information')} type='subtitle' />
        <div className={classes.profileContainer}>
          <div className={classes.imageContainer}>
            <ImageUpload
              photo={photo}
              onFileUpload={updatePhoto}
              removePhoto={removePhoto}
              disabled={disabled}
            />
          </div>
          <div className={classes.column}>
            <div className={classes.row}>
              <div className={classes.firstName}>
                <Input
                  label={t('first_name')}
                  value={firstName}
                  onChange={setFirstName}
                  disabled={disabled}
                />
              </div>
              <div className={classes.rowSpacer} />
              <div className={classes.lastName}>
                <Input
                  label={t('last_name')}
                  value={lastName}
                  onChange={setLastName}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className={classes.email}>
              <Input
                label={t('email')}
                value={email}
                onChange={setEmail}
                disabled={disabled}
              />
            </div>
            <div className={classes.phone}>
              <Input
                label={t('phone_number')}
                value={phone}
                onChange={setPhone}
                disabled={disabled}
              />
            </div>
          </div>
        </div>
        <Title title={t('additional_information')} type='subtitle' />
        <div className={classes.infoContainer}>
          <div className={classes.jobTitle}>
            <Input
              label={t('job_title')}
              value={title}
              onChange={setTitle}
              disabled={disabled}
            />
          </div>
          <div className={classes.description}>
            <Input
              label={t('description')}
              value={description}
              onChange={setDescription}
              disabled={disabled}
              multiline
            />
          </div>
        </div>
      </>
    )
  }

  const renderDeleteProfile = () => {
    if (tab === TABS.user) {
      // TODO: check if can delete profile
      return (
        <div>
          <div className={classes.line} />
          <Button
            text={isActive ? t('delete_profile') : t('activate_profile')}
            onClick={openAlert}
            disabled={!editMode}
            outlined
            fullWidth
          />
        </div>
      )
    }
    return null
  }

  return (
    <div className={classes.root}>
      <PageHeader
        title={t('user_details')}
        tabs={tabOptions}
        currentTab={tab}
        onTabChange={selectTab}
        onEditClick={toggleEditMode}
        editMode={editMode}
      />
      <div className={classes.contentContainer}>
        <div className={classes.content}>
          <div className={classes.contentInnerContainer}>
            <div className={classes.contentInner}>
              {renderContent()}
            </div>
          </div>
        </div>
        <div className={classes.actions}>
          <div>
            <p className={classes.text}>{t('user_details_actions_description')}</p>
            <p className={classes.text}>
              <span className={classes.boldText}>{t('last_modified')}: </span>
              {formatDateTime(get(userStore, 'user.updatedAt'))}
            </p>
            <Button text={getUpdateText()} onClick={updateUser} disabled={disabled} fullWidth />
          </div>
          {renderDeleteProfile()}
        </div>
      </div>
      <Dialog
        open={alertVisible}
        handleClose={closeAlert}
        handleOk={isActive ? deleteProfile : activateProfile}
        title={isActive ? t('delete_profile_title') : t('activate_profile_title')}
        description={isActive ? t('delete_user_profile_description') : t('activate_user_profile_description')}
      />
    </div>
  )
}

export default observer(User)
