import React from 'react'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import makeStyles from '@mui/styles/makeStyles'
import { formatDateTime } from '../../Utils/dateTime'
import { Button } from '../../Components'
import { Colors } from '../../Utils/theme'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: Colors.backgroundGrey,
    '&:hover': {
      backgroundColor: `${Colors.tableHover} !important`
    }
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  cell: {
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: 400,
    height: '4rem',
    color: Colors.black,
    borderBottom: 'none'
  },
  firstCell: {
    fontFamily: 'Roboto',
    fontSize: '1.125rem',
    fontWeight: 600,
    height: '4rem',
    color: Colors.black,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    borderBottom: 'none'
  },
  lastCell: {
    height: '4rem',
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    borderBottom: 'none',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  valid: {
    color: Colors.green,
    fontWeight: 700
  },
  expired: {
    color: Colors.red,
    fontWeight: 700
  },
  sendButton: {
    maxWidth: '10.5rem'
  },
  sendButtonText: {
    fontSize: '0.875rem'
  }
}))

function UserInviteTableRow (props) {
  const { user } = props
  const classes = useStyles()
  const { t } = useTranslation()

  const sendInviteAgain = () => props.inviteUser(user.email, user.role)

  return (
    <TableRow classes={{ root: classes.root }} hover>
      <TableCell className={classes.cell} size='small'>{user.email}</TableCell>
      <TableCell className={classes.cell} size='small'>{t(user.role)}</TableCell>
      <TableCell className={classes.cell} size='small'>
        <span className={user.isValid ? classes.valid : classes.expired}>
          {formatDateTime(user.validUntil)}
        </span>
      </TableCell>
      <TableCell className={classes.lastCell} size='small'>
        <Button
          text={t('send_again')}
          onClick={sendInviteAgain}
          buttonStyle={classes.sendButton}
          buttonTextStyle={classes.sendButtonText}
          small
        />
      </TableCell>
    </TableRow>
  )
}

export default UserInviteTableRow
