import React from 'react'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import makeStyles from '@mui/styles/makeStyles'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles((theme) =>({
  outerContainer: {
    borderBottom: 'none'
  },
  container: {
    borderBottom: 'none'
  },
  lightContainer: {
    borderBottom: `1px solid ${Colors.black20}`,
    marginBottom: '0.625rem'
  },
  cell: {
    height: '2.5rem',
    padding: '0 1rem',
    backgroundColor: Colors.black80,
    color: Colors.white,
    fontFamily: 'Rubik',
    fontSize: '0.875rem',
    fontWeight: 700,
    textTransform: 'uppercase'
  },
  lightCell: {
    height: '2.5rem',
    padding: '0 1rem',
    backgroundColor: Colors.white,
    fontFamily: 'Rubik',
    color: Colors.black80,
    fontSize: '1rem',
    fontWeight: 700
  },
  firstCell: {
    borderTopLeftRadius: '0.3125rem',
    borderBottomLeftRadius: '0.3125rem'
  },
  lastCell: {
    borderTopRightRadius: '0.3125rem',
    borderBottomRightRadius: '0.3125rem'
  }
}))

function TableHeader (props) {
  const classes = useStyles()

  const containerClass = props.light ? classes.lightContainer : classes.container

  return (
    <TableHead className={classes.outerContainer}>
      <TableRow className={containerClass}>
        {props.columnNames.map((columnName, index) => {
          let className = props.light ? classes.lightCell : classes.cell
          if (index === 0) className = `${className} ${classes.firstCell}`
          else if (index === props.columnNames.length - 1) className = `${className} ${classes.lastCell}`
          return <TableCell key={index} className={className}>{columnName}</TableCell>
        })}
      </TableRow>
    </TableHead>
  )
}

export default TableHeader
