import React, { useEffect, useState } from 'react'
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete'
import useOnclickOutside from 'react-cool-onclickoutside'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import get from 'lodash/get'
import TextField from '@mui/material/TextField'
import SearchIcon from '../../Assets/Icons/search.svg'
import { getMapsApiKey } from '../../Services/MapsApi'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  container: {
    position: 'relative',
    minWidth: '16rem'
  },
  iconContainer: {
    position: 'absolute',
    top: 0,
    right: '0.75rem',
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    pointerEvents: 'none'
  },
  icon: {
    position: 'absolute',
    right: '0rem',
    height: '1rem',
    userSelect: 'none',
  },
  input: {
    height: '3.125rem',
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: '1rem',
    color: Colors.inputText,
    backgroundColor: Colors.white,
    borderRadius: '0.3125rem',
    padding: '0 1rem !important',
    paddingRight: '2.25rem !important',
    '&::placeholder': {
      opacity: 1
    }
  },
  nameMain: {
    fontWeight: 600,
    fontSize: '.85rem'
  },
  name: {
    fontSize: '.85rem'
  },
  listContainer: {
    display: 'flex',
    paddingTop: '.25rem',
    paddingBottom: '.25rem',
    marginTop: '0rem',
    listStyle: 'none',
    margin: 0,
    padding: 0,
    background: Colors.white,
    borderRadius: '.25rem',
    border: `2px solid ${Colors.border}`
  },
  suggestions: {
    flexGrow: 1,
    width: 0
  },
  listRow: {
    boxSizing: 'border-box',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontFamily: 'Roboto',
    fontSize: '1rem',
    padding: '.25rem .5rem',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: Colors.black05
    }
  },
  notchedOutline: {
    border: `2px solid ${Colors.border}`
  }
}))

const MapAutocomplete = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { value, suggestions: { status, data }, setValue, clearSuggestions } = usePlacesAutocomplete({
    requestOptions: {},
    debounce: 300
  })

  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions()
  })

  const handleInput = (e) => {
    // Update the keyword of the input element
    setValue(e.target.value)
  }

  const handleSelect = ({ description }) => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    setValue(description, false)
    clearSuggestions()

    // Get latitude and longitude via utility functions
    getGeocode({ address: description })
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        console.log('Coordinates: ', { lat, lng })
        props.setFocus({ longitude: lng, latitude: lat, zoom: 14 })
      })
      .catch((error) => {
        console.log('Error: ', error)
      })
  }

  const renderSuggestions = () => data.map((suggestion) => {
    const { place_id, structured_formatting: { main_text, secondary_text } } = suggestion

    return (
      <li key={place_id} onClick={() => handleSelect(suggestion)} className={classes.listRow}>
        <span className={classes.nameMain}>{main_text} </span>
        <span className={classes.name}>{secondary_text}</span>
      </li>
    )
  })

  return (
    <div ref={ref} className={classes.root}>
      <div className={classes.container}>
        <TextField
          value={value}
          placeholder={t('center_map_to_address')}
          InputProps={{
            classes: { input: classes.input, notchedOutline: classes.notchedOutline }
          }}
          variant='outlined'
          onChange={handleInput}
          fullWidth
        />
        <div className={classes.iconContainer}>
          <img src={SearchIcon} className={classes.icon} />
        </div>
      </div>
      {/* We can use the "status" to decide whether we should display the dropdown or not */}
      {
        status === 'OK' && (
          <ul className={classes.listContainer}>
            <div className={classes.suggestions}>{renderSuggestions()}</div>
          </ul>
        )
      }
    </div>
  )
}

const MapAutocompleteLoader = (props) => {
  const [ready, setReady] = useState(false)
  const [checkCount, setCheckCount] = useState(0)

  // Inject Google Maps Script
  useEffect(() => {
    // Prevent injecting multiple times
    const IS_INJECTED_KEY = 'GIS_GOOGLE_MAPS_IS_INJECTED'
    if (!window[IS_INJECTED_KEY]) {
      window[IS_INJECTED_KEY] = true
      const apiKey = getMapsApiKey()
      const script = document.createElement('script')
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`
      script.async = true
      document.body.appendChild(script)
      checkReady()
    } else {
      setReady(true)
    }
  }, [])

  const checkReady = () => {
    if (!ready) {
      // Check if Google Maps is loaded
      if (get(window, 'google.maps')) {
        setReady(true)
      } else {
        if (checkCount < 10) {
          setTimeout(() => checkReady(), 500 * (checkCount + 1))
          setCheckCount(checkCount + 1)
        }
      }
    }
  }

  if (!ready) return null
  return <MapAutocomplete {...props} />

}

export default MapAutocompleteLoader
