import React from 'react'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import makeStyles from '@mui/styles/makeStyles'
import { Colors } from '../../Utils/theme'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { formatDateTime } from '../../Utils/dateTime'
import StarIcon from '../../Assets/Icons/star.svg'

const useStyles = makeStyles({
  root: {
    backgroundColor: Colors.backgroundGrey,
    borderBottom: 'none'
  },
  name: {
    fontWeight: 500
  },
  link: {
    display: 'block',
    paddingTop: '1rem',
    paddingBottom: '1rem',
    textDecoration: 'none',
    color: Colors.black,
    '&:hover': {
      opacity: 0.8
    }
  },
  firstCell: {
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5
  },
  lastCell: {
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5
  },
  cell: {
    padding: '.25rem 1rem',
    borderBottom: 'none'
  },
  ratingCell: {
    fontWeight: 600
  },
  starCount: {

    position: 'relative'
  },
  starIcon: {
    position: 'absolute',
    top: '-1.33rem',
    left: 0,
    marginBottom: '.25rem',
    height: '2.75rem',
    width: '2.75rem'
  },
  starNumber: {
    fontSize: '1.125rem',
    position: 'absolute',
    top: '-.6rem',
    left: '.75rem',
    marginLeft: '.25rem'
  },
  message: {
    display: 'inline-block',
    maxHeight: '3.25rem',
    overflow: 'hidden',
    maxWidth: '40vw',
    position: 'relative'
  },
  messageFade: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    height: '2rem',
    background: `linear-gradient(rgba(244, 244, 244, 0), ${Colors.backgroundGrey})`
  },
  red: {
    color: Colors.red,
    fontWeight: 600
  },
  green: {
    color: Colors.green,
    fontWeight: 600
  },
  blue: {
    color: Colors.blue,
    fontWeight: 600
  }
})

function FeedbackTableRow (props: any) {
  const { item } = props
  const classes = useStyles()

  const { t } = useTranslation()

  const renderName = () => {
    let name = item.mainEntityName
    if (item.mainEntityName && item.subEntityName) {
      name = `${name}: `
    }
    if (item.subEntityName) name = name + item.subEntityName
    return name
  }

  const renderContent = () => {
    let content = ''
    if (item.title) {
      content = `${item.title}: `
    }
    content = `${content} ${item.message}`.trim()
    return content
  }

  const renderStatus = () => {
    const { moderatedBy } = item

    if (moderatedBy) {
      if (item.public) {
        return (
          <div className={classes.green}>
            {t('public')}
          </div>
        )
      }
      return (
        <div className={classes.red}>
          {t('hidden')}
        </div>
      )
    }
    return (
      <div className={classes.blue}>
        {t('waiting_moderation')}
      </div>
    )
  }

  return (
    <TableRow classes={{ root: classes.root }} hover>
      <TableCell className={`${classes.name} ${classes.firstCell} ${classes.cell}`}>
        <Link to={`/feedback/${item.id}`} className={classes.link}>
          {renderName()}
        </Link>
      </TableCell>
      <TableCell className={classes.cell}>
        <div className={classes.message}>
          {renderContent()}
          <div className={classes.messageFade} />
        </div>
      </TableCell>
      <TableCell className={[classes.cell, classes.ratingCell].join(' ')}>
        <div className={classes.starCount}>
          <img src={StarIcon} className={classes.starIcon} />
          <div className={classes.starNumber}>{item.rating}</div>
        </div>
      </TableCell>
      <TableCell className={classes.cell}>
        {renderStatus()}
      </TableCell>
      <TableCell className={`${classes.lastCell} ${classes.cell}`}>
        {formatDateTime(item.createdAt)}
      </TableCell>
    </TableRow>
  )
}

export default FeedbackTableRow
