import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import { useStore } from '../../../Models/RootStore'
import { PollForm } from '../../../Components'

function CreatePoll() {
  const navigate = useNavigate()

  const { pollStore, entityStore }: any = useStore()
  const [langTab, setLangTab] = useState('fi')
  const [titleFi, setTitleFi] = useState('')
  const [titleEn, setTitleEn] = useState('')
  const [contentFi, setContentFi] = useState('')
  const [contentEn, setContentEn] = useState('')
  const [validFrom, setValidFrom] = useState(moment().seconds(0).milliseconds(0).format())
  const [validUntil, setValidUntil] = useState(null)
  const [draft, setDraft] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [selectedMainEntityTypes, setSelectedMainEntityTypes] = useState([])
  const [selectedMainEntities, setSelectedMainEntities] = useState([])

  const handleSetSelectedMainEntityTypes = (types) => {
    setSelectedMainEntityTypes(types)
    setSelectedMainEntities(selectedMainEntities.filter(id => {
      const mainEntity = entityStore.mainEntities.find(item => item.id === id)
      if (!mainEntity) return false
      return types.includes(mainEntity.mainEntityTypeId)
    }))
  }

  // Initialize poll with one empty question
  const [questions, setQuestions] = useState([{
    id: uuidv4(),
    type: 'select',
    titleFi: '',
    titleEn: '',
    contentFi: '',
    contentEn: '',
    answersFi: ['', ''],
    answersEn: ['', '']
  }])

  useEffect(() => {
    // Load main entity types
    entityStore.getMainEntityTypes()
    entityStore.getMainEntities()
  }, [])

  // Redirect to polls list after create
  useEffect(() => {
    if (pollStore.createOk) {
      navigate('/polls')
    }
  }, [pollStore.createOk])

  const createPoll = () => {
    setSubmitted(true)

    pollStore.createPoll({
      draft,
      titleFi,
      titleEn: titleEn || titleFi,
      contentFi,
      contentEn: contentEn || contentFi,
      validFrom: validFrom ? moment(validFrom).toISOString() : validFrom,
      validUntil: validUntil ? moment(validUntil).toISOString() : validUntil,
      questions,
      mainEntityTypeIds: selectedMainEntityTypes,
      mainEntityIds: selectedMainEntities
    })
  }

  const renderForm = () => {
    return (
      <PollForm
        langTab={langTab}
        setLangTab={setLangTab}
        titleFi={titleFi}
        setTitleFi={setTitleFi}
        titleEn={titleEn}
        setTitleEn={setTitleEn}
        contentFi={contentFi}
        setContentFi={setContentFi}
        contentEn={contentEn}
        setContentEn={setContentEn}
        questions={questions}
        setQuestions={setQuestions}
        submitted={submitted}
        mainEntityTypes={entityStore.mainEntityTypes}
        selectedMainEntityTypes={selectedMainEntityTypes}
        setSelectedMainEntityTypes={handleSetSelectedMainEntityTypes}
        mainEntities={entityStore.mainEntities}
        selectedMainEntities={selectedMainEntities}
        setSelectedMainEntities={setSelectedMainEntities}
        validFrom={validFrom}
        setValidFrom={setValidFrom}
        validUntil={validUntil}
        setValidUntil={setValidUntil}
        draft={draft}
        setDraft={setDraft}
        save={createPoll}
        mode='create'
      />
    )
  }

  return renderForm()
}

export default observer(CreatePoll)
