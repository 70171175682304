import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import { useTranslation } from 'react-i18next'
import { TableHeader, DataMessage } from '..'
import MaintenanceLogTableRow from './MaintenanceLogTableRow'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  root: {
  },
  spacer: {
    height: '1rem'
  }
}))

function MaintenanceLogTable (props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const renderRows = () => {
    if (props.logs) {
      return props.logs.map((log) => {
        return (
          <React.Fragment key={log?.id}>
            <div className={classes.spacer} />
            <MaintenanceLogTableRow
              log={log}
              editLog={props.editLog}
              deleteLog={props.deleteLog}
            />
          </React.Fragment>
        )
      })
    }
    return null
  }

  return (
    <>
      <Table classes={{ root: classes.root }}>
        <TableHeader columnNames={[t('date_short'), t('condition'), t('description'), t('actions')]} />
        <TableBody>{renderRows()}</TableBody>
      </Table>
      <DataMessage data={props.logs} message={t('no_maintenance_logs')} />
    </>
  )
}

export default MaintenanceLogTable
