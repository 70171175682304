import apisauce from 'apisauce'

const API_URLS = {
  Local: 'http://127.0.0.1:1337/',
  Stage: 'https://lokaatioalusta-api.stage.geniem.io/',
  Production: 'https://api.jana-gis.fi/'
}

let currentApi = API_URLS.Production
if (window.location.hostname.includes('.stage.geniem.io')) currentApi = API_URLS.Stage
if (window.location.hostname.includes('localhost') || window.location.hostname.includes('127.0.0.1')) currentApi = API_URLS.Local

// const auth = (token) => ({ headers: { Authorization: `Bearer ${token}` } })

const create = (baseURL = currentApi) => {
  const api = apisauce.create({
    withCredentials: true,
    baseURL,
    timeout: 20000
  })

  return {
    // Auth
    login: (data) => api.post('auth/login', data),
    logout: () => api.put('auth/logout'),
    logoutOthers: () => api.put('auth/logout-others'),
    keepAuthAlive: () => api.put('auth/keep-alive'),
    requestPasswordReset: (data) => api.post('auth/password-reset', data),
    completePasswordResetWithCode: (data) => api.put('auth/password-reset', data),
    inviteUser: (data) => api.post('auth/email-invite', data),
    verifyUserInvite: (data) => api.put('auth/email-invite', data),
    createEmailChange: (data) => api.post('auth/email-change', data),
    getEmailChange: (code) => api.get(`auth/email-change/${code}`),
    verifyEmailChange: (data) => api.put('auth/email-change', data),
    getUserInvite: (code) => api.get(`auth/email-invite/${code}`),
    getUserInvites: () => api.get('auth/invited'),

    // User
    getMe: () => api.get('user/extra/me'),
    updateMe: (data) => api.put('user/me', data),
    updateMyPhoto: (data) => {
      const formData = new FormData()
      formData.append('file', data)
      const options = { headers: { 'Content-Type': 'multipart/form-data' } }
      return api.post('user/me/photo', formData, options)
    },
    updateUser: (id, data) => api.put(`user/my/${id}`, data),
    updateUserPhoto: (userId, data) => {
      const formData = new FormData()
      formData.append('file', data)
      const options = { headers: { 'Content-Type': 'multipart/form-data' } }
      return api.post(`/user/my/${userId}/photo`, formData, options)
    },
    changePassword: (data) => api.put('user/me/password', data),
    getMyOrganizationUsers: () => api.get('user/my'),
    getMyOrganizationUser: (id) => api.get(`user/my/${id}`),
    createUser: (data) => api.post('user', data),
    deactivateUser: (id: number) => api.delete(`user/${id}`),
    getUser: (id) => api.get(`user/${id}`),

    // User - Access rights (role: employee)
    addUserAccessRights: (userId, data) => api.post(`user/my/${userId}/access-right`, data),
    removeUserAccessRights: (userId, data) => api.delete(`user/my/${userId}/access-right`, data),

    // User - Main Entity type access rights (role: entity_admin)
    addUserMainEntityTypeAccessRights: (userId, data) => api.post(`user/my/${userId}/entity-type-access-right`, data),
    removeUserMainEntityTypeAccessRights: (userId, data) => api.delete(`user/my/${userId}/entity-type-access-right`, data),

    // Organization
    getMyOrganization: () => api.get('organization/my'),
    updateMyOrganization: (data) => api.put('organization/my', data),
    updateMyOrganizationLogo: (data) => {
      const formData = new FormData()
      formData.append('file', data)
      const options = { headers: { 'Content-Type': 'multipart/form-data' } }
      return api.post('organization/my/logo', formData, options)
    },
    getOrganizations: () => api.get('organization'),
    getOrganization: (id: number) => api.get(`organization/${id}`),
    createOrganization: (data) => api.post('organization', data),
    updateOrganization: (id: number, data) => api.put(`organization/${id}`, data),
    updateOrganizationLogo: (organizationId, data) => {
      const formData = new FormData()
      formData.append('file', data)
      const options = { headers: { 'Content-Type': 'multipart/form-data' } }
      return api.post(`organization/${organizationId}/logo`, formData, options)
    },

    // Super Admin endpoints
    createMainEntityType: (data) => api.post('main-entity/type', data),
    updateMainEntityType: (id, data) => api.put(`main-entity/type/${id}`, data),
    getMainEntityFields: () => api.get('main-entity/field'),
    updateMainEntityField: (id, data) => api.put(`main-entity/field/${id}`, data),
    createMainEntityField: (data) => api.post(`main-entity/field`, data),
    getMainEntityTypesMainEntityFields: () => api.get('main-entity/type-field'),
    addMainEntityTypeFields: (data) => api.post('main-entity/add-type-fields', data),
    deleteMainEntityTypeFields: (data) => api.post('main-entity/delete-type-fields', data),
    deleteMainEntityField: (id) => api.delete(`main-entity/field/${id}`),
    adminInviteUser: (organizationId, data) => api.post(`auth/admin/email-invite/organization/${organizationId}`, data),
    getAdminOrganizationMainEntityTypes: (organizationId) => api.get(`organization-main-entity-type/admin/organization/${organizationId}`),
    addOrganizationMainEntityTypes: (data) => api.post('organization-main-entity-type/admin/add-types', data),
    deleteOrganizationMainEntityTypes: (data) => api.post('organization-main-entity-type/admin/delete-types', data),
    getAllMainEntityTypes: () => api.get('main-entity/type'),
    getOranizationUsers: (organizationId) => api.get(`user/organization/${organizationId}`),
    getOrganizationInvitedUsers: (organizationId) => api.get(`auth/admin/email-invite/organization/${organizationId}`),

    addMainEntityTypeSubEntityTypes: (data) => api.post('main-entity-type-sub-entity-type/admin/add-types', data),
    deleteMainEntityTypeSubEntityTypes: (data) => api.post('main-entity-type-sub-entity-type/admin/delete-types', data),

    createSubEntityType: (data) => api.post('sub-entity/type', data),
    updateSubEntityType: (id, data) => api.put(`sub-entity/type/${id}`, data),
    getSubEntityFields: () => api.get('sub-entity/field'),
    updateSubEntityField: (id, data) => api.put(`sub-entity/field/${id}`, data),
    createSubEntityField: (data) => api.post(`sub-entity/field`, data),
    getSubEntityTypesSubEntityFields: () => api.get('sub-entity/type-field'),
    addSubEntityTypeFields: (data) => api.post('sub-entity/add-type-fields', data),
    deleteSubEntityTypeFields: (data) => api.post('sub-entity/delete-type-fields', data),
    deleteSubEntityField: (id) => api.delete(`sub-entity/field/${id}`),
    getMainEntityTypeSubEntityTypes: (mainEntityTypeId) => api.get(`main-entity-type-sub-entity-type/main-entity-type/${mainEntityTypeId}`),

    // Main entities
    getMainEntities: () => api.get('main-entity'),
    getMainEntity: (id) => api.get(`main-entity/${id}`),
    createMainEntity: (data) => api.post('main-entity', data),
    getMainEntityTypes: () => api.get('main-entity/type/my'),
    updateMainEntity: (id, data) => api.put(`main-entity/${id}`, data),
    deleteMainEntity: (id) => api.delete(`main-entity/${id}`),
    updateMainEntityPhoto: (mainEntityId, data) => {
      const formData = new FormData()
      formData.append('file', data)
      const options = { headers: { 'Content-Type': 'multipart/form-data' } }
      return api.post(`main-entity/${mainEntityId}/photo`, formData, options)
    },
    getMainEntityGpxRecordings: (mainEntityId) => api.get(`gpx-recording/main-entity/${mainEntityId}`),
    exportGpxRecordingFile: (gpxRecordingId, filename = 'export') => {
      window.open(`${currentApi}gpx-recording/export-gpx/${gpxRecordingId}/${filename}.gpx`)
    },
    updateGpxRecording: (id, recording) => api.put(`gpx-recording/${id}`, recording),

    // Files
    uploadPhoto: (data) => {
      const formData = new FormData()
      formData.append('file', data)
      const options = { headers: { 'Content-Type': 'multipart/form-data' } }
      return api.post(`file/photo`, formData, options)
    },

    // Info pages
    getInfoPages: () => api.get('page/my'),
    getInfoPage: (pageId) => api.get(`page/${pageId}`),
    editInfoPage: (pageId, params) => api.put(`page/${pageId}`, params),
    createInfoPage: (params) => api.post('page', params),
    createOrEditMainEntityPage: (mainEntityId, params) => api.put(`page/main-entity/${mainEntityId}`, params),
    createOrEditMainEntityTypePage: (mainEntityTypeId, params) => api.put(`page/entity-type/${mainEntityTypeId}`, params),
    getServicePage: () => api.get('page/service'),
    createOrEditServicePage: (params) => api.put('page/service', params),

    // Feedbacks
    getEntityFeedbacks: () => api.get('feedback/entity-feedback'),
    getMainEntityFeedbacks: (mainEntityId) => api.get(`feedback/main-entity/${mainEntityId}`),
    getSubEntityFeedbacks: (subEntityId) => api.get(`feedback/sub-entity/${subEntityId}`),
    getEntityFeedback: (feedbackId) => api.get(`feedback/entity-feedback/${feedbackId}`),
    moderateEntityFeedback: (feedbackId, data) => api.put(`feedback/entity-feedback/${feedbackId}/moderate`, data),

    // Polls
    getPolls: () => api.get('poll/my'),
    getPoll: (id) => api.get(`poll/${id}`),
    createPoll: (data) => api.post('poll', data),
    getPollAnswers: (id) => api.get(`poll/raw-answers/${id}`),
    updatePoll: (id, poll) => api.put(`poll/${id}`, poll),

    // Snapshots
    createSnapshot: (mainEntityId, data) => api.post(`snapshot/main-entity/${mainEntityId}`, data),
    getLatestSnapshot: (mainEntityId) => api.get(`snapshot/main-entity/${mainEntityId}/latest`),
    getSnapshots: (mainEntityId) => api.get(`snapshot/main-entity/${mainEntityId}`),
    restoreSnapshot: (snapshotId) => api.put(`snapshot/${snapshotId}/restore`),

    // Sub entities
    createSubEntity: (data) => api.post('sub-entity', data),
    updateSubEntity: (id, data) => api.put(`sub-entity/${id}`, data),
    deleteSubEntity: (id) => api.delete(`sub-entity/${id}`),
    getSubEntityTypes: () => api.get('sub-entity/type'),
    updateSubEntityPhoto: (subEntityId, data) => {
      const formData = new FormData()
      formData.append('file', data)
      const options = { headers: { 'Content-Type': 'multipart/form-data' } }
      return api.post(`sub-entity/${subEntityId}/photo`, formData, options)
    },

    // Main entity notifications
    getMainEntityNotifications: (mainEntityId) => api.get(`main-entity-notification/main-entity/${mainEntityId}`),
    getMainEntityNotification: (mainEntityNotificationId) => api.get(`main-entity-notification/${mainEntityNotificationId}`),
    createMainEntityNotification: (mainEntityNotification) => api.post('main-entity-notification', mainEntityNotification),
    updateMainEntityNotification: (mainEntityNotificationId, mainEntityNotification) => api.put(`main-entity-notification/${mainEntityNotificationId}`, mainEntityNotification),
    deleteMainEntityNotification: (mainEntityNotificationId) => api.delete(`main-entity-notification/${mainEntityNotificationId}`),

    // Notes
    getMainEntityNotes: (mainEntityId) => api.get(`note/main-entity/${mainEntityId}`),
    createNote: (body) => api.post('note', body),
    editNote: (id, body) => api.put(`note/${id}`, body),
    deleteNote: (id) => api.put(`/note/${id}`, { active: false }),

    // Analytics
    getAnalyticsDashboard: () => api.get('/analytics/dashboard'),
    getMainEntityAnalytics: (mainEntityId) => api.get(`/analytics/main-entity/${mainEntityId}`),

    // Logs
    getMyLoginLog: (params = {}) => api.get('log/my/login', params)
  }
}

const Api = create()

export default Api
