import React from 'react'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import LinearProgress from '@mui/material/LinearProgress'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: Colors.backgroundGrey,
    height: '3rem',
    width: '8rem',
    borderRadius: '0.625rem',
    padding: '0.25rem 1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  progressText: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '0.875rem',
    color: Colors.black,
    textAlign: 'center',
    marginBottom: '0.3125rem'
  },
  root: {
    height: '0.5rem',
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.black20}`
  },
  colorPrimary: {
    backgroundColor: Colors.white
  },
  bar: {
    borderRadius: '0.3125rem',
    backgroundColor: Colors.green
  }
}))

function ProgressBox (props) {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={`${classes.container} ${props.containerStyle || ''}`.trim()}>
      <p className={classes.progressText}>{props.progress}% {t('ready').toLowerCase()}</p>
      <LinearProgress
        variant='determinate'
        value={props.progress}
        classes={{ root: classes.root, colorPrimary: classes.colorPrimary, bar: classes.bar }}
      />
    </div>
  )
}

export default ProgressBox
