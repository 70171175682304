import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Colors } from '../../Utils/theme'
import Grid from '@mui/material/Grid'
import ButtonBase from '@mui/material/ButtonBase'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    borderRadius: '0.3125rem',
  },
  last: {
    marginRight: 0
  },
  businessId: {
    color: Colors.black80,
    fontSize: '0.875rem',
    marginBottom: '0.25rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
  spacer: {
    flex: 1
  },
  name: {
    textAlign: 'center',
    color: Colors.black,
    fontSize: '1.25rem',
    fontFamily: 'Rubik',
    fontWeight: 700,
    margin: 0,
    padding: '.5rem .5rem .75rem',
    opacity: 0.7
  },
  photo: {
    borderRadius: '.3125rem',
    width: '100%',
    overflow: 'hidden',
    margin: 0,
    paddingTop: '42.25%',
    position: 'relative',
    marginBottom: '1rem',
    backgroundPosition: '50% 50%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  },
  servicePhoto: {
    borderRadius: '.3125rem',
    width: '100%',
    overflow: 'hidden',
    margin: 0,
    paddingTop: '42.25%',
    position: 'relative',
    marginBottom: '1rem',
    backgroundPosition: '50% 50%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundColor: Colors.lightGrey
  },
  serviceIcon: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  serviceIconInner: {
    fontSize: '4rem',
    color: Colors.black,
    opacity: 0.5
  }
}))

function WikiMainEntityTypeCard (props) {
  const { item } = props
  const classes = useStyles()

  const onClick = () => props.onClick(item.id)

  const renderImage = () => {
    if (!item.photo) {
      return (
        <div className={classes.servicePhoto}>
          <div className={classes.serviceIcon}>
            <div className={classes.serviceIconInner}>{(item.name || '').slice(0, 1)}</div>
          </div>
        </div>
      )
    }
    const backgroundStyle = item.photo ? { backgroundImage: `url(${item.photo})` } : undefined
    return (
      <div className={classes.photo} style={backgroundStyle} />
    )
  }

  return (
    <Grid item xl={6} lg={6} md={6} xs={12}>
      <ButtonBase className={classes.container} onClick={onClick}>
        {renderImage()}
        <div className={classes.row}>
          <p className={classes.name}>
            {item.name}
          </p>
        </div>
      </ButtonBase>
    </Grid>
  )
}

export default WikiMainEntityTypeCard
