import React, { useState } from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import makeStyles from '@mui/styles/makeStyles'
import IconButton from '@mui/material/IconButton'
import { Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Colors } from '../../Utils/theme'
import EditIconDark from '../../Assets/Icons/edit-dark.svg'
import AddIcon from '../../Assets/Icons/add-dark.svg'
import DeleteIcon from '../../Assets/Icons/delete.svg'
import CornerBulletIcon from '../../Assets/Icons/corner-bullet.svg'
import DragIcon from '../../Assets/Icons/drag.svg'
import { Dialog } from '..'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: Colors.backgroundGrey,
    '&:hover': {
      backgroundColor: `${Colors.black10} !important`
    }
  },
  evenRoot: {
    backgroundColor: Colors.white,
    '&:hover': {
      backgroundColor: `${Colors.black10} !important`
    }
  },
  draggingRoot: {
    '&:hover': {
      backgroundColor: `${Colors.white} !important`,
      boxShadow: `rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px`
    }
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: 1,
    width: '99%'
  },
  firstCell: {
    minHeight: '3rem',
    maxHeight: '3rem',
    height: '3rem',
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: 400,
    color: Colors.black,
    borderBottom: 'none'
  },
  firstCellText: {
    boxSizing: 'border-box',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  cell: {
    minHeight: '3rem',
    maxHeight: '3rem',
    height: '3rem',
    fontFamily: 'Roboto',
    fontSize: '1rem',
    color: Colors.black,
    borderBottom: 'none'
  },
  lastCell: {
    minHeight: '3rem',
    maxHeight: '3rem',
    height: '3rem',
    borderBottom: 'none',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  link: {
    textDecoration: 'none',
    color: Colors.black,
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8
    }
  },
  buttonLink: {
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.white,
    width: 32,
    height: 32,
    borderRadius: '0.3125rem',
    marginRight: '1rem'
  },
  actionButton: {
    backgroundColor: Colors.white,
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.black20}`,
    height: '2rem',
    width: '2rem',
    marginLeft: '0.5rem',
    '&:hover': {
      backgroundColor: `${Colors.violet10} !important`
    }
  },
  addButton: {
    backgroundColor: Colors.white,
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.black20}`,
    height: '2rem',
    width: '2rem',
    marginLeft: '0.5rem',
    '&:hover': {
      backgroundColor: `${Colors.violet10} !important`
    }
  },
  addButtonActive: {
    backgroundColor: Colors.black20,
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.black20}`,
    height: '2rem',
    width: '2rem',
    marginLeft: '0.5rem',
    '&:hover': {
      backgroundColor: `${Colors.black20} !important`
    },
    '&:active': {
      color: Colors.violet
    }
  },
  actionIcon: {
    height: '1.125rem',
    width: 'auto',
    maxHeight: '1.125rem',
    maxWidth: '1.125rem'
  },
  deleteButton: {
    backgroundColor: Colors.white,
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.black20}`,
    height: '2rem',
    width: '2rem',
    marginLeft: '0.5rem',
    '&:hover': {
      backgroundColor: `${Colors.violet10} !important`
    }
  },
  bullet: {
    maxHeight: '1rem',
    maxWidth: '1rem',
    marginRight: '0.5rem'
  },
  typeBadge: {
    fontFamily: 'Roboto',
    fontSize: '0.875rem',
    fontWeight: 600
  },
  inset: {
    paddingLeft: '1rem'
  },
  dragIcon: {
    height: '1.125rem',
    width: 'auto',
    maxHeight: '1.125rem',
    maxWidth: '1.125rem',
    cursor: 'grab',
    marginLeft: '1rem'
  },
  dragging: {
    cursor: 'grabbing'
  }
}))

function SubEntityTableRow (props) {
  const { item, addSubEntityId, editSubEntityId } = props
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    attributes,
    listeners,
    transform,
    transition,
    setNodeRef,
    isDragging
  } = useSortable({ id: item.id })

  const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false)

  const hideConfirms = () => setConfirmDeleteVisible(false)

  const onMouseEnter = () => props.setHighlightedEntity(props.item)
  const onMouseLeave = () => props.setHighlightedEntity(null)

  const handleClick = () => props.onClick(item.id)

  const handleEditPress = () => {
    if (editSubEntityId === item.id) {
      props.setEditSubEntityId(null)
    } else {
      props.setEditSubEntityId(item.id)
    }
  }

  const handleAddSubEntityId = () => {
    if (addSubEntityId === item.id) {
      props.setAddSubEntityId(null)
    } else {
      props.setAddSubEntityId(item.id)
    }
  }

  const handleDelete = () => {
    if (confirmDeleteVisible) {
      props.deleteSubEntity(item.id)
      setConfirmDeleteVisible(false)
    } else {
      setConfirmDeleteVisible(true)
    }
  }

  const renderAddGeoButton = () => {
    // Check if geo is not drawed
    if (!item.hasGeo) {
      return (
        <Tooltip title={t('add_geo_feature')}>
          <IconButton
            onClick={handleAddSubEntityId}
            className={props.addSubEntityId === item.id ? classes.addButtonActive : classes.addButton}
            size='large'
          >
            <img src={AddIcon} className={classes.actionIcon} alt='add' />
          </IconButton>
        </Tooltip>
      )
    }
  }

  const renderEditButton = () => {
    return (
      <Tooltip title={t('edit_entity')}>
        <IconButton onClick={handleEditPress} className={classes.actionButton} size='large'>
          <img src={EditIconDark} className={classes.actionIcon} alt='icon' />
        </IconButton>
      </Tooltip>
    )
  }

  const renderDeleteButton = () => {
    return (
      <>
        <Tooltip title={t('delete_entity')}>
          <IconButton onClick={handleDelete} className={classes.deleteButton} size='large'>
            <img src={DeleteIcon} className={classes.actionIcon} />
          </IconButton>
        </Tooltip>
        <Dialog
          open={confirmDeleteVisible}
          handleClose={hideConfirms}
          handleOk={handleDelete}
          title={t('delete_entity')}
          description={t('delete_entity_description')}
        />
      </>
    )
  }

  const renderDragHandleComponent = () => {
    if (props.dragEnabled) {
      return (
        <img
          src={DragIcon}
          className={`${classes.dragIcon} ${isDragging ? classes.dragging : ''}`.trim()}
          alt='drag'
          {...attributes}
          {...listeners}
        />
      )
    }
    return null
  }

  const renderType = () => {
    if (item?.subEntityType?.nameFi || item.type) {
      return (
        <div className={classes.typeBadge}>
          {item?.subEntityType?.nameFi || t(item.type)}
        </div>
      )
    }
    return null
  }

  return (
    <TableRow
      ref={setNodeRef}
      classes={{
        root: `
          ${props.even ? classes.evenRoot : classes.root} ${isDragging ? classes.draggingRoot : ''}
        `.trim()
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{
        transform: CSS.Transform.toString(transform),
        transition: transition,
        position: 'relative',
        zIndex: isDragging ? 1 : undefined
      }}
    >
      <TableCell className={classes.firstCell} size='small'>
        <div className={classes.link} onClick={handleClick}>
          <div className={classes.row}>
            {/* props.item.parentSubEntityId ? <div className={classes.inset} /> : null */}
            <img src={CornerBulletIcon} className={classes.bullet} />
            <span className={classes.firstCellText}>{item.nameFi || item.nameEn}</span>
          </div>
        </div>
      </TableCell>
      <TableCell className={classes.cell} size='small'>
        {renderType()}
      </TableCell>
      <TableCell className={classes.lastCell} size='small'>
        {renderEditButton()}
        {renderAddGeoButton()}
        {renderDeleteButton()}
        {renderDragHandleComponent()}
      </TableCell>
    </TableRow>
  )
}

export default SubEntityTableRow
