import apisauce from 'apisauce'

// TODO: CHANGE AUTH KEY
const AUTH_KEY = {
  Stage: 'AIzaSyDesXaUq1TyBhzu0IlV6p1zRRzn7Qqios8',
  Production: 'AIzaSyDOi_rNe7DOfeuTAQPhXaOkrVbJs6WzcQc'
}

let authValue = AUTH_KEY.Production
if (window.location.hostname.includes('.stage.geniem.io')) authValue = AUTH_KEY.Stage
if (window.location.hostname.includes('localhost') || window.location.hostname.includes('127.0.0.1')) authValue = AUTH_KEY.Stage

export const getMapsApiKey = () => authValue

const AUTH = `key=${authValue}`
const LANG = 'language=fi'
const LOCATION = 'location=61.49770309,23.7599138&radius=2000000&strictbounds=true'

const create = (baseURL = 'https://maps.googleapis.com/maps/api/') => {
  const api = apisauce.create({
    baseURL,
    headers: {
    },
    timeout: 20000
  })

  const getPlaces = (text, sessiontoken, cancelToken) => api.get(
    `place/autocomplete/json?&input=${encodeURIComponent(text)}&${LOCATION}&${LANG}&${AUTH}&sessiontoken=${sessiontoken}`,
    {},
    { cancelToken }
  )

  const getDetails = (id, sessiontoken) => api.get(
    `place/details/json?placeid=${id}&${LANG}&${AUTH}&sessiontoken=${sessiontoken}`,
    {},
    {}
  )

  const getPlaceByCoords = (lat, lng) => api.get(
    `geocode/json?latlng=${lat},${lng}&${LANG}&${AUTH}`,
    {},
    {}
  )

  return {
    getPlaces,
    getDetails,
    getPlaceByCoords
  }
}

const MapsApi = create()

export default MapsApi
