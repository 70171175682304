import React from 'react'
import { Link } from 'react-router-dom'
import get from 'lodash/get'

export const renderName = (item) => {
  return (
    <div>
      <Link to={`/entity/${item.id}`} style={{ color: 'inherit', textDecoration: 'none' }}>
        <div>{item.nameFi}</div>
      </Link>
    </div>
  )
}

export const getSelectValue = (item, field) => {
  const currentVal = get(item, `fields[${field}].value`, null)
  if (!currentVal) return null
  // Find text value
  const options = get(item, `fields[${field}].options`, [])
  const match = options.find(o => o.value === currentVal)
  if (match && match.labelFi) return match.labelFi
  return null
}

export const getMultiselectValues = (item, field) => {
  const currentVals = get(item, `fields[${field}].value`, [])
  if (!currentVals || !currentVals.length) return null
  const options = get(item, `fields[${field}].options`, [])
  const selectedOptions = options.filter(o => currentVals.includes(o.value))
  return selectedOptions.map(o => o.labelFi).filter(v => !!v).join(', ')
}

export const renderCondition = (item) => {
  const cond = get(item, 'fields.condition.value', null)
  if (!cond) return null

  const styles: any = {
    good: {
      height: '1.5rem',
      width: '1.5rem',
      minHeight: '1.5rem',
      minWidth: '1.5rem',
      borderRadius: '2rem',
      backgroundColor: '#ABC872',
      border: `.25rem solid #E5EBDA`,
      marginRight: '.5rem'
    }
  }
  styles.bad = {
    ...styles.good,
    backgroundColor: '#EB5E58',
    border: `.25rem solid #F2D6D5`
  }
  styles.unknown = {
    ...styles.good,
    backgroundColor: '#8e8e8e',
    border: `.25rem solid #dddddd`
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <div style={styles[cond] || styles['unknown']} />
      <div>
        {getSelectValue(item, 'condition')}
      </div>
    </div>
  )
}
