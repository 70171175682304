import {
  SPACER,
  AIR_TEMPERATURE,
  WATER_TEMPERATURE,
  ALGAE_STATUS,
  BEACH_PROPERTIES,
  MAINTENANCE_TITLE,
  CONDITION,
  EXCEPTION_NOTIFICATION,
  CONTACT_INFORMATION_TITLE,
  STREET_ADDRESS,
  POSTAL_CODE,
  CITY,
  WWW,
  EMAIL,
  PHONE,
  BEACH_OTHER_SERVICES,
  BEACH_LINE,
  STATUS_INFORMATION_TITLE,
  EQUIPMENT_TITLE,
  ADDITIONAL_SERVICES_TITLE,
  ADDITIONAL_INFORMATION_TITLE,
  DESCRIPTION,
  DESCRIPTION_TITLE
} from '../elements.template'

const LAYOUT =   {
  type: 'beach',
  layout: [
    [
      // Start left column
      DESCRIPTION_TITLE,
      DESCRIPTION,
      STATUS_INFORMATION_TITLE,
      {
        type: 'container',
        className: 'customRowFixed',
        items: [
          AIR_TEMPERATURE,
          SPACER,
          WATER_TEMPERATURE,
          SPACER,
          { ...ALGAE_STATUS, style: { flex: 1, maxWidth: '20rem', marginLeft: '0.75rem' } }
        ]
      },
      EQUIPMENT_TITLE,
      { ...BEACH_PROPERTIES, hideLabel: true, label: null },
      ADDITIONAL_SERVICES_TITLE,
      BEACH_OTHER_SERVICES,
      ADDITIONAL_INFORMATION_TITLE,
      BEACH_LINE
      // End left column
    ],
    [
      // Start right column
      MAINTENANCE_TITLE,
      { ...CONDITION, style: { flex: 1, maxWidth: '24rem' } },
      EXCEPTION_NOTIFICATION,
      CONTACT_INFORMATION_TITLE,
      {
        type: 'container',
        className: 'customRowFixed',
        items: [
          { ...PHONE, style: { flex: 1, maxWidth: '12rem' } },
          SPACER,
          { ...EMAIL, style: { flex: 1, maxWidth: '24rem' } }
        ]
      },
      { ...WWW, style: { flex: 1, maxWidth: '38rem' } },
      {
        type: 'container',
        className: 'customRowFixed',
        style: { paddingBottom: '10rem' },
        items: [
          STREET_ADDRESS,
          SPACER,
          CITY,
          SPACER,
          POSTAL_CODE
        ]
      }
      // End right column
    ]
  ]
}

export default LAYOUT
