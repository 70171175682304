import React, { useState } from 'react'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import makeStyles from '@mui/styles/makeStyles'
import { Colors } from '../../Utils/theme'
import { IconButton, Tooltip } from '@mui/material'
import RestoreIcon from '../../Assets/Icons/restore-dark.svg'
import { useTranslation } from 'react-i18next'
import { formatDateTime } from '../../Utils/dateTime'
import { Dialog } from '..'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: Colors.backgroundGrey,
    borderBottom: 'none',
    '&:hover': {
      backgroundColor: `${Colors.tableHover} !important`
    }
  },
  actionButton: {
    backgroundColor: Colors.white,
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.black20}`,
    height: '2rem',
    width: '2rem',
    '&:hover': {
      backgroundColor: Colors.violet10
    },
    '&:active': {
      color: Colors.violet
    }
  },
  actionIcon: {
    height: '1.125rem',
    width: '1.125rem'
  },
  firstCell: {
    height: '3rem',
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: 600,
    color: Colors.black,
    borderBottom: 'none'
  },
  cell: {
    height: '3rem',
    fontFamily: 'Roboto',
    fontSize: '1rem',
    color: Colors.black,
    borderBottom: 'none'
  },
  lastCell: {
    height: '3rem',
    borderBottom: 'none',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  message: {
    maxHeight: '2.25rem',
    overflow: 'hidden',
    maxWidth: '50vw'
  }
}))

function EntityVersionTableRow (props: any) {
  const { item } = props
  const classes = useStyles()

  const [confirmRestoreVisible, setConfirmRestoreVisible] = useState(false)

  const { t } = useTranslation()

  const closeConfirms = () => setConfirmRestoreVisible(false)

  const handleRestore = () => {
    if (confirmRestoreVisible) {
      props.onRestore(props.item.id)
      setConfirmRestoreVisible(false)
    } else {
      setConfirmRestoreVisible(true)
    }
  }

  const renderRestore = () => {
    return (
      <>
        <Tooltip title={t('restore')}>
          <IconButton onClick={handleRestore} className={classes.actionButton} size="large">
            <img src={RestoreIcon} className={classes.actionIcon} alt='icon' />
          </IconButton>
        </Tooltip>
        <Dialog
          open={confirmRestoreVisible}
          handleClose={closeConfirms}
          handleOk={handleRestore}
          title={t('restore_version')}
          description={t('restore_version_description')}
        />
      </>
    )
  }

  return (
    <TableRow classes={{ root: classes.root }} hover>
      <TableCell className={classes.firstCell} size='small'>
        {item.name}
      </TableCell>
      <TableCell className={classes.cell} size='small'>
        <div className={classes.message}>
          <span title={item.message}>
            {item.message}
          </span>
        </div>
      </TableCell>
      <TableCell className={classes.cell} size='small'>
        {item.type}
      </TableCell>
      <TableCell className={classes.cell} size='small'>
        {formatDateTime(item.createdAt)}
      </TableCell>
      <TableCell className={classes.lastCell} size='small'>
        {renderRestore()}
      </TableCell>
    </TableRow>
  )
}

export default EntityVersionTableRow
