import React, { useState, useEffect } from 'react'
import qs from 'qs'
import get from 'lodash/get'
import { useNavigate } from 'react-router-dom'
import orderBy from 'lodash/orderBy'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'
import { useStore } from '../../../Models/RootStore'
import { PageHeader, CustomEntityTable, DataActions, CreateMainEntityModal } from '../../../Components'
import { EntitySortOptions, MainEntityTypes } from '../../../Constants'

const useStyles = makeStyles({
  root: {
  },
  container: {
    padding: '2.5rem',
    paddingBottom: 100
  }
})

const TABS = {
  activeItems: 0,
  closedItems: 1
}

function Entities(props) {
  const classes = useStyles()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const getTabs = () => [
    { value: TABS.activeItems, label: t('active_items') },
    { value: TABS.closedItems, label: t('closed_items') }
  ]

  const getTypeFilter = () => {
    return qs.parse(window.location.search, { ignoreQueryPrefix: true }).type || ''
  }

  const getInitialSortValue = () => {
    const sortVal = qs.parse(window.location.search, { ignoreQueryPrefix: true }).sort || ''
    if (sortVal && EntitySortOptions.find(opt => opt.value === sortVal)) {
      return sortVal
    }
    return EntitySortOptions[0].value
  }

  const { entityStore, sessionStore, infoPageStore }: any = useStore()
  useEffect(() => {
    entityStore.getMainEntities()
    entityStore.getMainEntityTypes()
    infoPageStore.getInfoPages()
  }, [])

  const [tab, setTab] = useState(TABS.activeItems)
  const [searchValue, setSearchValue] = useState('')
  const [sortValue, setSortValue] = useState(getInitialSortValue())
  const [createOpen, setCreateOpen] = useState(false)
  const toggleCreateOpen = () => setCreateOpen(!createOpen)

  const selectTab = (value) => setTab(value)

  const createEntity = (fields: any) => {
    const body = {
      name: fields?.nameFi,
      nameFi: fields?.nameFi,
      nameEn: fields?.nameEn,
      mainEntityTypeId: fields?.type,
      // TODO: fix later
      areaMargin: 0
    }
    const callback = (entityId) => navigate(`/entity/${entityId}?mode=edit`)
    entityStore.createMainEntity(body, callback)
  }

  const deleteMainEntity = (mainEntityId) => {
    entityStore.deleteMainEntity(mainEntityId)
  }
  const restoreEntity = (mainEntityId) => {
    entityStore.restoreMainEntity(mainEntityId)
  }

  const getTypeName = () => {
    const currentType = getTypeFilter()
    const type = entityStore.mainEntityTypes.find((type) => type.type === currentType)
    if (type) return type.name
    return null
  }

  const getMainEntities = () => {
    // Filter type
    const type = getTypeFilter()
    let mainEntities = entityStore.mainEntities || []

    // Apply tab
    if (tab === TABS.activeItems) mainEntities = mainEntities.filter(item => item.active)
    else if (tab === TABS.closedItems) mainEntities = mainEntities.filter(item => !item.active)

    if (type) {
      mainEntities = mainEntities.filter(item => item.mainEntityType.type === type)
    }

    // Apply search filters
    if (searchValue) {
      mainEntities = mainEntities.filter(item => item.name.toLowerCase().includes(searchValue.trim().toLowerCase()))
    }

    // Apply sorting
    if (sortValue === 'created_at') {
      mainEntities = orderBy(mainEntities, ['createdAt'], ['desc'])
    } else if (sortValue === 'alphabetical') {
      mainEntities = orderBy(mainEntities, ['name'], ['asc'])
    } else if (sortValue === 'snapshotted_at') {
      mainEntities = orderBy(mainEntities, ['snapshotCreatedAt'], ['desc'])
    }

    return mainEntities
  }

  const renderCreateEntityModal = () => {
    if (createOpen) {
      return (
        <CreateMainEntityModal
          open={createOpen}
          handleCreate={createEntity}
          handleClose={toggleCreateOpen}
          allMainEntityTypes={Object.values(MainEntityTypes)}
          myMainEntityTypes={entityStore.mainEntityTypes}
        />
      )
    }
    return null
  }

  return (
    <div className={classes.root}>
      <PageHeader
        title={getTypeName()}
        tabs={getTabs()}
        currentTab={tab}
        onTabChange={selectTab}
        onCreateClick={toggleCreateOpen}
        createButtonDisabled={!['admin', 'boss', 'entity_admin'].includes(get(sessionStore.user, 'role'))}
      />
      <div className={classes.container}>
        <DataActions
          searchPlaceholder={t('search_by_name')}
          searchValue={searchValue}
          onSearchChange={setSearchValue}
          sortOptions={EntitySortOptions}
          sortValue={sortValue}
          onSortChange={setSortValue}
        />
        <CustomEntityTable
          mainEntityTypes={entityStore.mainEntityTypes}
          entities={getMainEntities()}
          onDelete={deleteMainEntity}
          onRestore={restoreEntity}
        />
        {renderCreateEntityModal()}
      </div>
    </div>
  )
}

export default observer(Entities)
