import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { Title, Select, Input, Button, PageHeader, MultipleSelect } from '../../../Components'
import { EmployeeCountOptions, IndustryOptions, RevenueOptions } from '../../../Constants'
import { Colors } from '../../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    overflow: 'hidden'
  },
  contentContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 0
  },
  content: {
    position: 'relative',
    flexDirection: 'column',
    flexGrow: 1,
    padding: '2.5rem',
    paddingBottom: '20rem',
    overflow: 'auto'
  },
  actions: {
    borderLeft: `1px solid ${Colors.black20}`,
    height: 'calc(100% - 11rem)',
    width: '24rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexShrink: 0,
    overflowX: 'hidden',
    overflowY: 'hidden',
    padding: '2.5rem'
  },
  text: {
    margin: 0,
    fontFamily: 'Roboto',
    fontSize: '1.125rem',
    fontWeight: 300,
    color: Colors.black,
    marginBottom: '1.5rem'
  },
  boldText: {
    fontWeight: 700
  },
  line: {
    height: 1,
    width: '100%',
    backgroundColor: Colors.black20,
    marginBottom: '2rem'
  },
  profileContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '1rem 0',
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column'
    }
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column'
    }
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  spacer: {
    width: '3rem',
    height: '1rem',
    [theme.breakpoints.down('lg')]: {
      height: 0
    }
  },
  infoContainer: {
    paddingRight: '3rem',
    [theme.breakpoints.down('lg')]: {
      padding: 0
    }
  },
  accountContainer: {
    padding: '1rem 0'
  },
  locationContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  address: {
    width: 700
  },
  zip: {
    width: 168,
    marginRight: '2rem'
  },
  city: {
    width: 500
  }
}))

function CreateOrganization() {
  const classes = useStyles()
  const { t } = useTranslation()

  const [email, setEmail] = useState('')
  const [submitted, setSubmitted] = useState(false)
  const [name, setName] = useState('')
  const [businessId, setBusinessId] = useState('')
  const [description, setDescription] = useState('')
  const [address, setAddress] = useState('')
  const [zipCode, setZipCode] = useState('')
  const [city, setCity] = useState('')
  const [contactInfo, setContactInfo] = useState('')
  const [employeeCount, setEmployeeCount] = useState('')
  const [industry, setIndustry] = useState([])
  const [revenue, setRevenue] = useState('')

  const { organizationStore }: any = useStore()

  const emailValidation = () => {
    if (submitted && !/\S+@\S+\.\S+/.test(email)) {
      return { error: true, helperText: t('invalid_email') }
    }
    return {}
  }

  const disabled = !email || !name || !businessId

  const createOrganization = () => {
    setSubmitted(true)
    organizationStore.createOrganization({
      logo: null,
      name,
      businessId,
      description,
      address,
      zipCode,
      city,
      contactInfo,
      employeeCount,
      industry: industry.length ? industry.join(',') : null,
      revenue
    })
  }

  return (
    <div className={classes.root}>
      <PageHeader title={t('create_organization')} />
      <div className={classes.contentContainer}>
        <div className={classes.content}>
          <Title
            title={t('organization_contact_person')}
            description={t('organization_contact_person_description')}
          />
          <Input label={t('email')} value={email} onChange={setEmail} {...emailValidation()} />
          <div className={classes.spacer} />
          <Title
            title={t('organization_identification_info')}
            description={t('organization_identification_description')}
          />
          <div className={classes.profileContainer}>
            <div className={classes.column}>
              <Input label={t('name')} value={name} onChange={setName} />
              <Input
                label={t('description')}
                value={description}
                onChange={setDescription}
                multiline
              />
            </div>
          </div>
          <div className={classes.line} />
          <Title title={t('business_information')} type='subtitle' />
          <div className={classes.rowContainer}>
            <div className={classes.column}>
              <Input label={t('business_id')} value={businessId} onChange={setBusinessId} />
            </div>
            <div className={classes.spacer} />
            <div className={classes.column}>
              <Select
                options={EmployeeCountOptions}
                label={t('employee_count')}
                value={employeeCount}
                onChange={setEmployeeCount}
              />
            </div>
          </div>
          <div className={classes.rowContainer}>
            <div className={classes.column}>
              <MultipleSelect
                options={IndustryOptions}
                label={t('industry')}
                value={industry}
                onChange={setIndustry}
              />
            </div>
            <div className={classes.spacer} />
            <div className={classes.column}>
              <Select
                options={RevenueOptions}
                label={t('revenue_class')}
                value={revenue}
                onChange={setRevenue}
              />
            </div>
          </div>
          <div className={classes.spacer} />
          <Title title={t('contact_info')} type='subtitle' />
          <div className={classes.locationContainer}>
            <div className={classes.address}>
              <Input label={t('street_address')} value={address} onChange={setAddress} />
            </div>
          </div>
          <div className={classes.locationContainer}>
            <div className={classes.zip}>
              <Input label={t('zip_code')} value={zipCode} onChange={setZipCode} />
            </div>
            <div className={classes.city}>
              <Input label={t('city')} value={city} onChange={setCity} />
            </div>
          </div>
          <Input
            label={t('contact_info')}
            value={contactInfo}
            onChange={setContactInfo}
            multiline
          />
        </div>
        <div className={classes.actions}>
          <div>
            <p className={classes.text}>{t('create_organization_description')}</p>
            <Button
              text={t('create_organization')}
              onClick={createOrganization}
              disabled={disabled}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default observer(CreateOrganization)
