import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import TextField from '@mui/material/TextField'
import { Colors } from '../../Utils/theme'
import CalendarIcon from '../../Assets/Icons/calendar.svg'

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingBottom: '1.5rem',
    position: 'relative'
  },
  containerNoMargin: {
    marginBottom: 0,
    paddingBottom: 0
  },
  containerQuantity: {
    minWidth: '8.25rem',
    width: '8.25rem'
  },
  inputRoot: {
    padding: 0
  },
  input: {
    height: '3.125rem',
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: '1rem',
    color: Colors.inputText,
    backgroundColor: Colors.lightGrey,
    borderRadius: '0.3125rem',
    padding: '0 1rem !important',
    '&:focus': {
      backgroundColor: Colors.white
    }
  },
  inputWithLeftIcon: {
    height: '3.125rem',
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: '1rem',
    color: Colors.inputText,
    backgroundColor: Colors.lightGrey,
    borderRadius: '0.3125rem',
    padding: '0 1rem 0 3rem',
    '&:focus': {
      backgroundColor: Colors.white
    },
    '&::placeholder': {
      opacity: 1
    }
  },
  multiline: {
    minHeight: '8rem',
    maxHeight: '8rem',
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: '1rem',
    color: Colors.inputText,
    backgroundColor: Colors.lightGrey,
    borderRadius: '0.3125rem',
    padding: '1rem !important',
    '&:focus': {
      backgroundColor: Colors.white
    }
  },
  label: {
    fontSize: '1rem',
    fontWeight: 500,
    margin: 0,
    marginBottom: '0.5rem',
    color: Colors.label
  },
  iconLeft: {
    position: 'absolute',
    left: '1rem',
    height: '1rem',
    userSelect: 'none'
  },
  quantityContainer: {
    userSelect: 'none',
    pointerEvents: 'none',
    position: 'absolute',
    right: 2,
    top: 2,
    bottom: 2,
    backgroundColor: Colors.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'calc(3.125rem - 4px)',
    padding: '0 1rem',
    borderLeft: `2px solid ${Colors.border}`,
    borderTopRightRadius: '0.3125rem',
    borderBottomRightRadius: '0.3125rem'
  },
  quantity: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: '1rem',
    color: Colors.violet
  },
  endAdornmentIconContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    backgroundColor: Colors.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '3.125rem',
    borderLeft: `2px solid ${Colors.border}`,
    borderTopRightRadius: '0.3125rem',
    borderBottomRightRadius: '0.3125rem',
    userSelect: 'none',
    pointerEvents: 'none'
  },
  endAdornmentIcon: {
    maxWidth: '1.25rem',
    // marginRight: '.25rem'
  },
  notchedOutline: {
    border: `2px solid ${Colors.border}`
  }
}))

export default function Input(props) {
  const { iconLeft, ...rest } = props 

  const classes = useStyles()

  const getContainerClass = () => {
    const classNames = [classes.container]
    if (props.noMargin) classNames.push(classes.containerNoMargin)
    if (props.type === 'quantity') classNames.push(classes.containerQuantity)
    return classNames.join(' ')
  }

  const getInputClass = () => {
    if (props.multiline) return classes.multiline
    if (props.iconLeft) return classes.inputWithLeftIcon
    return classes.input
  }

  const onChange = (evt) => props.onChange(evt.target.value)

  const renderQuantity = () => {
    if (props.type === 'quantity') {
      return (
        <div className={classes.quantityContainer}>
          <div className={classes.quantity}>{props.unit}</div>
        </div>
      )
    }
    return null
  }

  const renderIconLeft = () => {
    if (props.iconLeft) {
      return <img src={props.iconLeft} className={classes.iconLeft} />
    }
    return null
  }

  const getEndAdornment = () => {
    if (props.type === 'date') {
      return (
        <div className={classes.endAdornmentIconContainer}>
          <img src={CalendarIcon} className={classes.endAdornmentIcon} />
        </div>
      )
    }
    return null
  }

  const renderLabel = () => {
    if (props?.label) {
      return <p className={classes.label}>{props.label}</p>
    }
    return null
  }

  return (
    <div className={getContainerClass()}>
      {renderLabel()}
      <TextField
        {...rest}
        id={props.id}
        value={props.value || ''}
        onKeyPress={props.handleKeyPress}
        InputProps={{
          classes: {
            input: getInputClass(),
            root: classes.inputRoot,
            notchedOutline: classes.notchedOutline
          },
          endAdornment: getEndAdornment()
        }}
        inputProps={{ maxLength: props.maxLength }}
        type={props.type}
        multiline={props.multiline}
        variant='outlined'
        autoFocus={!!props.autoFocus}
        disabled={props.disabled}
        error={props.error}
        helperText={props.helperText}
        onChange={onChange}
        label={undefined}
        InputLabelProps={!props.label ? undefined : {
          shrink: false
        }}
        sx={{
          '& input[type="time"]::-webkit-calendar-picker-indicator': {
            filter: 'invert(19%) sepia(62%) saturate(2227%) hue-rotate(274deg) brightness(90%) contrast(91%)'
          }
        }}
      />
      {renderQuantity()}
      {renderIconLeft()}
    </div>
  )
}
