import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'
import { useStore } from '../../../Models/RootStore'
import Grid from '@mui/material/Grid'
import { WikiHeader, WikiMainEntityTypeCard } from '../../../Components'
import { useNavigate, useParams } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
  },
  container: {
    padding: '2.5rem 2rem 4rem'
  },
  cardsContainer: {
    width: '100%',
    margin: '0 auto',
    maxWidth: '51rem'
  }
}))

interface MainEntityCardItem {
  id: number
  name: string
  photo: string
}

interface MainEntityCardItems extends Array<MainEntityCardItem>{}

function WikiEntities() {
  const classes = useStyles()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const { mainEntityType }: any = useParams()

  const { entityStore, infoPageStore }: any = useStore()

  useEffect(() => {
    entityStore.getMainEntities()
    entityStore.getMainEntityTypes()
    infoPageStore.getInfoPages()
  }, [])

  const getMainEntities = () => {
    const mainEntities: MainEntityCardItems = []

    for (const mainEntity of entityStore.mainEntities) {
      if (mainEntity.mainEntityTypeId === Number(mainEntityType)) {
        mainEntities.push({
          id: mainEntity.id,
          name: mainEntity.nameFi || mainEntity.nameEn,
          photo: mainEntity.photo || null
        })
      }
    }

    return mainEntities
  }

  const toWiki = (mainEntityId) => navigate(`/wiki/entity/${mainEntityId}`)

  const renderMainEntities = () => {
    let mainEntities = getMainEntities()

    return mainEntities.map(item => (
      <WikiMainEntityTypeCard
        key={item.id}
        item={item}
        onClick={() => toWiki(item.id)}
      />
    ))
  }

  return (
    <div className={classes.root}>
      <WikiHeader
        title={t('entity_type_guides')}
      />
      <div className={classes.container}>
        <div className={classes.cardsContainer}>
          <Grid
            direction='row'
            justifyContent='flex-start'
            alignItems='center'
            spacing={2}
            container
          >
            {renderMainEntities()}
          </Grid>
        </div>
      </div>
    </div>
  )
}

export default observer(WikiEntities)
