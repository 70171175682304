import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'
import get from 'lodash/get'
import { useParams } from 'react-router-dom'
import { useStore } from '../../../Models/RootStore'
import { PageHeader, Button, Input, Select } from '../../../Components'
import { CUSTOM_FILED_TYPES } from '../../../Constants'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  contentContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 0
  },
  content: {
    padding: '2.5rem'
  }
}))

const AdminMainEntityField = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { adminStore }: any = useStore()
  const { mainEntityFieldId }: any = useParams()

  useEffect(() => {
    adminStore.getMainEntityField(Number(mainEntityFieldId))
  }, [])

  useEffect(() => {
    if (adminStore.mainEntityField && adminStore.mainEntityField.id === Number(mainEntityFieldId)) {
      setFieldType(get(adminStore.mainEntityField, 'fieldType', null))
      setFieldKey(get(adminStore.mainEntityField, 'fieldKey', null))
      setLabelFi(get(adminStore.mainEntityField, 'labelFi', null))
      setLabelEn(get(adminStore.mainEntityField, 'labelEn', null))
      const optionsObj = get(adminStore.mainEntityField, 'options', null)
      setOptions(optionsObj ? JSON.stringify(optionsObj) : null)
      const fieldDefaultValueObj = get(adminStore.mainEntityField, 'fieldDefaultValue', null)
      setFieldDefaultValue(fieldDefaultValueObj ? JSON.stringify(fieldDefaultValueObj) : null)
    }
  }, [adminStore.mainEntityField])

  const [fieldType, setFieldType] = useState(null)
  const [fieldKey, setFieldKey] = useState(null)
  const [fieldDefaultValue, setFieldDefaultValue] = useState(null)

  const [labelFi, setLabelFi] = useState(null)
  const [labelEn, setLabelEn] = useState(null)
  const [options, setOptions] = useState(null)

  const handleDelete = () => {
    if ((window.prompt('WARNING: This will delete alot of data. Are you absolutely sure what you are about to do? Type YES if you are sure.') || '').toLowerCase() === 'yes') {
      adminStore.deleteMainEntityField(Number(mainEntityFieldId))
    }
  }

  const handleSave = () => {
    const optionsJson = (options && options.trim()) ? JSON.parse(options) : null
    const fieldDefaultValueJson = (fieldDefaultValue && fieldDefaultValue.trim()) ? JSON.parse(fieldDefaultValue) : null

    adminStore.editMainEntityField(
      Number(mainEntityFieldId),
      {
        fieldType,
        fieldKey,
        labelFi,
        labelEn,
        options: optionsJson,
        fieldDefaultValue: fieldDefaultValueJson
      }
    )
  }

  const getTypeOptions = () => CUSTOM_FILED_TYPES.map(type => {
    return {
      label: type,
      value: type
    }
  })

  return (
    <div className={classes.root}>
      <PageHeader
        title={t('edit_main_entity_field')}
      />
      <div className={classes.contentContainer}>
        <div className={classes.content}>
          <Select
            options={getTypeOptions()}
            value={fieldType}
            onChange={setFieldType}
            label='fieldType'
          />
          <Input
            label='fieldKey'
            value={fieldKey}
            onChange={setFieldKey}
          />
          <Input
            label='labelFi'
            value={labelFi}
            onChange={setLabelFi}
             />
          <Input
            label='labelEn'
            value={labelEn}
            onChange={setLabelEn}
          />
          <Input
            label='options'
            value={options}
            onChange={setOptions}
            multiline
          />
          <Input
            label='fieldDefaultValue'
            value={fieldDefaultValue}
            onChange={setFieldDefaultValue}
            multiline
          />
          <Button
            text={t('save')}
            onClick={handleSave}
          />
          <div style={{ height: '10rem' }} />
          <Button
            text={t('delete')}
            onClick={handleDelete}
            outlined
          />
        </div>
      </div>
    </div>
  )
}

export default observer(AdminMainEntityField)
