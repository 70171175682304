import React from 'react'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import { Colors } from '../../Utils/theme'
import ButtonBase from '@mui/material/ButtonBase'
import Chip from '@mui/material/Chip'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    width: '22.5rem',
    height: '14.375rem',
    overflowY: 'hidden',
    backgroundColor: Colors.backgroundGrey,
    borderRadius: '0.3125rem',
    marginBottom: '2rem',
    marginRight: '2rem',
    padding: '1.5rem',
    '&:hover': {
      backgroundColor: Colors.grey
    }
  },
  businessId: {
    fontFamily: 'Roboto',
    fontWeight: 300,
    color: Colors.black,
    fontSize: '0.875rem',
    marginBottom: '0.5rem',
    textAlign: 'left'
  },
  name: {
    color: Colors.black,
    fontSize: '1.5rem',
    fontFamily: 'Rubik',
    fontWeight: 600,
    margin: 0,
    padding: 0,
    marginBottom: '1rem',
    textAlign: 'left',
  },
  description: {
    fontFamily: 'Roboto',
    color: Colors.black,
    fontSize: '1rem',
    fontWeight: 300,
    textAlign: 'left',
    // Limit max lines
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden'
  },
  domains: {
    flexDirection: 'row',
    marginTop: '2rem'
  },
  chip: {
    backgroundColor: Colors.black80,
    fontFamily: 'Roboto',
    fontSize: '0.75rem',
    fontWeight: 600,
    color: Colors.white,
    borderRadius: '0.3125rem',
    marginRight: '0.5rem'
  }
}))

function OrganizationCard (props) {
  const { organization } = props
  const classes = useStyles()
  const { t } = useTranslation()

  const onClick = () => props.onClick(organization.id)

  const renderDomains = () => {
    const industries = organization.industry
    if (industries && industries.length) {
      if (industries.includes(',')) {
        return industries.split(',').map((industry) => (
          <Chip label={t(industry)} classes={{ root: classes.chip }} />
        ))
      }
      return <Chip label={t(industries)} classes={{ root: classes.chip }} />
    }
    return null
  }

  return (
    <ButtonBase className={classes.container} onClick={onClick}>
      <div>
        <p className={classes.businessId}>{organization.businessId}</p>
        <p className={classes.name}>{organization.name}</p>
        <p className={classes.description}>{organization.description}</p>
      </div>
      <div className={classes.domains}>
        {renderDomains()}
      </div>
    </ButtonBase>
  )
}

export default OrganizationCard
