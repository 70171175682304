import moment from 'moment'

export const formatDateTime = (dateISOString: string) => {
  return moment(dateISOString).format('DD.MM.YYYY HH.mm')
}

// Check if date A (ISO string) is before date B (ISO string)
export const isBefore = (a: string, b: string) => {
  if (a && b) {
    return moment(a).valueOf() < moment(b).valueOf()
  }
  return false
}
