import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { Colors } from '../../Utils/theme'
import CheckIcon from '../../Assets/Icons/check-minimal.svg'

const useStyles = makeStyles((theme) => ({
  container: {
    border: `2px solid ${Colors.border}`,
    height: '1.5625rem',
    width: '1.5625rem',
    borderRadius: '.25rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.lightGrey,
    marginRight: '0.25rem'
  },
  check: {
    height: '1rem',
    maxHeight: '1rem',
    maxWidth: '1rem'
  },
  label: {
    fontWeight: 500,
    color: Colors.inputText
  }
}))

export default function CustomCheckbox(props) {
  const classes = useStyles()

  const getClasses = () => {
    if (props.classes) {
      return { label: classes.label, ...props.classes }
    }
    return { label: classes.label }
  }

  const getContainerClasses = () => {
    if (props.containerStyle) {
      return `${classes.container} ${props.containerStyle}`
    }
    return classes.container
  }

  const renderUnchecked = () => {
    return <div className={getContainerClasses()} />
  }
  const renderChecked = () => {
    return (
      <div className={getContainerClasses()}>
        <img src={CheckIcon} className={props.checkStyle ? props.checkStyle : classes.check} />
      </div>
    )
  }

  return (
    <FormControlLabel
      control={
        <Checkbox
          checkedIcon={renderChecked()}
          icon={renderUnchecked()}
          checked={props.checked}
          onChange={props.onChange}
        />
      }
      label={props.label}
      classes={getClasses()}
    />
  )
}
