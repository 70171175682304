import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'

export default class MainEntityNotificationStore {
  rootStore
  mainEntityNotifications = []
  mainEntityNotification = null
  loading = null

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  updateMainEntityNotification = (mainEntityNotification) => { this.mainEntityNotification = mainEntityNotification }
  updateMainEntityNotifications = (mainEntityNotifications) => { this.mainEntityNotifications = mainEntityNotifications }
  updateLoading = (loading) => { this.loading = loading }

  async getMainEntityNotifications(mainEntityId) {
    this.updateLoading(true)
    const response: any = await Api.getMainEntityNotifications(mainEntityId)
    this.updateLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, true, false)

    if (response.ok) {
      const mainEntityNotifications = response.data
      this.updateMainEntityNotifications(mainEntityNotifications)
    }
  }

  async getMainEntityNotification(mainEntityNotificationId) {
    this.updateLoading(true)
    const response: any = await Api.getMainEntityNotification(mainEntityNotificationId)
    this.updateLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, true, false)

    if (response.ok) {
      const mainEntityNotification = response.data
      this.updateMainEntityNotification(mainEntityNotification)
    }
  }

  async createMainEntityNotification(mainEntityNotification) {
    this.updateLoading(true)
    const response: any = await Api.createMainEntityNotification(mainEntityNotification)
    this.updateLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, true, false)
    if (response.ok) {
      this.getMainEntityNotifications(mainEntityNotification.mainEntityId)
    }
  }

  async deleteMainEntityNotification(mainEntityNotificationId) {
    //this.updateLoading(true)
    const response: any = await Api.deleteMainEntityNotification(mainEntityNotificationId)
    //this.updateLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'notification_deleted')

    if (response.ok) {
      // Drop notification from our list
      const newMainEntityNotifications = (this.mainEntityNotifications || []).filter(item => item.id !== mainEntityNotificationId)
      this.updateMainEntityNotifications(newMainEntityNotifications)
    }
  }

  async editMainEntityNotification(mainEntityNotificationId, mainEntityNotification) {
    this.updateLoading(true)
    const response: any = await Api.updateMainEntityNotification(mainEntityNotificationId, mainEntityNotification)
    this.updateLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, true, false)

    if (response.ok) {
      this.mainEntityNotification = response.data
    }
  }
}
