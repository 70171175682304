import {
  SPACER,
  EQUIPMENT_TITLE,
  OPEN_HOURS,
  OPEN_HOURS_AND_PRICE_INFO_TITLE,
  OTHER_SERVICES_TITLE,
  PRICE,
  SAUNA_TYPES,
  SWIMMING_HALL_EQUIPMENT,
  SWIMMING_HALL_OTHER_SERVICES,
  EXCEPTION_NOTIFICATION,
  CONTACT_INFORMATION_TITLE,
  STREET_ADDRESS,
  CITY,
  POSTAL_CODE,
  EXCEPTION_NOTIFICATIONS_TITLE,
  DESCRIPTION,
  DESCRIPTION_TITLE
} from '../elements.template'

const LAYOUT = {
  type: 'swimming_hall',
  layout: [
    [
      // Start left column
      DESCRIPTION_TITLE,
      DESCRIPTION,
      OPEN_HOURS_AND_PRICE_INFO_TITLE,
      OPEN_HOURS,
      PRICE
      // End left column
    ],
    [
      // Start right column
      EQUIPMENT_TITLE,
      SWIMMING_HALL_EQUIPMENT,
      SAUNA_TYPES,
      OTHER_SERVICES_TITLE,
      SWIMMING_HALL_OTHER_SERVICES,
      EXCEPTION_NOTIFICATIONS_TITLE,
      { ...EXCEPTION_NOTIFICATION, hideLabel: true, style: { paddingTop: '0.375rem' } },
      CONTACT_INFORMATION_TITLE,
      {
        type: 'container',
        className: 'customRowFixed',
        style: { paddingBottom: '10rem' },
        items: [
          STREET_ADDRESS,
          SPACER,
          CITY,
          SPACER,
          POSTAL_CODE
        ]
      }
      // End right column
    ]
  ]
}

export default LAYOUT
