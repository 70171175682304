import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'
import get from 'lodash/get'
import { useParams } from 'react-router-dom'
import { useStore } from '../../../Models/RootStore'
import { PageHeader, Title, Button, ImageUpload, Input, Select, DataTable } from '../../../Components'
import { Colors } from '../../../Utils/theme'
import { EmployeeCountOptions, IndustryOptions, RevenueOptions } from '../../../Constants'
import { formatDateTime } from '../../../Utils/dateTime'
import AdminOrganizationMainEntityTypesForm from '../../../Components/Admin/AdminOrganizationMainEntityTypesForm'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    overflow: 'hidden'
  },
  contentContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 0
  },
  content: {
    position: 'relative',
    flexDirection: 'column',
    flexGrow: 1,
    padding: '2.5rem',
    paddingBottom: '20rem',
    overflow: 'auto'
  },
  actions: {
    borderLeft: `1px solid ${Colors.black20}`,
    height: 'calc(100% - 11rem)',
    width: '24rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexShrink: 0,
    overflowX: 'hidden',
    overflowY: 'hidden',
    padding: '2.5rem'
  },
  text: {
    margin: 0,
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: 300,
    color: Colors.black,
    marginBottom: '1.5rem'
  },
  boldText: {
    fontWeight: 700
  },
  line: {
    height: 1,
    width: '100%',
    backgroundColor: Colors.black20,
    marginBottom: '2rem'
  },
  profileContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '1rem 0',
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column'
    }
  },
  usersContainer: {
    padding: '0 2.5rem',
    flex: 1
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: 1055,
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column'
    }
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  spacer: {
    width: '3rem',
    height: '1rem',
    [theme.breakpoints.down('lg')]: {
      height: 0
    }
  },
  infoContainer: {
    paddingRight: '3rem',
    [theme.breakpoints.down('lg')]: {
      padding: 0
    }
  },
  accountContainer: {
    padding: '1rem 0'
  },
  locationContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  address: {
    width: 700
  },
  zip: {
    width: 168,
    marginRight: '2rem'
  },
  city: {
    width: 500
  }
}))

const TABS = {
  basicInfo: 0,
  entityTypes: 1,
  users: 2,
  invites: 3
}

const Organization = () => {
  const classes = useStyles()
  const { organizationStore, adminStore }: any = useStore()
  const { organizationId }: any = useParams()

  useEffect(() => {
    organizationStore.getOrganization(organizationId)
    adminStore.getMainEntityTypes()
    adminStore.getOrganizationMainEntityTypes(organizationId)
    adminStore.getOrganizationUsers(organizationId)
    adminStore.getOrganizationInvites(organizationId)
  }, [])

  useEffect(() => {
    if (organizationStore.organization) {
      setLogo(get(organizationStore.organization, 'logo', null))
      setName(get(organizationStore.organization, 'name'))
      setBusinessId(get(organizationStore.organization, 'businessId'))
      setAddress(get(organizationStore.organization, 'address'))
      setCity(get(organizationStore.organization, 'city'))
      setZipCode(get(organizationStore.organization, 'zipCode'))
      setDescription(get(organizationStore.organization, 'description'))
      setContactInfo(get(organizationStore.organization, 'contactInfo'))
      setEmployeeCount(get(organizationStore.organization, 'employeeCount'))
      setIndustry(get(organizationStore.organization, 'industry'))
      setRevenue(get(organizationStore.organization, 'revenue'))
    }
  }, [organizationStore.organization])

  const [editMode, setEditMode] = useState(false)
  const [logo, setLogo] = useState(get(organizationStore.organization, 'logo', null))
  const [name, setName] = useState(get(organizationStore.organization, 'name', ''))
  const [businessId, setBusinessId] = useState(get(organizationStore.organization, 'businessId', ''))
  const [address, setAddress] = useState(get(organizationStore.organization, 'address', ''))
  const [city, setCity] = useState(get(organizationStore.organization, 'city', ''))
  const [zipCode, setZipCode] = useState(get(organizationStore.organization, 'zipCode', ''))
  const [description, setDescription] = useState(get(organizationStore.organization, 'description', ''))
  const [contactInfo, setContactInfo] = useState(get(organizationStore.organization, 'contactInfo', ''))
  const [employeeCount, setEmployeeCount] = useState(get(organizationStore.organization, 'employeeCount', ''))
  const [industry, setIndustry] = useState(get(organizationStore.organization, 'industry'))
  const [revenue, setRevenue] = useState(get(organizationStore.organization, 'revenue', ''))

  const [inviteEmail, setInviteEmail] = useState(null)

  const [tab, setTab] = useState(TABS.basicInfo)
  const selectTab = (value) => {
    setTab(value)
    // Refresh organization main entity types
    adminStore.getOrganizationMainEntityTypes(organizationId)
  }

  const toggleEditMode = () => setEditMode(!editMode)

  const { t } = useTranslation()

  const getTabs = () => {
    return [
      { value: TABS.basicInfo, label: t('basic_info') },
      { value: TABS.entityTypes, label: t('organization_entity_types') },
      { value: TABS.users, label: t('organization_users') },
      { value: TABS.invites, label: t('invites') }
    ]
  }

  const saveMainEntityTypes = (typeIds) => {
    const currentMainEntityTypeIds = adminStore.organizationMainEntityTypes.map(item => {
      return item.mainEntityTypeId
    })

    const addIds = []
    const removeIds = []
    for (const typeId of typeIds) {
      if (!currentMainEntityTypeIds.includes(typeId)) {
        addIds.push(typeId)
      }
    }
    for (const currentTypeId of currentMainEntityTypeIds) {
      if (!typeIds.includes(currentTypeId)) {
        removeIds.push(currentTypeId)
      }
    }

    adminStore.addOrganizationMainEntityTypes(Number(organizationId), addIds)
    adminStore.deleteOrganizationMainEntityTypes(Number(organizationId), removeIds)

  }

  // TODO: Make this better...
  const inviteAdminUser = () => {
    if (inviteEmail && inviteEmail.length > 3) {
      organizationStore.inviteAdminUser(organizationStore.organization.id, {
        email: inviteEmail,
        role: 'boss'
      })
    }
  }

  const updateOrganizationLogo = (files) => {
    if (files && files.length) {
      const file = files[0]
      setLogo(URL.createObjectURL(file))
      organizationStore.updateOrganizationLogo(organizationId, file)
    }
  }

  const removeOrganizationLogo = () => {
    setLogo(null)
    organizationStore.updateOrganizationData(organizationId, { logo: null })
  }

  const updateOrganization = () => {
    organizationStore.updateOrganizationData(organizationId, {
      logo,
      name,
      businessId,
      city,
      address,
      zipCode,
      description,
      contactInfo,
      employeeCount,
      industry,
      revenue
    })
  }

  const disabled = !editMode

  const renderBasicInfo = () => {
    return (
      <>
        <div className={classes.content}>
          <Title title={t('organization_details')} description={t('organization_details_description')} />
          <div className={classes.profileContainer}>
            <ImageUpload
              photo={logo}
              onFileUpload={updateOrganizationLogo}
              removePhoto={removeOrganizationLogo}
              disabled={disabled}
            />
            <div className={classes.spacer} />
            <div className={classes.column}>
              <Input
                label={t('name')}
                value={name}
                onChange={setName}
                disabled={disabled}
              />
              <Input
                label={t('description')}
                value={description}
                onChange={setDescription}
                disabled={disabled}
                multiline
              />
            </div>
          </div>
          <div className={classes.line} />
          <Title title={t('business_information')} type='subtitle' />
          <div className={classes.rowContainer}>
            <div className={classes.column}>
              <Input
                label={t('business_id')}
                value={businessId}
                onChange={setBusinessId}
                disabled={disabled}
              />
            </div>
            <div className={classes.spacer} />
            <div className={classes.column}>
              <Select
                options={EmployeeCountOptions}
                label={t('employee_count')}
                value={employeeCount}
                onChange={setEmployeeCount}
                disabled={disabled}
              />
            </div>
          </div>
          <div className={classes.rowContainer}>
            <div className={classes.column}>
              <Select
                options={IndustryOptions}
                label={t('industry')}
                value={industry}
                onChange={setIndustry}
                disabled={disabled}
              />
            </div>
            <div className={classes.spacer} />
            <div className={classes.column}>
              <Select
                options={RevenueOptions}
                label={t('revenue_class')}
                value={revenue}
                onChange={setRevenue}
                disabled={disabled}
              />
            </div>
          </div>
          <div className={classes.spacer} />
          <Title title={t('contact_info')} type='subtitle' />
          <div className={classes.locationContainer}>
            <div className={classes.address}>
              <Input
                label={t('street_address')}
                value={address}
                onChange={setAddress}
                disabled={disabled}
              />
            </div>
          </div>
          <div className={classes.locationContainer}>
            <div className={classes.zip}>
              <Input label={t('zip_code')} value={zipCode} onChange={setZipCode} disabled={disabled} />
            </div>
            <div className={classes.city}>
              <Input label={t('city')} value={city} onChange={setCity} disabled={disabled} />
            </div>
          </div>
          <Input
            label={t('contact_info')}
            value={contactInfo}
            onChange={setContactInfo}
            disabled={disabled}
            multiline
          />
        </div>
        <div className={classes.actions}>
          <div>
            <p className={classes.text}>{t('organization_actions_description')}</p>
            <p className={classes.text}>
              <span className={classes.boldText}>{t('last_modified')}: </span>
              {formatDateTime(get(organizationStore, 'organization.updatedAt'))}
            </p>
            <Button text={t('update_info')} onClick={updateOrganization} disabled={disabled} />
          </div>
        </div>
      </>
    )
  }

  const renderEntityTypes = () => {
    return (
      <div className={classes.content}>
        <AdminOrganizationMainEntityTypesForm
          mainEntityTypes={adminStore.mainEntityTypes}
          organizationMainEntityTypes={adminStore.organizationMainEntityTypes}
          onSave={saveMainEntityTypes}
        />
      </div>
    )
  }

  const renderUsers = () => {
    return (
      <>
        <div className={classes.usersContainer}>
          <div>
            <h2>{t('users')}</h2>
            <DataTable
              columnNames={[
                t('id'),
                t('email'),
                t('role'),
              ]}
              data={adminStore.organizationUsers.map(user => {
                return [
                  user.id,
                  user.email,
                  user.role
                ]
              })}
            />
          </div>
        </div>
        <div className={classes.actions}>
          <div>
            <h3>{t('invite_admin_user')}</h3>
            <Input label={t('email')} value={inviteEmail} onChange={setInviteEmail} />
            <Button
              text={t('invite_admin_user')}
              onClick={inviteAdminUser}
            />
          </div>
        </div>
      </>
    )
  }

  const renderInvites = () => {
    return (
      <>
        <div className={classes.usersContainer}>
          <div>
            <h2>{t('invites')}</h2>
            <DataTable
              columnNames={[
                t('id'),
                t('email'),
                t('role'),
                t('valid_until')
              ]}
              data={adminStore.organizationInvites.map(invite => {
                return [
                  invite.id,
                  invite.email,
                  invite.role,
                  invite.validUntil
                ]
              })}
            />
          </div>
        </div>
        <div className={classes.actions}>
          <div>
            <h3>{t('invite_admin_user')}</h3>
            <Input label={t('email')} value={inviteEmail} onChange={setInviteEmail} />
            <Button
              text={t('invite_admin_user')}
              onClick={inviteAdminUser}
            />
          </div>
        </div>
      </>
    )
  }

  const renderContent = () => {
    if (tab === TABS.basicInfo) return renderBasicInfo()
    if (tab === TABS.entityTypes) return renderEntityTypes()
    if (tab === TABS.users) return renderUsers()
    if (tab === TABS.invites) return renderInvites()
  }

  return (
    <div className={classes.root}>
      <PageHeader
        title={get(organizationStore.organization, 'name') || '-'}
        onEditClick={toggleEditMode}
        editMode={editMode}
        tabs={getTabs()}
        currentTab={tab}
        onTabChange={selectTab}
      />
      <div className={classes.contentContainer}>
        {renderContent()}
      </div>
    </div>
  )
}

export default observer(Organization)
