import React, { useEffect, useState } from 'react'
import { get } from 'lodash'
import {  useParams } from 'react-router-dom'
import { observer } from 'mobx-react'
import moment from 'moment'
import { useStore } from '../../../Models/RootStore'
import { PollForm, PollResult } from '../../../Components'

function Poll() {
  const { pollStore, entityStore }: any = useStore()
  const { pollId }: any = useParams()

  const [langTab, setLangTab] = useState('fi')

  const [titleFi, setTitleFi] = useState('')
  const [titleEn, setTitleEn] = useState('')
  const [contentFi, setContentFi] = useState('')
  const [contentEn, setContentEn] = useState('')
  const [validFrom, setValidFrom] = useState(moment().seconds(0).milliseconds(0).format())
  const [validUntil, setValidUntil] = useState(null)

  const [draft, setDraft] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const [selectedMainEntityTypes, setSelectedMainEntityTypes] = useState([])
  const [selectedMainEntities, setSelectedMainEntities] = useState([])

  const handleSetSelectedMainEntityTypes = (types) => {
    setSelectedMainEntityTypes(types)
    setSelectedMainEntities(selectedMainEntities.filter(id => {
      const mainEntity = entityStore.mainEntities.find(item => item.id === id)
      if (!mainEntity) return false
      return types.includes(mainEntity.mainEntityTypeId)
    }))
  }

  // Initialize poll with one empty question
  const [questions, setQuestions] = useState([])

  const [tab, setTab] = useState('results')

  // Load poll and answer data
  useEffect(() => {
    // Load main entity types
    entityStore.getMainEntityTypes()
    entityStore.getMainEntities()
    pollStore.getPoll(pollId)
    pollStore.getPollAnswers(pollId)
  }, [])

  useEffect(() => {
    if (pollStore.poll) {
      setTitleFi(get(pollStore.poll, 'titleFi', ''))
      setTitleEn(get(pollStore.poll, 'titleEn', ''))
      setContentFi(get(pollStore.poll, 'contentFi', ''))
      setContentEn(get(pollStore.poll, 'contentEn', ''))
      setQuestions(get(pollStore.poll, 'questions', []))
      setDraft(get(pollStore.poll, 'draft', false))
      setValidFrom(get(pollStore.poll, 'validFrom') ? moment(get(pollStore.poll, 'validFrom')).format('YYYY-MM-DD HH:mm:ss') : null)
      setValidUntil(get(pollStore.poll, 'validUntil') ? moment(get(pollStore.poll, 'validUntil')).format('YYYY-MM-DD HH:mm:ss') : null)
      setSelectedMainEntityTypes(get(pollStore.poll, 'mainEntityTypeIds', []))
      setSelectedMainEntities(get(pollStore.poll, 'mainEntityIds', []))
    }
  }, [pollStore.poll])

  const updatePoll = () => {
    setSubmitted(true)

    pollStore.updatePoll(Number(pollId), {
      draft,
      titleFi,
      titleEn: titleEn || titleFi,
      contentFi,
      contentEn: contentEn || contentFi,
      validFrom: validFrom ? moment(validFrom).toISOString() : validFrom,
      validUntil: validUntil ? moment(validUntil).toISOString() : validUntil,
      questions,
      mainEntityTypeIds: selectedMainEntityTypes,
      mainEntityIds: selectedMainEntities
    })
  }

  const renderForm = () => {
    return (
      <PollForm
        langTab={langTab}
        setLangTab={setLangTab}
        titleFi={titleFi}
        setTitleFi={setTitleFi}
        titleEn={titleEn}
        setTitleEn={setTitleEn}
        contentFi={contentFi}
        setContentFi={setContentFi}
        contentEn={contentEn}
        setContentEn={setContentEn}
        questions={questions}
        setQuestions={setQuestions}
        submitted={submitted}

        mainEntityTypes={entityStore.mainEntityTypes}
        selectedMainEntityTypes={selectedMainEntityTypes}
        setSelectedMainEntityTypes={handleSetSelectedMainEntityTypes}
        mainEntities={entityStore.mainEntities}
        selectedMainEntities={selectedMainEntities}
        setSelectedMainEntities={setSelectedMainEntities}

        validFrom={validFrom}
        setValidFrom={setValidFrom}
        validUntil={validUntil}
        setValidUntil={setValidUntil}
        draft={draft}
        setDraft={setDraft}
        save={updatePoll}
        mode='edit'
        onEditClick={() => setTab('results')}
      />
    )
  }

  const renderResults = () => {
    if (pollStore.poll && pollStore.pollAnswers) {
      return (
        <PollResult
          poll={pollStore.poll}
          pollAnswers={pollStore.pollAnswers}
          toEdit={() => setTab('edit')}
          mainEntities={entityStore.mainEntities}
        />
      )
    }
    return null
  }

  const renderContent = () => {
    if (tab === 'edit') {
      return renderForm()
    } else {
      return renderResults()
    }
  }

  return renderContent()
}

export default observer(Poll)
