import React from 'react'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import makeStyles from '@mui/styles/makeStyles'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: Colors.backgroundGrey
  },
  rootOdd: {
    backgroundColor: Colors.lightGrey
  },
  cell: {
    height: '2.75rem',
    fontFamily: 'Roboto',
    fontWeight: 400,
    color: Colors.black,
    fontSize: '1rem'
  }
}))

function DataTableRow (props: any) {
  const classes = useStyles()

  return (
    <TableRow classes={{ root: props.odd ? classes.rootOdd : classes.root }}>
      {props.data.map((value, index) => (
        <TableCell key={index} classes={{ root: classes.cell }} size='small'>
          {value}
        </TableCell>
      ))}
    </TableRow>
  )
}

export default DataTableRow
