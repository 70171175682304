import { Colors } from '../../Utils/theme'

// Main entity layouts
import BEACH from './customMainEntityLayouts/beach.layout'
import SAUNA from './customMainEntityLayouts/sauna.layout'
import SKI_TRAIL from './customMainEntityLayouts/ski_trail.layout'
import TENNIS from './customMainEntityLayouts/tennis.layout'
import PADEL from './customMainEntityLayouts/padel.layout'
import ICE_SKATING from './customMainEntityLayouts/ice_skating.layout'
import OUTDOOR_GYM from './customMainEntityLayouts/outdoor_gym.layout'
import BEACH_VOLLEY from './customMainEntityLayouts/beach_volley.layout'
import FOOTBALL from './customMainEntityLayouts/football.layout'
import BASEBALL_FIELD from './customMainEntityLayouts/baseball_field.layout'
import BASKETBALL from './customMainEntityLayouts/basketball.layout'
import SWIMMING_HALL from './customMainEntityLayouts/swimming_hall.layout'
import DISC_GOLF from './customMainEntityLayouts/disc_golf.layout'
import PLAYGROUND from './customMainEntityLayouts/playground.layout'
import SKATEPARK from './customMainEntityLayouts/skatepark.layout'
import SPORT_FIELD from './customMainEntityLayouts/sport_field.layout'
import PARKING_ZONE from './customMainEntityLayouts/parking_zone.layout'
import AMUSEMENT_PARK from './customMainEntityLayouts/amusement_park.layout'
import THEATER from './customMainEntityLayouts/theater.layout'
import MUSEUM from './customMainEntityLayouts/museum.layout'
import MARKET from './customMainEntityLayouts/market.layout'
import NATURE_TRAIL from './customMainEntityLayouts/nature_trail.layout'
import MARINA from './customMainEntityLayouts/marina.layout'
import STATION from './customMainEntityLayouts/station.layout'
import UNIVERSITY from './customMainEntityLayouts/university.layout'
import MULTIPURPOSE_FIELD from './customMainEntityLayouts/multipurpose_field_layout'
import MUSIC_EVENT from './customMainEntityLayouts/music_event.layout'
import SPORT_EVENT from './customMainEntityLayouts/sport_event.layout'
import THEME_EVENT from './customMainEntityLayouts/theme_event.layout'
import CULTURE_EVENT from './customMainEntityLayouts/culture_event.layout'
import INDOOR_SPORT_FACILITY from './customMainEntityLayouts/indoor_sport_facility.layout'
import WORKOUT_STAIRS from './customMainEntityLayouts/workout_stairs.layout'

// Subentity layouts
import AREA  from './customSubentityLayouts/area.layout'
import CAMPFIRE from './customSubentityLayouts/campfire.layout'
import CAMPSITE from './customSubentityLayouts/campsite.layout'
import CLOAKROOM_OR_STORAGE from './customSubentityLayouts/cloakroom_or_storage.layout'
import ENTRANCE from './customSubentityLayouts/entrance.layout'
import EVENT_PLACE from './customSubentityLayouts/event_place.layout'
import FAIRWAY from './customSubentityLayouts/fairway.layout'
import GUIDE_POST from './customSubentityLayouts/guide_post.layout'
import INFO from './customSubentityLayouts/info.layout'
import LEAN_TO from './customSubentityLayouts/lean_to.layout'
import OTHER from './customSubentityLayouts/other.layout'
import PARKING_AREA from './customSubentityLayouts/parking_area.layout'
import RECYCLE from './customSubentityLayouts/recycle.layout'
import RELIC from './customSubentityLayouts/relic.layout'
import RESTAURANT from './customSubentityLayouts/restaurant.layout'
import ROUTE from './customSubentityLayouts/route.layout'
import TICKET_SALE from './customSubentityLayouts/ticket_sale.layout'
import VANTAGE_POINT from './customSubentityLayouts/vantage_point.layout'
import WARNING from './customSubentityLayouts/warning.layout'
import WATER_POINT from './customSubentityLayouts/water_point.layout'
import WC from './customSubentityLayouts/wc.layout'

// Custom custom field layouts
export const CUSTOM_FIELD_CUSTOM_LAYOUTS = [
  // Main entities
    BEACH,
    SAUNA,
    SKI_TRAIL,
    TENNIS,
    PADEL,
    ICE_SKATING,
    OUTDOOR_GYM,
    BEACH_VOLLEY,
    FOOTBALL,
    BASEBALL_FIELD,
    BASKETBALL,
    SWIMMING_HALL,
    DISC_GOLF,
    PLAYGROUND,
    SKATEPARK,
    SPORT_FIELD,
    PARKING_ZONE,
    AMUSEMENT_PARK,
    THEATER,
    MUSEUM,
    MARKET,
    NATURE_TRAIL,
    MARINA,
    STATION,
    UNIVERSITY,
    MULTIPURPOSE_FIELD,
    MUSIC_EVENT,
    SPORT_EVENT,
    THEME_EVENT,
    CULTURE_EVENT,
    INDOOR_SPORT_FACILITY,
    WORKOUT_STAIRS,
    // Subentities
    AREA,
    CAMPFIRE,
    CAMPSITE,
    CLOAKROOM_OR_STORAGE,
    ENTRANCE,
    EVENT_PLACE,
    FAIRWAY,
    GUIDE_POST,
    INFO,
    LEAN_TO,
    OTHER,
    PARKING_AREA,
    RECYCLE,
    RELIC,
    RESTAURANT,
    ROUTE,
    TICKET_SALE,
    VANTAGE_POINT,
    WARNING,
    WATER_POINT,
    WC
]

// Custom field custom layout styles
export const getCustomFieldCustomLayoutStyles = (theme) => {
  return {
    customBigTitle: {
      fontFamily: 'Rubik',
      fontWeight: 600,
      color: Colors.violet,
      fontSize: '1.675rem',
      paddingBottom: '0.325rem',
      marginTop: '1.375rem',
      marginBottom: '1.5rem',
      borderBottom: `2px solid ${Colors.border}`
    },
    customCheckboxTitle: {
      fontSize: '1rem',
      fontWeight: 500,
      color: Colors.label
    },
    customTitle: {
      fontSize: '1rem',
      fontWeight: 500,
      paddingBottom: '.5rem',
      color: Colors.label
    },
    customRow: {
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down('xl')]: {
        display: 'block'
      }
    },
    customRowFixed: {
      display: 'flex',
      flexDirection: 'row'
    },
    customInnerRow: {
      display: 'flex',
      flexDirection: 'row',
    },
    customColumn: {
      display: 'flex',
      flexDirection: 'column'
    },
    customSpacer: {
      minWidth: '2rem'
    },
    customBlockInfo: {
      maxWidth: '44rem',
      fontSize: '1rem',
      fontWeight: 300,
      color: Colors.label,
      marginBottom: '1.5rem'
    }
  }
}
