import React from 'react'
import moment from 'moment'
import makeStyles from '@mui/styles/makeStyles'
import { Colors } from '../../Utils/theme'
import { useTranslation } from 'react-i18next'
import StarIcon from '../../Assets/Icons/star.svg'
import StarEmptyIcon from '../../Assets/Icons/star-empty.svg'
import { Button } from '..'

const useStyles = makeStyles({
  container: {
    padding: '1.25rem 1.5rem',
    background: Colors.backgroundGrey,
    position: 'relative',
    borderRadius: '0.3125rem'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    paddingRight: '.5rem'
  },
  entityName: {
    color: Colors.black70,
    fontSize: '1rem',
    fontFamily: 'Rubik',
    textDecoration: 'none'
  },
  date: {
    fontSize: '0.875rem',
    color: Colors.black70,
    paddingBottom: '0.375rem'
  },
  title: {
    color: Colors.black,
    fontFamily: 'Rubik',
    fontSize: '1.5rem',
    overflow: 'hidden',
    paddingBottom: '0.5rem'
  },
  content: {
    fontSize: '1rem',
    fontWeight: 300,
    color: Colors.black70,
    height: '7rem',
    overflow: 'auto',
    padding: '.125rem 0'
  },
  ratingRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  starContainer: {
    padding: '.25rem .5rem 0.25rem 0'
  },
  moderatePublishButton: {
    height: '3.125rem',
    maxWidth: '12rem',
    marginRight: '1.5rem'
  },
  moderateHideButton: {
    height: '3.125rem',
    maxWidth: '9rem'
  },
  moderationRow: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '1rem'
  }
})

function FeedbackCard (props: any) {
  const { item } = props
  const classes = useStyles()

  const { t } = useTranslation()

  const renderName = () => {
    let name = item.mainEntityName
    if (item.mainEntityName && item.subEntityName) {
      name = `${name}: `
    }
    if (item.subEntityName) name = name + item.subEntityName
    return name
  }

  const renderRating = () => {
    const rating = item.rating || 0
    return (
      <div className={classes.ratingRow}>
        {Array(5).fill(null).map((x, index) => {
          return (
            <div key={index} className={classes.starContainer}>
              <img
                src={(index < rating) ? StarIcon : StarEmptyIcon}
                style={{ opacity: (index < rating) ? 1 : 0.5 }}
              />
            </div>
          )
        })}
      </div>
    )
  }

  const renderModeration = () => {
    return (
      <div className={classes.moderationRow}>
        {(!item.moderatedBy || !item.public) && (
          <Button
            text={t('set_as_public')}
            onClick={props.makePublic}
            buttonStyle={classes.moderatePublishButton}
          />
        )}
        {(!item.moderatedBy || !!item.public) && (
          <Button
            text={t('set_as_hidden')}
            onClick={props.makePrivate}
            buttonStyle={classes.moderateHideButton}
            outlined
          />
        )}
      </div>
    )
  }

  const renderDate = () => {
    let text = ''
    text = moment(item.createdAt).format('DD.MM.YYYY')
    if (item.visitedAt) {
      const month = t(`month_${moment(item.visitedAt).month() + 1}`)
      const year = moment(item.visitedAt).year()
      text = `${text} - ${t('time_of_visit')} ${month} ${year}`
    }
    return text
  }

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        {renderRating()}
        <div className={classes.entityName}>
          {renderName()}
        </div>
      </div>
      <div className={classes.date}>
        {t('feedback_sent_at')} {renderDate()}
      </div>
      <div className={classes.title}>
        “{item.title}”
      </div>
      <div className={classes.content}>
        {item.message}
      </div>
      {renderModeration()}
    </div>
  )
}

export default FeedbackCard
