import {
  SPACER,
  CONTACT_INFORMATION_TITLE,
  IS_FREE,
  OPEN_HOURS,
  PRICE,
  STREET_ADDRESS,
  CITY,
  POSTAL_CODE,
  WWW,
  EMAIL,
  PHONE,
  TRIP_ADVISOR_URL,
  OPEN_HOURS_AND_PRICE_INFO_TITLE,
  SAUNA_SERVICES,
  RULES_TITLE,
  SAUNA_RULES,
  ACCESSIBLE,
  UNISEX_SAUNA,
  PROPERTIES_TITLE,
  DESCRIPTION_TITLE,
  DESCRIPTION
} from '../elements.template'

const LAYOUT =  {
  type: 'sauna',
  layout: [
    [
      // Start of left column
      DESCRIPTION_TITLE,
      DESCRIPTION,
      OPEN_HOURS_AND_PRICE_INFO_TITLE,
      OPEN_HOURS,
      IS_FREE,
      PRICE
      // End of left column
    ],
    [
      // Start right column
      PROPERTIES_TITLE,
      SAUNA_SERVICES,
      {
        type: 'container',
        className: 'customRowFixed',
        items: [
          ACCESSIBLE,
          SPACER,
          SPACER,
          UNISEX_SAUNA
        ]
      },
      RULES_TITLE,
      SAUNA_RULES,
      CONTACT_INFORMATION_TITLE,
      {
        type: 'container',
        className: 'customRowFixed',
        items: [
          { ...PHONE, style: { flex: 1, maxWidth: '12rem' } },
          SPACER,
          { ...EMAIL, style: { flex: 1, maxWidth: '24rem' } }
        ]
      },
      { ...WWW, style: { flex: 1, maxWidth: '38rem' } },
      { ...TRIP_ADVISOR_URL, style: { flex: 1, maxWidth: '38rem' } },
      {
        type: 'container',
        className: 'customRowFixed',
        style: { paddingBottom: '10rem' },
        items: [
          STREET_ADDRESS,
          SPACER,
          CITY,
          SPACER,
          POSTAL_CODE
        ]
      }
      // End of left column
    ]
  ]
}

export default LAYOUT
