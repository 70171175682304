import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $wrapNodeInElement, mergeRegister } from '@lexical/utils'
import {
  $createParagraphNode,
  $getNodeByKey,
  $insertNodes,
  $isRootOrShadowRoot,
  COMMAND_PRIORITY_EDITOR,
  createCommand,
  LexicalCommand
} from 'lexical'
import { useEffect } from 'react'
import { $createImageNode, ImageNode, ImagePayload } from '../nodes/ImageNode'

export type InsertImagePayload = Readonly<ImagePayload>
export const INSERT_IMAGE_COMMAND: LexicalCommand<InsertImagePayload> = createCommand('INSERT_IMAGE_COMMAND')
export type DeleteImagePayload = string
export const DELETE_IMAGE_COMMAND: LexicalCommand<DeleteImagePayload> = createCommand('DELETE_IMAGE_COMMAND')

export default function ImagesPlugin({
  captionsEnabled
}: {
  captionsEnabled?: boolean
}): JSX.Element | null {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    if (!editor.hasNodes([ImageNode])) {
      throw new Error("ImagesPlugin: ImageNode not registered on editor")
    }

    return mergeRegister(
      editor.registerCommand<InsertImagePayload>(
        INSERT_IMAGE_COMMAND,
        (payload) => {
          // Make sure we received src url
          if (payload.src) {
            const imageNode = $createImageNode(payload);
            $insertNodes([imageNode]);
            if ($isRootOrShadowRoot(imageNode.getParentOrThrow())) {
              $wrapNodeInElement(imageNode, $createParagraphNode).selectEnd();
            }
          }
          return true
        },
        COMMAND_PRIORITY_EDITOR
      )
    );
  }, [captionsEnabled, editor])

  useEffect(() => {
    if (!editor.hasNodes([ImageNode])) {
      throw new Error("ImagesPlugin: ImageNode not registered on editor")
    }

    return mergeRegister(
      editor.registerCommand<DeleteImagePayload>(
        DELETE_IMAGE_COMMAND,
        (nodeKey) => {
          // Replace node with empty paragraph
          // This will give smoother experiance than just .remove()
          $getNodeByKey(nodeKey).replace($createParagraphNode())
          return true;
        },
        COMMAND_PRIORITY_EDITOR
      )
    );
  }, [captionsEnabled, editor])

  return null
}
