import {
  CONTACT_INFORMATION_TITLE,
  HAS_LIGHTS,
  ICE_SKATING_EQUIPMENT,
  CONDITION,
  MAINTENANCE_TITLE,
  BOOKING_CALENDAR_LINK,
  LAST_MAINTAINED,
  EXCEPTION_NOTIFICATION,
  STREET_ADDRESS,
  CITY,
  POSTAL_CODE,
  SPACER,
  PROPERTIES_TITLE,
  DESCRIPTION_TITLE,
  DESCRIPTION
} from '../elements.template'

const LAYOUT = {
  type: 'ice_skating',
  layout: [
    [
      // Start left column
      DESCRIPTION_TITLE,
      DESCRIPTION,
      PROPERTIES_TITLE,
      {
        type: 'container',
        className: 'customRowFixed',
        items: [
          ICE_SKATING_EQUIPMENT,
          SPACER,
          HAS_LIGHTS
        ]
      },
      { ...BOOKING_CALENDAR_LINK, style: { flex: 1, maxWidth: '38rem' } }
      // End left column
    ],
    [
      // Start right column
      MAINTENANCE_TITLE,
      {
        type: 'container',
        className: 'customRowFixed',
        items: [
          { ...CONDITION, style: { flex: 1, maxWidth: '24rem'  } },
          SPACER,
          LAST_MAINTAINED
        ]
      },
      EXCEPTION_NOTIFICATION,
      CONTACT_INFORMATION_TITLE,
      {
        type: 'container',
        className: 'customRowFixed',
        style: { paddingBottom: '10rem' },
        items: [
          STREET_ADDRESS,
          SPACER,
          CITY,
          SPACER,
          POSTAL_CODE
        ]
      }
      // End right column
    ]
  ]
}

export default LAYOUT
