import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'
import { useStore } from '../../../Models/RootStore'
import { PageHeader, Button, Input, Select } from '../../../Components'
import { ENTITY_GEO_TYPES } from '../../../Constants'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  contentContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 0
  },
  content: {
    padding: '2.5rem'
  }
}))

const AdminCreateSubEntityType = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { adminStore }: any = useStore()

  const [type, setType] = useState(null)
  const [nameFi, setNameFi] = useState(null)
  const [nameEn, setNameEn] = useState(null)
  const [icon, setIcon] = useState(null)
  const [geoType, setGeoType] = useState('point')

  const handleCreate = () => {
    adminStore.createSubEntityType({
      type,
      name: nameFi,
      nameFi,
      nameEn,
      icon: icon || null,
      geoType
    })
  }

  const getGeoTypeOptions = () => {
    return ENTITY_GEO_TYPES.map(type => {
      return {
        value: type,
        label: type
      }
    })
  }

  return (
    <div className={classes.root}>
      <PageHeader
        title={t('create_sub_entity_type')}
      />
      <div className={classes.contentContainer}>
        <div className={classes.content}>
          <Input label={t('type')} value={type} onChange={setType} />

          <Input label={t('nameFi')} value={nameFi} onChange={setNameFi} />
          <Input label={t('nameEn')} value={nameEn} onChange={setNameEn} />

          <Input label={t('icon')} value={icon} onChange={setIcon} />

          <Select
            options={getGeoTypeOptions()}
            value={geoType}
            onChange={setGeoType}
            label={t('geo_type')}
          />

          <div>(point, area, route)</div>
          <Button
            text={t('save')}
            onClick={handleCreate}
          />
        </div>
      </div>
    </div>
  )
}

export default observer(AdminCreateSubEntityType)
