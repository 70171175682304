import React from 'react'
import { Popup } from 'react-map-gl'
import makeStyles from '@mui/styles/makeStyles'
import { Colors } from '../../Utils/theme'
import { formatMeters } from '../../Utils/map'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '0.125rem',
    maxWidth: '12rem'
  },
  title: {
    margin: 0,
    fontFamily: 'Roboto',
    fontSize: '0.875rem',
    fontWeight: 700,
    color: Colors.black
  },
  text: {
    margin: 0,
    fontFamily: 'Roboto',
    fontSize: '0.875rem',
    fontWeight: 400,
    color: Colors.label
  }
}))

export default function MapTooltip(props) {
  const classes = useStyles()
  const { latitude, longitude, title } = props

  if (!latitude || !longitude) return null

  const renderType = () => {
    if (props.type) {
      return <p className={classes.text}>{props.type}</p>
    }
    return null
  }

  const renderLength = () => {
    if (props.routeLength) {
      return <p className={classes.text}>{formatMeters(props.routeLength)}</p>
    }
    return null
  }

  if (props.type === 'undefined') {
    return null
  }

  return (
    <Popup
      latitude={latitude}
      longitude={longitude}
      closeButton={false}
      closeOnClick={false}
      offsetTop={-14}
    >
      <div className={classes.container}>
        <p className={classes.title}>{title}</p>
        {renderType()}
        {renderLength()}
      </div>
    </Popup>
  )
}
