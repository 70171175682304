import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  tabsRoot: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
  },
  tabsContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '2rem'
  },
  tabLarge: {
    margin: 0,
    fontFamily: 'Rubik',
    fontWeight: 500,
    fontSize: '1.875rem',
    color: Colors.black30,
    padding: 0,
    paddingTop: '0.75rem',
    paddingBottom: '1.25rem',
    transition: 'color .2s linear',
    textTransform: 'none',
    '&:hover': {
      color: Colors.violet,
      cursor: 'pointer'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.75rem'
    }
  },
  tabMedium: {
    margin: 0,
    fontFamily: 'Rubik',
    fontWeight: 500,
    fontSize: '1.25rem',
    color: Colors.black30,
    padding: 0,
    paddingTop: '0.75rem',
    paddingBottom: '1rem',
    transition: 'color .2s linear',
    textTransform: 'none',
    '&:hover': {
      color: Colors.violet,
      cursor: 'pointer'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.125rem'
    }
  },
  tabSmall: {
    margin: 0,
    fontFamily: 'Rubik',
    fontWeight: 500,
    fontSize: '1.125rem',
    color: Colors.black30,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: '0.5rem',
    paddingBottom: '.75rem',
    transition: 'color .2s linear',
    textTransform: 'none',
    '&:hover': {
      color: Colors.violet,
      cursor: 'pointer'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem'
    }
  },
  tabMarginLarge: {
    marginRight: '3rem'
  },
  tabMarginMedium: {
    marginRight: '1.5rem'
  },
  tabMarginSmall: {
    marginRight: '.75rem'
  },
  activeTab: {
    color: `${Colors.violet} !important`
  },
  indicator: {
    backgroundColor: Colors.violet,
    height: '3px'
  },
  tabLine: {
    position: 'absolute',
    bottom: '2rem',
    width: '100%',
    height: 3,
    backgroundColor: Colors.black20
  }
}))

function FormTabs (props) {
  const classes: any = useStyles()

  const renderTabs = () => {
    const { value, tabs } = props

    const tabValueIndex = tabs.map((tab, index) => ({ index, tab })).find(item => item.tab.value === value).index

    let tabClass = classes.tabLarge
    let tabMarginClass = classes.tabMarginLarge
    if (tabs.length > 3) {
      tabClass = classes.tabMedium
      tabMarginClass = classes.tabMarginMedium
    }
    if (tabs.length > 4) {
      tabClass = classes.tabSmall
      tabMarginClass = classes.tabMarginSmall
    }
    return (
      <Tabs
        value={tabValueIndex}
        onChange={(evt, val) => props.onChange(tabs[val].value) }
        classes={{ indicator: classes.indicator }}
      >
        {
          tabs.map((t, index) => (
            <Tab
              key={index}
              label={t.name}
              classes={{
                root: [
                  tabClass, value === t.value ? classes.activeTab : '',
                  (index !== (tabs.length - 1)) ? tabMarginClass : ''
                ].join(' ')
              }}
            />
          ))
        }
      </Tabs>
    )
  }

  return (
    <div className={classes.tabsRoot}>
      <div className={classes.tabsContainer}>
        {renderTabs()}
      </div>
      <div className={classes.tabLine} />
    </div>
  )
}

export default FormTabs
