import React, { useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Colors } from '../../Utils/theme'
import { Button, Dialog, WikiBox } from '..'
import AddIcon from '../../Assets/Icons/add-dark.svg'
import { useTranslation } from 'react-i18next'


const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: '2rem'
  },
  title: {
    margin: '0 auto',
    width: '100%',
    maxWidth: '51rem',
    fontFamily: 'Rubik',
    fontSize: '1.875rem',
    color: Colors.black,
    lineHeight: '1.1',
    paddingTop: 0,
    paddingBottom: '.28rem',
    borderBottom: '2px solid #ccc'
  },
  addButtonContainer: {
    width: '9rem',
    margin: '1rem 0 0'
  }
}))

export default function WikiEditorRepeater(props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const [locked, setLocked] = useState(false)
  const toggleLock = () => setLocked(!locked)
  const [deleteIndex, setDeleteIndex] = useState(null)
  const [tick, setTick] = useState(1)

  const handleAddItem = () => {
    const newItem = {
      titleFi: '',
      titleEn: '',
      htmlFi: '',
      htmlEn: '',
      validFrom: null,
      validUntil: null
    }
    props.setItems([...props.items, newItem])
  }

  const deleteItem = (index) => {
    if (deleteIndex !== index) {
      setDeleteIndex(index)
    } else {
      props.setItems(props.items.filter((item, i) => i !== index))
      setDeleteIndex(null)
    }
  }

  const moveItem = (index, direction) => {
    const newItems = [...props.items]

    if (direction === 'up') {
      if (index === 0) return
      const temp = newItems[index - 1]
      newItems[index - 1] = newItems[index]
      newItems[index] = temp
    } else if (direction === 'down') {
      if (index === (newItems.length - 1)) return
      const temp = newItems[index + 1]
      newItems[index + 1] = newItems[index]
      newItems[index] = temp
    }
    props.setItems(newItems)
    setTick(tick + 1)
  }

  const replaceItem = (data, index) => {
    const newItems = props.items.map((original, idx) => {
      if (idx === index) return data
      return original
    })
    props.setItems(newItems)
  }

  const renderItems = () => {
    return props.items.map((item, index) => {
      return (
        <WikiBox
          key={`${tick}-${index}`}
          index={index}
          totalItems={props.items.length}
          title={props.allBoxTitle}
          item={item}
          updateItem={(data) => replaceItem(data, index)}
          lang={props.lang}
          useValidityRange={props.useValidityRange}
          moveUp={() => moveItem(index, 'up')}
          moveDown={() => moveItem(index, 'down')}
          locked={locked}
          toggleLock={toggleLock}
          delete={() => deleteItem(index)}
        />
      )
    })
  }

  const renderDeleteConfirm = () => {
    return (
      <Dialog
        open={deleteIndex !== null}
        handleClose={() => setDeleteIndex(null)}
        handleOk={() => deleteItem(deleteIndex)}
        title={t('confirm_delete_section')}
        description={t('confirm_delete_section_description')}
      />
    )
  }

  return (
    <div className={classes.root}>
      <h1 className={classes.title}>{props.title}</h1>
      {renderItems()}
      <div className={classes.addButtonContainer}>
        <Button
          onClick={handleAddItem}
          text={t('add_new')}
          rightIcon={AddIcon}
          outlined
        />
      </div>
      {renderDeleteConfirm()}
    </div>
  )
}
