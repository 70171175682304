import {
  SPACER,
  DESCRIPTION,
  STREET_ADDRESS,
  CITY,
  POSTAL_CODE,
  DESCRIPTION_TITLE,
  ADDRESS_INFORMATION_TITLE
} from '../elements.template'

const LAYOUT = {
  type: 'subentity_relic',
  layout: [
    [
      // Start left column
      DESCRIPTION_TITLE,
      DESCRIPTION,
      ADDRESS_INFORMATION_TITLE,
      {
        type: 'container',
        className: 'customRowFixed',
        items: [
          STREET_ADDRESS,
          SPACER,
          CITY,
          SPACER,
          POSTAL_CODE
        ]
      }
      // End left column
    ],
    [
      // Start of right column
      // End of right column
    ]
  ]
}

export default LAYOUT
