import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '7rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0 2rem 0',
    backgroundColor: Colors.backgroundGrey,
    borderBottom: `1px solid ${Colors.black20}`
  },
  title: {
    margin: '0 auto',
    width: '100%',
    maxWidth: '51rem',
    fontFamily: 'Rubik',
    fontSize: '2.5rem',
    color: Colors.black,
    lineHeight: '1.1'
  }
}))

export default function WikiHeader(props) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <h1 className={classes.title}>{props.title}</h1>
    </div>
  )
}
