import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import { useTranslation } from 'react-i18next'
import { TableHeader, DataMessage } from '..'
import EntityTableRow from './EntityTableRow'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  root: {
  },
  spacer: {
    height: '1rem'
  }
}))

function EntityTable (props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const renderRows = () => {
    if (props.entities) {
      return props.entities.map((entity) => {
        return (
          <React.Fragment key={entity.id}>
            <div className={classes.spacer} />
            <EntityTableRow
              entity={entity}
              onDelete={props.onDelete}
              onRestore={props.onRestore}
            />
          </React.Fragment>
        )
      })
    }
    return null
  }

  return (
    <>
      <Table classes={{ root: classes.root }}>
        <TableHeader columnNames={[t('name'), t('type'), t('snapshot_published_at'), t('actions')]} />
        <TableBody>{renderRows()}</TableBody>
      </Table>
      <DataMessage data={props.entities} message={t('no_entities')} />
    </>
  )
}

export default EntityTable
