import gpxParser from 'gpxparser'
import haversine from 'haversine'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import WebMercatorViewport from '@math.gl/web-mercator'
import { XMLParser } from 'fast-xml-parser'

export const GEOMETRY_TYPES = {
  Point: 'Point',
  Polygon: 'Polygon',
  LineString: 'LineString',
  MultiLineString: 'MultiLineString'
}

const GEO_TYPE_TO_GEOMETRY_TYPE = {
  point: GEOMETRY_TYPES.Point,
  geofence: GEOMETRY_TYPES.Point,
  area: GEOMETRY_TYPES.Polygon,
  route: GEOMETRY_TYPES.LineString,
  multiroute: GEOMETRY_TYPES.MultiLineString
}

// This function tries to detect a bad line strings with too big gaps
// and tranforms it into MultiLineString format
export const fixBadLineStringFeature = (feature) => {
  const getDist = (coordsA, coordsB) => {
    const a = {
      lon: coordsA[0],
      lat: coordsA[1]
    }
    const b = {
      lon: coordsB[0],
      lat: coordsB[1]
    }
    return getGpxPointDistance(a, b)
  }

  try {
    if (feature?.geometry?.type === GEOMETRY_TYPES.LineString) {
      if ((feature?.geometry?.coordinates || []).length > 10) {
        // Calculate AVG point distance
        let distanceSum: number = 0
        for (let i = 0; i < feature.geometry.coordinates.length - 1; i++) {
          distanceSum = distanceSum + getDist(
              feature.geometry.coordinates[i],
              feature.geometry.coordinates[i + 1]
          )
        }
        const avgDist = distanceSum / feature.geometry.coordinates.length
        // You may have to adjust this, this is just a good guess...
        const limitDistance = 10 * avgDist

        const breakPointIndexes = []

        // Find break points
        for (let i = 0; i < feature.geometry.coordinates.length - 1; i++) {
          const distance = getDist(
              feature.geometry.coordinates[i],
              feature.geometry.coordinates[i + 1]
          )
          if (distance > limitDistance) {
            breakPointIndexes.push(i)
          }
        }

        if (breakPointIndexes.length) {
          const newCoordSets: any = []
          let startIndex = 0
          for (const breakPoint of [...breakPointIndexes, feature.geometry.coordinates.length]) {
            const coords = feature.geometry.coordinates.slice(startIndex, breakPoint)
            newCoordSets.push(coords)

            // Set next start index to previous cut point
            startIndex = breakPoint + 1
          }

          // Compose new MultiLineString feature
          const newFeature = {
            ...feature,
            geometry: {
              ...feature.geometry,
              type: GEOMETRY_TYPES.MultiLineString,
              coordinates: newCoordSets
            }
          }

          return newFeature
        }
      }
    }
  } catch (err) {
    // Ignore error
    console.log(err)
  }

  // Error or no transform needed
  return feature
}

const getGpxPointDistance = (a, b) => {
  const start = {
    latitude: a.lat,
    longitude: a.lon
  }
  const end = {
    latitude: b.lat,
    longitude: b.lon
  }

  return haversine(start, end, { unit: 'meter' })
}

// Get avg coordinates from points
const getAvgCoordinates = (points) => {
  let longitudeSum = 0
  let latitudeSum = 0
  for (const point of points) {
    longitudeSum += point[0]
    latitudeSum += point[1]
  }
  return {
    latitude: latitudeSum / points.length,
    longitude: longitudeSum / points.length
  }
}

// Get viewport props in the center of geometry
export const getFeatureViewport = (feature) => {
  const type = get(feature, 'geometry.type')
  if (type === GEOMETRY_TYPES.Point) {
    return {
      longitude: get(feature, 'geometry.coordinates[0]'),
      latitude: get(feature, 'geometry.coordinates[1]')
    }
  }
  if (type === GEOMETRY_TYPES.LineString) {
    const points = get(feature, 'geometry.coordinates')
    return getAvgCoordinates(points)
  }
  if (type === GEOMETRY_TYPES.Polygon) {
    const points = get(feature, 'geometry.coordinates[0]')
    return getAvgCoordinates(points)
  }
  // Unknown type, change nothing
  return {}
}

const getAvgGpxPointDistance = (points) => {
  let distanceSum = 0

  if (points.length - 2 < 1) {
    return distanceSum
  }

  for (let i = 0; i < points.length - 2; i++) {
    distanceSum += getGpxPointDistance(points[i], points[i+1])
  }
  const avgDistance = distanceSum / (points.length - 1)
  return avgDistance
}

const getGpxPointsFeatureType = (points) => {
  if (points.length === 1) {
    return GEOMETRY_TYPES.Point
  }
  const avgDistance = getAvgGpxPointDistance(points)
  const startToEndDistance = getGpxPointDistance(points[0], points[points.length - 1])
  if (startToEndDistance / avgDistance < Math.sqrt(points.length)) {
    return GEOMETRY_TYPES.Polygon
  }
  return GEOMETRY_TYPES.LineString
}

const isLatitude = (val) => {
  if ((val || val === 0) && val >= -90 && val <= 90) return true
  return false
}

const isLongitude = (val) => {
  if ((val || val === 0) && val >= -180 && val <= 180) return true
  return false
}

const isCoordinate = (coord) => {
  return isLatitude(coord.lat) && isLongitude(coord.lon)
}

export const gpxToFeature = (gpxFileContent, enabledTypes) => {
  try {
    const gpx = new gpxParser()
    gpx.parse(gpxFileContent)


    let points = []
    let hasMultipleTracks = false

    const parser = new XMLParser({
      ignoreAttributes : false
    })
    const jObj = parser.parse(gpxFileContent)
    if (jObj && get(jObj, 'gpx.trk.trkseg.length', 0) > 1) {
      hasMultipleTracks = true
    }

    // Try to use first track or first route from parsed GPX file
    if (!points.length && get(gpx, 'tracks[0].points')) {
      points = gpx.tracks[0].points
      if (gpx.tracks.length > 1) {
        hasMultipleTracks = true
      }
    } else if(!points.length && get(gpx, 'routes[0].points')) {
      points = gpx.routes[0].points
    }

    // Could not find points, use fallback GPX parsing
    if (!points.length) {
      let dataOk = true

      // Replace new lines with spaces for easier parsing
      const content = gpxFileContent.replace(/(?:\r\n|\r|\n)/g, ' ')

      // Parse latitudes
      // eslint-disable-next-line
      const latitudes = content.match(/\ lat="(.*?)"/g).map(lat => {
        const result = parseFloat(lat.replace(/[^0-9.-]/g, ''))
        if (!result) dataOk = false
        return result
      })
      // Parse longitudes
      // eslint-disable-next-line
      const longitudes = content.match(/\ lon="(.*?)"/g).map(lat => {
        const result =  parseFloat(lat.replace(/[^0-9.-]/g, ''))
        if (!result) dataOk = false
        return result
      })

      if (dataOk && latitudes.length && latitudes.length === longitudes.length) {
        const newPoints = []

        for (let i = 0; i < latitudes.length; i++) {
          newPoints.push({
            lat: latitudes[i],
            lon: longitudes[i]
          })
        }

        // Make sure all coordinates are valid
        const pointsValid = !points.find(p => !isCoordinate(p))
        if (pointsValid) {
          points = newPoints
        }
      }
    }

    // Error parsing
    if (!points.length) return null

    // Try to detect if we should insert Point, Polygon or Polyline
    let type = getGpxPointsFeatureType(points)

    if (type === GEOMETRY_TYPES.Point && !enabledTypes.includes('point')) {
      // Error
      console.log('ERROR: type:point not allowed')
      return null
    }
    if (enabledTypes.length === 1) {
      if (type !== GEO_TYPE_TO_GEOMETRY_TYPE[enabledTypes[0]]) {
        // Detect if not point when point needed
        if (enabledTypes[0] === 'point') {
          console.log('ERROR: type not allowed')
          return null
        } else {
          // It's usually safe to switch between type: LineString <-> Polygon
          type = GEO_TYPE_TO_GEOMETRY_TYPE[enabledTypes[0]]
        }
      }
    }

    let resultFeature: any = null

    // Generate GEO Feature from points
    if (type === GEOMETRY_TYPES.Point) {
      resultFeature = {
        type: 'Feature',
        properties: {},
        geometry: {
          type,
          coordinates: [
            points[0].lon,
            points[0].lat
          ]
        }
      }
    } else if (type === GEOMETRY_TYPES.LineString) {
      resultFeature = {
        type: 'Feature',
        properties: {},
        geometry: {
          type,
          coordinates: points.map(item => {
            return [
              item.lon,
              item.lat
            ]
          })
        }
      }
    } else if (type === GEOMETRY_TYPES.Polygon) {
      resultFeature = {
        type: 'Feature',
        properties: {},
        geometry: {
          type,
          coordinates: [
            points.map(item => {
              return [
                item.lon,
                item.lat
              ]
            })
          ]
        }
      }
    }

    // Check if we need to spit line string into multiple parts
    if (resultFeature) {
      if (hasMultipleTracks) {
        let parsingOk = true
        let coordSets = get(jObj, 'gpx.trk.trkseg', []).map(tracks => {
          return get(tracks, 'trkpt', []).map(track => {
            const longitude = parseFloat(get(track, '@_lon', null))
            const latitude = parseFloat(get(track, '@_lat', null))
            if (!longitude || !latitude) {
              parsingOk = false
            }
            return [
              longitude,
              latitude
            ]
          })
        })

        if (!coordSets.length) {
          parsingOk = true
          // Try different format
          coordSets = get(jObj, 'gpx.trk', []).map(tracks => {
            return get(tracks, 'trkseg.trkpt', []).map(track => {
              const longitude = parseFloat(get(track, '@_lon', null))
              const latitude = parseFloat(get(track, '@_lat', null))
              if (!longitude || !latitude) {
                parsingOk = false
              }
              return [
                longitude,
                latitude
              ]
            })
          })
        }

        // Something went wrong
        if (!parsingOk || !coordSets.length) return null

        const resultFeatures = coordSets.map(coordinates => {
          return {
            ...resultFeature,
            geometry: {
              ...resultFeature.geometry,
              type: GEOMETRY_TYPES.LineString,
              coordinates
            }
          }
        })

        return resultFeatures
      }
      return [resultFeature]
    }
  } catch (err) {
    console.log(err)
  }
  // Some error happened
  return null
}

// Toggle feature type between LineString and Polygon
export const convertFeatureType = (feature) => {
  // Find out current type
  const type = get(feature, 'geometry.type')

  if (type === GEOMETRY_TYPES.LineString) {
    const result = {
      type: 'Feature',
      properties: feature.properties,
      geometry : {
        type: GEOMETRY_TYPES.Polygon,
        coordinates: [feature.geometry.coordinates] // Additional array level
      }
    }

    if (result.geometry.coordinates[0].length > 1) {
      if (
        result.geometry.coordinates[0][0][0] !== result.geometry.coordinates[0][result.geometry.coordinates[0].length - 1][0] ||
        result.geometry.coordinates[0][0][1] !== result.geometry.coordinates[0][result.geometry.coordinates[0].length - 1][1]
      ) {
        result.geometry.coordinates[0].push(result.geometry.coordinates[0][0])
      }
    }
    return result
  } else if (type === GEOMETRY_TYPES.Polygon) {
    const result = {
      type: 'Feature',
      properties: feature.properties,
      geometry : {
        type: GEOMETRY_TYPES.LineString,
        coordinates: feature.geometry.coordinates[0] // Flatten coordinates
      }
    }

    if (result.geometry.coordinates.length > 1) {
      if (
        result.geometry.coordinates[0][0] === result.geometry.coordinates[result.geometry.coordinates.length - 1][0] &&
        result.geometry.coordinates[0][1] === result.geometry.coordinates[result.geometry.coordinates.length - 1][1]
      ) {
        result.geometry.coordinates.pop()
      }
    }

    return result
  } else {
    console.log(`Unsupported conversion for type: ${type}`)
  }

  // Fallback to original
  return feature
}

export const isSameFeature = (a, b) => {
  const aType = get(a, 'geometry.type')
  const bType = get(b, 'geometry.type')

  // Compare types
  if (aType !== bType) return false

  // Compare coordinates
  if (!isEqual(get(a, 'geometry.coordinates'), get(b, 'geometry.coordinates'))) {
    return false
  }

  // No changes
  return true
}

// Sort features for map: Polygon, LineString, Point
export const sortMapFeaturesByType = (features) => {
  const sortedFeatures = features.sort((a, b) => {
    const aType = get(a, 'geometry.type')
    const bType = get(b, 'geometry.type')

    if (aType === bType) {
      if (aType === GEOMETRY_TYPES.Polygon) {
        // Sort child sub entities after parent sub entities
        const aIsParent = !!get(a, 'properties.parentSubEntityId')
        const bIsParent = !!get(b, 'properties.parentSubEntityId')
        if (aIsParent !== bIsParent) {
          return aIsParent ? -1 : 1
        }
      }
      return 0
    }
    if (aType === GEOMETRY_TYPES.Point) return 1
    if (bType === GEOMETRY_TYPES.Point) return -1
    if (aType === GEOMETRY_TYPES.LineString) return 1
    if (bType === GEOMETRY_TYPES.LineString) return -1
    return 0
  })

  return sortedFeatures
}

export const getFeatureTooltipCoords = (feature) => {
  const type = get(feature, 'geometry.type')
  if (type === GEOMETRY_TYPES.Point) {
    return {
      longitude: get(feature, 'geometry.coordinates[0]'),
      latitude: get(feature, 'geometry.coordinates[1]')
    }
  }
  let allCoords = null
  if (type === GEOMETRY_TYPES.Polygon) {
    allCoords = get(feature, 'geometry.coordinates[0]')
  } else if(type === GEOMETRY_TYPES.LineString) {
    allCoords = get(feature, 'geometry.coordinates')
  }

  if (allCoords) {
    // Find top-center coords
    const avgCoords = getAvgCoordinates(allCoords)
    let maxLatitude = 0
    for (const point of allCoords) {
      maxLatitude = Math.max(maxLatitude, point[1])
    }

    return {
      longitude: avgCoords.longitude,
      latitude: maxLatitude
    }
  }

  return null
}

// Calculate nice zoom level for feature
export const getFeatureZoomLevel = (feature) => {
  try {
    const type = get(feature, 'geometry.type')

    if (type === GEOMETRY_TYPES.Point) {
      return 14
    }

    let minLatitude = 1000
    let maxLatitude = -1000
    let minLongitude = 1000
    let maxLongitude = -1000

    if (type === GEOMETRY_TYPES.LineString || type === GEOMETRY_TYPES.Polygon) {
      const coordsPath = type === GEOMETRY_TYPES.LineString ? 'geometry.coordinates' : 'geometry.coordinates[0]'
      const allCoords = get(feature, coordsPath)

      if (allCoords) {
        for (const coordset of allCoords) {
          const lng = coordset[0]
          const lat = coordset[1]

          // Set min and max values
          minLatitude = Math.min(minLatitude, lat)
          maxLatitude = Math.max(maxLatitude, lat)
          minLongitude = Math.min(minLongitude, lng)
          maxLongitude = Math.max(maxLongitude, lng)
        }
      }
    }
    // Make sure that min and max values are set
    if (![minLatitude, maxLatitude, minLongitude, maxLongitude].find(val => Math.abs(val) === 1000)) {

      const viewport = new WebMercatorViewport({ width: 600, height: 600 })
        .fitBounds([
          [minLongitude, minLatitude],
          [maxLongitude, maxLatitude]
        ], { padding: 50 })

      return viewport.zoom
    }
  } catch (err) {
    console.log(err)
  }
  // Fallback
  return 10
}

// Calculate center coords by avg
export const getFeatureCenterCoords = (feature) => {
  try {
    const type = get(feature, 'geometry.type')

    if (type === GEOMETRY_TYPES.Point) {
      const longitude = get(feature, 'geometry.coordinates[0]')
      const latitude = get(feature, 'geometry.coordinates[1]')
      if (latitude && longitude) {
        return {
          longitude,
          latitude
        }
      }
    } else if (type === GEOMETRY_TYPES.LineString) {
      const allCoords = get(feature, 'geometry.coordinates')
      if (allCoords) {
        const avgCoords = getAvgCoordinates(allCoords)
        if (avgCoords && avgCoords.latitude && avgCoords.longitude) {
          return {
            longitude: avgCoords.longitude,
            latitude: avgCoords.latitude
          }
        }
      }
    } else if (type === GEOMETRY_TYPES.Polygon) {
      const allCoords = get(feature, 'geometry.coordinates[0]')
      if (allCoords) {
        const avgCoords = getAvgCoordinates(allCoords)
        if (avgCoords && avgCoords.latitude && avgCoords.longitude) {
          return {
            longitude: avgCoords.longitude,
            latitude: avgCoords.latitude
          }
        }
      }
    } else if (type === GEOMETRY_TYPES.MultiLineString) {
      const allCoords = get(feature, 'geometry.coordinates')
      if (allCoords && allCoords[0]) {
        // Find coord set with most points
        let coordSet = []
        for (const coords of allCoords) {
          if (coords.length > coordSet) {
            coordSet = coords
          }
        }
        const avgCoords = getAvgCoordinates(coordSet)
        if (avgCoords && avgCoords.latitude && avgCoords.longitude) {
          return {
            longitude: avgCoords.longitude,
            latitude: avgCoords.latitude
          }
        }
      }
    }
  } catch (err) {}

  return {}
}

// Get total length of line or polygon in meters
export const getFeatureLength = (feature) => {
  const type = get(feature, 'geometry.type')

  // Get coordinates
  let distanceSum = 0
  let allCoords = []
  if (type === GEOMETRY_TYPES.Polygon) {
    allCoords = get(feature, 'geometry.coordinates[0]')
  } else if(type === GEOMETRY_TYPES.LineString) {
    allCoords = get(feature, 'geometry.coordinates')
  }

  for (let i = 0; i < allCoords.length - 1; i++) {
    const start = {
      longitude: allCoords[i][0],
      latitude: allCoords[i][1]
    }
    const end = {
      longitude: allCoords[i+1][0],
      latitude: allCoords[i+1][1]
    }
    distanceSum = distanceSum + haversine(start, end, { unit: 'meter' })
  }
  return Math.round(distanceSum)
}

// Format meters in m or km
export const formatMeters = (meters) => {
  if (meters < 1000) {
    return `${Math.round(meters)} m`
  } else if (meters < 10000) {
    // Display with 2 digits
    return `${(meters / 1000).toFixed(2)} km`.replace('.', ',')
  } else if (meters < 100000) {
    // Display one digit
    return `${(meters / 1000).toFixed(1)} km`.replace('.', ',')
  }
  // Don't display digits for distance >= 100km
  return `${Math.round(meters / 1000)} km`.replace('.', ',')
}

export const getInitialRegionForEntityCreation = (organizationId: number) => {
  let coordinates: any
  if (organizationId) {
    if (organizationId === 1) {
      // Tampere
      coordinates = { latitude: 61.498164674, longitude: 23.76083029 }
    } else if (organizationId === 2) {
      // Hamina
      coordinates = { latitude: 60.5967776, longitude: 27.166071 }
    } else if (organizationId === 3) {
      // Siilinjärvi
      coordinates = { latitude: 63.0737207, longitude: 27.6619688 }
    }
    if (coordinates) {
      return { viewport: { ...coordinates, zoom: 12 }, time: Date.now() }
    }
  }
  return null
}
