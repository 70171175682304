import {
  EVENT_PLACE_ADDRESS_TITLE,
  SPACER,
  STREET_ADDRESS,
  CITY,
  POSTAL_CODE,
  WWW,
  TIMETABLES_TITLE,
  PROGRAM_LIST,
  DESCRIPTION,
  EVENT_PLACE_DESCRIPTION_TITLE,
  EVENT_PLACE_OPEN_TITLE,
  OPEN_FROM,
  OPEN_UNTIL
} from '../elements.template'

const LAYOUT = {
  type: 'subentity_event_place',
  layout: [
    [
      // Start left column
      EVENT_PLACE_OPEN_TITLE,
      {
        type: 'container',
        className: 'customRowFixed',
        items: [
          OPEN_FROM,
          SPACER,
          SPACER,
          OPEN_UNTIL
        ]
      },
      EVENT_PLACE_DESCRIPTION_TITLE,
      DESCRIPTION,
      TIMETABLES_TITLE,
      PROGRAM_LIST,
      EVENT_PLACE_ADDRESS_TITLE,
      {
        type: 'container',
        className: 'customRowFixed',
        items: [
          STREET_ADDRESS,
          SPACER,
          CITY,
          SPACER,
          POSTAL_CODE
        ]
      },
      {
        ...WWW,
        label: 'website_url',
        hideLabel: true,
        style: { flex: 1, maxWidth: '38rem' }
      }
      // End left column
    ],
    [
      // Start of right column
      // End of right column
    ]
  ]
}

export default LAYOUT
