import {
  SPACER,
  DASH_SPACER,
  MAINTENANCE_TITLE,
  USE_START_DATE,
  USE_END_DATE,
  CONDITION,
  LAST_MAINTAINED,
  EXCEPTION_NOTIFICATION,
  CONTACT_INFORMATION_TITLE,
  STREET_ADDRESS,
  CITY,
  POSTAL_CODE,
  OBSTACLES_TITLE,
  SKATE_OBSTACLES,
  EQUIPMENT_RENTAL_TITLE,
  SKATE_EQUIPMENT_RENTAL,
  SKATE_EQUIPMENT_DESCRIPTION,
  SKATE_TYPE,
  SKATE_SURFACE,
  RAIN_COVER,
  PROPERTIES_TITLE,
  DESCRIPTION_TITLE,
  DESCRIPTION,
} from '../elements.template'

const LAYOUT = {
  type: 'skatepark',
  layout: [
    [
      // Start left column
      DESCRIPTION_TITLE,
      DESCRIPTION,
      PROPERTIES_TITLE,
      {
        type: 'container',
        className: 'customRowFixed',
        items: [
          SKATE_TYPE,
          SPACER,
          SKATE_SURFACE,
          SPACER,
          RAIN_COVER
        ]
      },
      OBSTACLES_TITLE,
      SKATE_OBSTACLES,
      EQUIPMENT_RENTAL_TITLE,
      SKATE_EQUIPMENT_RENTAL,
      SKATE_EQUIPMENT_DESCRIPTION
      // End left column
    ],
    [
      // Start right column
      MAINTENANCE_TITLE,
      {
        type: 'container',
        className: 'customColumn',
        style: {},
        items: [
          {
            type: 'container',
            items: [{ type: 'title', name: 'in_use_between' }]
          },
          {
            type: 'container',
            className: 'customInnerRow',
            items: [
              USE_START_DATE,
              DASH_SPACER,
              USE_END_DATE
            ]
          },
        ]
      },
      {
        type: 'container',
        className: 'customRowFixed',
        items: [
          { ...CONDITION, style: { flex: 1, maxWidth: '24rem' } },
          SPACER,
          LAST_MAINTAINED
        ]
      },
      EXCEPTION_NOTIFICATION,
      CONTACT_INFORMATION_TITLE,
      {
        type: 'container',
        className: 'customRowFixed',
        style: { paddingBottom: '10rem' },
        items: [
          STREET_ADDRESS,
          SPACER,
          CITY,
          SPACER,
          POSTAL_CODE
        ]
      }
      // End right column
    ]
  ]
}

export default LAYOUT
