import {
  SPACER,
  IS_FREE,
  AGE_RESTRICTIONS,
  TICKET_SALES_LINK,
  POSTAL_CODE,
  CITY,
  WWW,
  EVENT_PLACE_TITLE,
  STREET_ADDRESS,
  EVENT_CONTACT_INFORMATION_TITLE,
  PLACE_NAME,
  EVENT_CONTACT_INFOS,
  EVENT_TIMES_TITLE,
  EVENT_START_DATE,
  EVENT_END_DATE,
  EVENT_TICKET_SALES_TITLE,
  EVENT_VISIBILITY_TITLE,
  VISIBILITY_START_DATE,
  VISIBILITY_END_DATE,
  LOWEST_TICKET_PRICE,
  DASH_SPACER,
  HIGHEST_TICKET_PRICE,
  DESCRIPTION,
  DESCRIPTION_TITLE
} from '../elements.template'

const LAYOUT =  {
  type: 'culture_event',
  layout: [
    [
      // Start of left column
      DESCRIPTION_TITLE,
      DESCRIPTION,
      { ...EVENT_VISIBILITY_TITLE, info: 'event_visibility_info' },
      {
        type: 'container',
        className: 'customRowFixed',
        items: [
          VISIBILITY_START_DATE,
          SPACER,
          SPACER,
          VISIBILITY_END_DATE
        ]
      },
      EVENT_TIMES_TITLE,
      {
        type: 'container',
        className: 'customRowFixed',
        items: [
          EVENT_START_DATE,
          SPACER,
          SPACER,
          EVENT_END_DATE
        ]
      },
      EVENT_TICKET_SALES_TITLE,
      {
        type: 'container',
        className: 'customRowFixed',
        items: [
          { ...IS_FREE, label: 'event_is', hideLabel: true },
          SPACER,
          SPACER,
          {
            type: 'container',
            className: 'customColumn',
            style: {},
            items: [
              {
                type: 'container',
                items: [{ type: 'title', name: 'ticket_prices' }]
              },
              {
                type: 'container',
                className: 'customInnerRow',
                items: [
                  LOWEST_TICKET_PRICE,
                  DASH_SPACER,
                  HIGHEST_TICKET_PRICE
                ]
              }
            ]
          }
        ]
      },
      { ...AGE_RESTRICTIONS, label: 'event_age_limit', hideLabel: true },
      {
        ...TICKET_SALES_LINK,
        label: 'buy_tickets_url',
        hideLabel: true,
        style: { flex: 1, maxWidth: '38rem' }
      }
      // End of left column
    ],
    [
      // Start right column
      EVENT_PLACE_TITLE,
      {
        type: 'container',
        className: 'customRowFixed',
        items: [
          { ...PLACE_NAME, style: { flex: 1, maxWidth: '16rem' } },
          SPACER,
          {
            ...WWW,
            label: 'website_url',
            hideLabel: true,
            style: { flex: 1, maxWidth: '38rem' }
          }
        ]
      },
      {
        type: 'container',
        className: 'customRowFixed',
        items: [
          STREET_ADDRESS,
          SPACER,
          CITY,
          SPACER,
          POSTAL_CODE
        ]
      },
      EVENT_CONTACT_INFORMATION_TITLE,
      { ...EVENT_CONTACT_INFOS, style: { paddingBottom: '10rem' } }
      // End of right column
    ]
  ]
}

export default LAYOUT
