import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import Checkbox from '../Common/Checkbox'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: '1.5rem'
  },
  title: {
    // textAlign: 'center',
    fontSize: '1rem',
    fontWeight: 500,
    paddingBottom: '.5rem',
    color: Colors.label
  },
  row: {
    width: '10rem',
    height: '3.125rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around'
  }
}))

function CustomBooleanInput (props) {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.container}>
      <div className={classes.title}>{props.label}</div>
      <div className={classes.row}>
        {['yes', 'no'].map((label, index) => {
          return (
            <Checkbox
              key={index}
              checked={!!index !== !!props.value}
              onChange={props.onChange}
              label={t(label)}
            />
          )
        })}
      </div>
    </div>
  )

}

export default CustomBooleanInput
