import {
  EQUIPMENT,
  MAINTENANCE_TITLE,
  CONDITION,
  LAST_MAINTAINED,
  EXCEPTION_NOTIFICATION,
  CONTACT_INFORMATION_TITLE,
  STREET_ADDRESS,
  SPACER,
  CITY,
  POSTAL_CODE,
  PROPERTIES_TITLE,
  DESCRIPTION_TITLE,
  DESCRIPTION
} from '../elements.template'

const LAYOUT = {
  type: 'outdoor_gym',
  layout: [
    [
      // Start left column
      DESCRIPTION_TITLE,
      DESCRIPTION,
      PROPERTIES_TITLE,
      EQUIPMENT
      // End left column
    ],
    [
      // Start right column
      MAINTENANCE_TITLE,
      {
        type: 'container',
        className: 'customRowFixed',
        items: [
          { ...CONDITION, style: { flex: 1, maxWidth: '24rem' } },
          SPACER,
          LAST_MAINTAINED
        ]
      },
      EXCEPTION_NOTIFICATION,
      CONTACT_INFORMATION_TITLE,
      {
        type: 'container',
        className: 'customRowFixed',
        style: { paddingBottom: '10rem' },
        items: [
          STREET_ADDRESS,
          SPACER,
          CITY,
          SPACER,
          POSTAL_CODE
        ]
      }
      // End right column
    ]
  ]
}

export default LAYOUT
