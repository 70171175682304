import React from 'react'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import * as moment from 'moment'
import IconButton from '@mui/material/IconButton'
import { Tooltip } from '@mui/material'
import EditIconDark from '../../Assets/Icons/edit-dark.svg'
import DeleteIconDark from '../../Assets/Icons/delete.svg'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: Colors.backgroundGrey,
    border: 0
  },
  rootOdd: {
    backgroundColor: Colors.white,
    border: 0
  },
  cell: {
    height: '2.75rem',
    fontFamily: 'Roboto',
    fontWeight: 400,
    color: Colors.black,
    fontSize: '1rem',
    borderBottom: 'none'
  },
  actionButton: {
    backgroundColor: Colors.white,
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.black20}`,
    height: '2rem',
    width: '2rem',
    marginLeft: '.5rem'
  },
  actionIcon: {
    height: '1.25rem',
    width: 'auto',
    maxHeight: '1.25rem',
    maxWidth: '1.25rem'
  }
}))

function MainEntityNotificationsTableRow (props: any) {
  const classes = useStyles()
  const { t } = useTranslation()
  const { item } = props


  const renderActions = () => {
    return (
      <div>
        <Tooltip title={t('edit_notification')}>
          <IconButton onClick={props.onEdit} className={classes.actionButton} size="large">
            <img src={EditIconDark} className={classes.actionIcon} alt='icon' />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('delete_notification')}>
          <IconButton onClick={props.onDelete} className={classes.actionButton} size="large">
            <img src={DeleteIconDark} className={classes.actionIcon} alt='icon' />
          </IconButton>
        </Tooltip>
      </div>
    )
  }

  return (
    <TableRow classes={{ root: props.odd ? classes.rootOdd : classes.root }}>
      <TableCell classes={{ root: classes.cell }} size='small'>
        {item.titleFi || item.titleEn}
      </TableCell>
      <TableCell classes={{ root: classes.cell }} size='small'>
        {moment(item.publishedAt).format('DD.MM.YYYY HH:mm')}
      </TableCell>
      <TableCell classes={{ root: classes.cell }} size='small'>
        {renderActions()}
      </TableCell>
    </TableRow>
  )
}

export default MainEntityNotificationsTableRow
