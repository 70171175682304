import React, { useState, useCallback } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { observer } from 'mobx-react'
import Sidebar from './Sidebar'
// import Header from './Header'
import Notification from '../../Components/Common/Notification'
import { useStore } from '../../Models/RootStore'
import { AvailableRoutes } from '../../Constants'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    marginLeft: '5rem'
  },
  sidebarContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    zIndex: 9999
  }
}))

function AppLayout(props) {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const toggleDrawer = () => setOpen(!open)

  const { appStore, sessionStore }: any = useStore()
  const { notification, clearNotification } = appStore

  const getNavigationItems = useCallback(() => {
    if (sessionStore.user) {
      const role = sessionStore.user?.role ?? null
      const mainEntityTypes = sessionStore?.user?.availableMainEntityTypes ?? []
      return AvailableRoutes(role, mainEntityTypes)
    }
    return []
  }, [sessionStore.user])

  const logout = () => sessionStore.logout()

  return (
    <div className={classes.root}>
      <div className={classes.sidebarContainer}>
        <Sidebar
          open={open}
          toggleDrawer={toggleDrawer}
          navigationItems={getNavigationItems()}
          user={sessionStore.user}
          logout={logout}
        />
      </div>
      <main className={classes.content}>
        {props.children}
      </main>
      <Notification notification={notification} clearNotification={clearNotification} />
    </div>
  )
}

export default observer(AppLayout)
