import React from 'react'
import { Tooltip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Colors } from '../../Utils/theme'
import { useTranslation } from 'react-i18next'
import Title from '../Common/Title'

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: Colors.backgroundGrey,
    padding: '1.5rem',
    borderRadius: '0.3125rem'
  },
  row: {
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: Colors.black05
    }
  },
  rowTail: {
    width: '0.75rem'
  },
  barContainer: {
    flex: 1
  },
  bar: {
    backgroundColor: Colors.black40,
    height: '0.75rem',
    borderRadius: '0.375rem'
  },
  weekNumber: {
    margin: 0,
    fontFamily: 'Roboto',
    fontSize: '0.875rem',
    fontWeight: 300,
    color: Colors.black70,
    marginRight: '1rem'
  },
  xAxisContainer: {
    display: 'flex',
    flexDirection: 'row',
    color: Colors.black40,
    paddingTop: '0.75rem'
  },
  xAxisFirstColumn: {
    flex: 1,
    textAlign: 'left',
    margin: 0,
    fontFamily: 'Roboto',
    fontSize: '0.875rem',
    fontWeight: 300,
    color: Colors.black70,
    marginRight: '1rem'
  },
  xAxisCenterColumn: {
    flex: 1,
    textAlign: 'center',
    margin: 0,
    fontFamily: 'Roboto',
    fontSize: '0.875rem',
    fontWeight: 300,
    color: Colors.black70,
    marginRight: '1rem'
  },
  xAxisLastColumn: {
    flex: 1,
    textAlign: 'right',
    margin: 0,
    fontFamily: 'Roboto',
    fontSize: '0.875rem',
    fontWeight: 300,
    color: Colors.black70,
    marginRight: '1rem'
  }
}))

function WeeklyInterestBarChart (props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const getMaxCount = () => {
    let max = 0
    for (const item of props.items) {
      if (max < item.count) max = item.count
    }
    return max
  }

  const renderRows = () => {
    const maxCount = getMaxCount()

    return props.items.slice(0, 10).map((item, index) => {
      const width = maxCount ? Math.floor((item.count / maxCount) * 100) : 0
      return (
        <Tooltip title={item.count} key={index}>
          <div className={classes.row}>
            <p className={classes.weekNumber}>{item.weekNumber}</p>
            <div className={classes.barContainer}>
              <div className={classes.bar} style={{ width: `${width}%` }} />
            </div>
            <div className={classes.rowTail} />
          </div>
        </Tooltip>
      )
    })
  }

  const renderXAxis = () => {
    const maxCount = getMaxCount()
    const midCount = Math.round(maxCount / 2)
    const minCount = 0

    return (
      <div className={classes.xAxisContainer}>
        <div className={classes.weekNumber} />
        <p className={classes.xAxisFirstColumn}>{minCount}</p>
        <p className={classes.xAxisCenterColumn}>{midCount}</p>
        <p className={classes.xAxisLastColumn}>{maxCount}</p>
        <div className={classes.rowTail} />
      </div>
    )
  }

  return (
    <div className={classes.container}>
      <Title title={t('weekly_interest_counts')} type='widget' />
      {renderRows()}
      {renderXAxis()}
    </div>
  )
}

export default WeeklyInterestBarChart
