import React from 'react'
import Chip from '@mui/material/Chip'
import Autocomplete from '@mui/material/Autocomplete'
import Input from './Input'
import { useTranslation } from 'react-i18next'
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
    },
    input: {
      backgroundColor: Colors.black80,
      fontFamily: 'Roboto',
      fontSize: '0.75rem',
      fontWeight: 600,
      color: Colors.white,
      borderRadius: '0.3125rem'
    },
    deleteIcon: {
      color: Colors.white,
      '&:hover': {
        color: Colors.white80
      }
    }
  })
)

export default function MultipleSelect(props) {
  const { t } = useTranslation()
  const classes = useStyles()

  const onChange = (evt, value) => props.onChange(value)

  const renderInput = (params) => (
    <Input
      label={props.label}
      onChange={() => {}}
      {...params}
    />
  )
  const renderTags = (value: string[], getTagProps) => {
    return value.map((option: string, index: number) => {
      if (!!option) {
        return (
          <Chip
            key={index}
            label={props.options.find((opt) => option === opt.value)?.label || option}
            classes={{ root: classes.input, deleteIcon: classes.deleteIcon }}
            {...getTagProps({ index })}
          />
        )
      }
      return null
    }
  )}

  return (
    <div className={classes.root}>
      <Autocomplete
        id='domains'
        options={props.options.map((option) => option.label)}
        renderInput={renderInput}
        renderTags={renderTags}
        openText={t('open')}
        closeText={t('close')}
        clearText={t('clear')}
        noOptionsText={t('no_results')}
        disabled={props.disabled}
        onChange={onChange}
        value={props.value}
        filterSelectedOptions
        multiple
      />
    </div>
  )
}
