import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import { Checkbox, Input } from '../'
import moment from 'moment'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles((theme) =>({
  root: {
  },
  title: {
    fontFamily: 'Rubik',
    fontWeight: 600,
    fontSize: '1.875rem'
  },
  description: {
    fontWeight: 300,
    marginBottom: '2rem'
  },
  inputTitle: {
    fontSize: '1rem',
    fontWeight: 500,
    marginBottom: '0.5rem',
    color: Colors.label
  },
  dateTimeContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  dateContainer: {
    width: '12rem',
    marginRight: '2rem',
    marginLeft: '2rem'
  },
  timeContainer: {
    width: '8rem'
  },
  checksContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '1rem'
  }
}))

function PollTimingForm (props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const [noExpiration, setNoExpiration] = useState(!props.validUntil)

  const [startDate, setStartDate] = useState(props.validFrom ? moment(props.validFrom).format('YYYY-MM-DD') : null)
  const [startTime, setStartTime] = useState(props.validFrom ? moment(props.validFrom).format('HH:mm') : null)

  const [endDate, setEndDate] = useState(props.validUntil ? moment(props.validUntil).format('YYYY-MM-DD') : null)
  const [endTime, setEndTime] = useState(props.validUntil ? moment(props.validUntil).format('HH:mm') : null)

  const toggleDraft = () => {
    props.setDraft(!props.draft)
    setStartDate(null)
    setStartTime(null)
    props.setValidFrom(null)
  }

  const toggleNoExpiration = () => {
    setNoExpiration(!noExpiration)
    setEndDate(null)
    setEndTime(null)
    props.setValidUntil(null)
  }

  const handleSetStartDate = (date) => {
    setStartDate(date)
    try {
      const newDate = moment(`${date} ${startTime}`)
      if (newDate.isValid()) {
        props.setValidFrom(newDate.format())
      }
    } catch (err) {
    }
  }

  const handleSetStartTime = (time) => {
    setStartTime(time)
    try {
      const newDate = moment(`${startDate} ${time}`)
      if (newDate.isValid()) {
        props.setValidFrom(newDate.format())
      }
    } catch (err) {
    }
  }


  const handleSetEndDate = (date) => {
    setEndDate(date)
    try {
      const newDate = moment(`${date} ${endTime}`)
      if (newDate.isValid()) {
        props.setValidUntil(newDate.format())
      }
    } catch (err) {
    }
  }

  const handleSetEndTime = (time) => {
    setEndTime(time)
    try {
      const newDate = moment(`${endDate} ${time}`)
      if (newDate.isValid()) {
        props.setValidUntil(newDate.format())
      }
    } catch (err) {
    }
  }

  return (
    <div className={classes.root}>
      <h2 className={classes.title}>{t('start_time')}</h2>
      <div className={classes.checksContainer}>
        <Checkbox
          checked={props.draft}
          onChange={toggleDraft}
          label={t('save_as_draft')}
        />
        <Checkbox
          checked={!props.draft}
          onChange={toggleDraft}
          label={t('timed')}
        />
      </div>
      <div className={classes.dateTimeContainer}>
        <div className={classes.dateContainer}>
          <div className={classes.inputTitle}>{t('date_short')}</div>
          <Input
            value={startDate}
            onChange={handleSetStartDate}
            type='date'
            disabled={props.draft}
          />
        </div>
        <div className={classes.timeContainer}>
          <div className={classes.inputTitle}>{t('time_short')}</div>
          <Input
            value={startTime}
            onChange={handleSetStartTime}
            type='time'
            disabled={props.draft}
          />
        </div>
      </div>

      <h2 className={classes.title}>{t('end_time')}</h2>
      <div className={classes.checksContainer}>
        <Checkbox
          checked={noExpiration}
          onChange={toggleNoExpiration}
          label={t('valid_for_now')}
        />
        <Checkbox
          checked={!noExpiration}
          onChange={toggleNoExpiration}
          label={t('ends_on_time')}
        />
      </div>

      <div className={classes.dateTimeContainer}>
        <div className={classes.dateContainer}>
          <div className={classes.inputTitle}>{t('date_short')}</div>
          <Input
            value={endDate}
            onChange={handleSetEndDate}
            disabled={noExpiration}
            type='date'
          />
        </div>
        <div className={classes.timeContainer}>
          <div className={classes.inputTitle}>{t('time_short')}</div>
          <Input
            value={endTime}
            onChange={handleSetEndTime}
            disabled={noExpiration}
            type='time'
          />
        </div>
      </div>
    </div>
  )
}

export default PollTimingForm
