import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'
import { useStore } from '../../../Models/RootStore'
import { PageHeader, Button, Input, Checkbox } from '../../../Components'
import { ENTITY_GEO_TYPES } from '../../../Constants'
import { Colors } from '../../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  contentContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 0
  },
  content: {
    padding: '2.5rem'
  },
  geoTypesContainer: {
    paddingBottom: '2rem'
  },
  validationError: {
    color: Colors.red
  }
}))

const AdminCreateMainEntityType = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { adminStore }: any = useStore()

  const [type, setType] = useState(null)
  const [nameFi, setNameFi] = useState(null)
  const [nameEn, setNameEn] = useState(null)
  const [photo, setPhoto] = useState(null)
  const [geoTypes, setGeoTypes] = useState([])

  const toggleGeoType = (type) => {
    const newGeoTypes = geoTypes.filter(gt => gt !== type)
    if (newGeoTypes.length === geoTypes.length) newGeoTypes.push(type)
    setGeoTypes(newGeoTypes)
  }

  const handleCreate = () => {
    adminStore.createMainEntityType({
      type,
      name: nameFi, // Fallback for legacy field
      nameFi,
      nameEn,
      photo: photo || null,
      geoTypes: geoTypes.length ? geoTypes : null
    })
  }

  const renderGeoTypeSelectHint = () => {
    if (!geoTypes.length) {
      return (
        <p className={classes.validationError}>
          {t('select_min_1_geo_type')}
        </p>
      )
    }
    return null
  }

  const renderGeoTypes = () => {
    return (
      <div className={classes.geoTypesContainer}>
        <h3>{t('geo_types')}</h3>
        {renderGeoTypeSelectHint()}
        {ENTITY_GEO_TYPES.map(type => {
          const isChecked = geoTypes.includes(type)
          return (
            <div>
              <Checkbox
                key={type}
                checked={isChecked}
                onChange={() => toggleGeoType(type)}
                label={t(type)}
              />
            </div>
          )
        })}
      </div>
    )
  }

  const isCreateDisabled = () => {
    if (!geoTypes.length || !nameFi || !nameEn || !type) return true
    return false
  }

  return (
    <div className={classes.root}>
      <PageHeader
        title={t('create_main_entity_type')}
      />
      <div className={classes.contentContainer}>
        <div className={classes.content}>
          <Input label={t('type')} value={type} onChange={setType} />
          <Input label={t('name_fi')} value={nameFi} onChange={setNameFi} />
          <Input label={t('name_en')} value={nameEn} onChange={setNameEn} />
          <Input label={t('photo')} value={photo} onChange={setPhoto} />
          {renderGeoTypes()}
          <Button
            text={t('save')}
            onClick={handleCreate}
            disabled={isCreateDisabled()}
          />
        </div>
      </div>
    </div>
  )
}

export default observer(AdminCreateMainEntityType)
