import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'

export default class LogStore {
  rootStore
  loginLog: any = []
  loading = null

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  updateLoginLog = (log) => { this.loginLog = log }
  updateLoading = (loading) => { this.loading = loading }

  async getMyLoginLog() {
    this.updateLoading(true)
    const response = await Api.getMyLoginLog({ take: 20 })
    this.updateLoading(false)
    if (response.ok) {
      const loginLog = response.data
      this.updateLoginLog(loginLog)
    }
  }
}
