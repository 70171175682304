import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import makeStyles from '@mui/styles/makeStyles'
import Modal from '@mui/material/Modal'
import get from 'lodash/get'
import striptags from 'striptags'
import { decode } from 'html-entities'
import linkifyHtml from 'linkify-html'
import { Input, Button, Select, HtmlInput } from '..'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  modal: {
    // zIndex: 99999
  },
  contentContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: Colors.white,
    minWidth: '40rem',
    borderRadius: 3
  },
  content: {
    padding: '2rem 3rem',
    paddingBottom: '1rem',
    maxHeight: 'calc(90vh - 4rem)',
    overflow: 'auto'
  },
  title: {
    fontFamily: 'Rubik',
    fontWeight: 600,
    color: Colors.violet,
    fontSize: '1.675rem',
    paddingBottom: '0.325rem',
    marginBottom: '1rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  typeContainer: {
    flex: 1,
    marginRight: '2rem'
  },
  dateContainer: {
    marginRight: '1.5rem'
  },
  timeContainer: {
  },
  label: {
    fontSize: '1rem',
    fontWeight: 500,
    marginBottom: '0.5rem',
    color: Colors.label
  },
  footerContainer: {
    height: '5.5rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0rem 3rem',
    borderTop: `1px solid ${Colors.black20}`
  },
  saveButton: {
    width: '9rem',
    height: '3.125rem'
  },
  cancelButton: {
    width: '7rem',
    height: '3.125rem',
    border: 'none',
    margin: '0rem 0.75rem',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: Colors.violet10
    }
  },
  langContainer: {
    flex: 1,
    marginTop: '1.325rem'
  },
  spacer: {
    width: '2rem'
  },
  info: {
    fontSize: '1.125rem',
    fontWeight: 300,
    color: Colors.black70,
    marginBottom: '1.25rem'
  }
}))

export default function MainEntityNotificationModal(props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const { item, handleClose } = props

  const [lang, setLang] = useState('fi')
  const [titleFi, setTitleFi] = useState(get(item, 'titleFi') || null)
  const [titleEn, setTitleEn] = useState(get(item, 'titleEn') || null)

  const typeOptions = [
    {
      label: t('exception_notification'),
      value: 'exception_notification'
    }
  ]


  const [type, setType] = useState(typeOptions[0].value)
  const [startDate, setStartDate] = useState(get(item, 'publishedAt') ? moment(item.publishedAt).format('YYYY-MM-DD') : null)
  const [startTime, setStartTime] = useState(get(item, 'publishedAt') ? moment(item.publishedAt).format('HH:mm') : null)
  const [endDate, setEndDate] = useState(get(item, 'expiresAt') ? moment(item.expiresAt).format('YYYY-MM-DD') : null)
  const [endTime, setEndTime] = useState(get(item, 'expiresAt') ? moment(item.expiresAt).format('HH:mm') : null)


  const [descriptionHtmlFi, setDescriptionHtmlFi] = useState(get(item, 'descriptionHtmlFi') || '')
  const [descriptionHtmlEn, setDescriptionHtmlEn] = useState(get(item, 'descriptionHtmlEn') || '')

  const save = () => {
    // Descriptions
    let newDescriptionHtmlFi = null
    let newDescriptionFi = null

    if (descriptionHtmlFi) {
      newDescriptionHtmlFi = linkifyHtml(descriptionHtmlFi)
      newDescriptionFi = decode(striptags(newDescriptionHtmlFi, [], '\n').trim()).trim()
      if (!newDescriptionFi) newDescriptionHtmlFi = null
    }

    let newDescriptionHtmlEn = null
    let newDescriptionEn = null

    if (descriptionHtmlEn) {
      newDescriptionHtmlEn = linkifyHtml(descriptionHtmlEn)
      newDescriptionEn = decode(striptags(newDescriptionHtmlEn, [], '\n').trim()).trim()
      if (!newDescriptionEn) newDescriptionHtmlEn = null
    }

    const newStartDate = moment(`${startDate} ${startTime}`).format()
    let newEndDate = null
    if (endDate && endTime) {
      newEndDate = moment(`${endDate} ${endTime}`).format()
    }

    const newItem = {
      titleFi,
      titleEn,
      type,
      contentFi: newDescriptionFi,
      contentEn: newDescriptionEn,
      htmlFi: newDescriptionHtmlFi,
      htmlEn: newDescriptionHtmlEn,
      publishedAt: newStartDate,
      expiresAt: newEndDate
    }

    props.handleSave(newItem)
  }

  const isSaveDisabled = () => {
    if (!titleFi && !titleEn) return true
    if (!type) return true
    if (!descriptionHtmlFi && !descriptionHtmlEn) return true
    if (!startDate || !startTime) return true
    return false
  }

  const handleSetTitle = (value) => {
    if (lang === 'en') {
      setTitleEn(value)
    } else {
      setTitleFi(value)
    }
  }
  const getDescriptionHtml = () => {
    if (lang === 'en') {
      return descriptionHtmlEn
    } else {
      return descriptionHtmlFi
    }
  }

  const setDescriptionHtml = (value) => {
    if (lang === 'en') {
      setDescriptionHtmlEn(value)
    } else {
      setDescriptionHtmlFi(value)
    }
  }

  return (
    <Modal onClose={handleClose} classes={{ root: classes.modal }} open>
      <div className={classes.contentContainer}>
        <div className={classes.content}>
          <div className={classes.title}>{item ? t('edit_notification') : t('add_notification')}</div>
          <div className={classes.info}>
            {t('main_entity_notification_description')}
          </div>
          <div>
            <div className={classes.label}>{t('notification_title')}</div>
            <Input value={lang === 'en' ? titleEn : titleFi} onChange={handleSetTitle} />
          </div>
          <div className={classes.row}>
            <div className={classes.typeContainer}>
              <div className={classes.label}>{t('type')}</div>
              <Select
                options={typeOptions}
                value={type}
                onChange={setType}
                style={{
                  width: '100%',
                  minWidth: '22rem',
                  maxWidth: '22rem'
                }}
              />
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.dateContainer}>
              <div className={classes.label}>{t('publish_date')}</div>
              <Input
                value={startDate}
                onChange={setStartDate}
                type='date'
              />
            </div>
            <div className={classes.timeContainer}>
              <div className={classes.label}>{'\u00a0'}</div>
              <Input
                value={startTime}
                onChange={setStartTime}
                type='time'
              />
            </div>
            <div className={classes.spacer} />
            <div className={classes.dateContainer}>
              <div className={classes.label}>{t('expire_date')}</div>
              <Input
                value={endDate}
                onChange={setEndDate}
                type='date'
              />
            </div>
            <div className={classes.timeContainer}>
              <div className={classes.label}>{'\u00a0'}</div>
              <Input
                value={endTime}
                onChange={setEndTime}
                type='time'
              />
            </div>
          </div>
          <div className={classes.label}>{t('notification_content')}</div>
          <HtmlInput
            key={lang}
            placeholder={t('description')}
            value={getDescriptionHtml()}
            onChange={setDescriptionHtml}
          />
        </div>
        <div className={classes.footerContainer}>
          <div className={classes.langContainer}>
            <Select
              options={[
                {
                  label: 'FI',
                  value: 'fi'
                },
                {
                  label: 'EN',
                  value: 'en'
                }
              ]}
              value={lang}
              onChange={setLang}
              style={{
                backgroundColor: Colors.white,
                maxWidth: '4.25725rem'
              }}
            />
          </div>
          <Button
            onClick={handleClose}
            text={t('cancel')}
            buttonStyle={classes.cancelButton}
            outlined
          />
          <Button
            onClick={save}
            text={t('save')}
            disabled={isSaveDisabled()}
            buttonStyle={classes.saveButton}
          />
        </div>
      </div>
    </Modal>
  )
}
