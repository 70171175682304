import React, { useCallback, useEffect, useRef, useState } from 'react'
import ReactMapGL, { MapRef } from 'react-map-gl'
import {
  Editor,
  DrawLineStringMode,
  DrawPolygonMode,
  DrawPointMode,
  EditingMode
} from 'react-map-gl-draw'
import { useTranslation } from 'react-i18next'
import get from 'lodash/get'
import makeStyles from '@mui/styles/makeStyles'
import { useFilePicker } from 'use-file-picker'
import MapToolButton from './MapToolButton'
import MapTooltip from './MapTooltip'
import MapAutocomplete from './MapAutocomplete'
import SelectMapThemeModal from './SelectMapThemeModal'
import { Button, Select } from '..'
import { getFeatureStyle } from './mapStyles'
import {
  getFeatureLength,
  getFeatureTooltipCoords,
  getFeatureViewport,
  gpxToFeature
} from '../../Utils/map'
import { Colors } from '../../Utils/theme'

// Icons
import SplitScreenIcon from '@mui/icons-material/VerticalSplitOutlined'
import OnlyMapIcon from '@mui/icons-material/MapOutlined'
import OnlyTextIcon from '@mui/icons-material/ArticleOutlined'
import PlusIcon from '../../Assets/Icons/plus.svg'
import MinusIcon from '../../Assets/Icons/minus.svg'
import PinIcon from '../../Assets/Icons/pin.svg'
import PolygonIcon from '../../Assets/Icons/polygon.svg'
// import LockOpenIcon from '../../Assets/Icons/lock-open.svg'
// import LockClosedIcon from '../../Assets/Icons/lock-closed.svg'
import DeleteIcon from '../../Assets/Icons/delete-white.svg'
import GpxIcon from '../../Assets/Icons/gpx.svg'
import LineIcon from '../../Assets/Icons/line.svg'
// import MapVisibility from '../../Assets/Icons/map-visibility.svg'
// import MapSizeIcon from '../../Assets/Icons/size-horizontal.svg'
import LayersIcon from '../../Assets/Icons/layers.svg'
import { EntityMapFilterOptions, EntityMapFilterType, MapThemes } from '../../Constants'

// This is a dependency of react-map-gl even if you didn't explicitly install it
import mapboxgl from 'mapbox-gl'
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
(mapboxgl as any).workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default

const MapboxToken = 'pk.eyJ1IjoidG1haG9uZW5nZW5pZW0iLCJhIjoiY2tpNDdyc21vMG43dzJxc3l3ZXZtbGpzOCJ9.vjCYofggFaw8pg2y1LuM2g'

const MAP_STYLE_STORAGE_KEY = 'JANA_GIS_MAPBOX_MAP_STYLE_V1'

const useStyles = makeStyles((theme) => ({
  toolsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginBottom: '0.5rem',
    height: '7rem'
  },
  toolsContainerTopLeft: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: 'calc(100% - 12.5rem)',
    maxWidth: '60rem',
    marginTop: '0.625rem'
  },
  topLeftTool: {
    flex: 1
  },
  toolsContainerTopRight: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    margin: 0,
    marginTop: '0.625rem'
  },
  centerButtonsContainer: {
    position: 'absolute',
    top: '50%',
    right: '10px',
    transform: 'translateY(-50%)'
  },
  searchContainer: {
    background: Colors.white,
    borderRadius: '0.3125rem',
    display: 'inline-block'
  },
  saveButtonContainer: {
    height: '3.125rem',
    borderRadius: '0.3125rem',
    backgroundColor: Colors.violet80,
    '&:hover': {
      backgroundColor: Colors.violet
    },
    marginLeft: '1rem'
  },
  saveButton: {
    height: '3.125rem !important',
    backgroundColor: `${Colors.violet80} !important`,
    width: '14rem',
    '&:active': {
      color: Colors.violet
    }
  },
  saveButtonText: {
    fontSize: '1rem'
  },
  buttonsInlineContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  inlineSpacer: {
    width: '1rem'
  },
  inlineSmallSpacer: {
    width: '0.5rem'
  },
  layerButtonContainer: {
    marginBottom: '0.5rem'
  },
  toolButtonSpacer: {
    height: '1rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  fullScreenMargin: {
    marginLeft: '1rem'
  },
  noLocationContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: 'hidden',
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(255,255,255,.75)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    zIndex: 2
  },
  noLocationText: {
    fontSize: '2rem',
    marginBottom: '1.5rem',
    maxWidth: '30rem',
    minWidth: '4rem',
    overflow: 'hidden',
    textAlign: 'center',
    fontWeight: 300
  },
  beginButton: {
    width: '8rem'
  }
}))

const styles = {
  select: {
    minWidth: '12rem',
    height: '3.125rem',
    fontSize: '1rem',
    color: Colors.inputText,
    backgroundColor: Colors.white,
    marginRight: '0.5rem'
  },
  materialIcon: {
    color: Colors.white,
    height: '1.375rem',
    width: '1.375rem'
  }
} as const

function MapEditor(props) {
  const classes = useStyles()
  const editorRef = useRef(null)
  const { t } = useTranslation()

  const mapRef = useRef<MapRef>()

  const [openFileSelector, { filesContent }] = useFilePicker({
    accept: '.gpx',
    multiple: false
  })
  
  const [viewport, setViewport] = useState({
    latitude: props.latitude || 64.9147,
    longitude: props.longitude || 26.0673,
    zoom: (props.latitude && props.longitude) ? 14 : 4,
    bearing: 0,
    pitch: 0
  })
  const [mapTheme, setMapTheme] = useState(
    (
      window.localStorage ?
      window.localStorage.getItem(MAP_STYLE_STORAGE_KEY) :
      null
    ) ||
    MapThemes[0].value
  )
  const [previewMapTheme, setPreviewMapTheme] = useState(null)
  const [activeMode, setActiveMode] = useState('editing')
  const [modeHandler, setModeHandler] = useState(new EditingMode())
  const [selectedFeatureIndex, setSelectedFeatureIndex] = useState(null)
  const [filter, setFilter] = useState(EntityMapFilterType.AllEntities)
  const [savedAt, setSavedAt] = useState(0)
  const [updatedAt, setUpdatedAt] = useState(props.updatedAt || 0)
  // Handle refresh after map is toggled
  const [mountedAt] = useState(Date.now())
  const [selectThemeVisible, setSelectThemeVisible] = useState(false)

  useEffect(() => {
    if (!props.mapHidden && Date.now() - mountedAt > 1000) {
      setTimeout(() => {
        setViewport({ ...viewport })
      }, 500)
    }
  }, [props.mapHidden])

  // Handle focus events
  useEffect(() => {
    // Update viewport if event created < 500ms ago
    if (props.focusEvent && props.focusEvent.time > Date.now() - 500) {
      setViewport({ ...viewport, ...props.focusEvent.viewport })
      // Animate transition
      mapRef.current?.getMap().flyTo({ 
        center: [
          props?.focusEvent?.viewport?.longitude,
          props?.focusEvent?.viewport?.latitude
        ],
        duration: 1000
      })
    }
  }, [props.focusEvent])

  // Handle GPX file import
  useEffect(() => {
    if (filesContent && filesContent.length) {
      const importedFeatures = gpxToFeature(filesContent[0].content, props.addModes)
      if (importedFeatures) {

        // Add features in array mode
        props.addFeature(importedFeatures, true)

        // Switch to editing mode after gpx import
        switchMode('editing')

        // Center map based on first imported feature
        setViewport({ ...viewport, ...getFeatureViewport(importedFeatures[0]) })

        // Update timestamp
        setUpdatedAt(Date.now())
      } else {
        // Error when parsing GPX file
        if (props.onGpxImportError) {
          props.onGpxImportError()
        }
      }
    }
  }, [filesContent])

  useEffect(() => {
    // Trigger map update from parent component
    setUpdatedAt(props.updatedAt)
  }, [props.updatedAt])

  const toggleSelectThemeVisible = () => {
    setPreviewMapTheme(null)
    setSelectThemeVisible(!selectThemeVisible)
  }

  const getMapTheme = () => {
    if (previewMapTheme) {
      return previewMapTheme
    } else if (MapThemes.find(item => item.value === mapTheme)) {
      return mapTheme
    }
    return MapThemes?.[0]?.value
  }

  const updateMapTheme = (theme: string) => {
    setMapTheme(theme)
    setPreviewMapTheme(null)
    setSelectThemeVisible(false)
    if (window?.localStorage) {
      window.localStorage?.setItem(MAP_STYLE_STORAGE_KEY, theme)
    }
  }

  const getFeatures = () => {
    if (filter === EntityMapFilterType.OnlySubentities) {
      return props.features.filter((item: any) => !item.properties.isMainEntity)
    }
    return props.features
  }

  const onSelect = useCallback((options) => {
    setSelectedFeatureIndex(options && options.selectedFeatureIndex)
  }, [])

  const onDelete = useCallback(() => {
    if (selectedFeatureIndex !== null && selectedFeatureIndex >= 0) {
      props.deleteFeature(selectedFeatureIndex)
    }
  }, [selectedFeatureIndex])

  const getModes = () => {
    return [
      { id: 'editing', text: 'Edit Feature', handler: EditingMode },
      { id: 'drawPolyline', handler: DrawLineStringMode },
      { id: 'drawPolygon', handler: DrawPolygonMode },
      { id: 'drawPoint', text: 'Draw Point', handler: DrawPointMode }
    ]
  }

  const switchMode = (modeKey) => {
    if (activeMode === modeKey) {
      // Detect toggle
      setActiveMode(null)
      setModeHandler(null)
    } else {
      const mode = getModes().find((m) => m.id === modeKey)
      const modeHandler = mode ? new mode.handler() : null
      setActiveMode(modeKey)
      setModeHandler(modeHandler)
    }
  }

  const handleUpdate = (event) => {
    // Detect when adding ready
    if (event.editType === 'addFeature') {
      switchMode(null)
    }
    setUpdatedAt(Date.now())
    props.onUpdate(event)
  }

  const onNextViewport = (viewport) => setViewport(viewport)

  const zoomIn = () => setViewport({ ...viewport, zoom: viewport.zoom * 1.1 })
  const zoomOut = () => setViewport({ ...viewport, zoom: viewport.zoom * 0.9 })

  const handleSaveChanges = () => {
    props.onSave()
    setSavedAt(Date.now())
  }

  const getMapSettingsButtons = () => {
    const buttons: any = [
      {
        title: t('view_split_screen'),
        iconComponent: <SplitScreenIcon sx={styles.materialIcon} />,
        onClick: () => props.showSplitScreen(),
        active: !props.mapHidden && !props.fullScreen
      },
      {
        title: t('view_without_map'),
        iconComponent: <OnlyTextIcon sx={styles.materialIcon} />,
        onClick: () => props.smallViewport ? props.showSplitScreen() : props.hideMap(),
        active: props.mapHidden
      },
      {
        title: t('view_only_map'),
        iconComponent: <OnlyMapIcon sx={styles.materialIcon} />,
        onClick: () => props.showFullScreenMap(),
        active: props.fullScreen
      }
    ]
    if (props.smallViewport) return buttons.slice(1)
    return buttons
  }

  const getButtons = () => {
    let buttons = []

    if (props.mode === 'add') {
      const addModes = props.addModes || []
      if (addModes.includes('area')) {
        buttons.push(
          {
            title: t('draw_polygon'),
            icon: PolygonIcon,
            onClick: () => switchMode('drawPolygon'),
            active: activeMode === 'drawPolygon'
          }
        )
      }

      if (addModes.includes('route')) {
        buttons.push(
          {
            title: t('draw_line'),
            icon: LineIcon,
            onClick: () => switchMode('drawPolyline'),
            active: activeMode === 'drawPolyline'
          }
        )
      }

      if (addModes.includes('point')) {
        buttons.push(
          {
            title: t('draw_point'),
            icon: PinIcon,
            onClick: () => switchMode('drawPoint'),
            active: activeMode === 'drawPoint'
          }
        )
      }
      // Make sure atleast one add mode is enabled
      if (addModes.length) {
        buttons.push({
          title: t('import_gpx_file'),
          icon: GpxIcon,
          onClick: openFileSelector
        })
      }
    }

    if (selectedFeatureIndex || selectedFeatureIndex === 0) {
      buttons.push({
        title: t('delete_item'),
        icon: DeleteIcon,
        onClick: () => {
          onDelete()
          switchMode('editing')
        }
      })
    }

    return buttons
  }

  const renderTopLeftTools = () => {
    return (
      <div className={`mapboxgl-ctrl-top-left ${classes.toolsContainerTopLeft} ${props.fullScreen ? classes.fullScreenMargin : ''}`.trim()}>
        <div className={`mapboxgl-ctrl ${classes.topLeftTool}`}>
          <MapAutocomplete setFocus={props.setFocus} />
        </div>
        <div className={classes.inlineSmallSpacer} />
        <div className={`mapboxgl-ctrl ${classes.topLeftTool}`}>
          <Select
            options={EntityMapFilterOptions}
            value={filter}
            onChange={setFilter}
            style={styles.select}
          />
        </div>
      </div>
    )
  }

  const renderCenterButtons = () => {
    const buttons = getButtons()
    return (
      <div className={classes.centerButtonsContainer}>
        {buttons.map((button, index) => (
          <React.Fragment key={index}>
            <MapToolButton {...button} />
            {(index < buttons.length - 1) ? <div className={classes.toolButtonSpacer} /> : null}
          </React.Fragment>
        ))}
      </div>
    )
  }

  const renderSaveButton = () => {
    if (props.onSave && updatedAt > savedAt) {
      return (
        <div className={classes.saveButtonContainer}>
          <Button
            text={t('save_changes')}
            onClick={handleSaveChanges}
            buttonStyle={classes.saveButton}
            buttonTextStyle={classes.saveButtonText}
          />
        </div>
      )
    }
    return null
  }

  const renderTools = () => {
    return (
      <>
        <div className='mapboxgl-ctrl-top-right'>
          <div className='mapboxgl-ctrl'>
            <div className={classes.toolsContainerTopRight}>
              <div className={classes.buttonsInlineContainer}>
                {getMapSettingsButtons().map((button: any, index: number) => (
                  (
                    <React.Fragment key={index}>
                      {!!index && <div className={classes.inlineSpacer} />}
                      <MapToolButton key={index} {...button} />
                    </React.Fragment>
                  )
                ))}
              </div>
            </div>
          </div>
        </div>
        {renderCenterButtons()}
        <div className='mapboxgl-ctrl-bottom-right'>
          <div className='mapboxgl-ctrl'>
            <div className={classes.toolsContainer}>
              <MapToolButton title={t('zoom_in')} icon={PlusIcon} onClick={zoomIn} />
              <MapToolButton title={t('zoom_out')} icon={MinusIcon} onClick={zoomOut} />
            </div>
          </div>
        </div>
        <div className='mapboxgl-ctrl-bottom-left'>
          <div className={`mapboxgl-ctrl ${classes.row}`}>
            {renderMapStyleSelector()}
            {renderSaveButton()}
          </div>
        </div>
      </>
    )
  }

  const renderTooltip = () => {
    // Render tooltip if feature is selected
    if (props.mode !== 'add' && props.tooltipEnabled && selectedFeatureIndex !== null) {
      const selectedFeature = props.features[selectedFeatureIndex]
      const tooltipCoords = getFeatureTooltipCoords(selectedFeature)
      if (tooltipCoords) {
        let title = ''
        let type = ''

        console.log(selectedFeature)

        if (!get(selectedFeature, 'properties.isMainEntity')) {
          title = `${get(selectedFeature, 'properties.subEntityName')}`
          type = t(`${get(selectedFeature, 'properties.subEntityType')}`)
        } else {
          title = t(`${get(selectedFeature, 'properties.name')}`)
          type = t(`${get(selectedFeature, 'properties.mainEntityType')}`)
        }

        return (
          <MapTooltip
            latitude={tooltipCoords.latitude}
            longitude={tooltipCoords.longitude}
            title={title}
            type={type}
            subEntityType={get(selectedFeature, 'properties.subEntityType')}
            routeLength={getFeatureLength(selectedFeature)}
          />
        )
      }
    }
    return null
  }

  const renderMapStyleSelector = () => (
    <div className={classes.layerButtonContainer}>
      <MapToolButton
        icon={LayersIcon}
        onClick={toggleSelectThemeVisible}
        title={t('change_layer')}
      />
    </div>
  )

  const getCursor = (ref) => {
    let grabIcon = 'grab'

    // Use custom cursor when drawing pin, polygon or line
    if (activeMode && activeMode.includes('draw')) {
      grabIcon = 'url(/images/pin-cursor.png), grab'
    }

    const isDragging = ref.isDragging
    const isHovering = ref.isHovering
    return isDragging ? 'grabbing' : isHovering ? 'pointer' : grabIcon;
  }

  const renderNoLocationInfo = () => {
    if (props.showNoLocationInfo) {
      return (
        <div className={classes.noLocationContainer}>
          <div className={classes.noLocationText}>{t('map_missing_location_info')}</div>
          <div>
            <Button
              text={t('begin')}
              onClick={props.hideNoLocationInfo}
              buttonStyle={classes.beginButton}
            />
          </div>
        </div>
      )
    }
    return null
  }

  const renderSelectMapThemeModal = () => {
    if (selectThemeVisible) {
      return (
        <SelectMapThemeModal
          open={selectThemeVisible}
          handleSave={updateMapTheme}
          handleUpdate={setPreviewMapTheme}
          handleClose={toggleSelectThemeVisible}
          currentTheme={mapTheme}
        />
      )
    }
    return null
  }

  return (
    <>
      <ReactMapGL
        {...viewport}
        ref={mapRef}
        onViewportChange={onNextViewport}
        width='100%'
        height='100%'
        mapboxApiAccessToken={MapboxToken}
        getCursor={getCursor}
        mapStyle={getMapTheme()}
      >
        {renderTopLeftTools()}
        <Editor
          ref={editorRef}
          clickRadius={12}
          editHandleShape='circle'
          mode={modeHandler}
          onSelect={onSelect}
          onUpdate={handleUpdate}
          features={getFeatures()}
          featureStyle={getFeatureStyle(props.highlightedEntity)}
        />
        {renderTools()}
        {renderTooltip()}
        {renderNoLocationInfo()}
      </ReactMapGL>
      {renderSelectMapThemeModal()}
    </>
  )
}

export default MapEditor
