import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment'
import sortBy from 'lodash/sortBy'
import { Tooltip } from '@mui/material'
import { Button, Dialog, Select } from '..'
import ProgramRepeaterItem from './ProgramRepeaterItem'
import AddIcon from '../../Assets/Icons/add-dark.svg'
import ProgramModal from './ProgramModal'
import { Colors } from '../../Utils/theme'
import MiniArrowLeft from '../../Assets/Icons/mini-arrow-left.svg'
import MiniArrowRight from '../../Assets/Icons/mini-arrow-right.svg'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingBottom: '1.5rem'
  },
  list: {
    paddingBottom: '1rem'
  },
  header: {
    display: 'flex',
    flexDirection: 'row'
  },
  placeholderPhoto: {
    height: '3.75rem',
    width: '3.75rem',
    minHeight: '3.75rem',
    minWidth: '3.75rem',
    backgroundColor: 'rgba(0,0,0,.25)'
  },
  flex: {
    flex: 1
  },
  pager: {
    background: Colors.white,
    marginLeft: '1rem',
    height: '3.125rem',
    width: '3.125rem',
    minWidth: '3.125rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `2px solid ${Colors.border}`,
    borderRadius: '.3125rem',
    cursor: 'pointer'
  },
  pagerDisabled: {
    background: Colors.white,
    marginLeft: '1rem',
    height: '3.125rem',
    width: '3.125rem',
    minWidth: '3.125rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `2px solid ${Colors.border}`,
    borderRadius: '.3125rem',
    opacity: 0.5
  },
  pagerIcon: {
    height: '100%',
    width: '100%',
    maxHeight: '.5rem'
  }
}))

export default function ProgramRepeater(props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const [editIndex, setEditIndex] = useState(null)
  const [deleteIndex, setDeleteIndex] = useState(null)
  const [addMode, setAddMode] = useState(false)
  const [dateFilter, setDateFilter] = useState(null)

  useEffect(() => {
    if (dateFilter === null) {
      if (props.value && props.value.length) {
        setDateFilter(moment(props.value[0].startDate).format('YYYY-MM-DD'))
      }
    }
  }, [props.value])

  const handleEdit = (index) => {
    setAddMode(false)
    setDeleteIndex(null)
    setEditIndex(index)
  }

  const handleDelete = (index) => {
    setAddMode(false)
    setEditIndex(null)

    // Confirm delete
    if (deleteIndex === index) {
      const newItems = props.value.filter((item, idx) => {
        if (idx === index) {
          return false
        }
        return true
      })
      props.onChange(newItems)
      setDeleteIndex(null)
    } else {
      // Open delete dialog
      setDeleteIndex(index)
    }
  }

  const renderItem = (item, globalIndex, localIndex) => {
    return (
      <ProgramRepeaterItem
        item={item}
        odd={!(localIndex % 2)}
        onEdit={() => handleEdit(globalIndex)}
        onDelete={() => handleDelete(globalIndex)}
      />
    )
  }

  const handleSaveItem = (item) => {
    if (editIndex !== null) {
      const newItems = props.value.map((oldItem, index) => {
        if (index === editIndex) {
          return item
        }
        return oldItem
      })
      const sortedNewItems = sortBy(newItems, 'startDate')
      props.onChange(sortedNewItems)
    } else {
      // Add new
      const newItems = [...(props.value || []), item]

      // Update date filter
      setDateFilter(moment(item.startDate).format('YYYY-MM-DD'))

      // Sort
      const sortedNewItems = sortBy(newItems, 'startDate')
      props.onChange(sortedNewItems)
    }
    closeModals()
  }

  const closeModals = () => {
    setEditIndex(null)
    setAddMode(false)
    setDeleteIndex(null)
  }

  const renderAddOrEditModal = () => {
    if (editIndex !== null || addMode) {
      return (
        <ProgramModal
          handleSave={handleSaveItem}
          item={editIndex === null ? null : props.value[editIndex]}
          handleClose={closeModals}
        />
      )
    }
  }

  const renderDeleteDialog = () => {
    return (
      <Dialog
        open={deleteIndex !== null}
        handleClose={() => setDeleteIndex(null)}
        handleOk={() => handleDelete(deleteIndex)}
        title={t('confirm_delete_program')}
        description={t('confirm_delete_program_description')}
      />
    )
  }

  const getDates = () => {
    const uniqueDates: string[] = []
    if (props.value) {
      for (const program of props.value) {
        if (program.startDate) {
          const programDate = moment(program.startDate).format('YYYY-MM-DD')
          if (!uniqueDates.includes(programDate)) {
            uniqueDates.push(programDate)
          }
        }
      }
    }
    return uniqueDates.sort()
  }

  const renderPager = (dates) => {
    let currentDateIndex = -1
    if (dateFilter) {
      currentDateIndex = dates.indexOf(dateFilter)
    }
    const previousEnabled = currentDateIndex > 0
    const nextEnabled = currentDateIndex < dates.length - 1

    const toPreviousDate = () => {
      if (previousEnabled) {
        setDateFilter(dates[currentDateIndex - 1])
      }
    }

    const toNextDate = () => {
      if (nextEnabled) {
        setDateFilter(dates[currentDateIndex + 1])
      }
    }

    return (
      <>
        <Tooltip title={t('previous_day')}>
          <button
            className={previousEnabled ? classes.pager : classes.pagerDisabled}
            onClick={toPreviousDate}
          >
            <img src={MiniArrowLeft} className={classes.pagerIcon} />
          </button>
        </Tooltip>
        <Tooltip title={t('next_day')}>
          <button
            className={nextEnabled ? classes.pager : classes.pagerDisabled}
            onClick={toNextDate}
          >
            <img src={MiniArrowRight} className={classes.pagerIcon} />
          </button>
        </Tooltip>
      </>
    )
  }

  const renderHeader = () => {
    const dates = getDates()
    const dateOptions = dates.map(date => {
      return {
        label: moment(date).format('DD.MM.YYYY'),
        value: date
      }
    })

    if (dateOptions.length) {
      return (
        <div className={classes.header}>
          <Select
            options={dateOptions}
            value={dateFilter}
            onChange={setDateFilter}
            style={{
              maxWidth: '13.25rem'
            }}
          />
          <div className={classes.flex} />
          {renderPager(dates)}
        </div>
      )
    }
    return null
  }

  const getItems = () => {
    return (props.value || []).map((item, index) => {
      return {
        ...item,
        index
      }
    }).filter(item => {
      return moment(item.startDate).format('YYYY-MM-DD') === dateFilter
    })
  }

  return (
    <div className={classes.container}>
      {renderHeader()}
      <div className={classes.list}>
        {getItems().map((item, index) => {
          return (
            <React.Fragment key={item.index}>
              {renderItem(item, item.index, index)}
            </React.Fragment>
          )
        })}
      </div>
      <Button
        text={t('add_info')}
        onClick={() => setAddMode(true)}
        rightIcon={AddIcon}
        outlined
      />
      {renderAddOrEditModal()}
      {renderDeleteDialog()}
    </div>
  )

}
