import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import { Button, Checkbox } from '..'

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    marginTop: '1rem',
    maxWidth: '9rem'
  }
}))

function AdminOrganizationMainEntityTypesForm (props) {
  const { t } = useTranslation()
  const classes = useStyles()

  const [mainEntityTypeIds, setMainEntityTypeIds] = useState([])

  useEffect(() => {
    syncInternalState()
  }, [])

  useEffect(() => {
    syncInternalState()
  }, [props.organizationMainEntityTypes])

  const syncInternalState = () => {
    const typeIds = props.organizationMainEntityTypes.map(item => {
      return item.mainEntityTypeId
    })
    setMainEntityTypeIds(typeIds)
  }

  const toggleMainEntityTypeId = (id) => {
    const newMainEntityTypeIds = mainEntityTypeIds.filter(itemId => itemId !== id)
    if (newMainEntityTypeIds.length === mainEntityTypeIds.length) {
      // Need to add
      newMainEntityTypeIds.push(id)
    }
    setMainEntityTypeIds(newMainEntityTypeIds)
  }

  const handleSave = () => {
    props.onSave(mainEntityTypeIds)
  }

  const renderEntityTypes = () => {
    return props.mainEntityTypes.map(item => {
      const checked = mainEntityTypeIds.includes(item.id)
      return (
        <div>
          <Checkbox
            key={item.id}
            checked={checked}
            label={`(id: ${item.id}) ${item.name}`}
            onChange={() => toggleMainEntityTypeId(item.id)}
          />
        </div>
      )
    })
  }

  return (
    <div>
      {renderEntityTypes()}
      <div className={classes.buttonContainer}>
        <Button
          text={t('save')}
          onClick={handleSave}
        />
      </div>
    </div>
  )
}

export default AdminOrganizationMainEntityTypesForm
