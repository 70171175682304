import i18n from '../Localization'
import DashboardIcon from '../Assets/Icons/dashboard.svg'
import EntitiesIcon from '../Assets/Icons/list.svg'
import UsersIcon from '../Assets/Icons/user.svg'
import PollIcon from '../Assets/Icons/poll.svg'
// import analytics from '../Assets/Icons/analytics.svg'
import WikiIcon from '../Assets/Icons/wiki.svg'
import StarIcon from '../Assets/Icons/star-white.svg'
import SettingsIcon from '../Assets/Icons/settings.svg'
import FeedbackIcon from '../Assets/Icons/feedback.svg'
import StarOutlinedIcon from '../Assets/Icons/star-outlined.svg'

// Entity category icons
import SportLocationIcon from '../Assets/Icons/Entities/sport-location.svg'
import RecreationalocationIcon from '../Assets/Icons/Entities/recreational-location.svg'
import ServiceLocationIcon from '../Assets/Icons/Entities/service-location.svg'
import CultureLocationIcon from '../Assets/Icons/Entities/culture-location.svg'
import EventIcon from '../Assets/Icons/Entities/event.svg'
import OtherLocationIcon from '../Assets/Icons/Entities/other-location.svg'

// Entity icons
import AmusementParkIcon from '../Assets/Icons/Entities/amusement-park.svg'
import BaseballFieldIcon from '../Assets/Icons/Entities/baseball-field.svg'
import BasketballCourtIcon from '../Assets/Icons/Entities/basketball-court.svg'
import BeachVolleyFieldIcon from '../Assets/Icons/Entities/beach-volley-field.svg'
import BeachIcon from '../Assets/Icons/Entities/beach.svg'
import CultureEventIcon from '../Assets/Icons/Entities/culture-location.svg'
import DiscGolfCourseIcon from '../Assets/Icons/Entities/disc-golf-course.svg'
import FootballFieldIcon from '../Assets/Icons/Entities/football-field.svg'
import IceSkatingFieldcon from '../Assets/Icons/Entities/ice-skating-field.svg'
import IndoorSportFacilityIcon from '../Assets/Icons/Entities/indoor-sport-facility.svg'
import MarinaIcon from '../Assets/Icons/Entities/marina.svg'
import MarketIcon from '../Assets/Icons/Entities/market.svg'
import MultipurposeFieldIcon from '../Assets/Icons/Entities/multipurpose-field.svg'
import MuseumIcon from '../Assets/Icons/Entities/museum.svg'
import MusicEventIcon from '../Assets/Icons/Entities/music-event.svg'
import NatureTrailIcon from '../Assets/Icons/Entities/nature-trail.svg'
import OutdoorGymIcon from '../Assets/Icons/Entities/outdoor-gym.svg'
import PadelCourtIcon from '../Assets/Icons/Entities/padel-court.svg'
import ParkingZoneIcon from '../Assets/Icons/Entities/parking-zone.svg'
import PlaygroundIcon from '../Assets/Icons/Entities/playground.svg'
import SaunaIcon from '../Assets/Icons/Entities/sauna.svg'
import SkateparkIcon from '../Assets/Icons/Entities/skatepark.svg'
import SkiTrailIcon from '../Assets/Icons/Entities/ski-trail.svg'
import SportFieldIcon from '../Assets/Icons/Entities/sport-field.svg'
import SportEventIcon from '../Assets/Icons/Entities/sport-event.svg'
import StationIcon from '../Assets/Icons/Entities/station.svg'
import SwimmingHallIcon from '../Assets/Icons/Entities/swimming-hall.svg'
import TennisCourtIcon from '../Assets/Icons/Entities/tennis-court.svg'
import ThemeEventIcon from '../Assets/Icons/Entities/theme-event.svg'
import UniversityIcon from '../Assets/Icons/Entities/university.svg'
import WorkoutStairsIcon from '../Assets/Icons/Entities/workout-stairs.svg'

// Subentity category icons
import AreaIcon from '../Assets/Icons/Subentities/area.svg'
import GeofenceIcon from '../Assets/Icons/Subentities/geofence.svg'
import PointIcon from '../Assets/Icons/Subentities/point.svg'
import RouteIcon from '../Assets/Icons/Subentities/route.svg'

// Subentity icons
import BusStopIcon from '../Assets/Icons/Subentities/bus-stop.svg'
import CafeIcon from '../Assets/Icons/Subentities/cafe.svg'
import ChargingStationIcon from '../Assets/Icons/Subentities/charging-station.svg'
import CloakroomOrStorageIcon from '../Assets/Icons/Subentities/cloakroom-storage.svg'
import DogParkIcon from '../Assets/Icons/Subentities/dog-park.svg'
import EntranceIcon from '../Assets/Icons/Subentities/entrance.svg'
import FairwayIcon from '../Assets/Icons/Subentities/fairway.svg'
import CampfireIcon from '../Assets/Icons/Subentities/campfire.svg'
import EventPlaceIcon from '../Assets/Icons/Subentities/event-place.svg'
// import GasStationIcon from '../Assets/Icons/Subentities/gas-station.svg'
import InfoIcon from '../Assets/Icons/Subentities/info.svg'
import KioskIcon from '../Assets/Icons/Subentities/kiosk.svg'
import LifesaverIcon from '../Assets/Icons/Subentities/lifesaver.svg'
import ParkingAreaIcon from '../Assets/Icons/Subentities/parking-lot.svg'
import PierIcon from '../Assets/Icons/Subentities/pier.svg'
import RecycleIcon from '../Assets/Icons/Subentities/recycle.svg'
import RestaurantIcon from '../Assets/Icons/Subentities/restaurant.svg'
import ShowerIcon from '../Assets/Icons/Subentities/shower.svg'
import TaxiIcon from '../Assets/Icons/Subentities/taxi.svg'
import TerraceIcon from '../Assets/Icons/Subentities/terrace.svg'
import TicketSaleIcon from '../Assets/Icons/Subentities/ticket-sale.svg'
import TramStopIcon from '../Assets/Icons/Subentities/tram-stop.svg'
import WarningIcon from '../Assets/Icons/Subentities/warning.svg'
import WaterPointIcon from '../Assets/Icons/Subentities/water-point.svg'
import WCIcon from '../Assets/Icons/Subentities/wc.svg'
import LeanToIcon from '../Assets/Icons/Subentities/lean-to.svg'
import CampsiteIcon from '../Assets/Icons/Subentities/campsite.svg'
import VantagePointIcon from '../Assets/Icons/Subentities/vantage-point.svg'
import RelicIcon from '../Assets/Icons/Subentities/relic.svg'
import GuidePostIcon from '../Assets/Icons/Subentities/guide-post.svg'

// Map theme previews
import MapThemePreview1 from '../Assets/Images/map-theme-preview-1.png'
import MapThemePreview2 from '../Assets/Images/map-theme-preview-2.png'
import MapThemePreview3 from '../Assets/Images/map-theme-preview-3.png'

export enum Role {
  SuperAdmin = 'superadmin',
  Boss = 'boss',
  Employee = 'employee',
  EntityAdmin = 'entity_admin'
}

// Note: Super admin is not included here. API will not accept to change user role to superadmin
export const ROLES = ['boss', 'entity_admin', 'employee']

export const LANG_OPTIONS = [
  { label: 'FI', value: 'fi' },
  { label: 'EN', value: 'en' }
]

export enum ValueLimits {
  PasswordMinLength = 8
}

export const AvailableRoutes = (role: string, types: any[]) => {
  if (!role) return []
  if (role === Role.SuperAdmin) {
    return [
      { url: '/organizations', icon: UsersIcon, text: 'organizations' },
      { url: '/admin/main-entity-types', icon: StarIcon, text: 'main_entity_types' },
      { url: '/admin/sub-entity-types', icon: StarIcon, text: 'sub_entity_types' },
      { url: '/settings', icon: SettingsIcon, text: 'settings' }
    ]
  }

  // Check that user has access to at least one event entity type
  const eventsEnabled = types?.length &&
    types
      .map((type) => type?.type)
      .some((type) => MainEntityCategoryTypeMappings.event.includes(type))

  if (role === Role.Boss) {
    return [
      { url: '/dashboard', icon: DashboardIcon, text: 'dashboard' },
      { url: '/entity-types', icon: EntitiesIcon, text: 'entities' },
      eventsEnabled ? { url: '/events', icon: StarOutlinedIcon, text: 'events' } : null,
      { url: '/feedbacks', icon: FeedbackIcon, text: 'feedbacks' },
      { url: '/polls', icon: PollIcon, text: 'polls' },
      { url: '/users', icon: UsersIcon, text: 'users' },
      // { url: '/analytics', icon: AnalyticsIcon, text: 'analytics' },
      { url: '/settings', icon: SettingsIcon, text: 'settings' },
      { url: '/wiki', icon: WikiIcon, text: 'use_guides' }
    ].filter((item) => item)
  }

  if (role === Role.EntityAdmin) {
    return [
      { url: '/dashboard', icon: DashboardIcon, text: 'dashboard' },
      { url: '/entity-types', icon: EntitiesIcon, text: 'entities' },
      eventsEnabled ? { url: '/events', icon: StarOutlinedIcon, text: 'events' } : null,
      { url: '/feedbacks', icon: FeedbackIcon, text: 'feedbacks' },
      // { url: '/analytics', icon: AnalyticsIcon, text: 'analytics' },
      { url: '/settings', icon: SettingsIcon, text: 'settings' },
      { url: '/wiki', icon: WikiIcon, text: 'use_guides' }
    ]
  }
  return [
    { url: '/dashboard', icon: DashboardIcon, text: 'dashboard' },
    { url: '/entity-types', icon: EntitiesIcon, text: 'entities' },
    { url: '/feedbacks', icon: FeedbackIcon, text: 'feedbacks' },
    // { url: '/analytics', icon: AnalyticsIcon, text: 'analytics' },
    { url: '/settings', icon: SettingsIcon, text: 'settings' }
  ]
}

export const EmployeeCountOptions = [
  { id: 1, label: i18n.t('unknown'), value: 'unknown' },
  { id: 2, label: '0-4', value: '0-4' },
  { id: 3, label: '5-9', value: '5-9' },
  { id: 4, label: '10-19', value: '10-19' },
  { id: 5, label: '20-49', value: '20-49' },
  { id: 6, label: '50-99', value: '50-99' },
  { id: 7, label: '100-249', value: '100-249' },
  { id: 8, label: '250-499', value: '250-499' },
  { id: 9, label: '500-999', value: '500-999' },
  { id: 10, label: '1000-', value: '1000-' }
]

export const IndustryOptions = [
  { id: 1, label: i18n.t('agriculture_forestry_fishing'), value: i18n.t('agriculture_forestry_fishing') },
  { id: 2, label: i18n.t('mining'), value: i18n.t('mining') },
  { id: 3, label: i18n.t('manufacturing'), value: i18n.t('manufacturing') },
  { id: 4, label: i18n.t('construction'), value: i18n.t('construction') },
  { id: 5, label: i18n.t('retail_sales'), value: i18n.t('retail_sales') },
  { id: 6, label: i18n.t('transportation'), value: i18n.t('transportation') },
  { id: 7, label: i18n.t('accommodation_food'), value: i18n.t('accommodation_food') },
  { id: 8, label: i18n.t('information_communication'), value: i18n.t('information_communication') },
  { id: 9, label: i18n.t('financial_insurance'), value: i18n.t('financial_insurance') },
  { id: 10, label: i18n.t('real_estate'), value: i18n.t('real_estate') },
  { id: 11, label: i18n.t('public_administration'), value: i18n.t('public_administration') },
  { id: 12, label: i18n.t('education'), value: i18n.t('education') },
  { id: 13, label: i18n.t('health_social_services'), value: i18n.t('health_social_services') },
  { id: 14, label: i18n.t('arts_entertainment_recreation'), value: i18n.t('arts_entertainment_recreation') },
  { id: 16, label: i18n.t('other'), value: i18n.t('other') }
]

export const RevenueOptions = [
 { id: 1, label: i18n.t('unknown'), value: 'unknown' },
 { id: 2, label: '0 - 199 999', value: '0 - 199 999' },
 { id: 3, label: '200 000 - 399 999', value: '200 000 - 399 999' },
 { id: 4, label: '400 000 - 999 999', value: '400 000 - 999 999' },
 { id: 5, label: '1 000 000 - 1 999 999', value: '1 000 000 - 1 999 999' },
 { id: 6, label: '2 000 000 - 9 999 999', value: '2 000 000 - 9 999 999' },
 { id: 7, label: '10 000 000 - 19 999 999', value: '10 000 000 - 19 999 999' },
 { id: 8, label: '20 000 000-', value: '20 000 000-' }
]

export const UserSortOptions = [
  { id: 1, label: i18n.t('sort_by_alphabetical_order'), value: 'alphabetical' },
  { id: 2, label: i18n.t('sort_by_created_at'), value: 'created_at' }
]

export const OrganizationSortOptions = [
  { id: 1, label: i18n.t('sort_by_alphabetical_order'), value: 'alphabetical' },
  { id: 2, label: i18n.t('sort_by_created_at'), value: 'created_at' }
]

export const EntitySortOptions = [
  { id: 1, label: i18n.t('sort_by_alphabetical_order'), value: 'alphabetical' },
  { id: 2, label: i18n.t('sort_by_created_at'), value: 'created_at' },
  { id: 3, label: i18n.t('sort_by_snapshotted_at'), value: 'snapshotted_at' }
]

export const EntityTypeSortOptions = [
  { id: 1, label: i18n.t('sort_by_alphabetical_order'), value: 'alphabetical' }
]

export enum EntityMapFilterType {
  AllEntities = 'all_entities',
  OnlySubentities = 'only_subentities'
}

export const EntityMapFilterOptions = [
  { id: 1, label: i18n.t('view_all_entities'), value: EntityMapFilterType.AllEntities },
  { id: 2, label: i18n.t('view_only_subentities'), value: EntityMapFilterType.OnlySubentities }
]

export enum ViewMode {
  List = 'list',
  Grid = 'grid'
}

export const ENTITY_GEO_TYPES = [
  'area',
  'route',
  'point'
]

export const featureTypeOptions = [
  { label: i18n.t('area'), value: 'area' },
  { label: i18n.t('route'), value: 'route' },
  { label: i18n.t('point'), value: 'point' },
  { label: i18n.t('geofence'), value: 'geofence' }
]

export const CUSTOM_FILED_TYPES = [
  'number',
  'text',
  'textarea',
  'editor',
  'boolean',
  'select',
  'date',
  'datetime',
  'time',
  'multiselect',
  'weekday_time_ranges',
  'date_and_time',
  'sport_field_repeater',
  'services_contact_information_repeater',
  'service_open_hours_repeater',
  'link_repeater',
  'faculty_repeater',
  'campus_repeater',
  'service_repeater',
  'program_repeater',
  'event_service_repeater',
  'service_phone_repeater',
  'service_www_repeater'
]

export const EntityTypesWithMaintenanceLog = [
  'ski_trail',
  'beach',
  'baseball_field',
  'sauna',
  'ice_skating',
  'outdoor_gym',
  'tennis',
  'disc_golf',
  'beach_volley',
  'sport_field',
  'nature_trail',
  'playground',
  'football',
  'skatepark',
  'padel',
  'basketball'
]

export const ConditionOptions = [
  { label: '🟢 Hyvässä kunnossa', value: 'good' },
  { label: '🔴 Huonossa kunnossa', value: 'bad' },
  { label: '⚪ Ei tietoa', value: 'unknown' }
]

export const MainEntityTypes = {
  AmusementPark: 'amusement_park',
  BaseballField: 'baseball_field',
  BasketballCourt: 'basketball',
  Beach: 'beach',
  BeachVolleyField: 'beach_volley',
  CultureEvent: 'culture_event',
  DiscGolfCourse: 'disc_golf',
  FootballField: 'football',
  IceSkatingField: 'ice_skating',
  IndoorSportFacility: 'indoor_sport_facility',
  Marina: 'marina',
  Market: 'market',
  MultipurposeField: 'multipurpose_field',
  Museum: 'museum',
  MusicEvent: 'music_event',
  NatureTrail: 'nature_trail',
  OutdoorGym: 'outdoor_gym',
  PadelCourt: 'padel',
  ParkingZone: 'parking_zone',
  Playground: 'playground',
  Sauna: 'sauna',
  Skatepark: 'skatepark',
  SkiTrail: 'ski_trail',
  SportField: 'sport_field',
  SportEvent: 'sport_event',
  Station: 'station',
  SwimmingHall: 'swimming_hall',
  TennisCourt: 'tennis',
  Theater: 'theater',
  ThemeEvent: 'theme_event',
  University: 'university',
  WorkoutStairs: 'workout_stairs'
}

export const MainEntityIcons = {
  'amusement_park': AmusementParkIcon,
  'baseball_field': BaseballFieldIcon,
  'basketball': BasketballCourtIcon,
  'beach': BeachIcon,
  'beach_volley': BeachVolleyFieldIcon,
  'culture_event': CultureEventIcon,
  'disc_golf': DiscGolfCourseIcon,
  'football': FootballFieldIcon,
  'ice_skating': IceSkatingFieldcon,
  'indoor_sport_facility': IndoorSportFacilityIcon,
  'marina': MarinaIcon,
  'market': MarketIcon,
  'multipurpose_field': MultipurposeFieldIcon,
  'museum': MuseumIcon,
  'music_event': MusicEventIcon,
  'nature_trail': NatureTrailIcon,
  'outdoor_gym': OutdoorGymIcon,
  'padel': PadelCourtIcon,
  'parking_zone': ParkingZoneIcon,
  'playground': PlaygroundIcon,
  'sauna': SaunaIcon,
  'skatepark': SkateparkIcon,
  'ski_trail': SkiTrailIcon,
  'sport_field': SportFieldIcon,
  'sport_event': SportEventIcon,
  'station': StationIcon,
  'swimming_hall': SwimmingHallIcon,
  'tennis': TennisCourtIcon,
  'theater': CultureLocationIcon,
  'theme_event': ThemeEventIcon,
  'university': UniversityIcon,
  'workout_stairs': WorkoutStairsIcon
}

export const MainEntityCategories = {
  SportLocation: 'sport_location',
  RecreationalLocation: 'recreational_location',
  ServiceLocation: 'service_location',
  CultureLocation: 'culture_location',
  Event: 'event',
  OtherLocation: 'other_location'
}

export const MainEntityCategoryOptions = [
  { label: i18n.t('all_entity_types'), value: 'all_entity_types' },
  ...Object.values(MainEntityCategories).map((item) => ({ label: i18n.t(item), value: item }))
]

export const MainEntityCategoryIcons = {
  'sport_location': SportLocationIcon,
  'recreational_location': RecreationalocationIcon,
  'service_location': ServiceLocationIcon,
  'culture_location': CultureLocationIcon,
  'event': EventIcon,
  'other_location': OtherLocationIcon
}

export const MainEntityCategoryTypeMappings = {
  'sport_location': [
    MainEntityTypes.BaseballField,
    MainEntityTypes.BasketballCourt,
    MainEntityTypes.BeachVolleyField,
    MainEntityTypes.DiscGolfCourse,
    MainEntityTypes.FootballField,
    MainEntityTypes.IceSkatingField,
    MainEntityTypes.IndoorSportFacility,
    MainEntityTypes.MultipurposeField,
    MainEntityTypes.OutdoorGym,
    MainEntityTypes.PadelCourt,
    MainEntityTypes.Skatepark,
    MainEntityTypes.SkiTrail,
    MainEntityTypes.SportField,
    MainEntityTypes.SwimmingHall,
    MainEntityTypes.TennisCourt,
    MainEntityTypes.WorkoutStairs
  ],
  'recreational_location': [
    MainEntityTypes.AmusementPark,
    MainEntityTypes.Beach,
    MainEntityTypes.NatureTrail,
    MainEntityTypes.Sauna
  ],
  'service_location': [
    MainEntityTypes.Marina,
    MainEntityTypes.Market,
    MainEntityTypes.Playground,
    MainEntityTypes.Station,
    MainEntityTypes.University
  ],
  'culture_location': [
    MainEntityTypes.Museum,
    MainEntityTypes.Theater
  ],
  'event': [
    MainEntityTypes.MusicEvent,
    MainEntityTypes.SportEvent,
    MainEntityTypes.ThemeEvent,
    MainEntityTypes.CultureEvent
  ],
  'other_location': [
    MainEntityTypes.ParkingZone
  ]
}

export const SubentityCategories = {
  Area: 'area',
  Route: 'route',
  Point: 'point',
  Geofence: 'geofence'
}

export const SubentityCategoryIcons = {
  'area': AreaIcon,
  'route': RouteIcon,
  'point': PointIcon,
  'geofence': GeofenceIcon
}

export const SubentityTypes = {
  BasketballCourt: 'basketball',
  Beach: 'beach',
  BusStop: 'bus_stop',
  Cafe: 'coffee',
  Campsite: 'campsite',
  ChargingStation: 'ev_station',
  CloakroomOrStorage: 'cloakroom_or_storage',
  DogPark: 'dog_park',
  Entrance: 'entrance',
  EventPlace: 'event_place',
  Fairway: 'fairway',
  Campfire: 'campfire',
  FootballField: 'football',
  GasStation: 'gas_station',
  GuidePost: 'guide_post',
  Info: 'info',
  Kiosk: 'kiosk',
  LeanTo: 'lean_to',
  Lifesaver: 'lifesaver',
  Other: 'other',
  ParkingArea: 'parking_area',
  Pier: 'pier',
  Recycle: 'recycle',
  Relic: 'relic',
  Restaurant: 'restaurant',
  Shower: 'shower',
  Taxi: 'taxi',
  Terrace: 'terrace',
  TicketSale: 'ticket_sale',
  TramStop: 'tram_stop',
  VantagePoint: 'vantage_point',
  Warning: 'warning',
  WaterPoint: 'water_point',
  WC: 'wc'
}

export const SubentityIcons = {
  'basketball': BasketballCourtIcon,
  'beach': BeachIcon,
  'bus_stop': BusStopIcon,
  'campsite': CampsiteIcon,
  'coffee': CafeIcon,
  'cloakroom_or_storage': CloakroomOrStorageIcon,
  'entrance': EntranceIcon,
  'ev_station': ChargingStationIcon,
  'event_place': EventPlaceIcon,
  'dog_park': DogParkIcon,
  'fairway': FairwayIcon,
  'campfire': CampfireIcon,
  'football': FootballFieldIcon,
  'gas_station': ChargingStationIcon,
  'guide_post': GuidePostIcon,
  'info': InfoIcon,
  'kiosk': KioskIcon,
  'lean_to': LeanToIcon,
  'lifesaver': LifesaverIcon,
  'other': OtherLocationIcon,
  'parking_area': ParkingAreaIcon,
  'pier': PierIcon,
  'recycle': RecycleIcon,
  'relic': RelicIcon,
  'restaurant': RestaurantIcon,
  'shower': ShowerIcon,
  'taxi': TaxiIcon,
  'terrace': TerraceIcon,
  'ticket_sale': TicketSaleIcon,
  'tram_stop': TramStopIcon,
  'vantage_point': VantagePointIcon,
  'warning': WarningIcon,
  'water_point': WaterPointIcon,
  'wc': WCIcon
}

export const MapThemes = [
  {
    key: 'street',
    value: 'mapbox://styles/mapbox/streets-v11',
    image: MapThemePreview1,
    text: i18n.t('street_map_theme')
  },
  {
    key: 'outdoors',
    value: 'mapbox://styles/mapbox/outdoors-v11',
    image: MapThemePreview2,
    text: i18n.t('outdoors_map_theme')
  },
  {
    key: 'dark',
    value: 'mapbox://styles/mapbox/dark-v10',
    image: MapThemePreview3,
    text: i18n.t('dark_map_theme')
  }
]
