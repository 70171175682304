import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import { useTranslation } from 'react-i18next'
import { TableHeader, DataMessage } from '..'
import GpxRecordingTableRow from './GpxRecordingTableRow'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  root: {
  },
  spacer: {
    height: '1rem'
  }
}))

function GpxRecordingTable (props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const renderRows = () => {
    if (props.items) {
      return props.items.map((item) => {
        return (
          <React.Fragment key={item.id}>
            <div className={classes.spacer} />
            <GpxRecordingTableRow
              item={item}
              onReplace={props.onReplace}
              onEdit={props.onEdit}
              onDelete={props.onDelete}
              exportGpxRecording={props.exportGpxRecording}
            />
          </React.Fragment>
        )
      })
    }
    return null
  }

  return (
    <>
      <Table classes={{ root: classes.root }}>
        <TableHeader columnNames={[t('date_short'), t('type'), t('description'), t('actions')]} />
        <TableBody>{renderRows()}</TableBody>
      </Table>
      <DataMessage data={props.items} message={t('no_gpx_recordings')} />
    </>
  )
}

export default GpxRecordingTable
