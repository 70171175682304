import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import get from 'lodash/get'
import orderBy from 'lodash/orderBy'
import makeStyles from '@mui/styles/makeStyles'
import { useStore } from '../../../Models/RootStore'
import Grid from '@mui/material/Grid'
import { PageHeader, DataActions, MainEntityTypeCard, CreateMainEntityModal } from '../../../Components'
import { EntityTypeSortOptions, MainEntityCategoryOptions, MainEntityCategoryTypeMappings, MainEntityTypes } from '../../../Constants'
import { useNavigate } from 'react-router-dom'
import { Colors } from '../../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: '10rem'
  },
  container: {
    padding: '2.5rem'
  },
  cardsContainer: {
    marginTop: '1rem'
  },
  noResultsText: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    color: Colors.black70,
    fontSize: '1.25rem',
    fontFamily: 'Roboto',
    fontWeight: 700
  }
}))

function EntityTypes() {
  const classes = useStyles()
  const navigate = useNavigate()

  const { t } = useTranslation()

  const { entityStore, sessionStore }: any = useStore()
  useEffect(() => {
    entityStore.getMainEntities()
    entityStore.getMainEntityTypes()
  }, [])

  const [searchValue, setSearchValue] = useState('')
  const [typeValue, setTypeValue] = useState('all_entity_types')
  const [sortValue, setSortValue] = useState(EntityTypeSortOptions[0].value)
  const [createOpen, setCreateOpen] = useState(false)

  const toggleCreateOpen = () => setCreateOpen(!createOpen)
  const createEntity = (fields: any) => {
    const body = {
      name: fields?.nameFi,
      nameFi: fields?.nameFi,
      nameEn: fields?.nameEn,
      mainEntityTypeId: fields?.type,
      // TODO: fix later
      areaMargin: 0
    }
    const callback = (entityId) => navigate(`/entity/${entityId}?mode=edit`)
    entityStore.createMainEntity(body, callback)
  }
  const toEntityList = (mainEntityType: any) => navigate(`/entities?type=${mainEntityType}`)

  const getMainEntityTypes = () => {
    const mainEntityTypes: any = []
    const counts = {}

    for (const mainEntity of entityStore.mainEntities || []) {
      const isVisible = !!mainEntity.active
      if (isVisible) {
        if (mainEntity.mainEntityType) {
          // Update counts
          counts[mainEntity.mainEntityType.id] = (counts[mainEntity.mainEntityType.id] || 0) + 1
          if (counts[mainEntity.mainEntityType.id] === 1) {
            // Add only first time
            mainEntityTypes.push(mainEntity.mainEntityType)
          }
        }
      }
    }

    return mainEntityTypes.map((mainEntityType: any) => ({
      ...mainEntityType,
      count: counts[mainEntityType.id]
    }))
  }

  const renderMainEntityTypes = () => {
    let mainEntityTypes = getMainEntityTypes()

    // Apply search filters
    if (searchValue) {
      const searchKey = searchValue.trim().toLowerCase()
      mainEntityTypes = mainEntityTypes.filter((item) => {
        return item?.name.toLowerCase().includes(searchKey)
      })
    }

    if (typeValue && typeValue !== 'all_entity_types') {
      const allowedTypes = MainEntityCategoryTypeMappings[typeValue]
      mainEntityTypes = mainEntityTypes.filter((item) => {
        return allowedTypes.includes(item?.type)
      })
    }

    // Apply sorting
    if (sortValue === 'alphabetical') {
      mainEntityTypes = orderBy(mainEntityTypes, 'name', 'asc')
    }

    return mainEntityTypes.map((item: any) => (
      <MainEntityTypeCard
        key={item.id}
        item={item}
        onClick={() => toEntityList(item.type)}
      />
    ))
  }

  const renderNoSearchResults = () => {
    if (searchValue) {
      const mainEntityTypes = getMainEntityTypes().filter(item => item.name.toLowerCase().includes(searchValue.trim().toLowerCase()))
      if (!mainEntityTypes.length) {
        return <p className={classes.noResultsText}>{t('no_search_results')}</p>
      }
    }
    return null
  }

  const renderCreateEntityModal = () => {
    if (createOpen) {
      return (
        <CreateMainEntityModal
          open={createOpen}
          handleCreate={createEntity}
          handleClose={toggleCreateOpen}
          allMainEntityTypes={Object.values(MainEntityTypes)}
          myMainEntityTypes={entityStore.mainEntityTypes}
        />
      )
    }
    return null
  }

  return (
    <div className={classes.root}>
      <PageHeader
        title={t('main_entity_types')}
        onCreateClick={toggleCreateOpen}
        createButtonDisabled={!['admin', 'boss', 'entity_admin'].includes(get(sessionStore.user, 'role'))}
        compact
      />
      <div className={classes.container}>
        <DataActions
          searchPlaceholder={t('search_by_name')}
          searchValue={searchValue}
          onSearchChange={setSearchValue}
          typeOptions={MainEntityCategoryOptions}
          typeValue={typeValue}
          onTypeChange={setTypeValue}
          sortOptions={EntityTypeSortOptions}
          sortValue={sortValue}
          onSortChange={setSortValue}
        />
        <div className={classes.cardsContainer}>
          <Grid
            direction='row'
            justifyContent='flex-start'
            alignItems='center'
            spacing={7}
            container
          >
            {renderMainEntityTypes()}
          </Grid>
          {renderNoSearchResults()}
        </div>
        {renderCreateEntityModal()}
      </div>
    </div>
  )
}

export default observer(EntityTypes)
