import get from 'lodash/get'

export const generateEntityCode = (entityName) => {
  let value = (entityName || '').toLowerCase()
  value = value.replace(/ä/g, 'a')
  value = value.replace(/ö/g, 'o')
  value = value.replace(/å/g, 'a')
  value = value.replace(/ /g, '-')
  value = value.replace(/\./g, '')
  value = value.replace(/,/g, '')
  value = value.replace(/\(/g, '')
  value = value.replace(/\)/g, '')
  return value.toUpperCase().replace(/\s/g, '')
}

export const getMissingLangFieldCounts = (entity) => {
  const missingCount = {
    fi: 0,
    en: 0
  }

  const {
    nameFi,
    nameEn,
    descriptionFi,
    descriptionEn,
    fields
  } = entity

  // Check name
  if (!!nameFi !== !!nameEn) {
    missingCount.fi += nameFi ? 0 : 1
    missingCount.en += nameEn ? 0 : 1
  }

  // Check description
  if (!!descriptionFi !== !!descriptionEn) {
    missingCount.fi += descriptionFi ? 0 : 1
    missingCount.en += descriptionEn ? 0 : 1
  }

  const customFields = fields || []

  for (const field of customFields) {
    if (field && field.value) {
      if (typeof field.value === 'object') {
        const valueKeys = Object.keys(field.value)
        if (valueKeys.includes('fi') || valueKeys.includes('en')) {
          const valFi = get(field.value, 'fi')
          const valEn = get(field.value, 'en')
          if (valFi !== valEn && (!valFi || !valEn)) {
            missingCount.fi += valFi ? 0 : 1
            missingCount.en += valEn ? 0 : 1
          }
        }
      }
    }
  }

  return missingCount
}
