import React, { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { toJS } from 'mobx'
import get from 'lodash/get'
import qs from 'qs'
import moment from 'moment'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { useStore } from '../../../Models/RootStore'
import {
  PageHeader,
  SubEntityTable,
  CreateSubentityModal,
  MapEditor,
  EditSubEntityForm,
  EditEntityForm,
  Button,
  Dialog,
  Input
} from '../../../Components'
import {
  convertFeatureType,
  GEOMETRY_TYPES,
  getFeatureCenterCoords,
  getFeatureZoomLevel,
  getInitialRegionForEntityCreation,
  isSameFeature,
  sortMapFeaturesByType
} from '../../../Utils/map'
import { formatDateTime, isBefore } from '../../../Utils/dateTime'
import { Colors } from '../../../Utils/theme'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    overflow: 'hidden',
    position: 'relative'
  },
  column: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    width: '100%'
  },
  columnFull: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    width: '100%'
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflowY: 'hidden',
    overflowX: 'hidden'
  },
  tableContainer: {
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: '1rem 1.5rem 2rem'
  },
  mapContainer: {
    flex: 1,
    height: '100vh',
    backgroundColor: Colors.black20,
    borderLeft: `1px solid ${Colors.black20}`
  },
  fullScreenMapContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 9
  },
  mapContainerHidden: {
    display: 'hidden',
    top: 0,
    left: 0,
    height: 0,
    width: 0,
    overflowX: 'hidden',
    overflowY: 'hidden',
    position: 'absolute'
  },
  flexSpacer: {
    display: 'flex',
    flex: 1,
    minHeight: '4rem',
  },
  spacer: {
    flex: 1
  },
  footerContainer: {
    height: '5.5rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0rem 1.5rem',
    borderTop: `1px solid ${Colors.black20}`
  },
  publishContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  lastPublishedAt: {
    margin: 0,
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '1rem',
    color: Colors.black,
    marginBottom: '0.125rem'
  },
  publishHistoryLink: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '1rem',
    color: Colors.violet,
    cursor: 'pointer',
    textDecoration: 'underline'
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row'
  },
  cancelButton: {
    width: '8rem',
    height: '3.125rem',
    border: 'none',
    margin: '0rem 0.75rem',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: Colors.violet10
    }
  },
  publishButton: {
    width: '13rem',
    height: '3.125rem'
  },
  buttonSpacer: {
    width: '1rem'
  },
  releaseNoteContainer: {
    paddingTop: '1.5rem'
  }
}))

function Entity() {
  const theme = useTheme()
  const mdMatches = useMediaQuery(theme.breakpoints.down('lg'))
  const classes = useStyles()

  const { t } = useTranslation()
  const navigate = useNavigate()

  const { entityStore, mainEntityNotificationStore, appStore, sessionStore }: any = useStore()
  const { user } = sessionStore
  const { entityId }: any = useParams()

  const [features, setFeatures] = useState([])
  const [initialCoords, setInitialCoords]: any = useState({})
  const [focusEvent, setFocusEvent]: any = useState(null)
  const [mapVisible, setMapVisible] = useState(true)
  const [fullScreenMap, setFullScreenMap] = useState(false)
  const [lastDeletedAt, setLastDeletedAt] = useState(0)
  const [editMainEntityId, setEditMainEntityId] = useState(null)
  const [addMainEntityId, setAddMainEntityId] = useState(null)
  const [mapUpdatedAt, setMapUpdatedAt] = useState(0)
  // Sub entity active for editing
  const [editSubEntityId, setEditSubEntityId] = useState(null)
  // Sub entity active for drawing
  const [addSubEntityId, setAddSubEntityId] = useState(null)
  // Create sub entity
  const [createVisible, setCreateVisible] = useState(false)
  const [releaseNote, setReleaseNote] = useState('')
  const [confirmCreateSnapshotVisible, setConfirmCreateSnapshotVisible] = useState(false)
  const [highlightedEntity, setHighlightedEntity] = useState(null)
  const [showNoLocationInfo, setShowNoLocationInfo] = useState(true)

  const showFullScreenMap = () => {
    setMapVisible(true)
    setFullScreenMap(true)
  }
  const hideMap = () => setMapVisible(false)
  const showSplitScreen = () => {
    setMapVisible(true)
    setFullScreenMap(false)
  }

  const handleSetEditMainEntityId = (id) => {
    setHighlightedEntity(null)
    setAddMainEntityId(null)
    setAddSubEntityId(null)
    setEditSubEntityId(null)
    setEditMainEntityId(id)
  }

  const handleSetEditSubEntityId = (id) => {
    setHighlightedEntity(null)
    setEditMainEntityId(null)
    setAddMainEntityId(null)
    setAddSubEntityId(null)
    setEditSubEntityId(id)
  }

  const handleSetAddMainEntityId = (id) => {
    setHighlightedEntity(null)
    setEditMainEntityId(null)
    setEditSubEntityId(null)
    setAddSubEntityId(null)
    setAddMainEntityId(id)
  }

  const handleSetAddSubEntityId = (id) => {
    setHighlightedEntity(null)
    setEditMainEntityId(null)
    setEditSubEntityId(null)
    setAddMainEntityId(null)
    setAddSubEntityId(id)
  }

  const hideNoLocationInfo = () => {
    setShowNoLocationInfo(false)
    setAddMainEntityId(entityId)
  }

  const isUpdated = () => {
    const snapshotUpdatedAt = get(entityStore, 'snapshot.updatedAt')
    if (!snapshotUpdatedAt) return true

    const mainEntityUpdatedAt = get(entityStore, 'mainEntity.updatedAt')
    if (isBefore(snapshotUpdatedAt, mainEntityUpdatedAt)) {
      return true
    }
    const subEntities = get(entityStore, 'mainEntity.subEntities', [])
    for (const sub of subEntities) {
      const subUpdatedAt = get(sub, 'updatedAt')
      if (isBefore(snapshotUpdatedAt, subUpdatedAt)) {
        return true
      }
    }

    if (moment(snapshotUpdatedAt).isBefore(lastDeletedAt)) {
      return true
    }

    return false
  }

  useEffect(() => {
    entityStore.getMainEntityTypes()
    entityStore.getSubEntityTypes()

    if (entityId) {
      entityStore.getMainEntity(entityId)
      entityStore.getLatestSnapshot(entityId)
      entityStore.getGpxRecordings(entityId)
    }

    const qsMode = qs.parse(window.location.search, { ignoreQueryPrefix: true })?.mode ?? ''
    if (qsMode && qsMode === 'edit') {
      handleSetEditMainEntityId(entityId)
    }
  }, [])

  useEffect(() => {
    if (entityStore.snapshotCreatedAt && entityStore.snapshotCreatedAt > (Date.now() - 500)) {
      // Check entity type
      const type = get(entityStore, 'mainEntity.mainEntityType.type')
      if (type) {
        // Navigate to main entity list after snapshot save
        navigate(`/entities?type=${type}&sort=snapshotted_at`)
      }
    }
  }, [entityStore.snapshotCreatedAt])

  // Create initial focus event
  useEffect(() => {
    if (
      !focusEvent &&
      entityStore.mainEntity &&
      entityStore.mainEntity.id === Number(entityId)
    ) {
      const geo = entityStore.mainEntity?.geo
      if (geo && geo?.geometry?.type) {
        const centerCoords = getFeatureCenterCoords(geo)
        const zoomLevel = getFeatureZoomLevel(geo)
        // Create focus event
        if (centerCoords && centerCoords.latitude && centerCoords.longitude) {
          setFocusEvent({
            viewport: { ...centerCoords, zoom: zoomLevel },
            time: Date.now()
          })
        } else {
          // Fallback to organization center
          setFocusEvent(getInitialRegionForEntityCreation(user?.organizationId))
        }
      } else {
        if (user) {
          setFocusEvent(getInitialRegionForEntityCreation(user?.organizationId))
        }
      }
    }
    // Load main entity types
    if (entityStore.mainEntity && entityStore.mainEntity.mainEntityTypeId) {
      entityStore.getMainEntityTypeSubEntityTypes(entityStore.mainEntity.mainEntityTypeId)
    }
  }, [entityStore.mainEntity])

  useEffect(() => {
    if (entityStore.mainEntity && entityStore.mainEntity.subEntities) {
      // Detect old cache
      if (entityStore.mainEntity.id !== Number(entityId)) {
        return
      }

      const subEntities = toJS(entityStore.mainEntity).subEntities
      // Sync features
      let features = subEntities.map(subEntity => {
        // Strip properties from geo
        const geo = subEntity.geo

        if (!geo) return null

        // Add ids
        geo.properties = {
          mainEntityId: subEntity.mainEntityId,
          subEntityId: subEntity.id,
          subEntityName: subEntity.nameFi,
          subEntityType: subEntity.subEntityType?.nameFi ?? '',
          parentSubEntityId: subEntity.parentSubEntityId
        }
        return geo
      }).filter(Boolean)

      if (!initialCoords) {
        // Set initial coords for map centering
        const mainGeo = get(entityStore, 'mainEntity.geo')
        if (mainGeo && mainGeo.geometry) {
          const { latitude, longitude } = getFeatureCenterCoords(toJS(mainGeo))
          if (latitude && longitude) {
            setInitialCoords({ latitude, longitude })
          }
        }
      }

      const mainEntityGeo = get(entityStore, 'mainEntity.geo')
      if (mainEntityGeo) {
        let mainEntityGeoWithDetails = {
          ...toJS(mainEntityGeo),
          properties: {
            isMainEntity: true,
            id: entityStore.mainEntityId,
            name: get(entityStore, 'mainEntity.name'),
            mainEntityType: get(entityStore, 'mainEntity.mainEntityType.name')
          }
        }

        let mainEntityGeos = [mainEntityGeoWithDetails]
        if (mainEntityGeoWithDetails?.geometry?.type === GEOMETRY_TYPES.MultiLineString) {
          // Flatten multi line string into multiple line strings
          mainEntityGeos = mainEntityGeoWithDetails.geometry.coordinates.map((coordinates) => {
            return {
              ...mainEntityGeoWithDetails,
              geometry: {
                ...mainEntityGeoWithDetails.geometry,
                type: GEOMETRY_TYPES.LineString,
                coordinates
              }
            }
          })
        }

        // Sort features
        features = sortMapFeaturesByType(features)
        features = [...mainEntityGeos, ...features]
      }
      setFeatures(features)
    }
  }, [entityStore.mainEntity])

  const getMapStyle = () => {
    if (mapVisible) {
      if (fullScreenMap) return classes.fullScreenMapContainer
      if (!mdMatches) return classes.mapContainer
    }
    return classes.mapContainerHidden
  }

  const toMainEntityList = () => {
    // Check entity type
    const type = get(entityStore, 'mainEntity.mainEntityType.type')

    if (type) {
      // Navigate to main entity list
      navigate(`/entities?type=${type}`)
    } else {
      navigate('/entity-types')
    }
  }

  const editMainEntity = (fields) => {
    const mainEntityId = entityStore.mainEntity.id
    entityStore.updateMainEntityFields(mainEntityId, fields)
    focusToMainEntity()
  }

  const cancelMainEntityEdit = () => {
    setEditMainEntityId(null)
    setFocusEvent(null)
    setHighlightedEntity(null)
  }

  const editSubEntity = (subEntityId, fields) => {
    entityStore.updateSubEntity(subEntityId, fields)
    setEditSubEntityId(null)
    focusToSubEntity(subEntityId)
  }

  const cancelSubEntityEdit = () => {
    setEditSubEntityId(null)
    setFocusEvent(null)
    setHighlightedEntity(null)
    // Initialize viewport back to original viewport
    const feature = features.find(item => get(item, 'properties.isMainEntity'))
    if (feature) {
      const coords = getFeatureCenterCoords(feature)
      if (coords.latitude && coords.longitude) {
        const zoomLevel = getFeatureZoomLevel(feature)
        // Create focus event
        setFocusEvent({
          time: Date.now(),
          viewport: {
            latitude: coords.latitude,
            longitude: coords.longitude,
            zoom: zoomLevel
          }
        })
      }
    }
  }

  const deleteSubEntity = (subEntityId: number) => {
    entityStore.deleteSubEntity(subEntityId)
    // Remove feature from map
    const newFeatures = features.filter((item: any) => {
      if (item?.properties?.subEntityId === subEntityId) {
        return false
      }
      return true
    })
    setFeatures(newFeatures)
    setLastDeletedAt(Date.now())
  }

  const handleGpxImportError = () => {
    appStore.setError('failed_to_import_gpx_file')
  }

  const createSubEntity = ({
    nameFi,
    nameEn,
    type,
    parentSubEntityId,
    subEntityTypeId
  }) => {
    entityStore.createSubEntity({
      mainEntityId: entityStore.mainEntity.id,
      parentSubEntityId,
      subEntityTypeId,
      name: nameFi, // TODO:remove
      nameFi,
      nameEn,
      type
    })
    toggleCreateVisible()
  }

  const getEnabledAddModes = () => {
    try {
      if (addSubEntityId) {
        // Find sub entity type
        const subEntities = get(entityStore.mainEntity, 'subEntities', [])
        const currentSubEntity = subEntities.find(item => item.id === addSubEntityId)
        if (currentSubEntity) {

          const currentType = currentSubEntity.type

          if (currentType === 'geofence' || currentType === 'point') {
            return ['point']
          }
          if (currentType === 'route') {
            return ['route']
          }
          if (currentType === 'area') {
            return ['area']
          }
        }
      }
      if (addMainEntityId) {
        // TODO: Filter based on main entity type
        const currentType = entityStore.mainEntityTypes.find(t => {
          return (
            entityStore.mainEntity.mainEntityTypeId &&
            t.id === entityStore.mainEntity.mainEntityTypeId
          )
        })

        if (currentType) {
          if (currentType.geoTypes) {
            return currentType.geoTypes
          } else {
            // No geotypes set -> allow all
            return ['point', 'area', 'route']
          }
        }
        // No type set
        return []
      }
    } catch (err) {
      console.log(err)
    }
    return []
  }

  const toggleCreateVisible = () => setCreateVisible(!createVisible)

  const focusToMainEntity = () => {
    setEditMainEntityId(entityId)
    // Find main entity geo
    const feature = features.find(item => get(item, 'properties.isMainEntity'))
    if (feature) {
      const coords = getFeatureCenterCoords(feature)
      if (coords.latitude && coords.longitude) {
        const zoomLevel = getFeatureZoomLevel(feature)
        // Create focus event
        setFocusEvent({
          time: Date.now(),
          viewport: {
            latitude: coords.latitude,
            longitude: coords.longitude,
            zoom: zoomLevel
          }
        })
      }
    }
  }

  const handleSetFocus = (viewport) => {
    setFocusEvent({
      time: Date.now(),
      viewport // latitude, longitude, zoom
    })
  }

  const focusToSubEntity = (id) => {
    setEditSubEntityId(id)
    // Find sub entity geo
    const feature = features.find(item => {
      if (id && get(item, 'properties.subEntityId') === id) {
       return true
      }
      return false
    })

    if (feature) {
      const coords = getFeatureCenterCoords(feature)
      if (coords.latitude && coords.longitude) {
        const zoomLevel = getFeatureZoomLevel(feature)
        // Create focus event
        setFocusEvent({
          time: Date.now(),
          viewport: {
            latitude: coords.latitude,
            longitude: coords.longitude,
            zoom: zoomLevel
          }
        })
      }
    }
  }

  const items = useMemo(() => {
    if (entityStore?.mainEntity) {
      let items = get(entityStore.mainEntity, 'subEntities', [])

      // Add hasGeo flag
      items = items.map((item: any) => {
        // Try to find geo
        const hasGeo = features.some(f => f.properties.subEntityId === item.id)
        return { ...item, hasGeo }
      })

      // Add main entity to top
      if (entityStore.mainEntity) {
        // Find type name
        let type = null
        const mainEntityType = entityStore.mainEntityTypes.find(({ id }) => {
          return id === entityStore.mainEntity?.mainEntityTypeId
        })
        if (mainEntityType) {
          type = mainEntityType.name
        }
        const hasGeo = features.some((feature: any) => {
          return feature?.properties?.isMainEntity && feature?.geometry?.coordinates?.length
        })
        items = [
          { ...entityStore.mainEntity, isMainEntity: true, hasGeo, type },
          ...items
        ]
      }

      return items
    }
  }, [entityStore.mainEntity, features, editMainEntityId, editSubEntityId])

  const getMainEntityName = () => {
    if (items.length) {
      return items[0].nameFi
    }
    return null
  }

  const getPageTitle = () => {
    if (editSubEntityId) {
      const subEntity = entityStore.mainEntity.subEntities.find(item => item.id === editSubEntityId)
      if (subEntity) {
        return subEntity.nameFi || subEntity.nameEn
      }
    }
    return getMainEntityName()
  }

  const getRootSubEntities = () => {
    const subEntities = get(entityStore, 'mainEntity.subEntities', [])
    return subEntities.filter(item => !item.parentSubEntityId && item.type === 'area')
  }

  const deleteFeature = (featureIndex) => {
    setFeatures(features.filter((_, index) => index !== featureIndex))
  }

  const toggleFeatureType = (featureIndex) => {
    setFeatures(features.map((item, index) => {
      if (index !== featureIndex) return item
      // Apply conversion
      return convertFeatureType(item)
    }))
  }

  const hideConfirms = () => setConfirmCreateSnapshotVisible(false)

  const createSnapshot = () => {
    if (confirmCreateSnapshotVisible) {
      setConfirmCreateSnapshotVisible(false)
      entityStore.createSnapshot(entityStore.mainEntity.id, releaseNote)
      setReleaseNote('')
    } else {
      // Display confirm
      setConfirmCreateSnapshotVisible(true)
    }
  }

  const getAvailableSubEntityTypes = () => {
    return entityStore.subEntityTypes.filter(subEntityType => {
      return !!entityStore.mainEntityTypeSubEntityTypes.find(ms => (
        ms.mainEntityTypeId === get(entityStore, 'mainEntity.mainEntityTypeId') &&
        ms.subEntityTypeId === subEntityType.id
      ))
    })
  }

  const onEditorUpdate = (event) => {
    const data = event.data

    // Detect add feature
    if (event.editType === 'addFeature') {
      // Cannot add
      if ((!addSubEntityId && !addMainEntityId) || (addSubEntityId && addMainEntityId)) {
        return
      }

      if (addSubEntityId) {
        // Add Sub entity id
        data[event.editContext.featureIndexes[0]].properties = {
          mainEntityId: entityStore.mainEntity.id,
          subEntityId: addSubEntityId
        }

        // Stop adding
        handleSetAddSubEntityId(null)
      }
      if (addMainEntityId) {
        data[event.editContext.featureIndexes[0]].properties = {
          isMainEntity: true,
          id: entityStore.mainEntity.id
        }
      }
    }

    setFeatures(data)
  }

  const addFeature = (newFeature, arrayMode = false) => {
    let featuresToAdd: any = []
    if (arrayMode) {
      for (let i = 0; i < newFeature.length; i++) {
        newFeature[i].properties = {
          mainEntityId: entityStore.mainEntity.id,
          subEntityId: addSubEntityId,
          isMainEntity: !addSubEntityId
        }
      }
      featuresToAdd = newFeature
    } else {
      newFeature.properties = {
        mainEntityId: entityStore.mainEntity.id,
        subEntityId: addSubEntityId,
        isMainEntity: !addSubEntityId
      }
      featuresToAdd = [newFeature]
    }
    const combined: any = [...features, ...featuresToAdd]
    setFeatures(combined)
    handleSetAddSubEntityId(null)
  }

  const addOrReplaceFeature = (newFeature) => {
    const newFeatures = [...features.filter(existingFeature => {
      if (newFeature.properties.isMainEntity && existingFeature.properties.isMainEntity) return false

      // Compare sub entities
      return (
        [newFeature.properties.mainEntityId, newFeature.properties.subEntityId].join('_')
        !==
        [existingFeature.properties.mainEntityId, existingFeature.properties.subEntityId].join('_')
      )
    }), newFeature]
    setFeatures([...newFeatures])
    setMapUpdatedAt(Date.now())
  }

  const getFlattedFeatures = () => {
    const allFeatures: any = []
    let mainEntityFeatures: any = features.filter(item => item?.properties?.isMainEntity)

    // Temporary fix
    if (mainEntityFeatures.length === 2 && !mainEntityFeatures?.[0]?.properties?.id) {
      allFeatures.push({
        geometry: mainEntityFeatures?.[1]?.geometry,
        properties: {
          ...mainEntityFeatures?.[0]?.properties,
          ...mainEntityFeatures?.[1]?.properties,
        }
      })
    } else if (mainEntityFeatures.length > 1) {
      const mainEntityFeature = { ...mainEntityFeatures[0] }
      mainEntityFeature.geometry = {
        type: GEOMETRY_TYPES.MultiLineString,
        coordinates: mainEntityFeatures.filter(item => {
          return item.geometry.type === GEOMETRY_TYPES.LineString
        }).map(item => item.geometry.coordinates),
        properties: mainEntityFeatures[0].properties
      }
      allFeatures.push(mainEntityFeature)
    } else {
      // Just one
      if (mainEntityFeatures.length === 1) {
        allFeatures.push(mainEntityFeatures[0])
      }
    }
    let subEntityFeatures: any = features.filter(item => !item.properties.isMainEntity)
    return [...allFeatures, ...subEntityFeatures]
  }

  const saveGeoChanges = () => {
    // Detect changes in main entity feature
    const mainGeoFeature = getFlattedFeatures().find(item => item.properties?.isMainEntity)
    if (true || !isSameFeature(entityStore.mainEntity.geo, mainGeoFeature)) {
      entityStore.updateMainEntityFields(entityStore.mainEntity.id, {
        geo: {
          ...mainGeoFeature,
          // Clear properties
          properties: {}
        }
      })
    }

    // Detect changes to sub entity features
    for (const subEntity of entityStore.mainEntity.subEntities) {
      // Find geo feature
      const geoFeature = features.find(item => item.properties?.subEntityId === subEntity.id)
      if (!isSameFeature(subEntity.geo, geoFeature)) {
        // Update geo feature
        const newGeo = { ...geoFeature }
        newGeo.properties = {}
        entityStore.updateSubEntity(subEntity.id, { geo: newGeo })
      }
    }
  }

  const updateSubEntityOrder = (ids: number[]) => {
    entityStore.updateMainEntityFields(entityStore.mainEntity.id, {
      sortSubEntityIds: ids
    })
  }

  const renderPublishActions = () => {
    const versionDate = get(entityStore, 'snapshot.createdAt')
    return (
      <div className={classes.footerContainer}>
        <div className={classes.publishContainer}>
          <p className={classes.lastPublishedAt}>
            {t('published_at')} {versionDate ? formatDateTime(versionDate) : '-'}
          </p>
          <Link to={`/entity/${entityId}/version`} className={classes.publishHistoryLink}>
            {t('publish_history')}
          </Link>
        </div>
        <div className={classes.spacer} />
        <div className={classes.buttonsContainer}>
          <Button
            text={t('go_back')}
            onClick={toMainEntityList}
            buttonStyle={classes.cancelButton}
            outlined
          />
          <Button
            text={t('publish_new_version')}
            onClick={createSnapshot}
            disabled={!isUpdated()}
            buttonStyle={classes.publishButton}
          />
        </div>
        <Dialog
          open={confirmCreateSnapshotVisible}
          handleClose={hideConfirms}
          handleOk={createSnapshot}
          title={t('publish_entity')}
          description={t('publish_entity_description')}
        >
          <div className={classes.releaseNoteContainer}>
            <Input
              placeholder={t('release_note')}
              value={releaseNote}
              onChange={setReleaseNote}
              multiline
            />
          </div>
        </Dialog>
      </div>
    )
  }

  const renderTable = () => {
    return (
      <>
        <div className={classes.tableContainer}>
          <SubEntityTable
            items={items}
            onMainEntityClick={focusToMainEntity}
            onSubEntityClick={focusToSubEntity}
            addMainEntityId={addMainEntityId}
            addSubEntityId={addSubEntityId}
            editSubEntityId={editSubEntityId}
            setAddMainEntityId={handleSetAddMainEntityId}
            setAddSubEntityId={handleSetAddSubEntityId}
            setEditSubEntityId={focusToSubEntity}
            setEditMainEntityId={handleSetEditMainEntityId}
            setHighlightedEntity={setHighlightedEntity}
            deleteSubEntity={deleteSubEntity}
            updateSubEntityOrder={updateSubEntityOrder}
          />
        </div>
        <div>{renderPublishActions()}</div>
      </>
    )
  }

  const handleCreateMainEntityNotificaiton = (mainEntityNotification) => {
    const notification = {
      ...mainEntityNotification,
      mainEntityId: Number(entityId)
    }
    mainEntityNotificationStore.createMainEntityNotification(notification)
  }

  const renderEditMainEntityForm = () => {
    const mainEntity = entityStore.mainEntity
    return (
      <EditEntityForm
        mainEntity={mainEntity}
        onSave={editMainEntity}
        onCancel={cancelMainEntityEdit}
        mainEntityTypes={entityStore.mainEntityTypes}
        maintenanceLogs={entityStore.maintenanceLogs}
        mainEntityGpxRecordings={entityStore.mainEntityGpxRecordings}
        updateGpxRecording={entityStore.updateGpxRecording}
        deleteGpxRecording={entityStore.deleteGpxRecording}
        exportGpxRecording={entityStore.exportGpxRecording}
        addOrReplaceFeature={addOrReplaceFeature}
        getMaintenanceLogs={() => entityStore.getMainEntityMaintenanceLogs(mainEntity.id)}
        createMaintenanceLog={entityStore.createMaintenanceLog}
        updateMaintenanceLog={entityStore.updateMaintenanceLog}
        deleteMaintenanceLog={entityStore.deleteMaintenanceLog}
        updatePhoto={(data) => entityStore.updateMainEntityPhoto(mainEntity.id, data)}
        removePhoto={() => entityStore.updateMainEntityFields(mainEntity.id, { photo: null })}
        getMainEntityNotifications={() => mainEntityNotificationStore.getMainEntityNotifications(entityId)}
        getMainEntityNotification={mainEntityNotificationStore.getMainEntityNotification}
        createMainEntityNotification={handleCreateMainEntityNotificaiton}
        updateMainEntityNotification={mainEntityNotificationStore.editMainEntityNotification}
        mainEntityNotifications={mainEntityNotificationStore.mainEntityNotifications}
        mainEntityNotification={mainEntityNotificationStore.mainEntityNotification}
        deleteMainEntityNotification={(id) => mainEntityNotificationStore.deleteMainEntityNotification(id)}
        fullWidth={!mapVisible && !mdMatches}
      />
    )
  }

  const renderEditSubEntityForm = () => {
    const subEntity = entityStore.mainEntity.subEntities.find(item => item.id === editSubEntityId)
    return (
      <EditSubEntityForm
        mainEntityGpxRecordings={entityStore.mainEntityGpxRecordings}
        subEntity={subEntity}
        onSave={editSubEntity}
        onCancel={cancelSubEntityEdit}
        rootSubEntities={getRootSubEntities()}
        subEntityTypes={getAvailableSubEntityTypes()}
        updateGpxRecording={entityStore.updateGpxRecording}
        deleteGpxRecording={entityStore.deleteGpxRecording}
        exportGpxRecording={entityStore.exportGpxRecording}
        addOrReplaceFeature={addOrReplaceFeature}
        updatePhoto={(data) => entityStore.updateSubEntityPhoto(subEntity.id, data)}
        removePhoto={() => entityStore.updateSubEntity(subEntity.id, { photo: null })}
        fullWidth={!mapVisible && !mdMatches}
      />
    )
  }

  const renderContent = () => {
    if (editSubEntityId) {
      return renderEditSubEntityForm()
    }
    if (editMainEntityId) {
      return renderEditMainEntityForm()
    }
    return renderTable()
  }

  const renderCreateSubentityModal = () => {
    if (createVisible) {
      return (
        <CreateSubentityModal
          open={createVisible}
          handleCreate={createSubEntity}
          handleClose={toggleCreateVisible}
          subentityTypes={getAvailableSubEntityTypes()}
        />
      )
    }
    return null
  }

  const getHasNoLocation = () => {
    const geo = entityStore?.mainEntity?.geo ?? null
    if (!geo && !addMainEntityId && showNoLocationInfo) {
      return true
    }
    return false
  }

  // Detect loading...
  if (Number(entityId) !== get(entityStore, 'mainEntity.id')) {
    return null
  }

  return (
    <div className={classes.root}>
      <div className={mapVisible ? classes.column : classes.columnFull}>
        <PageHeader
          title={getPageTitle()}
          onCreateClick={!(editMainEntityId || editSubEntityId) && toggleCreateVisible}
          createButtonDisabled={!entityStore?.mainEntity?.geo}
          showSplitScreen={showSplitScreen}
          hideMap={hideMap}
          showFullScreenMap={showFullScreenMap}
          fullScreen={fullScreenMap}
          mapHidden={!mapVisible}
          showMapTools={!mapVisible || mdMatches}
          smallViewport={mdMatches}
          compact
        />
        <div className={classes.listContainer}>
          {renderContent()}
        </div>
      </div>
      <div className={getMapStyle()}>
        <MapEditor
          latitude={initialCoords.latitude}
          longitude={initialCoords.longitude}
          features={features}
          focusEvent={focusEvent}
          setFocus={handleSetFocus}
          onUpdate={onEditorUpdate}
          highlightedEntity={highlightedEntity}
          onSave={saveGeoChanges}
          addFeature={addFeature}
          deleteFeature={deleteFeature}
          toggleFeatureType={toggleFeatureType}
          mode={(addSubEntityId || addMainEntityId) ? 'add' : 'edit'}
          addModes={getEnabledAddModes()}
          showSplitScreen={showSplitScreen}
          hideMap={hideMap}
          showFullScreenMap={showFullScreenMap}
          fullScreen={fullScreenMap}
          mapHidden={!mapVisible}
          onGpxImportError={handleGpxImportError}
          key={get(entityStore, 'mainEntity.id')}
          smallViewport={mdMatches}
          updatedAt={mapUpdatedAt}
          showNoLocationInfo={getHasNoLocation()}
          hideNoLocationInfo={hideNoLocationInfo}
          tooltipEnabled
        />
      </div>
      {renderCreateSubentityModal()}
    </div>
  )
}

export default observer(Entity)
