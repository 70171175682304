import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'
import { useStore } from '../../../Models/RootStore'
import { PageHeader } from '../../../Components'
import AdminTable from '../../../Components/Admin/AdminTable'
import { useNavigate } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
  },
  container: {
    padding: '3rem'
  },
  cardsContainer: {
  },
  row: {
    display: 'flex',
    flexDirection: 'row'
  }
}))

const TABS = {
  subEntityTypes: 0,
  subEntityFields: 1
}

function AdminSubEntityTypes() {
  const classes = useStyles()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const getTabs = () => [
    { value: TABS.subEntityTypes, label: t('sub_entity_types') },
    { value: TABS.subEntityFields, label: t('sub_entity_fields') }
  ]
  const [tab, setTab] = useState(TABS.subEntityTypes)

  const selectTab = (value) => setTab(value)

  const { adminStore }: any = useStore()
  useEffect(() => {
    adminStore.getSubEntityTypes()
    adminStore.getSubEntityFields()
  }, [])

  const toCreate = () => {
    if (tab === TABS.subEntityTypes) navigate('/admin/sub-entity-types/create')
    if (tab === TABS.subEntityFields) navigate('/admin/sub-entity-fields/create')
  }

  const openSubEntityType = (item) => navigate(`/admin/sub-entity-types/${item.id}`)
  const openSubEntityField = (item) => navigate(`/admin/sub-entity-fields/${item.id}`)

  const renderSubEntityTypes = () => (
    <AdminTable
      items={adminStore.subEntityTypes}
      onClick={openSubEntityType}
      fields={[
        { key: 'id', name: 'id' },
        { key: 'type', name: 'type' },
        { key: 'name', name: 'name' },
        { key: 'icon', name: 'icon' }
      ]}
    />
  )

  const renderSubEntityFields = () => (
    <AdminTable
      items={adminStore.subEntityFields}
      onClick={openSubEntityField}
      fields={[
        { key: 'id', name: 'id' },
        { key: 'fieldType', name: 'fieldType' },
        { key: 'fieldKey', name: 'fieldKey' },
        { key: 'labelFi', name: 'labelFi' },
        { key: 'labelEn', name: 'labelEn' }
      ]}
    />
  )

  const renderContent = () => {
    if (tab === TABS.subEntityTypes) return renderSubEntityTypes()
    if (tab === TABS.subEntityFields) return renderSubEntityFields()
  }

  return (
    <div className={classes.root}>
      <PageHeader
        title={t('sub_entity_types')}
        tabs={getTabs()}
        currentTab={tab}
        onTabChange={selectTab}
        onCreateClick={toCreate}
      />
      <div className={classes.container}>
        {renderContent()}
      </div>
    </div>
  )
}

export default observer(AdminSubEntityTypes)
