import {
  SPACER,
  TOTAL_LENGTH_IN_M,
  PAR,
  DIFFICULTY,
  PROPERTIES_TITLE,
  DESCRIPTION,
  FAIRWAY_DESCRIPTION_TITLE
} from '../elements.template'

const LAYOUT = {
  type: 'subentity_fairway',
  layout: [
    [
      // Start left column
      PROPERTIES_TITLE,
      {
        type: 'container',
        className: 'customRowFixed',
        items: [
          TOTAL_LENGTH_IN_M,
          SPACER,
          PAR,
          SPACER,
          { ...DIFFICULTY, style: { flex: 1, maxWidth: '16rem' } }
        ]
      },
      FAIRWAY_DESCRIPTION_TITLE,
      DESCRIPTION
      // End left column
    ],
    [
      // Start of right column
      // End of right column
    ]
  ]
}

export default LAYOUT
