import {
  EXCEPTION_NOTIFICATION,
  CONTACT_INFORMATION_TITLE,
  STREET_ADDRESS,
  SPACER,
  CITY,
  POSTAL_CODE,
  EXCEPTION_NOTIFICATIONS_TITLE,
  BERTHS,
  GUEST_BERTHS,
  SALES_SEASON_START_DATE,
  SALES_SEASON_END_DATE,
  DASH_SPACER,
  EQUIPMENT_TITLE,
  MARINA_EQUIPMENT,
  EQUIPMENT_ADDITIONAL_INFO,
  MARINA_MANAGER,
  PHONE,
  EMAIL,
  SERVICE_HOURS,
  PROPERTIES_TITLE,
  DESCRIPTION_TITLE,
  DESCRIPTION
 } from '../elements.template'

const LAYOUT = {
  type: 'marina',
  layout: [
    [
      // Start left column
      DESCRIPTION_TITLE,
      DESCRIPTION,
      PROPERTIES_TITLE,
      {
        type: 'container',
        className: 'customRowFixed',
        items: [
          BERTHS,
          SPACER,
          GUEST_BERTHS,
          SPACER,
          {
            type: 'container',
            className: 'customColumn',
            style: {},
            items: [
              {
                type: 'container',
                items: [{ type: 'title', name: 'in_use_between' }]
              },
              {
                type: 'container',
                className: 'customInnerRow',
                items: [
                  SALES_SEASON_START_DATE,
                  DASH_SPACER,
                  SALES_SEASON_END_DATE
                ]
              }
            ]
          }
        ]
      },
      EQUIPMENT_TITLE,
      MARINA_EQUIPMENT,
      EQUIPMENT_ADDITIONAL_INFO
      // End left column
    ],
    [
      // Start right column
      EXCEPTION_NOTIFICATIONS_TITLE,
      { ...EXCEPTION_NOTIFICATION, hideLabel: true, style: { paddingTop: '0.375rem' } },
      CONTACT_INFORMATION_TITLE,
      { ...MARINA_MANAGER, style: { flex: 1, maxWidth: '30rem' } },
      {
        type: 'container',
        className: 'customRowFixed',
        items: [
          { ...PHONE, style: { flex: 1, maxWidth: '18rem' } },
          SPACER,
          { ...EMAIL, style: { flex: 1, maxWidth: '30rem' } }
        ]
      },
      SERVICE_HOURS,
      {
        type: 'container',
        className: 'customRowFixed',
        style: { paddingBottom: '10rem' },
        items: [
          STREET_ADDRESS,
          SPACER,
          CITY,
          SPACER,
          POSTAL_CODE
        ]
      }
      // End right column
    ]
  ]
}

export default LAYOUT
