import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import {
  CommandPayloadType,
  LexicalEditor,
  COMMAND_PRIORITY_HIGH,
  $getSelection,
  $isRangeSelection,
  DEPRECATED_$isGridSelection,
  PASTE_COMMAND
} from 'lexical'
import { useEffect } from "react"
import { $insertDataTransferForPlainText } from '@lexical/clipboard'

const PastePlugin = (props) => {
  const [editor] = useLexicalComposerContext()

  const onPasteForRichText = (
    event: CommandPayloadType<typeof PASTE_COMMAND>,
    editor: LexicalEditor
  ): void => {
    event.preventDefault()
    editor.update(
      () => {
        const selection = $getSelection()
        const clipboardData =
          event instanceof InputEvent || event instanceof KeyboardEvent
            ? null
            : event.clipboardData
        if (
          clipboardData != null &&
          ($isRangeSelection(selection) || DEPRECATED_$isGridSelection(selection))
        ) {
          // Original formatted:
          // $insertDataTransferForRichText(clipboardData, selection, editor);
          $insertDataTransferForPlainText(clipboardData, selection)
        }
      },
      {
        tag: 'paste'
      }
    )
  }

  const registerPasteHandler = () => {
    editor.registerCommand(
      PASTE_COMMAND,
      (event) => {
        const selection = $getSelection()
        if (
          $isRangeSelection(selection) ||
          DEPRECATED_$isGridSelection(selection)
        ) {
          onPasteForRichText(event, editor)
          return true
        }
        return false
      },
      COMMAND_PRIORITY_HIGH
    )
  }
  useEffect(() => {
    registerPasteHandler()
  }, [])

  return null
}

export default PastePlugin
