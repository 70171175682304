import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'
import { useStore } from '../../../Models/RootStore'
import { PageHeader, Button, Input } from '../../../Components'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  contentContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 0
  },
  content: {
    padding: '2.5rem'
  }
}))

const AdminCreateSubEntityField = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { adminStore }: any = useStore()

  const [fieldType, setFieldType] = useState(null)
  const [fieldKey, setFieldKey] = useState(null)

  const [labelFi, setLabelFi] = useState(null)
  const [labelEn, setLabelEn] = useState(null)
  const [options, setOptions] = useState(null)

  const handleSave = () => {
    const optionsJson = options && options.trim() ? JSON.parse(options) : null

    adminStore.createSubEntityField(
      {
        fieldType,
        fieldKey,
        labelFi,
        labelEn,
        options: optionsJson
      }
    )
  }

  return (
    <div className={classes.root}>
      <PageHeader
        title={t('create_sub_entity_field')}
      />
      <div className={classes.contentContainer}>
        <div className={classes.content}>
          <Input label='fieldType' value={fieldType} onChange={setFieldType} />
          <Input label='fieldKey' value={fieldKey} onChange={setFieldKey} />
          <Input label='labelFi' value={labelFi} onChange={setLabelFi} />
          <Input label='labelEn' value={labelEn} onChange={setLabelEn} />
          <Input label='options' value={options} onChange={setOptions} multiline />
          <Button
            text={t('save')}
            onClick={handleSave}
          />
        </div>
      </div>
    </div>
  )
}

export default observer(AdminCreateSubEntityField)
