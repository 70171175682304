import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'
import { Link, useParams } from 'react-router-dom'
import { useStore } from '../../../Models/RootStore'
import { PageHeader } from '../../../Components'
import { Colors } from '../../../Utils/theme'
import { formatDateTime } from '../../../Utils/dateTime'
import star from '../../../Assets/Icons/star.svg'
import starEmpty from '../../../Assets/Icons/star-empty.svg'
import { Button } from '../../../Components'

const useStyles = makeStyles((theme) => ({
  root: {
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 0
  },
  content: {
    flexDirection: 'column',
    flexGrow: 1,
    padding: '2.5rem',
    paddingBottom: '2rem',
    overflow: 'auto'
  },
  title: {
    margin: 0,
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: '1rem',
    color: Colors.black,
    marginBottom: '.125rem'
  },
  text: {
    margin: 0,
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: 300,
    color: Colors.black,
    marginBottom: '1.5rem',
    padding: '1rem 0 0',
    borderRadius: '0.3125rem'
  },
  message: {
    margin: 0,
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: 300,
    color: Colors.black,
    marginBottom: '1.5rem',
    padding: '1rem 0 0'
  },
  lineRow: {
    paddingBottom: '1rem'
  },
  ratingRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  starContainer: {
    padding: '.25rem .25rem 1rem'
  },
  red: {
    color: Colors.red,
    fontWeight: 600
  },
  green: {
    color: Colors.green,
    fontWeight: 600
  },
  blue: {
    color: Colors.blue,
    fontWeight: 600
  },
  moderatePublishButton: {
    maxWidth: '9rem',
    marginRight: '1rem',
    marginBottom: '1.25rem',
    backgroundColor: Colors.green
  },
  moderateHideButton: {
    maxWidth: '6rem',
    marginRight: '1rem',
    marginBottom: '1.25rem',
    backgroundColor: Colors.red
  }
}))

const Feedback = () => {
  const classes = useStyles()
  const { feedbackStore }: any = useStore()

  const { feedbackId }: any = useParams()

  const { t } = useTranslation()

  useEffect(() => {
    feedbackStore.getEntityFeedback(feedbackId)
  }, [])

  const handleMakePublic = () => {
    feedbackStore.moderateEntityFeedback(Number(feedbackId), { public: true })
  }

  const handleMakePrivate = () => {
    feedbackStore.moderateEntityFeedback(Number(feedbackId), { public: false })
  }

  const renderMessage = () => {
    const { message } = feedbackStore.entityFeedback
    return (
      <div>
        {message.split('\n').map(line => {
          return (
            <div className={classes.lineRow}>
              {line}
            </div>
          )
        })}
      </div>
    )
  }

  const renderStars = () => {
    const { rating } = feedbackStore.entityFeedback
    if ([0, 1, 2, 3, 4, 5].includes(rating)) {
      return (
        <>
          <div className={classes.title}>{t('rating')}</div>
          <div className={classes.ratingRow}>
            {Array(5).fill(null).map((x, index) => {
              return (
                <div key={index} className={classes.starContainer}>
                  <img
                    src={(index < rating) ? star : starEmpty}
                    style={{ opacity: (index < rating) ? 1 : 0.5 }}
                  />
                </div>
              )
            })}
          </div>
        </>
      )

    }
    return null
  }

  const renderStatus = () => {
    const { moderatedBy } = feedbackStore.entityFeedback

    if (moderatedBy) {
      if (feedbackStore.entityFeedback.public) {
        return (
          <div className={classes.green}>
            {t('public')}
          </div>
        )
      }
      return (
        <div className={classes.red}>
          {t('hidden')}
        </div>
      )
    }
    return (
      <div className={classes.blue}>
        {t('waiting_moderation')}
      </div>
    )
  }

  const renderModerationButtons = () => {
    const feedback = feedbackStore.entityFeedback

    return (
      <div className={classes.row}>
        {(!feedback.moderatedBy || !feedback.public) && (
          <Button
            text={t('set_as_public')}
            onClick={handleMakePublic}
            buttonStyle={classes.moderatePublishButton}
            small
          />
        )}
        {(!feedback.moderatedBy || !!feedback.public) && (
          <Button
            text={t('set_as_hidden')}
            onClick={handleMakePrivate}
            buttonStyle={classes.moderateHideButton}
            small
          />
        )}
      </div>
    )
  }

  const renderContent = () => {
    if (!feedbackStore.entityFeedback) return null
    const { mainEntityName, mainEntityId, subEntityName, createdAt, visitedAt } = feedbackStore.entityFeedback
    return (
      <div>
        <div className={classes.title}>{t('status')}</div>
        <div className={classes.text}>
          {renderStatus()}
        </div>
        {renderModerationButtons()}

        {renderStars()}
        <div className={classes.title}>{t('sent_at')}</div>
        <div className={classes.text}>{formatDateTime(createdAt)}</div>
        {!!visitedAt && (
          <>
            <div className={classes.title}>{t('visited_at')}</div>
            <div className={classes.text}>{formatDateTime(visitedAt)}</div>
          </>
        )}
        <div className={classes.title}>{t('entity')}</div>
        <div className={classes.text}>
          <Link to={`/entity/${mainEntityId}`}>
            {mainEntityName}
          </Link>
        </div>
        {!!subEntityName && (
          <>
            <div className={classes.title}>{t('sub_entity')}</div>
            <div className={classes.text}>{subEntityName}</div>
          </>
        )}
        <div className={classes.title}>{t('message')}</div>
        <div className={classes.message}>
          {renderMessage()}
        </div>
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <PageHeader
        title={t('feedback')}
      />
      <div className={classes.contentContainer}>
        <div className={classes.content}>
          {renderContent()}
        </div>
      </div>
    </div>
  )
}

export default observer(Feedback)
