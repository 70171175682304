import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import makeStyles from '@mui/styles/makeStyles'
import Modal from '@mui/material/Modal'
import get from 'lodash/get'
import { Input, Button, Select, LargeImageUpload } from '..'
import { Colors } from '../../Utils/theme'
import { useStore } from '../../Models/RootStore'

const useStyles = makeStyles((theme) => ({
  modal: {
    // zIndex: 99999
  },
  contentContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: Colors.white,
    minWidth: '40rem',
    borderRadius: 3
  },
  content: {
    padding: '2rem 3rem',
    paddingBottom: '1rem'
  },
  title: {
    fontFamily: 'Rubik',
    fontWeight: 600,
    color: Colors.black,
    fontSize: '1.675rem',
    marginBottom: '1.25rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  dateContainer: {
    marginRight: '1.5rem'
  },
  timeContainer: {
  },
  label: {
    fontSize: '1rem',
    fontWeight: 500,
    marginBottom: '0.5rem',
    color: Colors.label
  },
  footerContainer: {
    height: '5.5rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0rem 3rem',
    borderTop: `1px solid ${Colors.black20}`
  },
  saveButton: {
    width: '9rem',
    height: '3.125rem'
  },
  cancelButton: {
    width: '7rem',
    height: '3.125rem',
    border: 'none',
    margin: '0rem 0.75rem',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: Colors.violet10
    }
  },
  langContainer: {
    flex: 1,
    marginTop: '1.325rem'
  },
  spacer: {
    width: '1rem'
  }
}))

export default function ProgramModal(props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const { fileStore } = useStore()

  const { item, handleClose } = props

  const [lang, setLang] = useState('fi')
  const [titleFi, setTitleFi] = useState(get(item, 'titleFi') || null)
  const [titleEn, setTitleEn] = useState(get(item, 'titleEn') || null)
  const [startDate, setStartDate] = useState(get(item, 'startDate') ? moment(item.startDate).format('YYYY-MM-DD') : null)
  const [startTime, setStartTime] = useState(get(item, 'startDate') ? moment(item.startDate).format('HH:mm') : null)
  const [endTime, setEndTime] = useState(get(item, 'endDate') ? moment(item.endDate).format('HH:mm') : null)
  const [photo, setPhoto] = useState(get(item, 'photo') || null)

  const save = () => {
    let endDate = startDate

    // Ceck if end date should be on next day
    const startTimeTestMoment = moment(`2000-01-01 ${startTime}`)
    const endTimeTestMoment = moment(`2000-01-01 ${startTime}`)
    if (endTimeTestMoment.isBefore(startTimeTestMoment)) {
      endDate = moment(startDate).add(1, 'day').format('YYYY-MM-DD')
    }
    const newStartDate = moment(`${startDate} ${startTime}`)
    const newEndDate = moment(`${endDate} ${endTime}`)

    const newItem = {
      photo,
      titleFi,
      titleEn,
      startDate: moment(newStartDate).format(),
      endDate: moment(newEndDate).format()
    }

    props.handleSave(newItem)
  }

  const isSaveDisabled = () => {
    if (!titleFi && !titleEn) return true
    if (!startDate || !startTime || !endTime) return true
    return false
  }

  const handleSetTitle = (value) => {
    if (lang === 'en') {
      setTitleEn(value)
    } else {
      setTitleFi(value)
    }
  }

  const updatePhoto = async (files) => {
    if (files && files.length) {
      const file = files[0]
      const photoUrl = await fileStore.uploadPhoto(file)
      if (photoUrl) {
        setPhoto(photoUrl)
      }
    }
  }

  return (
    <Modal onClose={handleClose} classes={{ root: classes.modal }} open>
      <div className={classes.contentContainer}>
        <div className={classes.content}>
          <div className={classes.title}>{item ? t('edit_program_item') : t('add_program_item')}</div>
          <LargeImageUpload
            photo={photo}
            onFileUpload={updatePhoto}
            removePhoto={() => setPhoto(null)}
            small
          />
          <div>
            <div className={classes.label}>{t('name')}</div>
            <Input value={lang === 'en' ? titleEn : titleFi} onChange={handleSetTitle} />
          </div>
          <div className={classes.row}>
            <div className={classes.dateContainer}>
              <div className={classes.label}>{t('date')}</div>
              <Input value={startDate} onChange={setStartDate} type='date' />
            </div>
            <div className={classes.timeContainer}>
              <div className={classes.label}>{t('start_time')}</div>
              <Input value={startTime} onChange={setStartTime} type='time' />
            </div>
            <div className={classes.spacer} />
            <div className={classes.timeContainer}>
              <div className={classes.label}>{t('end_time')}</div>
              <Input value={endTime} onChange={setEndTime} type='time' />
            </div>
          </div>
        </div>
        <div className={classes.footerContainer}>
          <div className={classes.langContainer}>
            <Select
              options={[
                {
                  label: 'FI',
                  value: 'fi'
                },
                {
                  label: 'EN',
                  value: 'en'
                }
              ]}
              value={lang}
              onChange={setLang}
              style={{
                backgroundColor: Colors.white,
                maxWidth: '4.25725rem'
              }}
            />
          </div>
          <Button
            onClick={handleClose}
            text={t('cancel')}
            buttonStyle={classes.cancelButton}
            outlined
          />
          <Button
            onClick={save}
            text={t('save')}
            disabled={isSaveDisabled()}
            buttonStyle={classes.saveButton}
          />
        </div>
      </div>
    </Modal>
  )
}
