import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'

export default class FeedbackStore {
  rootStore
  entityFeedbacks: any = []
  entityFeedback = null
  loading: any = null

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  updateEntityFeedbacks = (feedbacks) => { this.entityFeedbacks = feedbacks }
  updateEntityFeedback = (feedback) => { this.entityFeedback = feedback }
  updateLoading = (loading) => { this.loading = loading }

  async getEntityFeedbacks() {
    this.updateLoading(true)
    const response = await Api.getEntityFeedbacks()
    this.updateLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, true, false)

    if (response.ok) {
      const entityFeedbacks = response.data
      this.updateEntityFeedbacks(entityFeedbacks)
    }
  }

  async getEntityFeedback(feedbackId) {
    this.updateLoading(true)
    const response = await Api.getEntityFeedback(feedbackId)
    this.updateLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, true, false)

    if (response.ok) {
      const entityFeedback = response.data
      this.updateEntityFeedbacks(this.entityFeedbacks.map(item => {
        if (item.id === entityFeedback.id) return entityFeedback
        return item
      }))
      this.updateEntityFeedback(entityFeedback)
    }
  }

  async moderateEntityFeedback(feedbackId, data) {
    this.updateLoading(true)
    const response = await Api.moderateEntityFeedback(feedbackId, data)
    this.updateLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'feedback_handled_success')
    // Update current feedback
    this.getEntityFeedback(feedbackId)
  }
}
