import React, { useEffect, useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import get from 'lodash/get'
import debounce from 'lodash/debounce'
import moment from 'moment'
import { isArray } from 'lodash'
import { useTranslation } from 'react-i18next'
import linkifyHtml from 'linkify-html'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Grid from '@mui/material/Grid'
import ButtonBase from '@mui/material/ButtonBase'
import {
  Select,
  Checkbox,
  Button,
  Input,
  HtmlInput,
  WeekdayTimeRanges,
  ServiceOpenHoursModal,
  ProgramRepeater,
  EventServiceRepeater,
  ServiceContactInfoRepeater
} from '..'
import { Colors } from '../../Utils/theme'
import AddIcon from '../../Assets/Icons/add-dark.svg'
import InfoIcon from '../../Assets/Icons/alert.svg'

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 600
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  paddedRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: '2rem'
  },
  booleanContainer: {
    paddingBottom: '1rem'
  },
  multiSelectContainerColumn: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '.75rem',
    paddingLeft: '1.5rem'
  },
  multiSelectContainerRow: {
    height: '3.125rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  title: {
    fontSize: '1rem',
    fontWeight: 500,
    paddingBottom: '.5rem',
    color: Colors.label
  },
  checkboxTitle: {
    fontSize: '1rem',
    fontWeight: 500,
    paddingBottom: '0.5rem',
    color: Colors.label
  },
  weekDayTimeRangeLabel: {
    fontSize: '1rem',
    fontWeight: 500,
    paddingBottom: '0.5rem',
    color: Colors.label,
    marginBottom: '-1rem'
  },
  inputTitle: {
    fontSize: '1rem',
    fontWeight: 500,
    marginBottom: '0.5rem',
    color: Colors.label
  },
  separatorText: {
    height: '3.125rem',
    fontSize: '1rem',
    fontWeight: 500,
    color: Colors.label,
    marginTop: '0.375rem',
    paddingTop: '0.875rem',
    paddingLeft: '1.125rem',
    paddingRight: '0.5rem'
  },
  verticalSpacer: {
    height: '.75rem'
  },
  inputContainer: {
  },
  editorContainer: {
  },
  timeRangeButtonsRow: {
    display: 'flex',
    marginBottom: '1.5rem'
  },
  weekdaysButton: {
    maxWidth: '7.5rem',
    height: '3.125rem',
    border: `1px solid ${Colors.violet}`,
    marginRight: '1.5rem',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: Colors.violet10
    }
  },
  weekdaysAndWeekendButton: {
    maxWidth: '6rem',
    height: '3.125rem',
    border: `1px solid ${Colors.violet}`,
    marginRight: '1.5rem',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: Colors.violet10
    }
  },
  dailyButton: {
    maxWidth: '9.5rem',
    height: '3.125rem',
    border: `1px solid ${Colors.violet}`,
    marginRight: '1.5rem',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: Colors.violet10
    }
  },
  timeRangeActiveButton: {
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: Colors.violet10
    }
  },
  timeRangeButtonText: {
    fontSize: '1rem',
    fontWeight: 400
  },
  timeRangesContainer: {
    maxWidth: '48rem',
    padding: '1.5rem 1.5rem 0.5rem 1.5rem',
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.border}`,
    marginBottom: '1.5rem',
    [theme.breakpoints.down('md')]: {
      maxWidth: '24.5rem'
    }
  },
  timeRangesWeekdaysContainer: {
    maxWidth: '26.25rem',
    padding: '1.5rem 1.5rem 0.5rem 1.5rem',
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.border}`,
    marginBottom: '1.5rem'
  },
  timeRangesWeekdaysAndWeekendContainer: {
    maxWidth: '27.75rem',
    padding: '1.5rem 1.5rem 0.5rem 1.5rem',
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.border}`,
    marginBottom: '1.5rem'
  },
  timeRangesTitle: {
    fontSize: '1rem',
    fontWeight: 500,
    marginBottom: '0.5rem',
    color: Colors.label
  },
  dayChunkContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      display: 'block'
    }
  },
  dayChunkBlock: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingRight: '2.5rem',
    [theme.breakpoints.down('md')]: {
      paddingRight: 0
    }
  },
  dayRow: {
    height: '3.125rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '1rem'
  },
  dayName: {
    display: 'flex',
    fontWeight: 700,
    alignItems: 'center',
    fontSize: '1rem',
    width: '1.25rem'
  },
  semiLongDayName: {
    width: '3.375rem'
  },
  longDayName: {
    width: '4.75rem'
  },
  timeInputContainer: {
    maxWidth: '7.5rem'
  },
  daySeparator: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '.75rem',
    marginRight: '.75rem',
    whiteSpace: 'nowrap'
  },
  daySeparatorLine: {
    fontWeight: 700,
    marginBottom: '0.75rem',
    marginRight: '0.5rem'
  },
  toggleWeekdaysButton: {
    marginTop: '.5rem',
    marginLeft: '.75rem',
    height: '2.5rem',
    width: '2.5rem',
    marginBottom: '-.5rem'
  },
  multiSelectContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    paddingBottom: '1.5rem'
  },
  multiSelectItemContainer: {
    height: '3.125rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: '0.75rem'
  },
  checkboxRoot: {
    height: '3.125rem'
  },
  repeaterContainer: {
    paddingBottom: '1.5rem'
  },
  repeaterRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  sportFieldRepeaterRowNumberContainer: {
    width: '5rem',
    marginRight: '1.5rem'
  },
  sportFieldRepeaterRowMaterialContainer: {
    flex: 1
  },
  sportFieldRepeaterRowHasLightsContainer: {
    marginLeft: '1.5rem',
    width: '9rem'
  },
  hasLightsTitle: {
    fontSize: '1rem',
    paddingBottom: '0.5rem',
    fontWeight: 500
  },
  repeaterServiceColumn: {
    flex: 1,
    minWidth: '12rem',
    maxWidth: '18rem',
    marginRight: '2rem'
  },
  repeaterEmailColumn: {
    flex: 1,
    maxWidth: '32rem'
  },
  repeaterPhoneColumn: {
    flex: 1,
    minWidth: '12rem',
    maxWidth: '12rem',
    marginRight: '2rem'
  },
  repeaterWwwColumn: {
    flex: 1,
    maxWidth: '38rem'
  },
  repeaterLinkTextColumn: {
    flex: 1,
    minWidth: '12rem',
    maxWidth: '18rem',
    marginRight: '2rem'
  },
  repeaterLinkColumn: {
    flex: 1,
    minWidth: '21rem'
  },
  repeaterNameColumn: {
    flex: 1,
    minWidth: '14rem',
    maxWidth: '21rem',
    marginRight: '2rem'
  },
  repeaterServiceNameColumn: {
    flex: 1,
    maxWidth: '33rem'
  },
  repeaterButtonContainer: {
    paddingTop: '0.5rem'
  },
  repeaterButton: {
    height: '3.125rem',
    width: '9.5rem',
    border: `1px solid ${Colors.violet}`,
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: Colors.violet10
    }
  },
  repeaterButtonText: {
    color: Colors.violet,
    fontSize: '1rem',
    fontWeight: 400
  },
  tooltip: {
    backgroundColor: Colors.black,
    padding: '0.5rem 0.75rem'
  },
  tooltipArrow: {
    color: Colors.black
  },
  infoContainer: {
    marginLeft: '0.75rem',
    marginBottom: '1.5rem'
  },
  checkboxSpacer: {
    width: '0.75rem'
  },
  serviceOpenHourGrid: {
    marginBottom: '1.5rem'
  },
  serviceOpenHourItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    backgroundColor: Colors.backgroundGrey,
    borderRadius: '0.3125rem',
    padding: '1.75rem',
    paddingBottom: '1.25rem',
    '&:hover': {
      backgroundColor: Colors.violet10
    }
  },
  serviceOpenHourTitle: {
    margin: 0,
    fontSize: '1.125rem',
    fontWeight: 800,
    fontFamily: 'Roboto',
    color: Colors.inputText,
    paddingBottom: '1rem'
  },
  serviceOpenHourText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: 0,
    fontSize: '1.125rem',
    fontWeight: 400,
    color: Colors.inputText,
    paddingBottom: '0.5rem'
  },
  serviceOpenHourDay: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '2.75rem'
  }
}))

const styles = {
  repeaterDivider: {
    borderColor: Colors.black20,
    marginBottom: '1.5rem'
  }
} as const

function CustomField (props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const { field } = props
  const label = field.labelFi

  const [editorInitialized, setEditorInitialized] = useState(false)
  // eslint-disable-next-line
  const [mountedAt, setMountedAt] = useState(Date.now())
  const [editorValueFi, setEditorValueFi] = useState(null)
  const [editorValueEn, setEditorValueEn] = useState(null)
  const [showServiceOpenHoursModal, setShowServiceOpenHoursModal] = useState(false)
  const [serviceOpenHoursItemIndex, setServiceOpenHoursItemIndex] = useState(null)

  const openServiceOpenHoursModal = (index) => {
    setServiceOpenHoursItemIndex(index)
    setShowServiceOpenHoursModal(true)
  }
  const closeServiceOpenHoursModal = () => {
    setShowServiceOpenHoursModal(false)
    setServiceOpenHoursItemIndex(null)
  }

  useEffect(() => {
    if (props.field && props.field.fieldType === 'editor') {
      initializeEditor()
    }
  }, [props.value])

  const initializeEditor = () => {
    // Already initialized
    if (editorInitialized) return

    // Make sure we dont trigger initialize if no value was received short after mount
    if (!editorInitialized && Date.now() -  mountedAt > 2000) {
      setEditorInitialized(true)
      return
    }

    if (props.value) {
      // Populate description
      const newDescriptionFi = get(props.value, 'fi') || null
      if (newDescriptionFi) {
        setEditorValueFi(newDescriptionFi)
      }
      const newDescriptionEn = get(props.value, 'en') || null
      if (newDescriptionEn) {
        setEditorValueEn(newDescriptionEn)
      }
      setEditorInitialized(true)
    } else if (editorValueFi || editorValueEn) {
      setEditorInitialized(true)
    }
  }

  const getEditorValue = () => {
    if (props.lang === 'fi') return editorValueFi
    if (props.lang === 'en') return editorValueEn
  }

  const handleSetEditorValue = (val) => {
    if (get(field, 'customType.unlocalized', false)) {
      // Change values of both languages for unlocalized fields
      setEditorValueFi(val)
      setEditorValueEn(val)
    } else {
      // Change value of current language
      if (props.lang === 'fi') {
        setEditorValueFi(val)
      }
      if (props.lang === 'en') {
        setEditorValueEn(val)
      }
    }
    syncEditorValueDebounced()
  }

  const syncEditorValue = () => {
    const newVal = {
      fi: get(props.value, 'fi', null),
      en: get(props.value, 'en', null)
    }
    if (editorValueFi) {
      const newHtmlFi = linkifyHtml(editorValueFi)
      newVal.fi = newHtmlFi
    }
    if (editorValueEn) {
      const newHtmlEn = linkifyHtml(editorValueEn)
      newVal.en = newHtmlEn
    }

    props.onChange(newVal)
  }
  const syncEditorValueDebounced = debounce(syncEditorValue, 500)


  const getTextValue = () => {
    if (!Object.keys(props.value || {}).includes('fi')) return props.value
    const textVal = get(props.value, `${props.lang}`, null)
    return textVal
  }

  const handleTextChange = (value) => {
    const newVal = Object.keys(props.value || {}).includes('fi') ? { ...props.value } : { fi: props.value || null, en: props.value || null }

    if (get(field, 'customType.unlocalized', false)) {
      // Change values of both languages for unlocalized fields
      newVal.fi = value || null
      newVal.en = value || null
    } else {
      // Change value of current language
      newVal[props.lang] = value || null
    }

    props.onChange(newVal)
  }

  const handleToggleCheckbox = () => {
    props.onChange(!props.value)
  }

  const handleNumberChange = (value) => {
    value = value.trim()

    if (value === '') {
      props.onChange(null)
    }
    else if (value === `${Number(value)}`) {
      props.onChange(Number(value))
    } else {
      // Not a valid number, still typing so use string value
      props.onChange(value)
    }
  }

  const renderInfo = () => {
    if (field.info) {
      return (
        <Tooltip
          classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
          placement='top'
          title={field.info}
          arrow
        >
          <IconButton className={classes.infoContainer}>
            <img src={InfoIcon} />
          </IconButton>
        </Tooltip>
      )
    }
    return null
  }

  // Type: number
  const renderNumberField = () => {
    return (
      <div className={classes.inputContainer}>
        <Input
          label={label}
          value={`${props.value || ''}`}
          onChange={handleNumberChange}
          disabled={props.disabled}
          type='number'
        />
      </div>
    )
  }

  // Type: text
  const renderTextField = () => {
    return (
      <div className={classes.inputContainer}>
        <Input
          label={label}
          value={getTextValue()}
          onChange={handleTextChange}
          disabled={props.disabled}
        />
      </div>
    )
  }

  // Type: textarea
  const renderTextAreaField = () => {
    return (
      <div className={classes.inputContainer}>
        <Input
          label={label}
          value={getTextValue()}
          onChange={handleTextChange}
          disabled={props.disabled}
          multiline
        />
      </div>
    )
  }

  const renderEditor = () => {
    return (
      <div className={classes.editorContainer}>
        {!!label && <div className={classes.inputTitle}>{label}</div>}
        <HtmlInput
          key={props.lang}
          placeholder={t('content')}
          value={getEditorValue()}
          onChange={handleSetEditorValue}
          disabled={props.disabled}
        />
      </div>
    )
  }

  const renderBooleanField = () => {
    let options = ['yes', 'no']

    if (field.positiveOptionLabel) options[0] = field.positiveOptionLabel
    if (field.negativeOptionLabel) options[1] = field.negativeOptionLabel

    return (
      <div className={classes.booleanContainer}>
        {!!label && (<div className={classes.checkboxTitle}>{label}</div>)}
        <div className={classes.multiSelectContainerRow}>
          {options.map((label, index) => {
            return (
              <React.Fragment key={index}>
                <Checkbox
                  checked={!!index !== !!props.value}
                  onChange={handleToggleCheckbox}
                  disabled={props.disabled}
                  label={t(label)}
                />
                {index < options.length - 1 ? <div className={classes.checkboxSpacer} /> : null}
              </React.Fragment>
            )
          })}
        </div>
      </div>
    )
  }

  const renderSelectFieldAsCheckbox = () => {
    const options = (get(field, 'options') || []).map((item, index) => {
      return { id: index, ...item }
    }).map((item: any) => {
      let label = item.labelFi

      // Status color support
      if (item.color === 'green') label = `🟢 ${label}`
      if (item.color === 'red') label = `🔴 ${label}`
      if (item.color === 'yellow') label = `🟡 ${label}`
      if (item.color === 'grey') label = `⚪ ${label}`

      return { ...item, label }
    })

    const toggleOpt = (value) => {
      let newVal = props.value === value ? null : value
      props.onChange(newVal)
    }

    return (
      <div>
        {!!label && (<div className={classes.checkboxTitle}>{label}</div>)}
        <div className={classes.multiSelectContainer}>
          <div className={classes.multiSelectContainerRow}>
            {options.map((opt: any, index) => {
              return (
                <React.Fragment key={index}>
                  <Checkbox
                    checked={props.value === opt.value}
                    onChange={() => toggleOpt(opt.value)}
                    disabled={props.disabled}
                    label={t(opt.label)}
                  />
                  {index < options.length - 1 ? <div className={classes.checkboxSpacer} /> : null}
                </React.Fragment>
              )
            })}
          </div>
        </div>
      </div>
    )
  }

  const renderSelectField = () => {
    const opts = (get(field, 'options') || []).map((item, index) => {
      return { id: index, ...item }
    }).map((item: any) => {
      let label = item.labelFi

      // Status color support
      if (item.color === 'green') label = `🟢 ${label}`
      if (item.color === 'red') label = `🔴 ${label}`
      if (item.color === 'yellow') label = `🟡 ${label}`
      if (item.color === 'grey') label = `⚪ ${label}`

      return { ...item, label }
    })

    return (
      <div>
        {!!label && <div className={classes.inputTitle}>{label}</div>}
        <div className={classes.row}>
          <Select
            options={opts}
            value={props.value}
            onChange={props.onChange}
            disabled={props.disabled}
          />
          {renderInfo()}
        </div>
      </div>
    )
  }

  const renderDateField = () => {
    return (
      <div>
        <Input
          label={label}
          value={props.value}
          onChange={props.onChange}
          disabled={props.disabled}
          type='date'
        />
      </div>
    )
  }

  const renderDateTimeField = () => (
    <Input
      label={label}
      value={props.value}
      onChange={props.onChange}
      disabled={props.disabled}
      type='datetime-local'
    />
  )

  const renderTimeField = () => (
    <Input
      label={label}
      value={props.value}
      onChange={props.onChange}
      disabled={props.disabled}
      type='time'
    />
  )

  const renderQuantityField = () => {
    const unit = field.customType?.unit || ''
    const unitFi = field.customType?.unitFi || ''
    const unitEn = field.customType?.unitEn || ''
    let unitSuffix = ''
    let unitSuffixFi = ''
    let unitSuffixEn = ''

    if (unit) {
      unitSuffix = ` ${unit}`
    } else if (unitFi && unitEn) {
      unitSuffixFi = unitFi
      unitSuffixEn = unitEn
      unitSuffix = props.lang === 'fi' ? unitSuffixFi : unitSuffixEn
    }
    let plainValue = getTextValue() || ''
    if (unitSuffix) {
      plainValue = plainValue.slice(0, -unitSuffix.length)
    }

    const handleQuantityChange = (val) => {
      let newVal = `${val.replace(/\./g, ',').replace(/[^\d,-]/g, '')}`
      let newValEn = newVal.replace(/,/g, '.')

      // Add unit suffix
      if (newVal) {
        if (unitFi && unitEn) {
          newVal = `${newVal}${unitSuffixFi}`
          newValEn = `${newValEn}${unitSuffixEn}`
        } else {
          newVal = `${newVal}${unitSuffix}`
          newValEn = `${newValEn}${unitSuffix}`
        }
      }

      // Change both fi and en values
      props.onChange({ fi: newVal, en: newValEn })
    }

    return (
      <div className={classes.inputContainer}>
        {!!label && <div className={classes.inputTitle}>{label}</div>}
        <div className={classes.row}>
          <Input
            value={plainValue}
            onChange={handleQuantityChange}
            unit={unitSuffix}
            type='quantity'
            disabled={props.disabled}
          />
          {renderInfo()}
        </div>
      </div>
    )
  }

  const renderMultiSelect = () => {
    const opts = get(field, 'options') || []
    const selecteds = (props.value && isArray(props.value)) ? props.value : []

    const toggleOpt = (value) => {
      let newSelecteds = selecteds.filter(s => s !== value)
      if (newSelecteds.length === selecteds.length) {
        newSelecteds.push(value)
      }

      // Filter old deprecated values
      const availableValues = opts.map(opt => opt.value)
      newSelecteds = newSelecteds.filter(val => availableValues.includes(val))

      props.onChange(newSelecteds)
    }

    return (
      <div>
        {!!label && (<div className={classes.checkboxTitle}>{label}</div>)}
        <div className={classes.multiSelectContainer}>
          {opts.map((opt: any) => {
            return (
              <div key={opt.id} className={classes.multiSelectItemContainer}>
                <Checkbox
                  key={opt.id}
                  checked={selecteds.includes(opt.value)}
                  onChange={() => toggleOpt(opt.value)}
                  label={opt.label || opt.labelFi}
                  classes={{ root: classes.checkboxRoot }}
                  disabled={props.disabled}
                />
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  const renderWeekdayTimeRanges = () => (
    <>
      {!!label && (<div className={classes.weekDayTimeRangeLabel}>{label}</div>)}
      <WeekdayTimeRanges
        value={props.value}
        onChange={props.onChange}
        disabled={props.disabled}
      />
    </>
  )

  const renderDateAndTime = () => {
    const date = (props?.value) ? moment(props.value).format('YYYY-MM-DD') : null
    const time = (props?.value) ? moment(props.value).format('HH:mm') : null

    const setDate = (val) => {
      let newVal: any = moment(`${val} ${time ?? moment().format('HH:mm')}`).format()
      if (!moment(newVal).isValid()) newVal = null
      props.onChange(newVal)
    }

    const setTime = (val) => {
      let newVal: any = moment(`${date ?? moment().format('YYYY-MM-DD')} ${val}`).format()
      if (!moment(newVal).isValid()) newVal = null
      props.onChange(newVal)
    }
    return (
      <div className={classes.row}>
        <div>
          <Input label={label} value={date} onChange={setDate} type='date' />
        </div>
        <p className={classes.separatorText}>{t('at')}</p>
        <div className={classes.paddedRow}>
          <Input value={time} onChange={setTime} type='time' />
        </div>
      </div>
    )
  }

  const renderSportFieldRepeater = () => {
    const langSuffix = props.lang === 'fi' ? 'Fi' : 'En'
    const getNumber = (row) => get(props.value, `[${row}].number`, null)
    const getMaterial = (row) => get(props.value, `[${row}].material${langSuffix}` )
    const getHasLights = (row) => !!get(props.value, `[${row}].hasLights`)

    const currentValue = get(props, 'value') || []

    const setNumber = (index, value) => {
      props.onChange(currentValue.map((row, i) => {
        if (i === index) return { ...row, number: value }
        return row
      }))
    }


    const setMaterial = (index, value) => {
      props.onChange(currentValue.map((row, i) => {
        if (i === index) {
          if (props.lang === 'fi') return { ...row, materialFi: value }
          if (props.lang === 'en') return { ...row, materialEn: value }
        }
        return row
      }))
    }

    const toggleHasLights = (index, option) => {
      props.onChange(currentValue.map((row, i) => {
        if (i === index) return { ...row, hasLights: option === 'yes' ? true : false }
        return row
      }))
    }

    const handleAddField = () => props.onChange([
      ...currentValue,
      {
        number: `${currentValue.length + 1}`,
        materialFi: '',
        materialEn: '',
        hasLights: false
      }
    ])

    return (
      <div className={classes.repeaterContainer}>
        {currentValue.map((item, index) => {
          return (
            <div key={index} className={classes.repeaterRow}>
              <div className={classes.sportFieldRepeaterRowNumberContainer}>
                <Input
                  label={t('field_number')}
                  value={getNumber(index)}
                  onChange={(value) => setNumber(index, value)}
                  disabled={props.disabled}
                />
              </div>
              <div className={classes.sportFieldRepeaterRowMaterialContainer}>
                <Input
                  label={t('surface_material')}
                  value={getMaterial(index)}
                  onChange={(value) => setMaterial(index, value)}
                  disabled={props.disabled}
                />
              </div>
              <div className={classes.sportFieldRepeaterRowHasLightsContainer}>
                <Select
                  label={t('lights')}
                  options={[{ label: t('yes'), value: 'yes' }, { label: t('no'), value: 'no' }]}
                  value={getHasLights(index) === false ? 'no' : 'yes'}
                  onChange={(option) => toggleHasLights(index, option)}
                  disabled={props.disabled}
                />
              </div>
            </div>
          )
        })}
        <div className={classes.repeaterButtonContainer}>
          <Button
            onClick={handleAddField}
            text={t('add_field')}
            buttonStyle={classes.repeaterButton}
            buttonTextStyle={classes.repeaterButtonText}
            rightIcon={AddIcon}
            disabled={props.disabled}
            outlined
          />
        </div>
      </div>
    )
  }

  const renderServicesContactInformationRepeater = () => {
    const langSuffix = props.lang === 'fi' ? 'Fi' : 'En'
    const getService = (row) => get(props.value, `[${row}].service${langSuffix}`)
    const getEmail = (row) => get(props.value, `[${row}].email`, null)
    const getPhone = (row) => get(props.value, `[${row}].phone`, null)
    const getWWW = (row) => get(props.value, `[${row}].www`, null)
    const getServiceHours = (row) => get(props.value, `[${row}].serviceHours${langSuffix}`)

    const currentValue = get(props, 'value') || []

    const setService = (index, value) => {
      props.onChange(currentValue.map((row, i) => {
        if (i === index) {
          if (props.lang === 'fi') return { ...row, serviceFi: value }
          if (props.lang === 'en') return { ...row, serviceEn: value }
        }
        return row
      }))
    }
    const setEmail = (index, value) => {
      props.onChange(currentValue.map((row, i) => {
        if (i === index) return { ...row, email: value }
        return row
      }))
    }
    const setPhone = (index, value) => {
      props.onChange(currentValue.map((row, i) => {
        if (i === index) return { ...row, phone: value }
        return row
      }))
    }
    const setWWW = (index, value) => {
      props.onChange(currentValue.map((row, i) => {
        if (i === index) return { ...row, www: value }
        return row
      }))
    }
    const setServiceHours = (index, value) => {
      props.onChange(currentValue.map((row, i) => {
        if (i === index) {
          if (props.lang === 'fi') return { ...row, serviceHoursFi: value }
          if (props.lang === 'en') return { ...row, serviceHoursEn: value }
        }
        return row
      }))
    }

    const handleAddField = () => props.onChange([
      ...currentValue,
      {
        serviceFi: '',
        serviceEn: '',
        email: '',
        phone: '',
        www: '',
        serviceHoursFi: '',
        serviceHoursEn: ''
      }
    ])

    return (
      <div className={classes.repeaterContainer}>
        {currentValue.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <div className={classes.repeaterRow}>
                <div className={classes.repeaterServiceColumn}>
                  <Input
                    label={t('service')}
                    value={getService(index)}
                    onChange={(value) => setService(index, value)}
                    disabled={props.disabled}
                  />
                </div>
                <div className={classes.repeaterEmailColumn}>
                  <Input
                    label={t('email')}
                    value={getEmail(index)}
                    onChange={(value) => setEmail(index, value)}
                    disabled={props.disabled}
                  />
                </div>
              </div>
              <div className={classes.repeaterRow}>
                <div className={classes.repeaterPhoneColumn}>
                  <Input
                    label={t('phone')}
                    value={getPhone(index)}
                    onChange={(value) => setPhone(index, value)}
                    disabled={props.disabled}
                  />
                </div>
                <div className={classes.repeaterWwwColumn}>
                  <Input
                    label={t('www')}
                    value={getWWW(index)}
                    onChange={(value) => setWWW(index, value)}
                    disabled={props.disabled}
                  />
                </div>
              </div>
              <Input
                label={t('service_hours')}
                value={getServiceHours(index)}
                onChange={(value) => setServiceHours(index, value)}
                disabled={props.disabled}
                multiline
              />
              {index < currentValue.length- 1 ? <Divider sx={styles.repeaterDivider} /> : null}
            </React.Fragment>
          )
        })}
        <div className={classes.repeaterButtonContainer}>
          <Button
            onClick={handleAddField}
            text={t('add_service')}
            buttonStyle={classes.repeaterButton}
            buttonTextStyle={classes.repeaterButtonText}
            rightIcon={AddIcon}
            disabled={props.disabled}
            outlined
          />
        </div>
      </div>
    )
  }

  const renderServiceOpenHoursRepeater = () => {
    const langSuffix = props.lang === 'fi' ? 'Fi' : 'En'
    const getService = (row) => get(props.value, `[${row}].service${langSuffix}`)
    const getOpenHours = (row) => get(props.value, `[${row}].openHours`, null)
    const getTime = (item) => {
      const startTime = item[0] ?? null
      const endTime = item[1] ?? null
      if (startTime && endTime) {
        return `${startTime} - ${endTime}`
      }
      return t('closed')
    }

    const currentValue = get(props, 'value') || []

    const handleAddField = () => {
      const newIndex = currentValue.length ?? 0
      openServiceOpenHoursModal(newIndex)
    }

    return (
      <>
        <div className={classes.repeaterContainer}>
          <Grid
            direction='row'
            justifyContent='flex-start'
            alignItems='center'
            spacing={4}
            classes={{ root: classes.serviceOpenHourGrid }}
            container
          >
            {currentValue.map((item, index) => {
              return (
                <Grid key={index} xl={6} lg={6} md={6} xs={12} item>
                  <ButtonBase
                    className={classes.serviceOpenHourItem}
                    onClick={() => openServiceOpenHoursModal(index)}
                  >
                    <p className={classes.serviceOpenHourTitle}>{getService(index)}</p>
                    {getOpenHours(index)?.slice(0, 7).map((item, idx) => (
                      <p className={classes.serviceOpenHourText}>
                        <div className={classes.serviceOpenHourDay}>
                          {t(`day_short_${idx + 1}`)}
                        </div> {getTime(item)}
                      </p>
                    ))}
                  </ButtonBase>
                </Grid>
              )
            })}
          </Grid>
          <div className={classes.repeaterButtonContainer}>
            <Button
              onClick={handleAddField}
              text={t('add_new')}
              buttonStyle={classes.repeaterButton}
              buttonTextStyle={classes.repeaterButtonText}
              rightIcon={AddIcon}
              disabled={props.disabled}
              outlined
            />
          </div>
        </div>
        {
          showServiceOpenHoursModal ? (
            <ServiceOpenHoursModal
              index={serviceOpenHoursItemIndex}
              value={props.value}
              lang={props.lang}
              onChange={props.onChange}
              handleClose={closeServiceOpenHoursModal}
            />
          ) : null
        }
      </>
    )
  }

  const renderProgramRepeater = () => (
    <ProgramRepeater
      value={props.value}
      onChange={props.onChange}
    />
  )

  const renderEventServiceRepeater = () => (
    <EventServiceRepeater
      value={props.value}
      onChange={props.onChange}
    />
  )

  const renderServicePhoneRepeater = () => (
    <ServiceContactInfoRepeater
      value={props.value}
      onChange={props.onChange}
      type='phone'
    />
  )

  const renderServiceWwwRepeater = () => (
    <ServiceContactInfoRepeater
      value={props.value}
      onChange={props.onChange}
      type='www'
    />
  )

  const renderLinkRepeater = () => {
    const langSuffix = props.lang === 'fi' ? 'Fi' : 'En'
    const getLinkText = (row) => get(props.value, `[${row}].linkText${langSuffix}`)
    const getLink = (row) => get(props.value, `[${row}].link`)

    const setLinkText = (index, value) => {
      props.onChange(currentValue.map((row, i) => {
        if (i === index) {
          if (props.lang === 'fi') return { ...row, linkTextFi: value }
          if (props.lang === 'en') return { ...row, linkTextEn: value }
        }
        return row
      }))
    }
    const setLink = (index, value) => {
      props.onChange(currentValue.map((row, i) => {
        if (i === index) return { ...row, link: value }
        return row
      }))
    }

    const currentValue = get(props, 'value') || []
    const handleAddField = () => props.onChange([
      ...currentValue,
      {
        linkTextFi: '',
        linkTextEn: '',
        link: ''
      }
    ])

    return (
      <div className={classes.repeaterContainer}>
        {currentValue.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <div className={classes.repeaterRow}>
                <div className={classes.repeaterLinkTextColumn}>
                  <Input
                    label={t('link_text')}
                    value={getLinkText(index)}
                    onChange={(value) => setLinkText(index, value)}
                    disabled={props.disabled}
                  />
                </div>
                <div className={classes.repeaterLinkColumn}>
                  <Input
                    label={t('link_url')}
                    value={getLink(index)}
                    onChange={(value) => setLink(index, value)}
                    disabled={props.disabled}
                  />
                </div>
              </div>
              {index < currentValue.length- 1 ? <Divider sx={styles.repeaterDivider} /> : null}
            </React.Fragment>
          )
        })}
        <div className={classes.repeaterButtonContainer}>
          <Button
            onClick={handleAddField}
            text={t('add_new')}
            buttonStyle={classes.repeaterButton}
            buttonTextStyle={classes.repeaterButtonText}
            rightIcon={AddIcon}
            disabled={props.disabled}
            outlined
          />
        </div>
      </div>
    )
  }

  const renderFacultyRepeater = () => {
    const langSuffix = props.lang === 'fi' ? 'Fi' : 'En'
    const getName = (row) => get(props.value, `[${row}].name${langSuffix}`)
    const getUrl = (row) => get(props.value, `[${row}].url`)

    const setName = (index, value) => {
      props.onChange(currentValue.map((row, i) => {
        if (i === index) {
          if (props.lang === 'fi') return { ...row, nameFi: value }
          if (props.lang === 'en') return { ...row, nameEn: value }
        }
        return row
      }))
    }
    const setUrl = (index, value) => {
      props.onChange(currentValue.map((row, i) => {
        if (i === index) return { ...row, url: value }
        return row
      }))
    }

    const currentValue = get(props, 'value') || []
    const handleAddField = () => props.onChange([
      ...currentValue,
      {
        nameFi: '',
        nameEn: '',
        url: ''
      }
    ])

    return (
      <div className={classes.repeaterContainer}>
        {currentValue.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <div className={classes.repeaterRow}>
                <div className={classes.repeaterNameColumn}>
                  <Input
                    label={t('faculty')}
                    value={getName(index)}
                    onChange={(value) => setName(index, value)}
                    disabled={props.disabled}
                  />
                </div>
                <div className={classes.repeaterLinkColumn}>
                  <Input
                    label={t('url')}
                    value={getUrl(index)}
                    onChange={(value) => setUrl(index, value)}
                    disabled={props.disabled}
                  />
                </div>
              </div>
              {index < currentValue.length- 1 ? <Divider sx={styles.repeaterDivider} /> : null}
            </React.Fragment>
          )
        })}
        <div className={classes.repeaterButtonContainer}>
          <Button
            onClick={handleAddField}
            text={t('add_new')}
            buttonStyle={classes.repeaterButton}
            buttonTextStyle={classes.repeaterButtonText}
            rightIcon={AddIcon}
            disabled={props.disabled}
            outlined
          />
        </div>
      </div>
    )
  }

  const renderCampusRepeater = () => {
    const langSuffix = props.lang === 'fi' ? 'Fi' : 'En'
    const getName = (row) => get(props.value, `[${row}].name${langSuffix}`)
    const getUrl = (row) => get(props.value, `[${row}].url`)

    const setName = (index, value) => {
      props.onChange(currentValue.map((row, i) => {
        if (i === index) {
          if (props.lang === 'fi') return { ...row, nameFi: value }
          if (props.lang === 'en') return { ...row, nameEn: value }
        }
        return row
      }))
    }
    const setUrl = (index, value) => {
      props.onChange(currentValue.map((row, i) => {
        if (i === index) return { ...row, url: value }
        return row
      }))
    }

    const currentValue = get(props, 'value') || []
    const handleAddField = () => props.onChange([
      ...currentValue,
      {
        nameFi: '',
        nameEn: '',
        url: ''
      }
    ])

    return (
      <div className={classes.repeaterContainer}>
        {currentValue.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <div className={classes.repeaterRow}>
                <div className={classes.repeaterNameColumn}>
                  <Input
                    label={t('name')}
                    value={getName(index)}
                    onChange={(value) => setName(index, value)}
                    disabled={props.disabled}
                  />
                </div>
                <div className={classes.repeaterLinkColumn}>
                  <Input
                    label={t('url')}
                    value={getUrl(index)}
                    onChange={(value) => setUrl(index, value)}
                    disabled={props.disabled}
                  />
                </div>
              </div>
              {index < currentValue.length- 1 ? <Divider sx={styles.repeaterDivider} /> : null}
            </React.Fragment>
          )
        })}
        <div className={classes.repeaterButtonContainer}>
          <Button
            onClick={handleAddField}
            text={t('add_new')}
            buttonStyle={classes.repeaterButton}
            buttonTextStyle={classes.repeaterButtonText}
            rightIcon={AddIcon}
            disabled={props.disabled}
            outlined
          />
        </div>
      </div>
    )
  }

  const renderServiceRepeater = () => {
    const langSuffix = props.lang === 'fi' ? 'Fi' : 'En'
    const getName = (row) => get(props.value, `[${row}].name${langSuffix}`)
    const getUrl = (row) => get(props.value, `[${row}].url`)

    const setName = (index, value) => {
      props.onChange(currentValue.map((row, i) => {
        if (i === index) {
          if (props.lang === 'fi') return { ...row, nameFi: value }
          if (props.lang === 'en') return { ...row, nameEn: value }
        }
        return row
      }))
    }
    const setUrl = (index, value) => {
      props.onChange(currentValue.map((row, i) => {
        if (i === index) return { ...row, url: value }
        return row
      }))
    }

    const currentValue = get(props, 'value') || []
    const handleAddField = () => props.onChange([
      ...currentValue,
      {
        nameFi: '',
        nameEn: '',
        url: ''
      }
    ])

    return (
      <div className={classes.repeaterContainer}>
        {currentValue.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <div className={classes.repeaterRow}>
                <div className={classes.repeaterNameColumn}>
                  <Input
                    label={t('name')}
                    value={getName(index)}
                    onChange={(value) => setName(index, value)}
                    disabled={props.disabled}
                  />
                </div>
                <div className={classes.repeaterLinkColumn}>
                  <Input
                    label={t('url')}
                    value={getUrl(index)}
                    onChange={(value) => setUrl(index, value)}
                    disabled={props.disabled}
                  />
                </div>
              </div>
              {index < currentValue.length- 1 ? <Divider sx={styles.repeaterDivider} /> : null}
            </React.Fragment>
          )
        })}
        <div className={classes.repeaterButtonContainer}>
          <Button
            onClick={handleAddField}
            text={t('add_new')}
            buttonStyle={classes.repeaterButton}
            buttonTextStyle={classes.repeaterButtonText}
            rightIcon={AddIcon}
            disabled={props.disabled}
            outlined
          />
        </div>
      </div>
    )
  }

  const renderContent = () => {
    const { fieldType, customType } = field

    // Custom types (variation of type)
    if (customType && customType?.type === 'quantity') {
      return renderQuantityField()
    }

    // Regular types
    if (fieldType === 'number') return renderNumberField()
    if (fieldType === 'text') return renderTextField()
    if (fieldType === 'textarea') return renderTextAreaField()
    if (fieldType === 'editor') return renderEditor()
    if (fieldType === 'boolean') return renderBooleanField()
    if (fieldType === 'select') return customType === 'checkbox' ? renderSelectFieldAsCheckbox() : renderSelectField()
    if (fieldType === 'date') return renderDateField()
    if (fieldType === 'datetime') return renderDateTimeField()
    if (fieldType === 'time') return renderTimeField()
    if (fieldType === 'multiselect') return renderMultiSelect()
    if (fieldType === 'weekday_time_ranges') return renderWeekdayTimeRanges()
    if (fieldType === 'date_and_time') return renderDateAndTime()
    if (fieldType === 'sport_field_repeater') return renderSportFieldRepeater()
    if (fieldType === 'services_contact_information_repeater') return renderServicesContactInformationRepeater()
    if (fieldType === 'service_open_hours_repeater') return renderServiceOpenHoursRepeater()
    if (fieldType === 'link_repeater') return renderLinkRepeater()
    if (fieldType === 'faculty_repeater') return renderFacultyRepeater()
    if (fieldType === 'campus_repeater') return renderCampusRepeater()
    if (fieldType === 'service_repeater') return renderServiceRepeater()
    if (fieldType === 'program_repeater') return renderProgramRepeater()
    if (fieldType === 'event_service_repeater') return renderEventServiceRepeater()
    if (fieldType === 'service_phone_repeater') return renderServicePhoneRepeater()
    if (fieldType === 'service_www_repeater') return renderServiceWwwRepeater()
  }

  return renderContent()
}

export default CustomField
