import React from 'react'
import clsx from 'clsx'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import ButtonBase from '@mui/material/ButtonBase'
import logo from '../../Assets/Images/logo.png'
import logoutIcon from '../../Assets/Icons/logout.svg'
import ChevronRightIcon from '../../Assets/Icons/chevron-right.svg'
import ChevronLeftIcon from '../../Assets/Icons/chevron-left.svg'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  drawer: {
    minHeight: '100vh',
    position: 'relative',
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    position: 'relative',
    overflowX: 'hidden',
    overflowY: 'hidden',
    whiteSpace: 'nowrap',
    minHeight: '100vh',
    width: '14.75rem',
    backgroundColor: Colors.black80,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    })
  },
  flex: {
    flex: 1
  },
  drawerClose: {
    minHeight: '100vh',
    overflowX: 'hidden',
    overflowY: 'hidden',
    width: '5rem',
    backgroundColor: Colors.black80,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    })
  },
  logo: {
    marginTop: '1.75rem',
    marginBottom: '1.75rem',
    alignSelf: 'center',
    maxWidth: '54%',
    height: 'auto'
  },
  logoHidden: {
    marginTop: '1.75rem',
    marginBottom: '1.75rem',
    alignSelf: 'center',
    maxWidth: '54%',
    height: 'auto',
    opacity: 0
  },
  listItem: {
    position: 'relative',
    height: '3.5rem',
    [theme.breakpoints.down('lg')]: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center'
    }
  },
  link: {
    width: '100%',
    height: '5rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: '1.25rem',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  linkClosed: {
    width: '100%',
    height: '5rem',
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  icon: {
    width: '1.875rem',
    minWidth: 1
  },
  itemTextRoot: {
    margin: 0
  },
  itemText: {
    fontFamily: 'Rubik',
    fontWeight: 500,
    color: Colors.white,
    fontSize: '1rem',
    marginLeft: '2rem'
  },
  logoutButton: {
    marginBottom: '1rem',
    alignSelf: 'center',
    backgroundColor: Colors.black20,
    paddingLeft: '1rem',
    paddingRight: '1rem',
    height: '3.5rem',
    width: '85%',
    borderRadius: '0.3125rem',
    alignItems: 'center',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: Colors.black40
    }
  },
  logoutText: {
    margin: 0,
    marginLeft: '.5rem',
    fontFamily: 'Rubik',
    fontSize: '1rem',
    fontWeight: 700,
    color: Colors.white
  },
  logoutIcon: {
    maxHeight: '2rem',
    maxWidth: '2rem',
  },
  chevronContainer: {
    position: 'fixed',
    left: '3.95rem',
    zIndex: 1300,
    animation: 'fadeIn linear .5s'
  },
  chevronContainerOpen: {
    position: 'fixed',
    left: '13.666rem',
    zIndex: 1300,
    animation: 'fadeIn linear 1s'
  },
  chevron: {
    background: Colors.white,
    height: '2rem',
    width: '2rem',
    borderRadius: '10rem',
    padding: '.5rem',
    border: `.25rem solid ${Colors.black80}`
  },
  chevronLeft: {
    background: Colors.white,
    height: '2rem',
    width: '2rem',
    borderRadius: '10rem',
    padding: '.5rem',
    border: `.25rem solid ${Colors.black80}`
  },
  backdrop: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(50,50,50,.78)'
  },
  itemIcon: {
    marginLeft: '.25rem',
    maxHeight: '1.5rem',
    maxWidth: '1.5rem',
    height: '100%',
    width: '100%'
  },
  itemIconOpen: {
    marginLeft: '1.25rem',
    maxHeight: '1.5rem',
    maxWidth: '1.5rem',
    height: '100%',
    width: '100%'
  },
  activeBadge: {
    display: 'block',
    backgroundColor: Colors.white,
    position: 'absolute',
    top: '28%',
    bottom: '28%',
    borderRadius: '10rem',
    left: '-.27rem',
    width: '.5rem'
  }
}))

export default function Sidebar(props) {
  const { t } = useTranslation()
  const classes = useStyles()
  const currentLocation = useLocation()
  const open = props.open

  const renderItemText = (text) => {
    if (!open) return null
    return (
      <ListItemText
        primary={t(text)}
        classes={{ root: classes.itemTextRoot, primary: classes.itemText }}
      />
    )
  }

  const handleListItemClick = () => {
    if (open) {
      props.toggleDrawer()
    }
  }

  const renderItems = () => {
    return props.navigationItems.map((item) => {
      // TODO: add better check here to handle entity == entities etc.
      const isActive = !!(currentLocation.pathname || '').substr(0, 6).includes(item.url.substr(0, 6))

      // Pressing Link bubbles also to the ListItem onClick event
      return (
        <ListItem
          onClick={handleListItemClick}
          key={item.url}
          classes={{ root: classes.listItem }}
          title={t(item.text)}
          disableGutters
          button
        >
          <Link to={item.url} className={open ? classes.link : classes.linkClosed}>
            <ListItemIcon classes={{ root: classes.icon }}>
              <img src={item.icon} className={open ? classes.itemIconOpen : classes.itemIcon} alt='icon' />
            </ListItemIcon>
            {renderItemText(item.text)}
          </Link>
          {isActive && <div className={classes.activeBadge} />}
        </ListItem>
      )
    })
  }

  const renderLogoutButtonContent = () => {
    if (open) {
      return <p className={classes.logoutText}>{t('logout')}</p>
    }
    return null
  }

  const renderChevron = () => {
    if (!open) {
      return (
        <div className={classes.chevronContainer}>
          <img src={ChevronRightIcon} className={classes.chevron} />
        </div>
      )
    }
    return (
      <div className={classes.chevronContainerOpen}>
        <img src={ChevronLeftIcon} className={classes.chevronLeft} />
      </div>
    )
  }

  return (
    <>
      {open && <div className={classes.backdrop} onClick={props.toggleDrawer} />}
      <Drawer
        variant='permanent'
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })
        }}
        open={open}
      >
        <ButtonBase onClick={props.toggleDrawer}>
          <img src={logo} alt='logo' className={open ? classes.logo : classes.logoHidden} />
          {renderChevron()}
        </ButtonBase>
        <List disablePadding>{renderItems()}</List>
        <div className={classes.flex} />
        <ButtonBase className={classes.logoutButton} onClick={props.logout}>
          {!open && <img src={logoutIcon} alt='icon' className={classes.logoutIcon} />}
          {renderLogoutButtonContent()}
        </ButtonBase>
      </Drawer>
    </>
  )
}
