import {
  SPACER,
  DESCRIPTION,
  ENTRANCE_DESCRIPTION_TITLE,
  ACCESSIBILITY_TITLE,
  ACCESSIBLE_ENTRANCE,
  AUTOMATIC_DOOR,
  DOORWAY_WIDTH,
  DOORSTEP,
  DOORSTEP_HEIGHT,
  OPEN_HOURS_TITLE,
  OPEN_HOURS,
  ENTRANCE_ADDRESS_TITLE,
  STREET_ADDRESS,
  CITY,
  POSTAL_CODE
} from '../elements.template'

const LAYOUT = {
  type: 'subentity_entrance',
  layout: [
    [
      // Start left column
      OPEN_HOURS_TITLE,
      OPEN_HOURS,
      { ...ACCESSIBILITY_TITLE, info: 'entrance_accessibility_info' },
      {
        type: 'container',
        className: 'customRowFixed',
        items: [
          ACCESSIBLE_ENTRANCE,
          SPACER,
          SPACER,
          AUTOMATIC_DOOR,
          SPACER,
          SPACER,
          DOORWAY_WIDTH
        ]
      },
      {
        type: 'container',
        className: 'customRowFixed',
        items: [
          DOORSTEP,
          SPACER,
          SPACER,
          DOORSTEP_HEIGHT
        ]
      },
      ENTRANCE_DESCRIPTION_TITLE,
      DESCRIPTION,
      ENTRANCE_ADDRESS_TITLE,
      {
        type: 'container',
        className: 'customRowFixed',
        items: [
          STREET_ADDRESS,
          SPACER,
          CITY,
          SPACER,
          POSTAL_CODE
        ]
      },
      // End left column
    ],
    [
      // Start of right column
      // End of right column
    ]
  ]
}

export default LAYOUT
