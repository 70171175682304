import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { observer } from 'mobx-react'
import { useStore } from '../../Models/RootStore'
import { Colors } from '../../Utils/theme'
import Notification from '../../Components/Common/Notification'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    overflow: 'hidden',
    [theme.breakpoints.down('lg')]: {
      justifyContent: 'center'
    }
  },
  leftColumn: {
    boxSizing: 'border-box',
    flex: 1,
    padding: 32,
    backgroundColor: Colors.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

  },
  rightColumn: {
    display: 'flex',
    minHeight: '100vh',
    boxSizing: 'border-box',
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    backgroundColor: Colors.black,
    backgroundImage: 'url(/images/background.jpg)',
    backgroundSize: 'cover',
    backgroundPosition: '50% 50%',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  logo: {
    backgroundImage: 'url(/images/logo.png)',
    backgroundPosition: '50% 50%',
    backgroundSize: 'contain',
    height: '6rem',
    width: '21rem',
    backgroundRepeat: 'no-repeat'
  }
}))

function AuthLayout(props) {
  const classes = useStyles()

  const { appStore }: any = useStore()
  const { notification, clearNotification } = appStore

  return (
    <div className={classes.root}>
      <main className={classes.content}>
        <div className={classes.leftColumn}>
          {props.children}
        </div>
        <div className={classes.rightColumn}>
          <div className={classes.logo} />
        </div>
      </main>
      <Notification notification={notification} clearNotification={clearNotification} />
    </div>
  )
}

export default observer(AuthLayout)
