import {
  SPACER,
  STREET_ADDRESS,
  CITY,
  POSTAL_CODE,
  OPEN_HOURS,
  OPEN_HOURS_TITLE,
  SERVICE_PHONES_AND_URLS_TITLE,
  SERVICE_PHONES,
  INFO_DESCRIPTION_TITLE,
  INFO_ADDRESS_TITLE,
  DESCRIPTION,
  SERVICE_URLS
} from '../elements.template'

const LAYOUT = {
  type: 'subentity_info',
  layout: [
    [
      // Start left column
      OPEN_HOURS_TITLE,
      OPEN_HOURS,
      INFO_ADDRESS_TITLE,
      {
        type: 'container',
        className: 'customRowFixed',
        items: [
          STREET_ADDRESS,
          SPACER,
          CITY,
          SPACER,
          POSTAL_CODE
        ]
      },
      INFO_DESCRIPTION_TITLE,
      DESCRIPTION,
      SERVICE_PHONES_AND_URLS_TITLE,
      SERVICE_PHONES,
      SERVICE_URLS,
      // End left column
    ],
    [
      // Start of right column
      // End of right column
    ]
  ]
}

export default LAYOUT
