import { makeAutoObservable } from 'mobx'

export default class AppStore {
  rootStore

  notification = null
  notificationUpdatedAt = 0

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  clearNotification = () => { this.notification = null }

  async setErrorOrSuccess(response, errorKey: any = true, successKey: any = true) {
    // Update timestamp
    this.notificationUpdatedAt = Date.now()

    if (response.ok) {
      if (typeof successKey === 'string' && successKey.length) {
        this.notification = { type: 'success', text: successKey }
      } else if (successKey === true) {
        this.notification = { type: 'success', text: 'generic_success' }
      } else {
        // this.notification = null
      }
    } else {
      if (typeof errorKey === 'string' && errorKey.length) {
        this.notification = { type: 'error', text: errorKey }
      } else if (errorKey === true) {
        // Detect network errors
        if (response.problem && ['NETWORK_ERROR', 'TIMEOUT_ERROR', 'CONNECTION_ERROR'].includes(response.problem)) {
          this.notification = { type: 'error', text: 'network_error' }
        } else if (response.status === 401) {
          this.notification = { type: 'error', text: 'unauthorized_error' }
        } else if (response.status === 403) {
          this.notification = { type: 'error', text: 'forbidden_error' }
        }
        else {
          this.notification = { type: 'error', text: 'generic_error' }
        }
      } else {
        // this.notification = null
      }
    }
  }

  setError(errorKey) {
    this.setErrorOrSuccess({ ok: false }, errorKey, false)
  }

  setSuccess(successKey) {
    this.setErrorOrSuccess({ ok: true }, false, successKey)
  }
}
