import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { uniq, orderBy, flatten } from 'lodash'
import makeStyles from '@mui/styles/makeStyles'
import PageHeader from '../../../Components/Common/PageHeader'
import { useStore } from '../../../Models/RootStore'
import { Title, Input, Select, Button, Checkbox } from '../../../Components'
import { MainEntityCategoryTypeMappings, ROLES } from '../../../Constants'
import { Colors } from '../../../Utils/theme'
import { IconButton } from '@mui/material'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

const useStyles = makeStyles((theme) => ({
  root: {
  },
  contentContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 0
  },
  contentInnerContainer: {
    maxWidth: '60rem'
  },
  content: {
    overflowY: 'auto',
    flexDirection: 'column',
    flexGrow: 1,
    padding: '0.5rem 2.5rem 10rem',
    overflow: 'auto',
    borderRight: `1px solid ${Colors.black20}`,
    minHeight: '100vh'
  },
  actions: {
    height: 'calc(100% - 10rem)',
    width: '25rem',
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    overflowX: 'hidden',
    overflowY: 'hidden',
    padding: '1.75rem'
  },
  text: {
    margin: 0,
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: 300,
    color: Colors.black,
    marginBottom: '1.5rem'
  },
  subtitleRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  subtitle: {
    fontFamily: 'Rubik',
    fontWeight: 600,
    fontSize: '1.125rem',
    color: Colors.violet
  },
  entityTypesContainer: {
    paddingTop: '0.375rem',
    paddingBottom: '1.125rem',
    paddingLeft: '2.125rem'
  },
  entityTypesRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingTop: '0.375rem',
    paddingBottom: '1.125rem',
    paddingLeft: '2.125rem'
  },
  categoryName: {
  },
  mainEntitiesContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingLeft: '2.125rem',
    paddingTop: '0.125rem',
    paddingBottom: '0.5rem'
  },
  mainEntityContainer: {
  },
  checkboxRoot: {
    marginRight: 0
  },
  largeCheckbox: {
    height: '1.375rem',
    width: '1.375rem',
    marginRight: '0.25rem'
  },
  largeCheck: {
    height: '0.825rem',
    maxHeight: '0.825rem',
    maxWidth: '0.825rem'
  },
  checkbox: {
    height: '1.25rem',
    width: '1.25rem',
    marginRight: '0.25rem'
  },
  check: {
    height: '0.75rem',
    maxHeight: '0.75rem',
    maxWidth: '0.75rem'
  },
  smallCheckbox: {
    height: '1.125rem',
    width: '1.125rem',
    marginRight: '0.125rem'
  },
  smallCheck: {
    height: '0.675rem',
    maxHeight: '0.675rem',
    maxWidth: '0.675rem'
  },
  label: {
    fontSize: '1rem',
    fontWeight: 500,
    marginRight: '0.25rem'
  },
  rowLabel: {
    fontSize: '1rem',
    fontWeight: 500,
    marginRight: '2rem'
  },
  smallLabel: {
    fontSize: '0.938rem',
    marginRight: '2rem'
  },
  toggleButton: {
    height: '2rem',
    width: '2rem'
  },
  email: {
    flex: 1,
    maxWidth: '30rem'
  },
  permissions: {
    maxWidth: '20rem'
  }
}))

function InviteUser(props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const [email, setEmail] = useState('')
  const [role, setRole] = useState('')
  const [submitted, setSubmitted] = useState(false)
  const [mainEntityTypeAccessRights, setMainEntityTypeAccessRights] = useState<number[]>([])
  const [accessRights, setAccessRights] = useState([])
  const [expandedTypes, setExpandedTypes] = useState([])

  useEffect(() => {
    if (role) {
      setMainEntityTypeAccessRights([])
      setAccessRights([])
      setExpandedTypes([])
    }
  }, [role])

  const getRoles = () => ROLES
    // .filter((role) => role !== 'entity_admin')
    .map((role, index) => ({ id: index + 1, label: t(role), value: role }))

  const { userStore, appStore, entityStore }: any = useStore()
  const inviteUser = () => {
    setSubmitted(true)
    userStore.inviteUser({
      email,
      role,
      accessRightMainEntityIds: accessRights,
      accessRightMainEntityTypeIds: mainEntityTypeAccessRights
    })
  }

  const toggleExpandType = (typeId) => {
    const newExpandedTypes = [...expandedTypes].filter(id => id !== typeId)
    if (newExpandedTypes.length === expandedTypes.length) newExpandedTypes.push(typeId)
    setExpandedTypes(newExpandedTypes)
  }

  const bulkToggleMainEntityTypeAccessRights = (ids: number[]) => {
    let newMainEntityTypeAccessRights: number[] = uniq([...mainEntityTypeAccessRights, ...ids])
    if (newMainEntityTypeAccessRights.length === mainEntityTypeAccessRights.length) {
      newMainEntityTypeAccessRights = newMainEntityTypeAccessRights.filter(id => !ids.includes(id))
    }
    setMainEntityTypeAccessRights(newMainEntityTypeAccessRights)
  }

  const toggleMainEntityTypeAccessRight = (typeId: number) => {
    const newMainEntityTypeAccessRights = [...mainEntityTypeAccessRights].filter(id => id !== typeId)
    if (newMainEntityTypeAccessRights.length === mainEntityTypeAccessRights.length) {
      newMainEntityTypeAccessRights.push(typeId)
    }
    setMainEntityTypeAccessRights(newMainEntityTypeAccessRights)
  }

  const toggleAccessRight = (mainEntityId) => {
    const newAccessRights = [...accessRights].filter(id => id !== mainEntityId)
    if (newAccessRights.length === accessRights.length) newAccessRights.push(mainEntityId)
    setAccessRights(newAccessRights)
  }

  const bulkToggleAccessRights = (ids) => {
    let newAccessRights = uniq([...accessRights, ...ids])
    if (newAccessRights.length === accessRights.length) {
      newAccessRights = newAccessRights.filter(id => !ids.includes(id))
    }
    setAccessRights(newAccessRights)
  }

  const isCategorySelected = (items) => {
    if (role === 'employee') {
      const ids = flatten(
        items.map((item: any) => item.mainEntities.map((entity: any) => entity.id))
      )
      return ids.every((id: number) => accessRights.includes(id))
    } else {
      const ids = items.map((item: any) => item.id)
      return ids.every((id: number) => mainEntityTypeAccessRights.includes(id))
    }
  }

  const isMainEntitySelected = (type) => {
    if (role === 'employee') {
      return !type.mainEntities.find((item) => !accessRights.includes(item.id))
    } else {
      return mainEntityTypeAccessRights.includes(type.id)
    }
  }

  const toggleCategoryTypes = (items) => {
    if (role === 'employee') {
      const ids = flatten(
        items.map((item: any) => item.mainEntities.map((entity: any) => entity.id))
      )
      bulkToggleAccessRights(ids)
    } else {
      const ids = items.map((item: any) => item.id)
      bulkToggleMainEntityTypeAccessRights(ids)
    }
  }

  const toggleMainEntityType = (type: any) => {
    if (role === 'employee') {
      const ids = type?.mainEntities.map((item: any) => item.id)
      bulkToggleAccessRights(ids)
    } else {
      toggleMainEntityTypeAccessRight(type?.id)
    }
  }

  useEffect(() => {
    entityStore.getMainEntities()
    entityStore.getMainEntityTypes()
  }, [])

  useEffect(() => {
    if (appStore.notification && appStore.notification.type === 'success') {
      // Clear fields after success
      setEmail('')
      setRole('')
      setSubmitted(false)
      setAccessRights([])
    }
  }, [appStore.notificationUpdatedAt])

  const emailValidation = () => {
    if (submitted && !/\S+@\S+\.\S+/.test(email)) {
      return { error: true, helperText: t('invalid_email') }
    }
    return {}
  }

  const roleValidation = () => {
    if (submitted && !role) {
      return { error: true, helperText: t('required_field') }
    }
  }

  const validFields = () => {
    // Simple validation for role and email
    return role && /(.+)@(.+){2,}\.(.+){2,}/.test(email)
  }

  const getAccessRightTree = () => {
    return Object.keys(MainEntityCategoryTypeMappings).map((key: string) => {
      const types = MainEntityCategoryTypeMappings[key].filter((type: string) => {
        const entityType = entityStore?.mainEntityTypes.find((item) => item?.type === type)
        if (entityType) {
          if (role === 'entity_admin') {
            return true
          }
          return entityStore
            .mainEntities
            .filter((mainEntity: any) => mainEntity.mainEntityTypeId === entityType.id)
            .length
        }
        return false
      })
      if (types.length) {
        return ({
          id: key,
          title: t(key),
          items: orderBy(
            types.map((type: any) => {
              const item = entityStore?.mainEntityTypes.find((item) => item?.type === type)
              return ({
                type,
                id: item.id,
                name: item.name,
                mainEntities: role === 'employee' ? orderBy(
                  entityStore.mainEntities
                    .filter((mainEntity: any) => mainEntity.mainEntityTypeId === item.id)
                    .map((mainEntity: any) => ({ id: mainEntity.id, name: mainEntity.name })),
                  'name',
                  'asc'
                ) : []
              })
            }),
            'name',
            'asc'
          )
        })
      }
      return null
    }).filter((item) => item)
  }

  const renderToggleButton = (type: any) => {
    if (type?.mainEntities?.length && role === 'employee') {
      return (
        <IconButton className={classes.toggleButton} onClick={() => toggleExpandType(type.id)}>
          {expandedTypes.includes(type.id) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      )
    }
    return null
  }

  const renderAvailableEntityTypes = (items: any) => {
    if (items?.length) {
      return items.map((type: any) => (
        <React.Fragment key={type.id}>
          <div className={classes.categoryName}>
            <Checkbox
              checked={isMainEntitySelected(type)}
              onChange={() => toggleMainEntityType(type)}
              label={type.name}
              classes={{ root: classes.checkboxRoot, label: role === 'employee' ? classes.label : classes.rowLabel }}
              containerStyle={classes.checkbox}
              checkStyle={classes.check}
            />
            {renderToggleButton(type)}
          </div>
          {renderExpandedAccessRights(type)}
        </React.Fragment>
      ))
    }
    return null
  }

  const renderExpandedAccessRights = (type: any) => {
    if (role === 'employee' && expandedTypes.includes(type.id)) {
      return (
        <div className={classes.mainEntitiesContainer}>
          {type.mainEntities.map((mainEntity: any) => (
              <div key={mainEntity.id} className={classes.mainEntityContainer}>
                <Checkbox
                  checked={accessRights.includes(mainEntity.id)}
                  onChange={() => toggleAccessRight(mainEntity.id)}
                  label={mainEntity.name}
                  classes={{ root: classes.checkboxRoot, label: classes.smallLabel }}
                  containerStyle={classes.smallCheckbox}
                  checkStyle={classes.smallCheck}
                />
              </div>
            )
          )}
        </div>
      )
    }
    return null
  }

  const renderAvailableAccessRights = () => {
    return getAccessRightTree().map((item: any) => (
      <React.Fragment key={item.id}>
        <Checkbox
          checked={isCategorySelected(item?.items)}
          onChange={() => toggleCategoryTypes(item?.items)}
          label={item?.title ?? ''}
          classes={{ root: classes.checkboxRoot, label: classes.subtitle }}
          containerStyle={classes.largeCheckbox}
          checkStyle={classes.largeCheck}
        />
        <div className={role === 'employee' ? classes.entityTypesContainer : classes.entityTypesRowContainer}>
          {renderAvailableEntityTypes(item?.items)}
        </div>
      </React.Fragment>
    ))
  }

  const renderAccessRights = () => {
    if (['employee', 'entity_admin'].includes(role)) {
      return (
        <>
          <Title title={t('entity_level_access_rights')} type='subtitle' />
          {renderAvailableAccessRights()}
        </>
      )
    }
    return null
  }

  return (
    <div className={classes.root}>
      <PageHeader title={t('add_new_user')} compact />
      <div className={classes.contentContainer}>
        <div className={classes.content}>
          <div className={classes.contentInnerContainer}>
            <Title title={t('user_details')} type='subtitle' />
            <div className={classes.email}>
              <Input
                label={t('email')}
                value={email}
                onChange={setEmail}
                {...emailValidation()}
              />
            </div>
            <Title
              title={t('account_details')}
              description={t('create_user_account_description')}
              type='subtitle'
            />
            <div className={classes.permissions}>
              <Select
                options={getRoles()}
                label={t('role')}
                value={role}
                onChange={setRole}
                {...roleValidation()}
              />
            </div>
            {renderAccessRights()}
          </div>
        </div>
        <div className={classes.actions}>
          <p className={classes.text}>{t('create_user_action_description')}</p>
          <Button
            text={t('send_invite')}
            onClick={inviteUser}
            disabled={!validFields()}
          />
        </div>
      </div>
    </div>
  )
}

export default observer(InviteUser)
