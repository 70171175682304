import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'
import { get } from 'lodash'
import orderBy from 'lodash/orderBy'
import makeStyles from '@mui/styles/makeStyles'
import { useStore } from '../../../Models/RootStore'
import { PageHeader, PollTable, DataActions } from '../../../Components'
import { UserSortOptions } from '../../../Constants'

const useStyles = makeStyles((theme) => ({
  root: {
  },
  container: {
    padding: '2.5rem'
  }
}))

const TABS = {
  all: 0,
  active: 1,
  deleted: 2
}

function Polls() {
  const classes = useStyles()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { pollStore }: any = useStore()

  useEffect(() => {
    pollStore.getPolls()
  }, [])

  const [tab, setTab] = useState(TABS.all)
  const selectTab = (value) => setTab(value)

  const getTabs = () => [
    { value: TABS.all, label: t('all_items') },
    { value: TABS.active, label: t('active_items') },
    { value: TABS.deleted, label: t('deleted_items') }
  ]

  const [searchValue, setSearchValue] = useState('')
  const [sortValue, setSortValue] = useState(UserSortOptions[0].value)

  const toCreatePoll = () => navigate('/polls/create')
  const handleDelete = (pollId) => pollStore.deletePoll(pollId)
  const handleRestore = (pollId) => pollStore.restorePoll(pollId)

  const getPolls = () => {
    let items = pollStore.polls
    // All
    if (tab === TABS.all) items = items.filter(item => item)
    // Active
    if (tab === TABS.active) items = items.filter(user => user.active)
    // Deleted
    if (tab === TABS.deleted) items = items.filter(user => !user.active)

    if (searchValue) {
      items = items.filter((item) => {
        const { titleFi = '', titleEn = '' } = item
        const mainEntityNameFi = get(item, 'mainEntity.nameFi', '')
        const mainEntityNameEn = get(item, 'mainEntity.nameEn', '')

        const targets = [titleFi, titleEn, mainEntityNameFi, mainEntityNameEn]
        return targets.some((target) => target.toLowerCase().includes(searchValue.trim().toLowerCase()))
      })
    }

  if (sortValue === 'created_at') {
    items = orderBy(items, 'createdAt', 'desc')
  } else {
    items = orderBy(items, 'titleFi', 'asc')
  }

    return items
  }

  return (
    <div className={classes.root}>
      <PageHeader
        title={t('polls')}
        tabs={getTabs()}
        currentTab={tab}
        onTabChange={selectTab}
        onCreateClick={toCreatePoll}
      />
      <div className={classes.container}>
        <DataActions
          searchPlaceholder={t('search')}
          searchValue={searchValue}
          onSearchChange={setSearchValue}
          sortOptions={UserSortOptions}
          sortValue={sortValue}
          onSortChange={setSortValue}
        />
        <PollTable
          items={getPolls()}
          onDelete={handleDelete}
          onRestore={handleRestore}
        />
      </div>
    </div>
  )
}

export default observer(Polls)
