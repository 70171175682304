import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import OrganizationCard from './OrganizationCard'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    marginTop: '0.5rem'
  }
})

function OrganizationGrid (props) {
  const classes = useStyles()

  const renderOrganizations = () => {
    return props.organizations.map((organization, index) => (
      <OrganizationCard
        key={organization.id}
        organization={organization}
        onClick={props.onClick}
      />
    ))
  }

  return (
    <div className={classes.container}>
      {renderOrganizations()}
    </div>
  )
}

export default OrganizationGrid
