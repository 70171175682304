import i18n from '../../Localization'

export const CONTACT_INFORMATION_TITLE = { type: 'big_title', name: 'contact_information' }
export const SERVICES_CONTACT_INFORMATION_TITLE = { type: 'big_title', name: 'services_contact_information' }
export const MAINTENANCE_TITLE = { type: 'big_title', name: 'maintenance_markings' }
export const OTHER_SERVICES_TITLE = { type: 'big_title', name: 'other_services' }
export const FIELDS_TITLE = { type: 'big_title', name: 'fields' }
export const SPORTS_TITLE = { type: 'big_title', name: 'sports' }
export const OPEN_HOURS_AND_PRICE_INFO_TITLE = { type: 'big_title', name: 'open_hours_and_price_info' }
export const OPEN_HOURS_TITLE = { type: 'big_title', name: 'open_hours' }
export const PRICING_AND_SERVICES_TITLE = { type: 'big_title', name: 'pricing_and_services' }
export const EQUIPMENT_TITLE = { type: 'big_title', name: 'equipment' }
export const EXCEPTION_NOTIFICATIONS_TITLE = { type: 'big_title', name: 'exception_notifications' }
export const OBSTACLES_TITLE = { type: 'big_title', name: 'obstacles' }
export const EQUIPMENT_RENTAL_TITLE = { type: 'big_title', name: 'equipment_rental' }
export const SERVICES_TITLE = { type: 'big_title', name: 'services' }
export const ACTIVITIES_TITLE = { type: 'big_title', name: 'activities' }
export const RULES_TITLE = { type: 'big_title', name: 'rules' }
export const MARKET_PLACES_TITLE = { type: 'big_title', name: 'market_places'  }
export const ADDITIONAL_SERVICES_TITLE = { type: 'big_title', name: 'additional_services' }
export const TIMETABLES_TITLE = { type: 'big_title', name: 'timetables' }
export const FACULTIES_TITLE = { type: 'big_title', name: 'faculties' }
export const CAMPUSES_TITLE = { type: 'big_title', name: 'campuses' }
export const PROPERTIES_TITLE = { type: 'big_title', name: 'properties' }
export const STATUS_INFORMATION_TITLE = { type: 'big_title', name: 'status_information' }
export const COURSE_INFORMATION_TITLE = { type: 'big_title', name: 'course_information' }
export const EQUIPMENT_AND_SERVICES_TITLE = { type: 'big_title', name: 'equipment_and_services' }
export const ADDITIONAL_INFORMATION_TITLE = { type: 'big_title', name: 'additional_info' }
export const EXHIBITION_INFORMATION_TITLE = { type: 'big_title', name: 'exhibition_information' }
export const SALES_LOCATIONS_TITLE = { type: 'big_title', name: 'sales_locations' }
export const EVENT_PLACE_TITLE = { type: 'big_title', name: 'event_place' }
export const EVENT_PLACE_ADDRESS_TITLE = { type: 'big_title', name: 'event_place_address' }
export const EVENT_CONTACT_INFORMATION_TITLE = { type: 'big_title', name: 'event_contact_infos' }
export const EVENT_TIMES_TITLE = { type: 'big_title', name: 'event_times' }
export const EVENT_TICKET_SALES_TITLE = { type: 'big_title', name: 'event_ticket_sales' }
export const EVENT_VISIBILITY_TITLE = { type: 'big_title', name: 'event_visibility' }
export const SERVICE_PHONES_AND_URLS_TITLE = { type: 'big_title', name: 'service_phone_number_and_urls' }
export const INFO_DESCRIPTION_TITLE = { type: 'big_title', name: 'info_description' }
export const INFO_ADDRESS_TITLE = { type: 'big_title', name: 'info_address' }
export const RESTAURANT_ADDRESS_TITLE = { type: 'big_title', name: 'restaurant_address' }
export const DESCRIPTION_TITLE = { type: 'big_title', name: 'description' }
export const RESTAURANT_DESCRIPTION_TITLE = { type: 'big_title', name: 'restaurant_description' }
export const FAIRWAY_DESCRIPTION_TITLE = { type: 'big_title', name: 'fairway_description' }
export const PARKING_DESCRIPTION_TITLE = { type: 'big_title', name: 'parking_description' }
export const EVENT_PLACE_DESCRIPTION_TITLE = { type: 'big_title', name: 'event_place_description' }
export const EVENT_PLACE_OPEN_TITLE = { type: 'big_title', name: 'event_place_open' }
export const ENTRANCE_DESCRIPTION_TITLE = { type: 'big_title', name: 'entrance_description' }
export const ACCESSIBILITY_TITLE = { type: 'big_title', name: 'accessibility' }
export const ENTRANCE_ADDRESS_TITLE = { type: 'big_title', name: 'entrance_address' }
export const STAIRS_DESCRIPTION_TITLE = { type: 'big_title', name: 'stairs_description' }
export const STAIRS_ADDRESS_TITLE = { type: 'big_title', name: 'stairs_address' }
export const INDOOR_SPORTS_OPTIONS_TITLE = { type: 'big_title', name: 'indoor_sports_options' }
export const INDOOR_SPORT_FACILITY_DESCRIPTION_TITLE = { type: 'big_title', name: 'indoor_sport_facility_description' }
export const INDOOR_SPORT_FACILITY_ADDRESS_TITLE = { type: 'big_title', name: 'indoor_sport_facility_address' }
export const PARKING_AREA_ADDRESS_TITLE = { type: 'big_title', name: 'parking_area_address' }
export const STORAGE_INFORMATION_TITLE = { type: 'big_title', name: 'storage_information' }
export const ADDRESS_INFORMATION_TITLE = { type: 'big_title', name: 'address_information' }

export const SPACER = {
  type: 'container',
  className: 'customSpacer',
  items: []
}

export const DASH_SPACER = {
  type: 'title',
  name: '_',
  style: {
    paddingTop: '0.5rem',
    paddingLeft: '0.375rem',
    paddingRight: '0.375rem',
    fontWeight: 500
  }
}

export const EXCEPTION_NOTIFICATION = { type: 'custom_field', field: 'exception_notification' }
export const BOOKING_CALENDAR_LINK = { type: 'custom_field', field: 'booking_calendar_link' }
export const BOOKING_LINK = { type: 'custom_field', field: 'booking_link' }
export const SHOW_SCHEDULES_LINK = { type: 'custom_field', field: 'show_schedules_link' }
export const TICKET_SALES_LINK = { type: 'custom_field', field: 'ticket_sales_link' }
export const TOUR_SCHEDULES_LINK = { type: 'custom_field', field: 'tour_schedules_link' }
export const OPEN_HOURS_TYPE = { type: 'custom_field', field: 'open_hours_type' }
export const OPEN_HOURS_DESCRIPTION = { type: 'custom_field', field: 'open_hours_description' }
export const SKI_DIRECTION = { type: 'custom_field', field: 'ski_direction' }
export const SKI_STYLE = { type: 'custom_field', field: 'ski_style' }
export const HAS_LIGHTS = { type: 'custom_field', field: 'has_lights' }
export const CONDITION = { type: 'custom_field', field: 'condition' }
export const ALGAE_STATUS = { type: 'custom_field', field: 'algae', info: i18n.t('automatic_integration_info') }
export const ICE_SKATING_EQUIPMENT = { type: 'custom_field',  field: 'ice_skating_equipment' }
export const EQUIPMENT = { type: 'custom_field', field: 'equipment' }
export const SPORT_FIELD_LIST = { type: 'custom_field', field: 'sport_field_list' }
export const TOPOGRAPHY = { type: 'custom_field', field: 'topography' }
export const PRICE = { type: 'custom_field', field: 'price', label: 'price_information' }
export const MAINTENANCE_FREQUENCY = { type: 'custom_field', field: 'maintenance_frequency' }
export const TRACK_SURFACE = { type: 'custom_field', field: 'track_surface_material' }
export const GRASS_TYPE = { type: 'custom_field', field: 'grass_type' }
export const GRASS_USAGE = { type: 'custom_field', field: 'grass_usage' }
export const SAUNA_TYPES = { type: 'custom_field', field: 'sauna_types' }
export const SKATE_EQUIPMENT_DESCRIPTION = { type: 'custom_field', field: 'skate_equipment_description' }
export const TRIP_ADVISOR_URL = { type: 'custom_field', field: 'trip_advisor_url' }
export const ACCESSIBLE = { type: 'custom_field', field: 'accessible' }
export const UNISEX_SAUNA = { type: 'custom_field', field: 'unisex_sauna' }
export const DIFFICULTY = { type: 'custom_field', field: 'difficulty' }
export const EXCEPTION_SCHEDULES = { type: 'custom_field', field: 'exception_schedules' }
export const OPENED = { type: 'custom_field', field: 'opened' }
export const YEARLY_VISITOR_COUNT = { type: 'custom_field', field: 'yearly_visitor_count' }
export const AMUSEMENT_PARK_EQUIPMENT = { type: 'custom_field', field: 'amusement_park_equipment' }
export const SERVICES_CONTACT_INFORMATION = { type: 'custom_field', field: 'services_contact_information' }
export const THEATER_SERVICES = { type: 'custom_field', field: 'theater_services' }
export const MUSEUM_THEMES = { type: 'custom_field', field: 'museum_themes' }
export const PAYMENT_METHODS = { type: 'custom_field', field: 'payment_methods' }
export const RESERVATION_NUMBER = { type: 'custom_field', field: 'reservation_number' }
export const GUIDER_TOUR = { type: 'custom_field', field: 'guided_tour' }
export const TOUR_PRICING = { type: 'custom_field', field: 'tour_pricing' }
export const SALES_LOCATION_BY_RESERVATION = { type: 'custom_field', field: 'sales_location_by_reservation' }
export const FLEA_MARKET_PLACES = { type: 'custom_field', field: 'flea_market_places' }
export const FACEBOOK_URL = { type: 'custom_field', field: 'facebook_url' }
export const CONTACT_PERSON = { type: 'custom_field', field: 'contact_person' }
export const WINTER_MAINTENANCE = { type: 'custom_field', field: 'winter_maintenance' }
export const EQUIPMENT_ADDITIONAL_INFO = { type: 'custom_field', field: 'equipment_additional_info' }
export const MARINA_MANAGER = { type: 'custom_field', field: 'marina_manager' }
export const SERVICE_HOURS = { type: 'custom_field', field: 'service_hours' }
export const SERVICE_OPEN_HOURS = { type: 'custom_field', field: 'service_open_hours' }
export const LINKS = { type: 'custom_field', field: 'links' }
export const FACULTIES = { type: 'custom_field', field: 'faculties' }
export const CAMPUSES = { type: 'custom_field', field: 'campuses' }
export const CUSTOM_SERVICES = { type: 'custom_field', field: 'custom_services' }
export const MULTIPURPOSE_FIELD_SURFACE = { type: 'custom_field', field: 'multipurpose_field_surface' }
export const FIELD_IN_USE = { type: 'custom_field', field: 'field_in_use' }
export const EVENT_CONTACT_INFOS = { type: 'custom_field', field: 'event_contact_infos' }
export const EVENT_START_DATE = { type: 'custom_field', field: 'event_start_date' }
export const EVENT_END_DATE = { type: 'custom_field', field: 'event_end_date' }
export const VISIBILITY_START_DATE = { type: 'custom_field', field: 'visibility_start_date' }
export const VISIBILITY_END_DATE = { type: 'custom_field', field: 'visibility_end_date' }
export const PROGRAM_LIST = { type: 'custom_field', field: 'program_list' }
export const SERVICE_PHONES = { type: 'custom_field', field: 'service_phones' }
export const SERVICE_URLS = { type: 'custom_field', field: 'service_urls' }
export const OPEN_FROM = { type: 'custom_field', field: 'open_from' }
export const OPEN_UNTIL = { type: 'custom_field', field: 'open_until' }
export const ACCESSIBLE_ENTRANCE = { type: 'custom_field', field: 'accessible_entrance' }
export const AUTOMATIC_DOOR = { type: 'custom_field', field: 'automatic_door' }
export const DOORSTEP = { type: 'custom_field', field: 'doorstep' }
export const INDOOR_SPORT_FACILITY_PROPERTIES = { type: 'custom_field', field: 'indoor_sport_facility_properties' }
export const INDOOR_SPORTS = { type: 'custom_field', field: 'indoor_sports' }
export const WORKOUT_STAIRS_EQUIPMENT = { type: 'custom_field', field: 'workout_stairs_equipment' }
export const WATER_POINT_TYPE = { type: 'custom_field', field: 'water_point_type' }
export const STORAGE_PAYMENT_METHODS = { type: 'custom_field', field: 'storage_payment_methods' }
export const UNOBSTRUCTED_PASSAGE = { type: 'custom_field', field: 'unobstructed_passage' }
export const PASSAGE_INSTRUCTIONS = { type: 'custom_field', field: 'passage_instructions' }

export const IS_FREE = {
  type: 'custom_field',
  field: 'is_free',
  label: 'paid',
  positiveOptionLabel: 'is_free',
  negativeOptionLabel: 'is_paid'
}

export const DESCRIPTION = { type: 'description' }
export const CONDITION_REPORT = {
  type: 'custom_field',
  field: 'condition_report',
  label: 'public_condition_report',
  hideLabel: true
}
export const BEACH_PROPERTIES = {
  type: 'custom_field',
  field: 'beach_properties',
  label: 'equipment'
}
export const BEACH_OTHER_SERVICES = {
  type: 'custom_field',
  field: 'beach_other_services',
  hideLabel: true
}
export const DISC_GOLF_OTHER_SERVICES = {
  type: 'custom_field',
  field: 'disc_golf_other_services',
  hideLabel: true
}
export const SPORT_FIELD_OTHER_SERVICES = {
  type: 'custom_field',
  field: 'sport_field_other_services',
  hideLabel: true
}
export const SWIMMING_HALL_OTHER_SERVICES = {
  type: 'custom_field',
  field: 'swimming_hall_other_services',
  hideLabel: true
}
export const OPEN_HOURS = {
  type: 'custom_field',
  field: 'open_hours',
  hideLabel: true
}
export const ATHLETICS_LIST = {
  type: 'custom_field',
  field: 'athletics',
  hideLabel: true
}
export const SWIMMING_HALL_EQUIPMENT = {
  type: 'custom_field',
  field: 'swimming_hall_equipment',
  label: 'pools'
}
export const SKATE_OBSTACLES = {
  type: 'custom_field',
  field: 'skate_obstacles',
  hideLabel: true
}
export const SKATE_EQUIPMENT_RENTAL = {
  type: 'custom_field',
  field: 'has_skate_equipment_rental',
  hideLabel: true
}
export const SAUNA_SERVICES = {
  type: 'custom_field',
  field: 'sauna_services',
  hideLabel: true
}
export const SAUNA_RULES = {
  type: 'custom_field',
  field: 'sauna_rules',
  hideLabel: true
}
export const NATURE_TRAIL_SERVICES = {
  type: 'custom_field',
  field: 'nature_trail_services',
  hideLabel: true
}
export const PLAYGROUND_EQUIPMENT = {
  type: 'custom_field',
  field: 'playground_equipment',
  hideLabel: true
}
export const MARINA_EQUIPMENT = {
  type: 'custom_field',
  field: 'marina_equipment',
  hideLabel: true
}
export const MULTIPURPOSE_FIELD_EQUIPMENT = {
  type: 'custom_field',
  field: 'multipurpose_field_equipment',
  hideLabel: true
}
export const VANTAGE_POINT_SERVICES = {
  type: 'custom_field',
  field: 'vantage_point_services',
  hideLabel: true
}
export const CAMPSITE_SERVICES = {
  type: 'custom_field',
  field: 'campsite_services',
  hideLabel: true
}
export const CAMPSITE_ACTIVITIES = {
  type: 'custom_field',
  field: 'campsite_activities',
  hideLabel: true
}
export const CAMPFIRE_SERVICES = {
  type: 'custom_field',
  field: 'campfire_services',
  hideLabel: true
}
export const CAMPFIRE_ACTIVITIES = {
  type: 'custom_field',
  field: 'campfire_activities',
  hideLabel: true
}
export const LEAN_TO_SERVICES = {
  type: 'custom_field',
  field: 'lean_to_services',
  hideLabel: true
}
export const LEAN_TO_ACTIVITIES = {
  type: 'custom_field',
  field: 'lean_to_activities',
  hideLabel: true
}

export const STORAGE_TIME = {
  type: 'custom_field',
  field: 'storage_time',
  style: { minWidth: '13rem' }
}
export const STREET_ADDRESS = {
  type: 'custom_field',
  field: 'street_address',
  style: { flex: 1, minWidth: '15rem', maxWidth: '28rem' },
  customType: { unlocalized: true }
}
export const POSTAL_CODE = {
  type: 'custom_field',
  field: 'postal_code',
  style: { flex: 1, maxWidth: '9rem' },
  customType: { unlocalized: true }
}
export const CITY = {
  type: 'custom_field',
  field: 'city',
  style: { flex: 1, minWidth: '10rem', maxWidth: '15rem' },
  customType: { unlocalized: true }
}
export const WWW = {
  type: 'custom_field',
  field: 'www',
  style: { flex: 1, maxWidth: '50%' }
}
export const EMAIL = {
  type: 'custom_field',
  field: 'email',
  style: { flex: 1, maxWidth: '35%' },
  customType: { unlocalized: true }
}
export const PHONE = {
  type: 'custom_field',
  field: 'phone',
  style: { flex: 1, maxWidth: '10rem' },
  customType: { unlocalized: true }
}
export const LAST_MAINTAINED = {
  type: 'custom_field',
  field: 'last_maintained',
  label: 'last_maintained',
  hideLabel: true,
  style: { maxWidth: '11.5rem' }
}
export const USE_START_DATE = {
  type: 'custom_field',
  field: 'use_start_date',
  hideLabel: true,
  style: { maxWidth: '11.5rem' }
}
export const USE_END_DATE = {
  type: 'custom_field',
  field: 'use_end_date',
  hideLabel: true,
  style: { maxWidth: '11.5rem' }
}
export const SALES_SEASON_START_DATE = {
  type: 'custom_field',
  field: 'sales_season_start_date',
  hideLabel: true,
  style: { maxWidth: '11.5rem' }
}
export const SALES_SEASON_END_DATE = {
  type: 'custom_field',
  field: 'sales_season_end_date',
  hideLabel: true,
  style: { maxWidth: '11.5rem' }
}
export const LOWEST_TICKET_PRICE = {
  type: 'custom_field',
  field: 'lowest_ticket_price',
  hideLabel: true,
  customType: { type: 'quantity', unit: '€' }
}
export const HIGHEST_TICKET_PRICE = {
  type: 'custom_field',
  field: 'highest_ticket_price',
  hideLabel: true,
  customType: { type: 'quantity', unit: '€' }
}
export const TOTAL_LENGTH_IN_KM = {
  type: 'custom_field',
  field: 'length',
  label: 'total_length',
  customType: { type: 'quantity', unit: 'km' }
}
export const TOTAL_LENGTH_IN_M = {
  type: 'custom_field',
  field: 'length',
  customType: { type: 'quantity', unit: 'm' }
}
export const AIR_TEMPERATURE = {
  type: 'custom_field',
  field: 'air_temperature',
  customType: { type: 'quantity', unit: 'ºC' },
  info: i18n.t('automatic_integration_info')
}
export const WATER_TEMPERATURE = {
  type: 'custom_field',
  field: 'water_temperature',
  customType: { type: 'quantity', unit: 'ºC' },
  info: i18n.t('automatic_integration_info')
}
export const BEACH_LINE = {
  type: 'custom_field',
  field: 'beach_line',
  customType: { type: 'quantity', unit: 'm' }
}
export const EQUIPMENT_COUNT = {
  type: 'custom_field',
  field: 'equipment_count',
  customType: { type: 'quantity', unitFi: 'kpl', unitEn: 'pcs' }
}
export const AREA = {
  type: 'custom_field',
  field: 'area',
  customType: { type: 'quantity', unit: 'km²' }
}
export const BERTHS = {
  type: 'custom_field',
  field: 'berths',
  customType: { type: 'quantity', unitFi: 'kpl', unitEn: 'pcs' }
}
export const GUEST_BERTHS = {
  type: 'custom_field',
  field: 'guest_berths',
  customType: { type: 'quantity', unitFi: 'kpl', unitEn: 'pcs' }
}
export const DOORWAY_WIDTH = {
  type: 'custom_field',
  field: 'doorway_width',
  customType: { type: 'quantity', unitFi: 'cm', unitEn: 'cm' }
}
export const DOORSTEP_HEIGHT = {
  type: 'custom_field',
  field: 'doorstep_height',
  customType: { type: 'quantity', unitFi: 'cm', unitEn: 'cm' }
}
export const STAIR_LENGTH = {
  type: 'custom_field',
  field: 'stair_length',
  customType: { type: 'quantity', unitFi: 'm', unitEn: 'm' }
}
export const STAIR_COUNT = {
  type: 'custom_field',
  field: 'stair_count',
  customType: { type: 'quantity', unitFi: 'kpl', unitEn: 'pcs' }
}
export const STORAGE_PRICE = {
  type: 'custom_field',
  field: 'storage_price',
  customType: { type: 'quantity', unit: '€' }
}
export const COURSE_CLASSIFICATION = {
  type: 'custom_field',
  field: 'course_classification',
  style: { flex: 1, maxWidth: '9rem' }
}
export const NUMBER_OF_BASKETS = {
  type: 'custom_field',
  field: 'num_baskets',
  style: { flex: 1, maxWidth: '9rem' },
  customType: { unlocalized: true }
}
export const COURSE_TYPE = {
  type: 'custom_field',
  field: 'course_type',
  style: { flex: 1, maxWidth: '20rem' }
}
export const DISC_GOLF_BASKET = {
  type: 'custom_field',
  field: 'disc_golf_basket',
  style: { flex: 1, maxWidth: '20rem' }
}
export const DISC_GOLF_TEE_AREAS = {
  type: 'custom_field',
  field: 'disc_golf_tee_areas',
  style: { flex: 1, maxWidth: '20rem' }
}
export const SKATE_TYPE = {
  type: 'custom_field',
  field: 'skate_style',
  style: { flex: 1 }
}
export const SKATE_SURFACE = {
  type: 'custom_field',
  field: 'skate_surface',
  style: { flex: 1 }
}
export const RAIN_COVER = {
  type: 'custom_field',
  field: 'rain_cover',
  style: { flex: 1 }
}
export const PARKING_ZONE_COLOR_CODE = {
  type: 'custom_field',
  field: 'parking_zone_color',
  style: { flex: 1, maxWidth: '15rem' }
}
export const PAR = {
  type: 'custom_field',
  field: 'fairway_par',
  style: { flex: 1, maxWidth: '5rem' }
}
export const PLACE_NAME = {
  type: 'custom_field',
  field: 'place_name'
}
export const AGE_RESTRICTIONS = {
  type: 'custom_field',
  field: 'age_restriction',
  customType: 'checkbox'
}
export const WC_GENDER_TYPE = {
  type: 'custom_field',
  field: 'wc_gender_type',
  customType: 'checkbox'
}
export const STORAGE_FEE = {
  type: 'custom_field',
  field: 'storage_fee',
  customType: 'checkbox'
}
