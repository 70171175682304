import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import { IconButton, Tooltip } from '@mui/material'
import linkifyHtml from 'linkify-html'
import { decode } from 'html-entities'
import * as striptags from 'striptags'
import get from 'lodash/get'
import flatten from 'lodash/flatten'
import Input from '../Common/Input'
import Select from '../Common/Select'
import Button from '../Common/Button'
import LargeImageUpload from '../Common/LargeImageUpload'
import CustomField from './CustomField'
import MaintenanceLogTable from '../MaintenanceLogTable/MaintenanceLogTable'
import GpxRecordingTable from '../GpxRecordingTable/GpxRecordingTable'
import { FormTabs, MaintenanceLogModal, GpxRecordingModal } from '../../Components'
import { CUSTOM_FIELD_CUSTOM_LAYOUTS, getCustomFieldCustomLayoutStyles } from '../CustomFieldCustomLayout/customFieldCustomLayout'
import { HtmlInput } from '..'
import CustomBooleanInput from './CustomBooleanInput'
import { EntityTypesWithMaintenanceLog, LANG_OPTIONS, MainEntityTypes } from '../../Constants'
import { getMissingLangFieldCounts } from '../../Utils/entity'
import { Colors } from '../../Utils/theme'
import MainEntityNotifications from '../MainEntityNotification/MainEntityNotifications'
import InfoIcon from '../../Assets/Icons/red-alert.svg'
import AddIcon from '../../Assets/Icons/add-dark.svg'

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    flexDirection: 'column',
    display: 'flex',
    overflowY: 'auto',
    padding: '1rem 2.5rem'
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  fullContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    width: '100%',
    maxWidth: '48rem',
    alignSelf: 'center'
  },
  content: {
    flexDirection: 'column',
    flexGrow: 1,
    paddingBottom: '.5rem'
  },
  infoContainer: {
    marginLeft: '.5rem'
  },
  infoIcon: {
    width: '100%',
    maxWidth: '1.75rem'
  },
  flexSpacer: {
    flex: 1,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  spacer: {
    width: '3rem',
    height: '1rem',
    [theme.breakpoints.down('lg')]: {
      height: 0
    }
  },
  footerContainer: {
    height: '5.5rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0rem 2.5rem',
    borderTop: `1px solid ${Colors.black20}`
  },
  fullFooterContainer: {
    height: '5.5rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderTop: `1px solid ${Colors.black20}`
  },
  footerContentContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: 1
  },
  fullFooterContentContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    maxWidth: '48rem',
    alignSelf: 'center'
  },
  cancelButton: {
    width: '7rem',
    height: '3.125rem',
    border: 'none',
    margin: '0rem 0.75rem',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: Colors.violet10
    }
  },
  saveButton: {
    width: '9rem',
    height: '3.125rem'
  },
  gridContainer: {
    display: 'block'
  },
  title: {
    fontSize: '1rem',
    fontWeight: 500,
    marginBottom: '.5rem',
    color: Colors.label
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  entityTitleColumn: {
    flex: 1,
    paddingRight: '1.5rem'
  },
  feedbackColumn: {
    display: 'flex'
  },
  entityCodeColumn: {
    flex: 1,
    paddingRight: '.75rem'
  },
  entityTypeColumn: {
    flex: 1,
    maxWidth: '18rem',
    paddingLeft: '.75rem'
  },
  tooltip: {
    backgroundColor: Colors.black,
    padding: '0.5rem 0.5rem',
    textAlign: 'center'
  },
  tooltipArrow: {
    color: Colors.black
  },
  addMaintenanceLogContainer: {
    paddingTop: '2rem'
  },
  addMaintenanceLogButton: {
    height: '3.125rem',
    width: '13.5rem',
    border: `1px solid ${Colors.violet}`,
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: Colors.violet10
    }
  },
  addMaintenanceLogButtonText: {
    color: Colors.violet,
    fontSize: '1rem',
    fontWeight: 400
  },
  input: {
    width: '100%'
  },
  ...(getCustomFieldCustomLayoutStyles(theme) as any)
}))

const styles = {
  langContainer: {
    marginBottom: '0 !important'
  },
  langSelect: {
    width: '4.125rem'
  }
}

const TABS = {
  basicInfo: 0,
  description: 1,
  notifications: 2,
  maintenanceLogs: 3,
  additionalInfo: 4,
  gpxRecordings: 5
}

const NOTIFICATIONS_ENABLED_TYPES: string[] = [
  MainEntityTypes.MusicEvent,
  MainEntityTypes.SportEvent,
  MainEntityTypes.ThemeEvent,
  MainEntityTypes.CultureEvent
]

function EditEntityForm (props) {
  const classes: any = useStyles()

  // List custom fields that are handled with custom layouts
  const { t } = useTranslation()

  const [tab, setTab] = useState(0)
  const [lang, setLang] = useState('fi')

  const [photo, setPhoto] = useState(props.mainEntity.photo)
  const [nameFi, setNameFi] = useState(props.mainEntity.nameFi || props.mainEntity.name) // TODO:remove fallback
  const [nameEn, setNameEn] = useState(props.mainEntity.nameEn || props.mainEntity.name) // TODO:remove fallback
  const [code, setCode] = useState(props.mainEntity.code || '')
  const [descriptionFi, setDescriptionFi] = useState(props.mainEntity.descriptionHtmlFi)
  const [descriptionEn, setDescriptionEn] = useState(props.mainEntity.descriptionHtmlEn)
  const [type, setType] = useState(props.mainEntity.mainEntityTypeId)
  const [feedbackEnabled, setFeedbackEnabled] = useState(props.mainEntity.feedbackEnabled)
  const [customFields, setCustomFields] = useState([])

  const [showMaintenanceLogModal, setShowMaintenanceLogModal] = useState(false)
  const [maintenanceLog, setMaintenanceLog] = useState(null)

  const [gpxRecordingModalVisible, setGpxRecordingModalVisible] = useState(false)
  const [gpxRecording, setGpxRecording] = useState(null)

  const toggleFeedbackEnabled = () => setFeedbackEnabled(!feedbackEnabled)

  const handleSetCode = (value) => setCode(value.replace(/\s+/g, '').toUpperCase())

  const getName = () => lang === 'en' ? nameEn : nameFi
  const handleSetName = (name) => {
    lang === 'en' ? setNameEn(name) : setNameFi(name)
  }

  const getDescription = () => lang === 'en' ? descriptionEn : descriptionFi
  const handleSetDescription = (name) => {
    lang === 'en' ? setDescriptionEn(name) : setDescriptionFi(name)
  }

  const handleAddMaintenanceLog = () => {
    setShowMaintenanceLogModal(true)
  }

  const closeMaintenanceLogModal = () => {
    setShowMaintenanceLogModal(false)
    setMaintenanceLog(null)
  }

  const editMaintenanceLog = (log) => {
    setMaintenanceLog(log)
    setShowMaintenanceLogModal(true)
  }

  const deleteMaintenaceLog = (id) => {
    props.deleteMaintenanceLog(id)
  }

  const saveMaintenanceLog = (log: any, id?: number) => {
    if (maintenanceLog) {
      props.updateMaintenanceLog(id, log)
    } else {
      props.createMaintenanceLog({
        mainEntityId: props?.mainEntity?.id,
        ...log
      })
    }
    closeMaintenanceLogModal()
  }

  const editGpxRecording = (rec) => {
    setGpxRecording(rec)
    setGpxRecordingModalVisible(true)
  }

  const saveGpxRecording = (id, rec) => {
    props.updateGpxRecording(id, rec)
    setGpxRecordingModalVisible(false)
  }

  useEffect(() => {
    // Populate custom fields
    const newCustomFields = []
    if (props.mainEntity && props.mainEntity.fieldValues) {
      for (const val of props.mainEntity.fieldValues) {
        newCustomFields.push({ id: val.mainEntityFieldId, value: val.value })
      }
      setCustomFields(newCustomFields)
    }

    // Load main entity notifications
    props.getMainEntityNotifications()
  }, [])

  useEffect(() => {
    if (tab === TABS.maintenanceLogs) {
      props.getMaintenanceLogs()
    }
  }, [tab])

  const handleSave = () => {
    let newDescriptionHtmlFi = null
    let newDescriptionFi = null

    if (descriptionFi) {
      newDescriptionHtmlFi = linkifyHtml(descriptionFi)
      newDescriptionFi = decode(striptags(newDescriptionHtmlFi, [], '\n').trim()).trim()
      if (!newDescriptionFi) newDescriptionHtmlFi = null
    }

    let newDescriptionHtmlEn = null
    let newDescriptionEn = null

    if (descriptionEn) {
      newDescriptionHtmlEn = linkifyHtml(descriptionEn)
      newDescriptionEn = decode(striptags(newDescriptionHtmlEn, [], '\n').trim()).trim()
      if (!newDescriptionEn) newDescriptionHtmlEn = null
    }

    const fields = {
      name: nameFi, // TODO: remove
      nameFi,
      nameEn,
      code,
      feedbackEnabled,
      description: newDescriptionFi, // TODO:remove
      descriptionHtml: newDescriptionHtmlFi, // TODO:remove
      descriptionFi: newDescriptionFi,
      descriptionHtmlFi: newDescriptionHtmlFi,
      descriptionEn: newDescriptionEn,
      descriptionHtmlEn: newDescriptionHtmlEn,
      mainEntityTypeId: type,
      // Filter disabled values away
      fields: customFields
        .filter((cf) => !getCustomFieldDisabled(cf.id))
        .map((cf) => {
          // id 25 = open_hours
          if (cf.id === 25 && cf.value?.length === 8) {
            return {  ...cf, value: cf.value.slice(0, 7) }
          } else if (cf.value?.length === 1) {
            if (cf.value[0]?.openHours) {
              return {
                ...cf,
                value: [
                  { ...cf.value[0], openHours: cf.value[0].openHours.slice(0, 7) }
                ]
              }
            }
          }
          return cf
        })
    }
    props.onSave(fields)
  }

  const renderMissingTranslationsInfo = () => {
    const entityData = {
      nameFi,
      nameEn,
      descriptionFi,
      descriptionEn,
      fields: customFields
    }
    const missingCounts = getMissingLangFieldCounts(entityData)

    // No missing translations
    if (!missingCounts.fi && !missingCounts.en) return null

    let infoText = t('missing_fi_and_en_translations')
    if (missingCounts.fi && !missingCounts.en) {
      infoText = t('missing_fi_translations')
    } else if (!missingCounts.fi && missingCounts.en) {
      infoText = t('missing_en_translations')
    }

    return (
      <Tooltip
        classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
        placement='top'
        title={infoText}
        arrow
      >
        <IconButton className={classes.infoContainer}>
          <img src={InfoIcon} className={classes.infoIcon} />
        </IconButton>
      </Tooltip>
    )
  }

  const getTypeOptions = () => {
    return props.mainEntityTypes.map(item => {
      return ({ value: item.id, label: item.name })
    })
  }

  const getCustomFieldValue = (fieldId) => {
    const match = customFields.find(item => {
      return (item.id === fieldId)
    })
    if (match) return match.value || null
    return null
  }

  const getCustomFieldDisabled = (fieldId) => {
    const match = props.mainEntity.fieldValues.find(({ mainEntityFieldId }) => mainEntityFieldId === fieldId)
    if (match) return match.disabled || false
    return false
  }

  const setCustomFieldValue = (fieldId, value) => {
    // Find existing
    let existing = false
    const newCustomFields = customFields.map(item => {
      if (item.id === fieldId) {
        existing = true
        return { ...item, value }
      }
      return item
    })
    if (!existing) {
      // Add new
      newCustomFields.push({ id: fieldId, value })
    }
    setCustomFields(newCustomFields)
  }

  const updateEntityPhoto = (files) => {
    if (files && files.length) {
      const file = files[0]
      setPhoto(URL.createObjectURL(file))
      props.updatePhoto(file)
    }
  }

  const removeEntityPhoto = () => {
    setPhoto(null)
    props.removePhoto()
  }

  const isEditDisabled = () => {
    if (!type) {
      return true
    }
    return false
  }

  const getAdditionalCustomFields = (type, mainEntityFields) => {
    const fields = getCustomLayoutFields(type)
    return mainEntityFields.filter(mef => !fields.includes(mef.fieldKey))
  }

  const renderCustomLayout = (block, mainEntityFields) => {
    if (Array.isArray(block)) {
      return block.map((b) => renderCustomLayout(b, mainEntityFields))
    }

    // Check if we should hide this block
    if (block && block.hiddenCheck) {
      if (block.hiddenCheck(mainEntityFields, customFields)) return null
    }

    if (block.type === 'container') {
      return (
        <div className={block.className ? classes[block.className] : ''} style={block.style || {}}>
          {renderCustomLayout(block.items, mainEntityFields)}
        </div>
      )
    }
    if (block.type === 'title') {
      return (
        <div className={block.className ? classes[block.className] : ''} style={block.style}>
          <div className={classes.customTitle}>
            {t(block.name)}
          </div>
        </div>
      )
    }
    if (block.type === 'big_title') {
      return (
        <div className={block.className ? classes[block.className] : ''} style={block.style}>
          <div className={classes.customBigTitle}>
            {t(block.name)}
          </div>
          {block?.info ? <p className={classes.customBlockInfo}>{t(block.info)}</p> : null}
        </div>
      )
    }
    if (block.type === 'description') {
      return (
        <div>
          <div className={classes.title}>{t('description_text')}</div>
          <HtmlInput
            key={lang}
            placeholder={t('description')}
            value={getDescription()}
            onChange={handleSetDescription}
          />
        </div>
      )
    }
    if (block.type === 'custom_field') {
      const customField = mainEntityFields.find((field) => field.fieldKey === block.field)
      return (
        <div style={block.style}>
          {customField ? renderCustomField({
            ...customField,
            labelFi: block.label ? t(block.label) : !block.hideLabel ? customField.labelFi : null,
            customType: block.customType ?? null,
            info: block.info ?? null,
            positiveOptionLabel: block.positiveOptionLabel ?? null,
            negativeOptionLabel: block.negativeOptionLabel ?? null
          }) : null}
        </div>
      )
    }
    return null
  }

  const getCustomLayoutFields = (type) => {
    const extractCustomlayoutFields = (block) => {
      let fields = []
      if (Array.isArray(block)) {
        const newFields = block.map((b) => extractCustomlayoutFields(b))
        fields = [...fields, ...newFields]
      }
      if (block.type === 'container') {
        const newFields = extractCustomlayoutFields(block.items)
        fields = [...fields, ...newFields]
      }
      if (block.type === 'custom_field') {
        return [block.field]
      }
      return flatten(fields).filter(v => !!v)
    }

    if (type) {
      const customLayout: any = CUSTOM_FIELD_CUSTOM_LAYOUTS.find(layout => layout.type === type)
      if (customLayout) {
        return extractCustomlayoutFields(customLayout.layout)
      }
    }
    return []
  }

  const renderCustomLayoutCustomFields = (column) => {
    const selectedType = props.mainEntityTypes.find((item) => {
      if (type === item.id) return true
      return false
    })

    if (selectedType) {
      const customLayout: any = CUSTOM_FIELD_CUSTOM_LAYOUTS.find(layout => layout.type === selectedType.type)
      if (customLayout && customLayout.layout[column]) {
        return renderCustomLayout(customLayout.layout[column], selectedType.mainEntityFields)
      }
    }
    return null
  }

  const renderBasicInfo = () => {
    return (
      <div className={classes.gridContainer}>
        <LargeImageUpload
          photo={photo}
          onFileUpload={updateEntityPhoto}
          removePhoto={removeEntityPhoto}
          compact
        />
        <div className={classes.rowContainer}>
          <div className={classes.entityTitleColumn}>
            <div className={classes.title}>{t('entity_name')}</div>
            <Input
              placeholder={t('entity_name')}
              value={getName()}
              onChange={handleSetName}
              className={classes.input}
            />
          </div>
          <div className={classes.feedbackColumn}>
            <CustomBooleanInput
              label={t('feedbacks_enabled')}
              value={feedbackEnabled}
              onChange={toggleFeedbackEnabled}
            />
          </div>
        </div>
        <div className={classes.rowContainer}>
          <div className={classes.entityCodeColumn}>
            <div className={classes.title}>{t('entity_code')}</div>
            <Input
              placeholder={t('entity_code')}
              value={code}
              onChange={handleSetCode}
              className={classes.input}
            />
          </div>
          <div className={classes.entityTypeColumn}>
            <div className={classes.title}>{t('entity_type')}</div>
            <Select
              options={getTypeOptions()}
              value={type}
              onChange={setType}
              disabled
            />
          </div>
        </div>
        {renderCustomLayoutCustomFields(0)}
        {renderCustomLayoutCustomFields(1)}
      </div>
    )
  }

  const renderCustomField = (field) => {
    return (
      <CustomField
        value={getCustomFieldValue(field.id)}
        disabled={getCustomFieldDisabled(field.id)}
        onChange={(value) => setCustomFieldValue(field.id, value)}
        field={field}
        lang={lang}
      />
    )
  }

  const renderMaintenanceLogModal = () => {
    if (showMaintenanceLogModal) {
      return (
        <MaintenanceLogModal
          log={maintenanceLog}
          subentities={props.mainEntity?.subEntities ?? []}
          handleSave={saveMaintenanceLog}
          handleClose={closeMaintenanceLogModal}
        />
      )
    }
    return null
  }

  const renderMaintenanceLogs = () => {
    return (
      <>
        <MaintenanceLogTable
          logs={props.maintenanceLogs}
          editLog={editMaintenanceLog}
          deleteLog={deleteMaintenaceLog}
        />
        <div className={classes.addMaintenanceLogContainer}>
          <Button
            onClick={handleAddMaintenanceLog}
            text={t('add_maintenance_log')}
            buttonStyle={classes.addMaintenanceLogButton}
            buttonTextStyle={classes.addMaintenanceLogButtonText}
            rightIcon={AddIcon}
            outlined
          />
        </div>
        {renderMaintenanceLogModal()}
      </>
    )
  }

  const renderGpxRecordingModal = () => {
    if (gpxRecordingModalVisible) {
      return (
        <GpxRecordingModal
          item={gpxRecording}
          handleSave={saveGpxRecording}
          handleClose={() => setGpxRecordingModalVisible(false)}
        />
      )
    }
    return null
  }

  const renderGpxRecordingsTable = () => {
    // Display only main entity items
    const filteredItems = props.mainEntityGpxRecordings.filter(item => {
      return (item.mainEntityId === props.mainEntity.id && !item.subEntityId)
    })

    return (
      <>
        <GpxRecordingTable
          items={filteredItems}
          onReplace={props.addOrReplaceFeature}
          exportGpxRecording={props.exportGpxRecording}
          onDelete={props.deleteGpxRecording}
          onEdit={editGpxRecording}
        />
        {renderGpxRecordingModal()}
      </>
    )
  }

  const renderNotifications = () => {
    return (
      <MainEntityNotifications
        mainEntityNotifications={props.mainEntityNotifications}
        mainEntityNotification={props.mainEntityNotification}
        getMainEntityNotifications={props.getMainEntityNotifications}
        getMainEntityNotification={props.getMainEntityNotification}
        createMainEntityNotification={props.createMainEntityNotification}
        updateMainEntityNotification={props.updateMainEntityNotification}
        deleteMainEntityNotification={props.deleteMainEntityNotification}
      />
    )
  }

  const renderAdditionalInfo = () => {
    const selectedType = props.mainEntityTypes.find(item => {
      if (type === item.id) {
        return true
      }
      return false
    })

    if (selectedType) {
      const fields = getAdditionalCustomFields(selectedType.type, selectedType.mainEntityFields)

      return fields.map((field, index) => {
        return (
          <div key={index}>
            {renderCustomField(field)}
          </div>
        )
      })
    }

    return null
  }

  const notificationsEnabled = () => {
    const mainEntityTypes = (props.mainEntityTypes || [])
    if (type) {
      const matchType = mainEntityTypes.find(t => t.id === type)
      if (matchType && NOTIFICATIONS_ENABLED_TYPES.includes(matchType.type)) {
        return true
      }
    }
    return false
  }

  const renderTabs = () => {
    const tabs = [
      { name: t('basic_info'), value: TABS.basicInfo }
    ]

    // Display notifications tab only for major events
    if (notificationsEnabled()) {
      tabs.push({ name: t('notifications'), value: TABS.notifications })
    }

    // Check if we have custom fields
    const selectedType = props.mainEntityTypes.find((item) => {
      if (type === item.id) return true
      return false
    })

    if (selectedType && EntityTypesWithMaintenanceLog.includes(selectedType?.type)) {
      tabs.push({ name: t('maintenance_logs'), value: TABS.maintenanceLogs })
    }

    if (
      selectedType &&
      selectedType.mainEntityFields.length &&
      getAdditionalCustomFields(selectedType.type, selectedType.mainEntityFields).length
    ) {
      tabs.push({ name: t('additional_info'), value: TABS.additionalInfo })
    }

    if (props?.mainEntityGpxRecordings?.find(rec => !rec.subEntityId && rec.mainEntityId === props.mainEntity?.id)) {
      tabs.push({ name: t('gpx_recordings'), value: TABS.gpxRecordings })
    }

    return <FormTabs value={tab} onChange={setTab} tabs={tabs} />
  }

  const renderContent = () => {
    if (tab === TABS.basicInfo) return renderBasicInfo()
    if (tab === TABS.notifications) return renderNotifications()
    if (tab === TABS.maintenanceLogs) return renderMaintenanceLogs()
    if (tab === TABS.additionalInfo) return renderAdditionalInfo()
    if (tab === TABS.gpxRecordings) return renderGpxRecordingsTable()
  }

  return (
    <>
      <div className={classes.container}>
        <div className={props.fullWidth ? classes.fullContentContainer : classes.contentContainer}>
          {renderTabs()}
          <div className={classes.content}>
            {renderContent()}
          </div>
        </div>
      </div>
      <div className={props.fullWidth ? classes.fullFooterContainer : classes.footerContainer}>
        <div className={props.fullWidth ? classes.fullFooterContentContainer : classes.footerContentContainer}>
          <div>
            <Select
              options={LANG_OPTIONS}
              value={lang}
              onChange={setLang}
              containerStyle={styles.langContainer}
              style={styles.langSelect}
            />
          </div>
          {renderMissingTranslationsInfo()}
          <div className={classes.flexSpacer} />
          <Button
            onClick={props.onCancel}
            text={t('cancel')}
            buttonStyle={classes.cancelButton}
            outlined
          />
          <Button
            onClick={handleSave}
            text={t('save')}
            disabled={isEditDisabled()}
            buttonStyle={classes.saveButton}
          />
        </div>
      </div>
    </>
  )
}

export default EditEntityForm
